import gql from 'graphql-tag'

const RESET_PASSWORD_REQUEST = gql`
  mutation requestResetPassword($email: String!, $marketplaceKey: String!) {
    requestResetPassword(email: $email, marketplaceKey: $marketplaceKey) {
      customer {
        id
        email
      }
      token
    }
  }
`

export default RESET_PASSWORD_REQUEST
