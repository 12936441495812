import fontStyles from '@styles/sharedJSS/fonts'
import Color from 'color'

const styles = theme => ({
  container: {
    padding: '2.4rem',

    '@media screen and (min-width: 768px)': {
      padding: '4.8rem',
    },
  },

  title: {
    ...fontStyles.h2,
    color: theme.bc3,
    marginBottom: '2.4rem',

    '@media screen and (min-width: 768px)': {
      marginBottom: '3.6rem',
    },
  },

  formContainer: {
    position: 'relative',

    '@media screen and (min-width: 768px)': {
      padding: '2.4rem',
      borderRadius: '0.4rem',
      border: `1px solid ${Color(theme.bc5).rgb().alpha(0.1).string()}`,
    },
  },

  form: {
    position: 'relative',
  },

  field: {
    display: 'flex',
    flexFlow: 'column',
    marginBottom: '2.4rem',

    '@media screen and (min-width: 768px)': {
      flexFlow: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    '&:last-child': {
      marginBottom: 0,
    },
  },

  label: {
    ...fontStyles.small,
    color: theme.bc5,
    marginBottom: '1.2rem',

    '@media screen and (min-width: 768px)': {
      marginBottom: 0,
    },
  },

  input: {
    ...fontStyles.small,
    backgroundColor: 'rgba(000,000,000,0.05)',
    boxShadow: 'none',
    padding: '0 1.2rem',
    border: 'none',
    width: '100%',
    height: '4.5rem',
    borderRadius: '4px',
    color: theme.bc5,

    '@media screen and (min-width: 768px)': {
      maxWidth: '30rem',
    },

    '&:focus': {
      outline: 'none',
    },
  },

  saveFormButton: {
    ...fontStyles.small,
    transition: 'all .25s ease-in-out',
    display: 'inline-block',
    padding: '1.2rem 2.4rem',
    backgroundColor: theme.bc1,
    borderRadius: '53px',
    border: 'none',
    color: 'white',
    fontWeight: 600,
    cursor: 'pointer',
    marginTop: '1.2rem',

    '@media screen and (min-width: 768px)': {
      marginTop: '2.4rem',
    },

    '&:hover': {
      backgroundColor: theme.bc2,
    },

    '&:focus': {
      outline: 'none',
    },
  },

  phoneNumberInputwrapper: {
    '@media screen and (min-width: 768px)': {
      maxWidth: '30rem',
      width: '100%',
    },
  },
})
export default styles
