import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './cardSectionStyles'
import _ from 'lodash'
import { ReactSVG } from 'react-svg'
import removeSVG from '@images/large-delete.svg'
import AddCardPopover from './AddCardPopover/AddCardPopover'
import Modal from '@components/ModalWrapper/ModalWrapper'
import { Elements, StripeProvider } from 'react-stripe-elements'
import { getMarketplace } from '@config/config'
import { client } from '@services/client'
import { GET_CARDS, DELETE_CARD } from './queries/userCards.query'
import { Query, Mutation } from 'react-apollo'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import { toast } from 'react-toastify'

class CardSectionEl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addCardModalOpen: false,
      card: [],
    }

    this.customFonts = [
      {
        cssSrc:
          'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600',
      },
    ]
  }

  cardRowClick = (e, card, deleteCardMutation, refetch) => {
    e.preventDefault()
    if (confirm('Are you sure you want to remove this card?')) {
      const deleteCard = deleteCardMutation({ variables: { cardId: card.id } })
      deleteCard.then(data => {
        toast.success('Card Removed', {
          position: toast.POSITION.TOP_CENTER,
        })
        refetch()
      })
    }
  }

  toggleInnerModal = show => {
    this.setState({
      showOptionsModalInner: show,
    })
  }

  componentDidMount() {
    const { stripePublicId } = getMarketplace()
    this.setState({
      stripePublicId: stripePublicId,
    })
  }

  renderCardRow(card, refetch) {
    const { classes } = this.props
    return (
      <Mutation mutation={DELETE_CARD}>
        {(deleteCard, { data, loading, error }) => {
          return (
            <div className={classes.fieldRow}>
              <LoadingSpinner show={loading} />
              <a className={classes.clickableRow}>
                <span className={classes.label}>
                  **** **** **** {card.last4}
                </span>
                <span
                  className={classes.rowAction}
                  onClick={e => this.cardRowClick(e, card, deleteCard, refetch)}
                >
                  <ReactSVG
                    src={removeSVG}
                    className={classes.rowActionIcon}
                    wrapper="span"
                  />
                </span>
              </a>
            </div>
          )
        }}
      </Mutation>
    )
  }

  openNewCardModal = e => {
    e.preventDefault()
    this.setState({
      addCardModalOpen: true,
    })
  }
  closeModal = () => {
    this.setState({
      addCardModalOpen: false,
    })
  }

  cardAdded = refetch => {
    this.setState({
      addCardModalOpen: false,
    })
    toast.success('Card Added', {
      position: toast.POSITION.TOP_CENTER,
    })
    refetch()
  }

  renderAddBtn = refetch => {
    const { classes } = this.props
    const { addCardModalOpen, stripePublicId } = this.state

    return (
      <>
        <div className={classes.fieldRow}>
          <span className={classes.emptyNotice}>
            You havent added any cards yet.
          </span>
        </div>
        <div className={classes.fieldRow}>
          <div className={classes.addNewRow}>
            <a
              className={classes.addNewBtn}
              href="#_"
              onClick={this.openNewCardModal}
            >
              + Add New Card
            </a>
          </div>
          <Modal open={addCardModalOpen} close={this.closeModal}>
            <StripeProvider apiKey={stripePublicId}>
              <Elements fonts={this.customFonts}>
                <AddCardPopover
                  close={this.closeModal}
                  cardAdded={card => this.cardAdded(refetch)}
                />
              </Elements>
            </StripeProvider>
          </Modal>
        </div>
      </>
    )
  }

  render() {
    return (
      <Query query={GET_CARDS} fetchPolicy="network-only">
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return <LoadingSpinner show={true} />
          }
          const card = _.get(data, 'cards.cards[0]', { id: null, last4: null })
          this.props.setCardToken(card.id)
          return !_.isEmpty(card) && card.last4
            ? this.renderCardRow(card, refetch)
            : this.renderAddBtn(refetch)
        }}
      </Query>
    )
  }
}

const CardSection = injectSheet(styles)(CardSectionEl)
export default CardSection
