import gql from 'graphql-tag'

const GET_MARKETING_PREFERENCES = gql`
  query getMarketingPreferences {
    myDetails {
      customer {
        id
        optOutNewRestaurantMarketing
        optOutMenuMarketing
        optOutPromotionMarketing
        optOutMarketingSMS
        optOutMarketingEmail
        optOutMarketingPush
      }
    }
  }
`

export default GET_MARKETING_PREFERENCES
