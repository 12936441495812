import React, { createContext, useState } from 'react'
import ls from '@utils/localStorage'
import moment from 'moment'

export const VoucherContext = createContext({
  discount: null,
  key: null,
  applyVoucherDiscount: _newState => {},
  removeDiscount: () => {},
})

export const VoucherContextProvider = ({ children }) => {
  const {
    discount: storedDiscount,
    key: storedKey,
    expiryTime,
  } = ls.get('voucherDiscount')

  // valid in the sense that's not older than 1h
  const isStoredDiscountValid =
    storedDiscount &&
    storedKey &&
    expiryTime &&
    moment(expiryTime).isAfter(moment(), 'minute')

  const [voucherDiscount, setVoucherDiscount] = useState({
    discount: isStoredDiscountValid ? storedDiscount : null,
    key: isStoredDiscountValid ? storedKey : null,
  })

  const applyVoucherDiscount = ({ discount, key }) => {
    ls.set('voucherDiscount', {
      discount,
      key,
      expiryTime: moment().add(1, 'h'),
    })
    setVoucherDiscount({ discount, key })
  }

  const removeDiscount = () => {
    ls.remove('voucherDiscount')
    setVoucherDiscount({
      discount: null,
      key: null,
    })
  }

  const { discount, key } = voucherDiscount
  return (
    <VoucherContext.Provider
      value={{
        discount,
        key,
        applyVoucherDiscount,
        removeDiscount,
      }}
    >
      {children}
    </VoucherContext.Provider>
  )
}
