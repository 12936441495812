import React from 'react'
import { ReactSVG } from 'react-svg'
import { object, shape, func, string, number, arrayOf } from 'prop-types'
import check from '@images/check-e.svg'
import { penceToPounds } from '@utils/helpers'
import _ from 'lodash'
const SingleOptionRow = ({
  classes,
  option,
  addOption,
  selectedOptions,
  removeOption,
}) => {
  const {
    optionItem,
    optionName,
    optionsSelect,
    checkIcon,
    price,
    soldOutOptionItemLozenge,
  } = classes
  const hasCost = option.price > 0

  const isSelected = _.find(selectedOptions, allOptions => {
    return allOptions.id === option.id
  })

  return (
    <li
      className={optionItem}
      onClick={() => {
        if (!option.soldOut)
          isSelected ? removeOption(option) : addOption(option)
      }}
    >
      <span className={optionName}>
        {option.name}
        {hasCost && (
          <span className={price}>+ £{penceToPounds(option.price)}</span>
        )}
        {option.soldOut ? (
          <span className={soldOutOptionItemLozenge}>Sold out</span>
        ) : null}
      </span>
      {isSelected && (
        <span className={optionsSelect}>
          <ReactSVG src={check} className={checkIcon} wrapper="span" />
        </span>
      )}
    </li>
  )
}

SingleOptionRow.propTypes = {
  classes: object,
  option: shape({
    id: string,
    name: string,
    price: number,
  }),
  addOption: func,
  selectedOptions: arrayOf(
    shape({
      id: string,
      name: string,
      price: number,
    })
  ),
  removeOption: func,
}
export default SingleOptionRow
