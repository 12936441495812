import gql from 'graphql-tag'

const SET_NEW_PASSWORD = gql`
  mutation resetPassword(
    $customerId: String!
    $token: String!
    $newPassword: String!
  ) {
    resetPassword(
      customerId: $customerId
      token: $token
      newPassword: $newPassword
    ) {
      customer {
        id
        email
      }
      message
      token
    }
  }
`

export default SET_NEW_PASSWORD
