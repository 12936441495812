import React, { createContext, useState } from 'react'

export const DiscountContext = createContext({
  appliedDiscountId: null,
  setAppliedDiscountId: _id => {},
})

export const DiscountContextProvider = ({ children }) => {
  const [appliedDiscountId, setAppliedDiscountId] = useState(null)

  return (
    <DiscountContext.Provider
      value={{
        appliedDiscountId,
        setAppliedDiscountId,
      }}
    >
      {children}
    </DiscountContext.Provider>
  )
}
