import gql from 'graphql-tag'

export const GET_REVIEW_FOR_ORDER = gql`
  query($orderId: ID) {
    myOrders(where: { id: $orderId }) {
      orders {
        orderNumber
        createdAt
        updatedAt
        grossTotal
        orderStatus
        outlet {
          name
        }
        review {
          id
          foodQuality
          restaurantService
          deliveryTime
          reviewText
          approved
          reply
          replyDate
        }
      }
    }
  }
`
