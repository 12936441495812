import React from 'react'
import { withStyles } from 'react-jss'
import { MEDIUM_MOBILE_BREAKPOINT } from '@utils/breakpoints'

const ServicesSuspended = ({ classes }) => (
  <div className={classes.container}>
    <div className={classes.inner}>
      <div className={classes.redbox} />
      <div>
        <h1 className={classes.title}>Redbox Services Suspended</h1>
        <p className={classes.subTitle}>
          If you are the owner of this website please contact your account
          manager.
        </p>
      </div>
    </div>
  </div>
)

const styles = {
  container: {
    height: '100vh',
    width: '100vw',
    maxHeight: '300px',
    maxWidth: '600px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inner: {
    maxWidth: '400px',
    position: 'relative',
    padding: '0 1rem',
  },
  redbox: {
    background: '#FF3B4C',
    width: '100%',
    maxWidth: '75px',
    borderRadius: '1rem',
    transform: 'rotate(45deg)',
    marginBottom: '2rem',

    [`@media screen and (min-width: ${MEDIUM_MOBILE_BREAKPOINT}px)`]: {
      position: 'absolute',
      right: '103%',
      bottom: '10%',
      marginBottom: '0',
    },

    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%',
    },
  },
  title: {
    fontSize: '2rem',
    opacity: 0.8,
    marginBottom: '0.5rem',
  },
  subTitle: {
    fontSize: '1.4rem',
    opacity: 0.6,
    lineHeight: '18px',
  },
}

export default withStyles(styles)(ServicesSuspended)
