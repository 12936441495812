import fontStyles from '@styles/sharedJSS/fonts'
import Color from 'color'
import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  LAPTOP_BREAKPOINT,
} from '@utils/breakpoints'

const sharedStyles = {
  display: 'flex',
  justifyContent: 'center',
}

const sharedFonts = theme => ({
  ...fontStyles.small,
  color: theme.bc5,
  textAlign: 'right',
})

const styles = theme => ({
  mainContainer: {
    padding: '2.4rem',

    [`@media screen and (min-width: ${TABLET_BREAKPOINT}px)`]: {
      padding: '4.8rem',
    },
  },

  title: {
    ...fontStyles.h3,
    color: theme.bc3,
    marginBottom: '3.6rem',
  },

  emptyText: {
    fontSize: '20px',
    color: '#a7a7a7',
  },

  restaurant: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    padding: '2.4rem',
    marginBottom: '2.4rem',
    border: `1px solid ${Color(theme.bc5)
      .rgb()
      .alpha(0.2)
      .string()}`,
    borderRadius: '4px',
    overflow: 'hidden',
    minHeight: '122px',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      padding: 0,
      flexFlow: 'row',
      alignItems: 'center',
      paddingRight: '2.4rem',
    },

    '&:hover': {
      cursor: 'pointer',

      '& $restaurantName': {
        color: theme.bc1,
      },
    },
  },

  logoJacket: {
    display: 'flex',
    flexShrink: 0,
    margin: '0 auto 2.4rem',
    width: '12rem',
    height: '12rem',
    overflow: 'hidden',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      flexFlow: 'row',
      margin: 0,
    },
  },

  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },

  restaurantDetails: {
    ...sharedStyles,
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      marginLeft: '2.4rem',
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },

  restaurantName: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: theme.bc3,
    marginBottom: '1.2rem',
    transition: 'all .25s ease-in-out',
  },

  dateOfOrder: {
    ...fontStyles.small,
    color: theme.bc5,
    opacity: 0.5,
  },

  orderDetails: {
    ...sharedStyles,
    flexFlow: 'row',
    alignItems: 'center',
    marginTop: '2.4rem',
    marginBottom: '2.4rem',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      marginRight: '3rem',
      marginTop: 0,
      marginBottom: 0,
    },

    [`@media screen and (min-width: ${LAPTOP_BREAKPOINT}px)`]: {
      marginRight: '6rem',
    },
  },

  orderDetailsTotal: {
    ...sharedFonts(theme),
    marginRight: '2.4rem',
    textAlign: 'center',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      marginRight: '3rem',
      textAlign: 'left',
    },
    [`@media screen and (min-width: ${LAPTOP_BREAKPOINT}px)`]: {
      marginRight: '6rem',
    },

    '& div': {
      marginBottom: '1.2rem',
    },

    '& main': {
      opacity: 0.5,
    },
  },

  orderDetailsItems: {
    ...sharedFonts(theme),
    textAlign: 'center',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      textAlign: 'left',
    },

    '& div': {
      marginBottom: '1.2rem',
    },

    '& main': {
      opacity: 0.5,
    },
  },

  orderStatus: {
    ...sharedStyles,
    alignItems: 'center',
  },

  status: {
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: 1,
    color: theme.bc1,
    padding: '1rem',
    textAlign: 'center',
    width: '12rem',
  },

  statusRejected: {
    color: theme.negative,
  },

  statusComplete: {
    color: `rgba(${theme.bc5}, 0.5)`,
  },

  statusSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  reOrder: {
    marginTop: '1.6rem',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      width: '100%',
    },
  },
})

export default styles
