import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  LAPTOP_BREAKPOINT,
} from '@utils/breakpoints'
import restaurantRowStyles from '@styles/sharedJSS/restaurantRow'
import fontStyles from '@styles/sharedJSS/fonts'
import Color from 'color'

const styles = theme => ({
  ...restaurantRowStyles(theme),

  status: {
    display: 'flex',

    '& > *:last-child': {
      marginLeft: '0.5rem',
    },

    [`@media screen and (max-width: ${MOBILE_BREAKPOINT}px)`]: {
      right: '14px',
      position: 'absolute',
      top: '-15px !important',
      zIndex: 10,
    },

    '@media print': {
      display: 'none',
    },
  },

  fulfilmentStatus: {
    height: '23px',
    [`@media screen and (min-width: ${TABLET_BREAKPOINT}px)`]: {
      display: 'none',
    },
  },

  menuView: {
    background: 'white',
    boxShadow: `0 0 2rem rgba(0, 0, 0, 0.1)`,
    position: 'relative',
    borderRadius: '10px',
    paddingBottom: '1.2rem',

    '@media screen and (min-width: 600px)': {
      marginTop: 0,
      paddingBottom: '2.4rem',
      borderRadius: '4px',
      marginBottom: '6rem',
    },

    '@media print': {
      marginTop: '-90px',
    },
  },

  restaurantListItem: {
    backgroundColor: 'white',
    lineHeight: '2.6rem',
    borderRadius: `0.4rem 0.4rem 0 0`,
    paddingBottom: 0,
    borderRadius: '10px',
    padding: '1.4rem',
  },

  restaurantIcon: {
    display: 'block',
    height: '8rem',
    width: '8rem',
    borderRadius: '4px',
    backgroundColor: '#f3f2f2',
    borderRadius: '5px',
    overflow: 'hidden',
    boxShadow: '0px 0px 2px #00000073',

    display: 'inline-block',
    margin: '0',
    marginRight: '1rem',
  },

  restaurantIconImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
  },

  restaurantTitleContainer: {
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    marginRight: '2.4rem',
    textAlign: 'left',
    width: 'auto',
    alignItems: 'start',

    '@media print': {
      marginBottom: '50px',
    },
  },

  restaurantTitle: {
    color: theme.bc5,
    fontSize: '2rem',
    fontWeight: 600,
  },

  restaurantOutlet: {
    ...fontStyles.small,
    color: theme.bc5,
  },

  cuisineslbl: {
    color: Color(theme.bc3)
      .rgb()
      .alpha(0.8)
      .string(),
    fontSize: '1.2rem',
    fontWeight: 600,
  },

  restaurantStats: {
    fontSize: '12px',
    fontWeight: 600,
    color: '#9e9e9e',
    textAlign: 'center',

    textAlign: 'left',

    '& > span': {
      display: 'inline-flex',
      alignItems: 'center',
      margin: '0 1rem 0 0',

      [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
        display: 'flex',
        margin: 0,
      },
    },
  },

  svgIcon: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '1',
    marginRight: '0.7rem',

    '& svg': {
      width: '1.4rem',
      height: '1.4rem',
      fill: '#9e9e9e',
    },
  },

  svgPriceIcon: {
    verticalAlign: 'middle',

    '& svg': {
      fill: theme.bc1,
      width: '1.5rem',
      height: '1.5rem',
      marginLeft: '0.8rem',
    },
  },

  tabContainer: {
    borderBottom: `2px solid #efefef`,
  },

  tabView: {
    backgroundColor: 'white',
  },

  tabViewItems: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& li': {
      width: '50%',
      textAlign: 'center',
    },
  },

  tabViewLink: {
    color: Color(theme.bc3)
      .rgb()
      .alpha(0.5)
      .string(),
    padding: '2rem 1.8rem 1.2rem',
    display: 'block',
    fontSize: '1.2rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    cursor: 'pointer',
    borderBottom: '2px solid transparent',
    marginBottom: '-2px',
    '&:hover': {
      color: theme.bc3,
    },

    '@media print': {
      display: 'none',
    },
  },

  activeTab: {
    color: theme.bc3,
    borderBottom: `2px solid ${theme.bc3}`,

    '@media print': {
      display: 'none',
    },
  },
  lozengePosition: {
    top: '4px',
    right: '0px',
    position: 'absolute',
    zIndex: 10,
    maxWidth: '11rem',

    '@media only screen and (min-width: 768px)': {
      position: 'relative',
      margin: 0,
      padding: '1px 7px',
      marginTop: '3px',
    },
  },

  dealsRow: {
    display: 'flex',
    marginBottom: '1.2rem',
    padding: '0.5rem 0.5rem 0.5rem 1rem',
    backgroundColor: '#fdf9ef',
    borderBottom: '1px dashed #ffe594',

    '@media print': {
      display: 'none',
    },

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      margin: '0px -5px 0px -5px',
      borderRadius: '4px 4px 0 0',
    },

    '& svg': {
      fill: '#eab300',
      height: '18px',
      width: '18px',
    },
  },

  dealText: {
    color: '#eab300',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '1.8rem',
  },
})

export default styles
