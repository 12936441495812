import React, { Component } from 'react'

const Instagram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 16 16"
  >
    <title>instagram</title>
    <g fill="#ffffff">
      <circle fill="#ffffff" cx="12.145" cy="3.892" r="0.96" />{' '}
      <path
        data-color="color-2"
        d="M8,12c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S10.206,12,8,12z M8,6C6.897,6,6,6.897,6,8 s0.897,2,2,2s2-0.897,2-2S9.103,6,8,6z"
      />{' '}
      <path
        fill="#ffffff"
        d="M12,16H4c-2.056,0-4-1.944-4-4V4c0-2.056,1.944-4,4-4h8c2.056,0,4,1.944,4,4v8C16,14.056,14.056,16,12,16z M4,2C3.065,2,2,3.065,2,4v8c0,0.953,1.047,2,2,2h8c0.935,0,2-1.065,2-2V4c0-0.935-1.065-2-2-2H4z"
      />
    </g>
  </svg>
)

export default Instagram
