import Color from 'color'

const styles = theme => ({
  subMenuWrapper: {
    marginTop: '10px',
    borderTop: `1px solid ${Color(theme.bc3)
      .alpha(0.4)
      .toString()}`,

    '&:first-child': {
      borderTop: 0,
    },

    '&:hover:not(:first-child)': {
      borderTop: `1px solid ${Color(theme.bc1)
        .alpha(0.8)
        .toString()}`,
    },

    '&:hover $subMenuTitle': {
      color: theme.bc1,
    },
  },
  subMenuTitle: {
    transition: 'color 0.2s ease',
    color: Color(theme.bc3)
      .alpha(0.6)
      .toString(),
    display: 'block',
    padding: '1.2rem 0px 0px 1.2rem',
    fontSize: '1.1rem',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  subMenuDescription: {
    display: 'block',
    padding: '1rem 0px 0px 1.2rem',
    color: '#7b7b7b',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
  },
})

export default styles
