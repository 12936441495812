import React from 'react'
import injectSheet from 'react-jss'
import cx from 'classnames'
import { object, oneOfType, arrayOf, node, bool } from 'prop-types'
import styles from './heroStyles'
import Header from '../Header/Header'
import { getMarketplace, AWS_CDN_URL } from '@config/config'

const Hero = ({ classes, children, branded }) => {
  const { heroImage } = getMarketplace()

  return (
    <section
      className={cx(classes.hero, { [classes.branded]: branded })}
      style={{
        backgroundImage: `url('${AWS_CDN_URL}${heroImage}')`,
      }}
    >
      <Header branded />
      {children}
    </section>
  )
}

Hero.propTypes = {
  classes: object,
  children: oneOfType([arrayOf(node), node]),
  branded: bool,
}

export default injectSheet(styles)(Hero)
