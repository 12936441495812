import React, { useState, useContext, useEffect } from 'react'
import { withStyles } from 'react-jss'
import styles from './addressSectionStyles'
import AddAddressPopover from './AddAddressPopover/AddAddressPopover'
import Modal from '@components/ModalWrapper/ModalWrapper'
import { GET_USER } from '../queries/getUserDetails.query'
import { Query } from 'react-apollo'
import AddressItem from './AddressItem'
import { OrderContext } from '@context/Order.context'
import { DeliveryCostToAddressContext } from '@context/DeliveryCostToAddress.context'
import get from 'lodash/get'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import { getMarketplace } from '@config/config'
import { BasketContext } from '@root/context/Basket.context'
import { withRouter } from 'react-router'

const AddressSection = ({ classes, customer, refetch, history }) => {
  const [addAddressModalOpen, setAddAddressModalOpen] = useState(false)
  const { attributes, updateOrder } = useContext(OrderContext)
  const { clearItems } = useContext(BasketContext)
  const {
    address: chosenOption,
    deliveryDestination = { type: 'POSTCODE', destination: '' },
  } = attributes
  const { isPostcodeMode } = getMarketplace()

  useEffect(() => {
    setDefaultAddress(customer)
  }, [customer])

  const setDefaultAddress = customer => {
    if (customer) {
      const { deliveryAddress } = customer

      if (deliveryDestination.type === 'POSTCODE') {
        const matchAddressToSavedAddresses = deliveryAddress.find(
          address => address.postcode === deliveryDestination.destination
        )
        if (matchAddressToSavedAddresses) {
          updateOrder({ address: matchAddressToSavedAddresses.id })
          return
        }
      }

      const currentChosenAddressExists = deliveryAddress.find(
        address => address.id === chosenOption
      )

      if (currentChosenAddressExists) {
        return
      }

      if (!deliveryAddress) {
        updateOrder({ address: null })
        return
      }

      let defaultAddress = deliveryAddress.find(address => {
        return address.default
      })
      if (!defaultAddress && deliveryAddress[0]) {
        defaultAddress = deliveryAddress[0]
      }
      if (defaultAddress && defaultAddress.id !== attributes.address) {
        updateOrder({ address: defaultAddress.id })
      }
    }
  }

  return (
    <DeliveryCostToAddressContext.Consumer>
      {({ error, loading }) => {
        const outletName = get(error, 'graphQLErrors[0].data.outletName', '')
        const restaurantName = get(
          error,
          'graphQLErrors[0].data.restaurantName',
          ''
        )
        const cleanMessage = error
          ? error.message ===
              'GraphQL error: Delivery is not available for this address' &&
            outletName &&
            restaurantName
            ? `Deliveries cannot be made to this address from ${restaurantName} at ${outletName}`
            : error.message.replace(/GraphQL error:\s?/, '')
          : ''

        return (
          <div>
            <LoadingSpinner show={loading} />

            <div className={classes.fieldRow}>
              {customer && customer.deliveryAddress.length == 0 && (
                <span className={classes.emptyNotice}>
                  You haven't added any addresses yet.
                </span>
              )}
              {customer &&
                customer.deliveryAddress &&
                customer.deliveryAddress.map(address => {
                  const isActive = attributes.address === address.id
                  return (
                    <AddressItem
                      key={address.id}
                      isActive={isActive}
                      error={
                        error && isActive && !loading ? (
                          <div style={{ margin: '0px 25px' }}>
                            {cleanMessage}.
                            <br />
                            {isPostcodeMode ? (
                              <button
                                className={classes.reSearchLink}
                                onClick={() => {
                                  updateOrder({
                                    deliveryDestination: {
                                      type: 'POSTCODE',
                                      destination: address.postcode,
                                    },
                                  })
                                  clearItems()
                                  history.push('/takeaways')
                                }}
                              >
                                Find restaurants for this address instead?
                              </button>
                            ) : null}
                          </div>
                        ) : null
                      }
                      address={address}
                      classes={classes}
                      refetch={refetch}
                    />
                  )
                })}
            </div>

            <div className={classes.fieldRow}>
              <div className={classes.addNewRow}>
                <a
                  className={classes.addNewBtn}
                  href="#_"
                  onClick={() => setAddAddressModalOpen(true)}
                >
                  + Add New Address
                </a>
              </div>
            </div>

            <Modal
              open={addAddressModalOpen}
              close={() => {
                setAddAddressModalOpen(false)
              }}
            >
              <AddAddressPopover
                close={() => {
                  setAddAddressModalOpen(false)
                }}
                refetch={choice => {
                  updateOrder({ address: choice.id })
                  refetch()
                }}
              />
            </Modal>
          </div>
        )
      }}
    </DeliveryCostToAddressContext.Consumer>
  )
}

const AddressSectionElWrapper = props => (
  <Query query={GET_USER} fetchPolicy={'network-only'}>
    {({ data, refetch, loading, error }) => {
      const customer = get(data, 'myDetails.customer', null)
      if (error) return `Error! ${error.message}`

      return (
        <div style={{ position: 'relative' }}>
          <LoadingSpinner show={loading} />
          <AddressSection {...props} customer={customer} refetch={refetch} />
        </div>
      )
    }}
  </Query>
)

export default withStyles(styles)(withRouter(AddressSectionElWrapper))
