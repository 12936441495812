import gql from 'graphql-tag'

const discountFragment = gql`
  fragment Discount_fragment on Discount {
    id
    name
    discountPercentage
    discountAmount
    startDate
    endDate
    daysOfWeek
    minimumSubtotalGross
    customerEnrolled
  }
`

export const GET_DISCOUNTS = gql`
  query discounts($id: String!) {
    outlet(id: $id) {
      id
      restaurant {
        id
        discounts {
          ...Discount_fragment
        }
      }
    }
    customerDiscountForBusiness(outletId: $id) {
      ...Discount_fragment
    }
  }
  ${discountFragment}
`
