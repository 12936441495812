import gql from 'graphql-tag'

export const GET_ORDERS = gql`
  query getOrders {
    myOrders(orderBy: createdAt_DESC, first: 10) {
      orders {
        id
        createdAt
        grossTotal
        orderStatus
        fulfillmentMethod
        customerDeliveryAddress
        tableSnapshot
        selectedDeliveryWindow
        orderItems {
          id
          menuItem
          optionItems
          singleItemNotes
          isAddOnItem
        }
        outlet {
          id
          name
          restaurant {
            id
            image
            name
          }
        }
        review {
          id
          foodQuality
          restaurantService
          deliveryTime
        }
        deliveryZone {
          id
          name
        }
      }
    }
  }
`

export const GET_SINGLE_ORDER = gql`
  query getSingleOrder($orderId: ID) {
    myOrders(where: { id: $orderId }) {
      orders {
        id
        orderNumber
        createdAt
        updatedAt
        subtotalNet
        subtotalGross
        tableSnapshot
        netTotal
        grossTotal
        orderStatus
        asap
        estimatedDeliveryDate
        estimatedCompletionTime
        selectedDeliveryWindow
        deliveryNotes
        customerDeliveryNotes
        customerOrderNotes
        customerDeliveryAddress
        fulfillmentMethod
        fulfillmentCharge
        cancellationNotes
        partnerCharge
        discount
        discountAmount
        orderItems {
          id
          grossTotal
          netTotal
          menuItem
          optionItems
          singleItemNotes
          isAddOnItem
        }
        outlet {
          id
          name
          outletPhone
          outletEmail
          outletAddress {
            firstLine
            secondLine
            city
            postcode
          }
          restaurant {
            id
            image
            name
          }
          marketplace {
            id
            partnerCharge
            partnerTableCharge
            partnerChargeDescription
            partnerTableChargeDescription
            key
          }
        }
        review {
          id
          foodQuality
          restaurantService
          deliveryTime
          reviewText
          approved
          reply
        }
      }
    }
  }
`
