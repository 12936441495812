import React from 'react'
import MenuItem from '@components/SingleRestaurant/Menu/MenuItem'

const MenuItems = ({ menuItems, openModal }) =>
  menuItems.length > 0
    ? menuItems.map(item => (
        <MenuItem item={item} openModal={openModal} key={item.id} />
      ))
    : null

export default MenuItems
