import get from 'lodash/get'

/**
 *
 * @param {*} err The error thrown by graphql mutations or queries. It means that under a single error object we can have an array of multiple messages.
 * @param {*} fallbackMessage The message to be displayed by default if no other messages exist
 * @returns A string that concatenates all the error messages or the default one
 */
export const parseIncomingError = (err, fallbackMessage) => {
  let errorMessage = ''
  const graphQLErrors = get(err, 'graphQLErrors', [])

  if (graphQLErrors.length > 0) {
    errorMessage = errorMessage.concat(
      graphQLErrors.reduce((acc, errorInstance) => {
        acc.push(errorInstance.message)
        return acc
      }, [])
    )
  }

  return errorMessage.length ? errorMessage : fallbackMessage
}
