import React, { Component } from 'react'
import { withStyles } from 'react-jss'
import styles from './addCardPopoverStyles'
import cx from 'classnames'
import valid from 'card-validator'
import { ADD_CARD } from '../queries/userCards.query'
import { Mutation } from 'react-apollo'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import { toast } from 'react-toastify'
import { ReactSVG } from 'react-svg'
import cardSVG from '@images/credit-locked.svg'
import Button from '@components/Button/Button'
import { GTMContext } from '@context/GTM.context'

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
} from 'react-stripe-elements'

const stripeStyles = {
  base: {
    fontSize: '17px',
    color: '#424770',
    fontFamily: "'Montserrat', 'sans-serif'",
    '::placeholder': {
      color: '#aab7c4',
      fontFamily: "'Montserrat', 'sans-serif'",
    },
    '&:focus': {
      borderColor: '#1e9de8',
    },
  },
  invalid: {
    color: '#e46564',
  },
}

class AddCardPopoverEl extends Component {
  static contextType = GTMContext
  constructor(props) {
    super(props)
    this.state = {
      isContactingStripe: false,
      number: {
        label: 'Card Number',
        value: '',
        placeholder: '0000 0000 0000 0000',
        error: false,
        className: 'col-xs-12',
        next: 'expiry',
        valid: false,
      },
      expiry: {
        label: 'Expiry Date',
        value: '',
        placeholder: 'MM / YY',
        error: false,
        className: 'col-xs-6',
        next: 'cvc',
        valid: false,
      },
      cvc: {
        label: 'CVC',
        value: '',
        placeholder: '000',
        error: false,
        className: 'col-xs-3',
        valid: false,
      },
    }
    this.fieldrefs = {
      number: React.createRef(),
      expiry: React.createRef(),
      cvc: React.createRef(),
    }

    this.canProceed = false
  }

  saveCard = () => {}

  splitString = input => {
    var foo = input.split(' ').join('')
    if (foo.length > 0) {
      foo = foo.match(new RegExp('.{1,4}', 'g')).join(' ')
    }
    return foo
  }

  splitStringDate = input => {
    var foo = input.split(' / ').join('')
    if (foo.length > 0) {
      foo = foo.match(new RegExp('.{1,2}', 'g')).join(' / ')
    }
    return foo
  }

  handleFieldChange = (e, field) => {
    const { value } = e.target
    let validateBy = field === 'number' ? 'number' : 'expirationDate'
    let formateBy = field === 'number' ? 'splitString' : 'splitStringDate'
    let validation = valid[validateBy](e.target.value)
    let formattedString = field === 'cvc' ? value : this[formateBy](value)
    let stateUpdate = {
      ...this.state[field],
      error: false,
      value: formattedString,
    }
    if (!validation.isPotentiallyValid) {
      stateUpdate.error = true
    }

    if (validation.isValid) {
      stateUpdate.valid = true
      if (field === 'expiry' && formattedString.length < 7) {
        //
      } else {
        if (this.state[field].next) {
          this.fieldrefs[this.state[field].next].current.focus()
        }
      }
    } else {
      stateUpdate.valid = false
    }
    this.setState({
      [field]: stateUpdate,
    })
  }

  hideLoader = () => {
    this.setState({
      isContactingStripe: false,
    })
  }

  raiseToastError = message => {
    toast.error(message || "Sorry, we're unable to add this card", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    })
    this.hideLoader()
  }

  handleSubmit = (ev, addCardMutation) => {
    ev.preventDefault()
    this.setState({
      isContactingStripe: true,
    })

    if (this.props.stripe) {
      this.props.stripe
        .createToken()
        .then(payload => {
          if (payload.error)
            return this.raiseToastError(
              payload.error.message || "Sorry, we're unable to add this card"
            )

          const addCard = addCardMutation({
            variables: { token: payload.token.id },
          })
          addCard.then(data => {
            this.hideLoader()
            this.props.cardAdded(data)
          })
          addCard.catch(() => {
            this.raiseToastError("Sorry, we're unable to add this card")
          })
        })
        .catch(() => {
          this.raiseToastError("Sorry, we're unable to add this card")
          this.hideLoader()
        })
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Mutation
        mutation={ADD_CARD}
        onCompleted={() => {
          this.context.pushDataToGTM({ event: 'add_payment_info' })
        }}
      >
        {(addCard, { loading }) => {
          return (
            <div>
              <div className={classes.titleArea}>
                <h2>Add Card</h2>
              </div>

              <div className={classes.cardForm}>
                <div className={cx('row', classes.fieldRow)}>
                  <div className={'col-xs-12'}>
                    <label className={classes.inputLabel}>Card Number</label>
                    <ReactSVG
                      src={cardSVG}
                      className={classes.svgIcon}
                      wrapper="span"
                    />
                    <CardNumberElement
                      className={classes.inputFieldWithPadding}
                      style={stripeStyles}
                    />
                  </div>
                </div>
                <div className={cx('row', classes.fieldRow)}>
                  <div className={'col-xs-6'}>
                    <label className={classes.inputLabel}>Card Expiry</label>
                    <CardExpiryElement
                      className={classes.inputField}
                      style={stripeStyles}
                    />
                  </div>
                  <div className={cx('col-xs-4', classes.gap)}>
                    <label className={classes.inputLabel}>Card CVC</label>
                    <CardCVCElement
                      className={classes.inputField}
                      style={stripeStyles}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.formFooter}>
                <p className={classes.securePara}>
                  All transactions are handled over
                  <br />a secure SSL connection
                </p>

                <Button
                  onClick={e => this.handleSubmit(e, addCard)}
                  className={classes.confirmButton}
                  fullWidth
                  capitalize
                  appearance="positive"
                >
                  Securely Add Card
                </Button>
              </div>
              <LoadingSpinner show={loading || this.state.isContactingStripe} />
            </div>
          )
        }}
      </Mutation>
    )
  }
}
const AddCardPopover = withStyles(styles)(injectStripe(AddCardPopoverEl))
export default AddCardPopover
