const styles = {
  modalEnter: {
    opacity: 0,
  },

  modalEnterActive: {
    opacity: 1,
  },

  modalEnterDone: {
    opacity: 1,
  },

  modalExit: {
    opacity: 1,
  },

  modalExitActive: {
    opacity: 0,
  },

  modalExitDone: {
    opacity: 0,
  },

  modalOverlay: {
    transition: `all 0.3s ease`,
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 10000,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',

    overflow: 'scroll',
    padding: '6.5rem 0',

    '@media screen and (max-width: 768px)': {
      padding: '0',
    },

    '@media screen and (min-width: 1024px)': {
      overflow: 'auto',
      padding: 0,
    },
  },

  modalView: {
    position: 'relative',
    top: 0,
    transform: 'none',
    left: 0,
    margin: '0 auto',

    backgroundColor: 'white',
    // width: 'calc(100vw - 8rem)',
    borderRadius: '4px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.60)',
    transition: `all 0.3s ease`,

    '@media screen and (min-width: 768px)': {
      maxWidth: '70rem',
      minWidth: '40rem',
    },

    '@media screen and (min-width: 1200px)': {
      position: 'absolute',
      top: '10%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      transform: 'translateX(-50%)',
      marginBottom: '5%',
    },
  },

  modalViewSmall: {
    maxWidth: '50em',
  },
}

export default styles
