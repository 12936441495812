const styles = {
  basketIconNumber: {
    minWidth: '2rem',
    height: '2rem',
    display: 'block',
    borderRadius: '100%',
    backgroundColor: '#23af44',
    position: 'relative',
    top: '-0.75rem',
    right: '0.5rem',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '2rem',
    transform: 'scale(0.001)',
    transitionDelay: '0.4s',
    transition: 'scale 0.2s ease',
  },
  basketIcon: {
    display: 'flex',
    color: 'white',
    height: '4rem',
    width: '5rem',
    padding: '0.5rem',

    '& svg': {
      color: 'white',
      strokeWidth: '4px',
      height: '3rem',
      width: '3rem',
    },
  },
  showBasketCount: {
    display: 'block',
    transform: 'scale(1)',
  },
}

export default styles
