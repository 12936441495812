import React, { useState, useCallback } from 'react'
import { getMarketplace } from '@config/config'

export const OutletListContext = React.createContext({
  activeCuisines: [],
  filterCuisine: () => {},
  cuisinesOpen: false,
  setCuisinesOpen: () => {},
  deliveryZonesModalOpen: false,
  setDeliveryZonesModalOpen: () => {},
  marketplace: {},
  isPostcodeMode: false,
})

export const OutletListContextProvider = ({ children }) => {
  const marketplace = getMarketplace()
  const isPostcodeMode = marketplace.orderMode === 'POSTCODE'

  const [activeCuisines, setActiveCuisines] = useState([])
  const [cuisinesOpen, setCuisinesOpen] = useState(false)
  const [deliveryZonesModalOpen, setDeliveryZonesModalOpen] = useState(false)

  const filterCuisine = useCallback(
    selectedCuisine => {
      const selectedCuisineIndex = activeCuisines.findIndex(
        ({ id }) => id === selectedCuisine.id
      )
      if (selectedCuisineIndex > -1) {
        const newActiveCuisines = [...activeCuisines]
        newActiveCuisines.splice(selectedCuisineIndex, 1)
        setActiveCuisines(newActiveCuisines)
      } else {
        setActiveCuisines([...activeCuisines, selectedCuisine])
      }
    },
    [activeCuisines]
  )

  return (
    <OutletListContext.Provider
      value={{
        activeCuisines,
        cuisinesOpen,
        deliveryZonesModalOpen,
        setDeliveryZonesModalOpen,
        setCuisinesOpen,
        marketplace,
        isPostcodeMode,
        filterCuisine,
      }}
    >
      {children}
    </OutletListContext.Provider>
  )
}
