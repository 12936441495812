import React from 'react'
import { ReactSVG } from 'react-svg'

import deliverySVG from '@images/cheeseburger.svg'
import dineInSVG from '@images/romantic-restaurant.svg'
import collectionSVG from '@images/bag-20.svg'

const FulfilmentDropdown = ({
  showDropdown,
  classes,
  setFulfilmentType,
  handleDropdown,
  isPopover,
}) => {
  return showDropdown === 'fulfilment' ? (
    <div
      className={
        isPopover ? classes.firstDropdownPopover : classes.firstDropdown
      }
    >
      <div
        className={classes.dropdownDiv}
        onClick={() => {
          setFulfilmentType('Delivery')
          handleDropdown('fulfilment')
        }}
      >
        <ReactSVG src={deliverySVG} className={classes.svg} />
        <p className={classes.title}>Delivery</p>
      </div>
      <div
        className={classes.dropdownDiv}
        onClick={() => {
          setFulfilmentType('Collection')
          handleDropdown('fulfilment')
        }}
      >
        <ReactSVG src={collectionSVG} className={classes.svg} />
        <p className={classes.title}>Collection</p>
      </div>
      <div
        className={classes.dropdownDivNoBorder}
        onClick={() => {
          setFulfilmentType('Dine in')
          handleDropdown('fulfilment')
        }}
      >
        <ReactSVG src={dineInSVG} className={classes.svg} />
        <p className={classes.title}>Dine in</p>
      </div>
    </div>
  ) : null
}

export default FulfilmentDropdown
