import React, { useState, useEffect, useContext } from 'react'
import { withStyles } from 'react-jss'
import cx from 'classnames'
import { Switch, Route } from 'react-router-dom'
import styles from './userAccountStyles.js'
import Hero from '../Hero/Hero'
import Orders from './Orders/Orders'
import Details from './Details/Details'
import Addresses from './Addresses/Addresses'
import ls from '@utils/localStorage'
import USER_DETAILS from './userdetails.query'
import Review from '@components/Review/Review'
import Reviews from '@components/Reviews/Reviews'
import UserAccountNavLink from './UserAccountNavLink'
import { client } from '@services/client'
import StickySideBar from '@components/StickySideBar/StickySideBar'
import { GTMContext } from '@context/GTM.context'
import { OrderContext } from '@context/Order.context'
import { BasketContext } from '@context/Basket.context'
import { getMarketplace } from '@config/config'
import MarketingPreferencesContainer from '@components/UserAccount/MarketingPreferences/MarketingPreferencesContainer.jsx'

const accountRoutes = [
  {
    path: '/account/orders',
    title: 'My Orders',
    component: Orders,
    isLink: true,
  },
  {
    path: '/account/orders/:orderid',
    title: 'Order',
    component: Orders,
    isLink: false,
  },
  {
    path: '/account/orders/:orderid/review',
    title: 'Review',
    component: Review,
    isLink: false,
  },
  {
    path: '/account/reviews',
    title: 'My Reviews',
    component: Reviews,
    isLink: true,
  },
  {
    path: '/account/details',
    title: 'My Details',
    component: Details,
    isLink: true,
  },
  {
    path: '/account/addresses',
    title: 'My Address Book',
    component: Addresses,
    isLink: true,
  },
  {
    path: '/account/marketing',
    title: 'Marketing Preferences',
    component: MarketingPreferencesContainer,
    isLink: true,
  },
  {
    path: 'logout',
    title: 'Logout',
    isLink: true,
  },
]

const AccountEl = props => {
  const gtm = useContext(GTMContext)
  const { replaceOrder } = useContext(OrderContext)
  const { clearItems } = useContext(BasketContext)
  const { allowReviews } = getMarketplace()
  const [authenticated, setAuthenticated] = useState(false)
  const [customer, setCustomer] = useState({})

  useEffect(() => {
    authenticateCustomer()
  }, [])

  const logout = () => {
    gtm.pushDataToGTM({ event: 'logged_out' })
    ls.remove('jwt')
    ls.remove('voucherDiscount')
    clearItems()
    replaceOrder({})
    props.history.push('/')
  }

  const loadLoginModal = () => {
    client.writeData({
      data: {
        loginOpen: true,
      },
    })
  }

  const authenticateCustomer = async () => {
    try {
      const { data } = await client.query({
        query: USER_DETAILS,
        fetchPolicy: 'no-cache',
      })
      if (data.myDetails) {
        setAuthenticated(true)
        setCustomer(data.myDetails.customer)
      }
    } catch (err) {
      loadLoginModal()
    }
  }

  const { listWrapper, userAccountColumn, subHeader, sidebarList } =
    props.classes

  return (
    <div>
      <Hero />
      <div className={cx('wrapper', listWrapper)}>
        <Switch>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-12 col-md-offset-1 col-md-2 col-lg-offset-0">
                <StickySideBar
                  title={
                    <>
                      <h2>Account</h2>
                      <h3 className={subHeader}>{customer.email}</h3>
                    </>
                  }
                >
                  <div className={sidebarList}>
                    <ul>
                      {accountRoutes.map(route => {
                        if (route.isLink) {
                          if (route.title.includes('Review') && !allowReviews) {
                            return null
                          }
                          return (
                            <UserAccountNavLink
                              route={route}
                              classes={props.classes}
                              logout={logout}
                            />
                          )
                        }
                      })}
                    </ul>
                  </div>
                </StickySideBar>
              </div>

              <div className="col-xs-12 col-md-8 col-lg-offset-1 col-lg-9">
                <div>
                  {authenticated
                    ? accountRoutes.map(route => {
                        if (route.title.includes('Review') && !allowReviews) {
                          return null
                        }
                        return (
                          <Route
                            key={route.path}
                            path={route.path}
                            component={props => {
                              const Comp = route.component
                              return (
                                <div
                                  className={
                                    route.path !== '/account/orders/:orderid' &&
                                    userAccountColumn
                                  }
                                >
                                  <Comp customer={customer} {...props} />
                                </div>
                              )
                            }}
                            exact
                            customer={customer}
                          />
                        )
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        </Switch>
      </div>
    </div>
  )
}

const Account = withStyles(styles)(AccountEl)
export default Account
