import React from 'react'
import { NavLink } from 'react-router-dom'
import Alert from '@components/Alert/Alert'

const UserAccountNavlink = ({ classes, logout, route }) => {
  const { sideBarLink, defaultState, sideBarLinkActive } = classes

  return (
    <li key={route.path} className={defaultState}>
      {!route.component ? (
        <Alert confirm={logout} question="Are you sure you want to logout?">
          <a className={sideBarLink}>{route.title}</a>
        </Alert>
      ) : (
        <NavLink
          activeClassName={sideBarLinkActive}
          className={sideBarLink}
          to={route.path}
        >
          {route.title}
        </NavLink>
      )}
    </li>
  )
}

export default UserAccountNavlink
