const styles = {
  titleArea: {
    padding: '15px',
    position: 'relative',
    lineHeight: '20px',
    '& h2': {
      color: '#2576a8',
      fontSize: '17px',
      fontWeight: 600,
    },
  },
  inner: {
    padding: '0px 15px',
    marginBottom: '20px',
  },
  bodyText: {
    color: '#636363',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '19px',
  },
  callPrompt: {
    borderTop: '1px dotted #cccccc',
  },
  callIcon: {
    verticalAlign: 'middle',
    '& svg': {
      width: '22px',
      marginTop: '5px',
      fill: '#35ba59',
    },
  },
  callLink: {
    display: 'flex',
    padding: '15px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  callText: {
    fontWeight: 500,
    fontSize: '16px',
    display: 'block',
    marginLeft: '15px',
    color: '#2576a8',
  },
  callNumber: {
    display: 'block',
    marginLeft: '15px',
    fontSize: '14px',
    marginTop: '4px',
    color: '#35ba59',
    fontWeight: 600,
  },
}

export default styles
