import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { withStyles } from 'react-jss'
import styles from './setNewPasswordStyles'
import ls from '@utils/localStorage'
import SET_NEW_PASSWORD from './request.mutation'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'

import { getMarketplace } from '@config/config'

class SetNewPasswordEl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resetSuccess: false,
      password: '',
      confirmPassword: '',
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  loginWithNewToken = (token, cb) => {
    ls.set('jwt', token)
    cb()
  }

  handleSubmit = (event, resetRequest) => {
    event.preventDefault()
    const { cid, token } = this.props
    const { password, confirmPassword } = this.state

    if (password !== confirmPassword) {
      toast.error('Passwords do not match', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      })
      return
    }
    const resetMutation = resetRequest({
      variables: {
        customerId: cid,
        token: token,
        newPassword: password,
      },
    })
    resetMutation.then(success => {
      const token = _.get(success, 'data.resetPassword.token', null)
      if (!_.isEmpty(token)) {
        this.loginWithNewToken(token, () => {
          this.setState({
            resetSuccess: true,
          })
        })
      }
    })
    resetMutation.catch(error => {
      error.graphQLErrors.map(err => {
        let message = 'Problem requesting password reset'
        switch (err.code) {
          case 'CLIENT_ERROR':
          case 'AUTHENTICATION_ERROR':
            message = err.message
            break
          default:
            message =
              err.data && err.data.errors
                ? err.data.errors.join(', ')
                : 'Problem requesting password reset'
            break
        }
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        })
      })
    })
  }

  render() {
    const {
      container,
      titleJacket,
      title,
      field,
      input,
      label,
      submit,
      successPara,
    } = this.props.classes

    const { resetSuccess } = this.state

    const { brandColor, brandSecondaryColor, featureLogin } = getMarketplace()

    return (
      <Mutation mutation={SET_NEW_PASSWORD}>
        {resetRequest => (
          <form
            onSubmit={event => this.handleSubmit(event, resetRequest)}
            className={container}
          >
            {resetSuccess ? (
              <>
                <div className={titleJacket}>
                  <h2
                    className={title}
                    style={{
                      color: brandColor,
                    }}
                  >
                    Password Reset
                    <br />
                    Successfully!
                  </h2>
                </div>
                <div className={field}>
                  <p className={successPara}>
                    {featureLogin &&
                      'You are now logged in with your new password.'}
                    {!featureLogin && 'Your password has been reset.'}
                  </p>
                </div>
                <button
                  type="button"
                  className={submit}
                  onClick={() => {
                    {
                      if (featureLogin) {
                        this.props.history.push('/')
                      }
                    }
                    this.props.close()
                  }}
                  style={{
                    backgroundColor: brandColor,
                  }}
                >
                  OK
                </button>
              </>
            ) : (
              <>
                <div className={titleJacket}>
                  <h2
                    className={title}
                    style={{
                      color: brandColor,
                    }}
                  >
                    Password Reset
                  </h2>
                </div>
                <div className={field}>
                  <label htmlFor="password" className={label}>
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className={input}
                    onChange={this.handleChange}
                  />
                </div>

                <div className={field}>
                  <label htmlFor="confirmPassword" className={label}>
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    className={input}
                    onChange={this.handleChange}
                  />
                </div>
                <div className={field}>
                  <button
                    type="submit"
                    className={submit}
                    style={{
                      backgroundColor: brandSecondaryColor,
                    }}
                  >
                    Reset Password
                  </button>
                </div>
              </>
            )}
          </form>
        )}
      </Mutation>
    )
  }
}

const SetNewPassword = withStyles(styles)(withRouter(SetNewPasswordEl))
export default SetNewPassword
