import fontStyles from '@styles/sharedJSS/fonts'

const styles = theme => ({
  container: {
    padding: '2.4rem',

    '@media screen and (min-width: 768px)': {
      padding: '3.6rem',
    },
  },

  formJacket: {
    position: 'relative',
  },

  titleJacket: {
    position: 'relative',
    display: 'block',
    marginBottom: '2.4rem',

    '@media screen and (min-width: 375px)': {
      marginBottom: '3.6rem',
    },
  },

  title: {
    ...fontStyles.h2,
    color: theme.bc3,
  },

  close: {
    ...fontStyles.small,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
    padding: '0',
    background: 'none',
    border: 'none',
    color: theme.bc5,
    transition: 'color .25s ease-in-out',
    cursor: 'pointer',

    '&:hover': {
      color: theme.bc3,
    },
  },

  field: {
    position: 'relative',
    display: 'block',
    marginBottom: '1.2rem',

    '@media screen and (min-width: 375px)': {
      marginBottom: '1.4rem',
    },
  },

  input: {
    ...fontStyles.small,
    backgroundColor: 'rgba(000,000,000,0.05)',
    boxShadow: 'none',
    padding: '0 1.2rem',
    fontSize: '1.6rem',
    border: 'none',
    width: '100%',
    height: '4.5rem',
    borderRadius: '4px',
    color: theme.bc5,

    '&:focus': {
      outline: 'none',
    },
  },

  label: {
    ...fontStyles.small,
    display: 'block',
    marginBottom: '0.2rem',
  },

  submit: {
    fontSize: '2rem',
    padding: '20px',
    marginTop: '2.4rem',
  },

  termsText: {
    ...fontStyles.small,
    color: theme.bc5,
    paddingTop: '1.2rem',

    '& a': {
      display: 'inline-block',
      color: theme.bc3,
      transition: 'color .25s ease-in-out',

      '&:hover': {
        color: theme.bc1,
      },
    },
  },

  error: {
    color: theme.error,
    marginTop: '0.2rem',
    fontSize: '1.4rem',
    maxWidth: '46rem',
  },
})

export default styles
