import fontStyles from '@styles/sharedJSS/fonts'
import colors from '@styles/sharedJSS/colors'

export const styles = theme => ({
  container: {
    padding: '3.6rem',
  },

  titleJacket: {
    position: 'relative',
    display: 'block',
    marginBottom: '2.4rem',

    '@media screen and (min-width: 375px)': {
      marginBottom: '3.6rem',
    },
  },

  title: {
    ...fontStyles.h2,
    color: theme.bc3,
  },

  close: {
    ...fontStyles.small,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
    padding: '0',
    background: 'none',
    border: 'none',
    color: colors.bc5,
    transition: 'all .25s ease-in-out',

    '&:hover': {
      color: colors.bc3,
      cursor: 'pointer',
    },
  },

  acceptMessage: {
    borderBottom: '1px dashed #ccc',
    marginBottom: '14px',
    paddingBottom: '11px',

    '& strong': {
      color: 'white',
      backgroundColor: 'red',
      padding: '1px 5px',
      borderRadius: '3px',
      fontWeight: 700,
      fontSize: '11px',
    },
    '& span': {
      display: 'block',
      fontSize: '12px',
      lineHeight: '16px',
      paddingTop: '7px',
      fontWeight: 500,
      color: '#656565',
    },
  },
  submit: {
    ...fontStyles.p,
    transition: 'all .25s ease-in-out',
    display: 'block',
    width: '100%',
    padding: '20px',
    backgroundColor: colors.bc1,
    borderRadius: '53px',
    border: 'none',
    color: 'white',
    fontWeight: 600,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: colors.bc2,
    },
  },
})
