import React from 'react'
import moment from 'moment'
import sortBy from 'lodash/sortBy'
import { withStyles } from 'react-jss'
import styles from './openingTimes.styles'

const OpeningTimes = ({ classes, openingTimes }) => {
  const openingTimesByDay = sortBy(openingTimes, ['start.day', 'start.time'])
  const twoWeeksOfOpeningTimes = [
    ...openingTimesByDay.map(time => ({ ...time, week: 0 })),
    ...openingTimesByDay.map(time => ({ ...time, week: 1 })),
  ]
  const now = moment()
  const isoDay = now.utc().isoWeekday()

  const nextOpeningTime = twoWeeksOfOpeningTimes.find(openingTime => {
    const [closingHour, closingMinute] = openingTime.end.time.split(':')
    const closingTime = moment()
      .utc()
      .add(openingTime.week, 'week')
      .isoWeekday(openingTime.end.day)
      .hour(closingHour)
      .minute(closingMinute)
    return now.isBefore(closingTime)
  })

  const isToday = nextOpeningTime.start.day === isoDay
  const isTomorrow = nextOpeningTime.start.day === isoDay + 1

  const getDay = () => {
    if (isToday) {
      return 'Today'
    }
    if (isTomorrow) {
      return 'Tomorrow'
    }
    return moment()
      .isoWeekday(nextOpeningTime.start.day)
      .format('dddd')
  }

  return (
    <div className={classes.container}>
      <p className={classes.day}>Open {getDay()}</p>
      <p className={classes.time}>
        {nextOpeningTime.start.time} - {nextOpeningTime.end.time}
      </p>
    </div>
  )
}

export default withStyles(styles)(OpeningTimes)
