import React from 'react'
import injectSheet from 'react-jss'
import { shape, string, number, object, bool } from 'prop-types'
import styles from './totalRowStyles'
import { penceToPounds } from '@utils/helpers'

const TotalRow = ({ row, classes }) => {
  const { label, cost, loading, discount, large, free, unavailable } = row
  const { cartFooterRow, cartPriceTag, cartTotalFooterRow } = classes

  return (
    <div className={large ? cartTotalFooterRow : cartFooterRow}>
      <span>{label}</span>
      {loading || unavailable ? (
        '--'
      ) : (
        <span className={cartPriceTag}>
          {free ? (
            'Free'
          ) : (
            <>
              {discount && '-'}£{penceToPounds(cost)}
            </>
          )}
        </span>
      )}
    </div>
  )
}

TotalRow.propTypes = {
  row: shape({
    label: string,
    cost: number | string,
    discount: bool,
    large: bool,
    free: bool,
    unavailable: bool,
  }),
  classes: object,
}
export default injectSheet(styles)(TotalRow)
