import React from 'react'
import { withStyles } from 'react-jss'
import MenuItems from '@components/SingleRestaurant/Menu/MenuItems'
import SubMenus from '@components/SingleRestaurant/Menu/SubMenus'
import leftArrow from '../../../images/left-arrow.svg'
import { ReactSVG } from 'react-svg'
import BasketIcon from '../../basketIcon/basketIcon'
import styles from '@components/SingleRestaurant/Menu/MenuSections.styles'

export const MenuSection = withStyles(styles)(
  ({
    classes,
    menuObj: { header, menuItems, subMenu },
    outletRestaurantName,
    openModal,
    menuSectionClicked,
    backToMenu,
    isActive,
  }) => (
    <div className={classes.menuHeaderSection}>
      <div
        className={classes.menuCategory}
        id={header.id}
        onClick={e => menuSectionClicked(e, header)}
      >
        <h6>{header.name}</h6>
        {header.description && <p>{header.description}</p>}
      </div>

      <div
        className={
          classes[isActive ? 'menuItemSectionActive' : 'menuItemSection']
        }
      >
        <div className={classes.inMenuHeaderBack}>
          <a className={classes.inMenuHeaderBackLink} onClick={backToMenu}>
            <ReactSVG src={leftArrow} wrapper="span" />
            {outletRestaurantName}
          </a>
          <BasketIcon />
        </div>

        <div className={classes.inMenuSubHeaderBack}>{header.name}</div>

        <SubMenus subMenus={subMenu} openModal={openModal} />

        <MenuItems menuItems={menuItems} openModal={openModal} />
      </div>
    </div>
  )
)

const MenuSections = ({
  menuSections,
  outletRestaurantName,
  openModal,
  menuSectionClicked,
  backToMenu,
}) => (
  <div>
    {menuSections.map(menuObj => (
      <MenuSection
        key={menuObj.header.id}
        menuObj={menuObj}
        outletRestaurantName={outletRestaurantName}
        openModal={openModal}
        menuSectionClicked={menuSectionClicked}
        backToMenu={backToMenu}
      />
    ))}
  </div>
)

export default MenuSections
