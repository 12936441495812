import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { config } from '@config/config'

const injectManifestJSON = marketplace => {
  const { name, metaDescription, storeURLGooglePlay, brandColor } = marketplace

  const getGooglePlayId = storeURLGooglePlay => {
    try {
      const url = new URL(storeURLGooglePlay)
      return url.searchParams.get('id')
    } catch (e) {
      return null
    }
  }
  const googlePlayId = getGooglePlayId(storeURLGooglePlay)

  if (googlePlayId) {
    const myDynamicManifest = {
      name: name,
      short_name: name,
      description: metaDescription,
      background_color: brandColor,
      theme_color: brandColor,
      prefer_related_applications: true,
      related_applications: [
        {
          platform: 'play',
          id: googlePlayId,
        },
      ],
    }

    const stringManifest = JSON.stringify(myDynamicManifest)
    const blob = new Blob([stringManifest], { type: 'application/json' })
    const manifestURL = URL.createObjectURL(blob)
    document
      .querySelector('#my-manifest-placeholder')
      .setAttribute('href', manifestURL)
  }
}

const HelmetWrapper = props => {
  const { marketplace } = props

  useEffect(() => {
    injectManifestJSON(marketplace)
  }, [marketplace.id])

  if (!marketplace) {
    return null
  }

  const title = marketplace.title || 'Redbox'
  const metaKeywords = marketplace.metaKeywords || 'Redbox'
  const metaDescription =
    marketplace.metaDescription || 'Redbox Ordering Platform'
  const faviconImage = marketplace.faviconImage || false

  /* BELOW NEEDS TO BE ID OF SOME KIND */

  const faviconLink = `${config.apiUrl}/${faviconImage}`

  const { storeURLApple } = marketplace
  const appleAppID = storeURLApple ? storeURLApple.split('id')[1] : null

  return (
    <Helmet>
      <title>
        {title} | {metaDescription}
      </title>
      <meta name="keywords" content={metaKeywords} />
      <meta name="description" content={metaDescription} />
      <link rel="shortcut icon" href={faviconLink} type="image/x-icon" />

      {appleAppID && (
        <meta name="apple-itunes-app" content={`app-id=${appleAppID}`} />
      )}
    </Helmet>
  )
}

export default HelmetWrapper
