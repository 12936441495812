import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from '@utils/breakpoints'
import restaurantRowStyles from '../../styles/sharedJSS/restaurantRow'
import { buttonSecondary } from '@styles/sharedJSS/buttons'

const styles = theme => ({
  ...restaurantRowStyles(theme),

  checkoutTitle: {
    cursor: 'initial !important',
    '&:$hover': {
      opacity: '1 !important',
    },
  },

  listWrapper: {
    marginTop: '-8rem',
    position: 'relative',
  },

  itemInformation: {
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '0.5rem',
    borderRadius: '50%',
    border: '1px solid #999999',
    marginBottom: '-5px',

    width: '2rem',
    height: '2rem',

    '& img': {
      width: '2.4rem',
      height: '2.4rem',
    },
  },

  ageRestricted: {
    width: '2rem',
    height: '2rem',
    marginLeft: '0.5rem',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& span': {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& svg': {
      width: '2rem',
      height: '2rem',
    },
  },

  cartSummary: {
    display: 'none',
    [`@media screen and (max-width: ${TABLET_BREAKPOINT}px)`]: {
      display: 'block',
    },
  },

  pageHeader: {
    color: '#444444',
    fontSize: '16px',
    fontWeight: 600,
  },

  columnWrapper: {
    position: 'relative',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '1.2rem',
    '@media screen and (min-width: 768px)': {
      marginRight: '12px',
      padding: '20px',
    },
  },

  selectBox: {
    color: theme.bc3,
    display: 'block',
    fontSize: '15px',
    transition: 'all 0.5s ease',
    fontWeight: 500,
    cursor: 'pointer',
  },

  activeIndicator: {
    width: '12px',
    height: '12px',
    display: 'inline-block',
    transition: 'all 0.5s ease',
    marginRight: '13px',
    borderRadius: '100%',
    backgroundColor: '#eaeff2',
    verticalAlign: 'middle',
    marginTop: '3px',
  },

  activeIndicatorisActive: {
    backgroundColor: theme.bc3,
  },

  fieldRow: {
    marginTop: '20px',

    '@media screen and (min-width: 768px)': {
      marginTop: '15px',
      padding: '0 0.5rem',
    },
  },

  subLabel: {
    fontSize: '12px',
    display: 'block',
    marginLeft: '25px',
    marginTop: '7px',
    color: '#757575',
  },

  inactive: {
    opacity: 0.6,
    '&:hover': {
      opacity: 0.8,
    },
  },

  active: {
    opacity: 1,
  },

  clickableRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    paddingBottom: '5px',
    cursor: 'pointer',
    position: 'relative',

    '&:last-child': {
      borderBottom: 0,
    },

    '@media screen and (min-width: 768px)': {
      display: 'block',
    },
  },

  formSection: {
    paddingTop: '15px',
    paddingBottom: '15px',
    borderBottom: '1px dotted #cfdbe0',

    '&:first-child': {
      borderTop: 0,
      marginTop: 0,
      paddingTop: 0,
    },

    '&:last-child': {
      borderBottom: 0,
    },
  },

  noBorder: {
    border: '0!important;',
  },
  animatedContainer: {
    transition: 'all 0.2s ease',
    overflow: 'hidden',
  },

  notesSection: {
    border: '1px solid #dbe4e8',
    width: '100%',
    borderRadius: '4px',
    minHeight: '90px',
    padding: '5px 6px',
    fontSize: '13px',

    '&:focus, &:active': {
      outline: 0,
      border: `1px solid ${theme.bc3}`,
    },
  },

  inputLabel: {
    color: theme.bc3,
    display: 'block',
    fontSize: '12px',
    fontWeight: 500,
    paddingBottom: '5px',
  },

  inputField: {
    width: '100%',
    fontSize: '15px',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #e0e8ea',
    '&:focus': {
      outline: 0,
      border: `1px solid ${theme.bc3}`,
    },
  },

  fieldErrorSpan: {
    fontSize: '10px',
    color: 'red',
    paddingTop: '6px',
    display: 'block',
    fontWeight: 500,
  },

  formFooter: {
    marginTop: '30px',
    textAlign: 'center',
    marginBottom: '20px',
    borderTop: '1px dotted #e0e8ea',
    paddingTop: '30px',
  },

  label: {
    marginRight: 'auto',
    color: theme.bc3,
    cursor: 'pointer',
    fontSize: '15px',
    fontWeight: 500,
    maxWidth: '80%',
    lineHeight: 'normal',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      lineHeight: 'normal',
    },
  },

  confirmButton: {
    padding: '17px 50px',
    textTransform: 'capitalize',

    '@media screen and (min-width: 768px)': {
      width: 'auto',
    },
  },

  backButton: {
    color: '#fff',
    width: '100%',
    display: 'inline-block',
    padding: '12px 30px 13px 30px',
    fontSize: '15px',
    fontWeight: 600,
    borderRadius: '50px',
    backgroundColor: '#23b044',
    marginTop: '20px',
    opacity: 0.9,
    transition: 'all 0.2s ease',

    '&:hover': {
      opacity: 1,
    },
    '@media screen and (min-width: 768px)': {
      width: 'auto',
    },
  },

  addNewRow: {},

  addNewBtn: {
    color: theme.bc1,
    padding: '8px 0',
    display: 'block',
    fontSize: '12px',
    fontWeight: 600,
    borderRadius: '50px',
  },

  noOrderViewWrapper: {
    padding: '40px',
    textAlign: 'center',
    minHeight: '354px',
  },
  noOrderHeader: {
    color: theme.bc3,
    fontSize: '20px',
    fontWeight: 700,
  },
  noOrderPara: {
    padding: '10px',
    fontSize: '13px',
    color: '#4d4d4d',
    fontWeight: 500,
  },

  rowAction: {
    display: 'inline-block',
    transition: 'all 0.2s ease',
    position: 'absolute',
    padding: '10px',
    top: '-5px',
    right: '-7px',
    '@media screen and (min-width: 768px)': {
      marginRight: 0,
    },
  },

  rowActionIcon: {
    width: '16px',
    border: '2px solid red',
    height: '16px',
    display: 'block',
    borderRadius: '100%',
    textAlign: 'center',
    marginTop: '-2px',
    transition: 'all 0.2s ease',

    '& svg': {
      height: '12px',
      width: '10px',
      fill: 'red',
      verticalAlign: 'top',
      transition: 'all 0.2s ease',
    },

    '&:hover': {
      backgroundColor: 'red',

      '& svg': {
        fill: '#fff',
      },
    },
  },

  hiddenInput: {
    width: 0,
    height: 0,
    opacity: 0,
    margin: 0,
    padding: 0,
    outline: 0,
  },

  emptyNotice: {
    fontSize: '1.3rem',
    fontStyle: 'italic',
    color: '#9e9e9e',
    margin: '10px 0px',
  },

  itemPrice: {
    color: theme.bc1,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    fontSize: '1.4rem',
    fontWeight: 600,
    marginTop: '-5px',
  },

  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: '20px',
  },

  addToBasket: {
    ...buttonSecondary(theme),
    display: 'block',
    padding: '1.8rem 2rem',
    marginBottom: '1rem',
    cursor: 'pointer',
  },

  selectedItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    opacity: 1,
  },

  footerButton: {
    width: '3.0rem',
    padding: '5px',
    height: '3.0rem',
    border: `2px solid ${theme.bc1}`,
    display: 'inline-block',
    borderRadius: '100%',
    verticalAlign: 'middle',
    fontSize: '3.6rem',
    lineHeight: '2.4rem',
    color: theme.bc1,
    cursor: 'pointer',
    transition: `all 0.2s ease`,

    '&:hover': {
      backgroundColor: theme.bc1,
      color: 'white',

      '& svg': {
        fill: 'white',
      },
    },
  },

  svgPriceIcon: {
    verticalAlign: 'middle',

    '& svg': {
      fill: theme.bc1,
      width: '1.5rem',
      height: '1.5rem',
      marginLeft: '0.8rem',
    },

    '@media print': {
      display: 'none',
    },
  },

  qtyControlIcon: {
    display: 'inline-block',
    lineHeight: '25px',
    position: 'relative',
    top: '-8px',
    left: '-1px',

    '& svg': {
      fill: theme.bc1,
      width: '1.8rem',
      height: '1.8rem',
      strokeWidth: '2px',
    },
  },

  // Add on item menu
  addOnItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    fontSize: '15px',
    paddingTop: '10px',
    width: '100%',
  },

  nameContainer: {
    width: '50%',
    textAlign: 'left',
    display: 'flex',
    alignContent: 'center',
  },

  quantityIndicator: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    color: '#444444',
    fontSize: '15px',
    fontWeight: 600,
    padding: '8px 12px 6px 12px',
    width: '15px',
  },

  priceIndicator: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-end',
    color: '#444444',
    fontSize: '15px',
    fontWeight: 600,
    marginTop: '2px',
    minWidth: '100px',
  },

  maxPriceIndicator: {
    fontWeight: 400,
    textDecoration: 'line-through',
    marginRight: '10px',
  },

  inRow: {
    display: 'flex',
  },
})

export default styles
