import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import cx from 'classnames'
import kebabCase from 'lodash/kebabCase'
import Button from '@components/Button/Button'
import get from 'lodash/get'
import { OrderContext } from '@context/Order.context'
import { BasketContext } from '@context/Basket.context'
import { getMarketplace } from '@config/config'

const CheckoutButton = ({
  outlet,
  classes,
  minimumOrderValueWarningVisible,
  history,
  openTablePicker,
  closeCart,
}) => {
  const { isListMode } = getMarketplace()
  const { attributes } = useContext(OrderContext)
  const { items = [] } = useContext(BasketContext)
  const { isOnline, id, preorderStatus, isOpen } = outlet
  const { checkoutBtn, disabledButton, inactiveButton } = classes

  const restaurantName = get(outlet, 'restaurant.name', '')

  const isCheckoutButtonDisabled = () => {
    return (
      (!attributes.asap &&
        !attributes.selectedDeliverySlot &&
        attributes.fulfilmentChosen &&
        attributes.fulfilmentChosen.id !== 'table') ||
      (!isOpen && isOnline && preorderStatus.includes('Order From')) ||
      (attributes.fulfilmentChosen.id === 'table' && !isOpen)
    )
  }

  let text = preorderStatus
  if (isOnline && (isOpen || !preorderStatus.includes('Order From')))
    text = 'Go to checkout'
  if (!isOnline || (!isOpen && attributes.fulfilmentChosen.id === 'table'))
    text = 'Currently Closed'

  const cantCheckout = minimumOrderValueWarningVisible || items.length < 1
  const buttonClass = cx(checkoutBtn, {
    [inactiveButton]: !isOnline,
    [disabledButton]: cantCheckout,
  })

  const needsToPickTable =
    attributes.fulfilmentChosen &&
    attributes.fulfilmentChosen.id === 'table' &&
    !attributes.table

  const goToCheckout = () => {
    closeCart()

    const deliveryZone = get(
      attributes,
      'deliveryZone.name',
      'undefined' // Maintain undefined for table service re order and to stop deadlink to takeawys page
    ).replace(/ /g, '-')

    const checkoutRoute = `${
      isListMode ? `/${deliveryZone}-takeaways` : ''
    }/${id}/${kebabCase(restaurantName)}-checkout`
    history.push(checkoutRoute)
  }

  return (
    <Button
      disabled={isCheckoutButtonDisabled()}
      square
      fullWidth
      positive
      className={buttonClass}
      onClick={() => {
        if (isOnline) {
          if (needsToPickTable) {
            openTablePicker(goToCheckout)
          } else {
            goToCheckout()
          }
        }
      }}
    >
      {text}
    </Button>
  )
}

export default withRouter(CheckoutButton)
