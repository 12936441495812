import React, { useContext } from 'react'
import CheckoutForm from './CheckoutForm'
import Hero from '../Hero/Hero'
import { OrderContext } from '@context/Order.context'
import { GTMContext } from '@context/GTM.context'
import { BasketContext } from '@context/Basket.context'

const Checkout = props => {
  const gtmContext = useContext(GTMContext)
  const orderContext = useContext(OrderContext)
  const basketContext = useContext(BasketContext)
  return (
    <>
      <Hero />
      <CheckoutForm
        gtmContext={gtmContext}
        orderContext={orderContext}
        basketContext={basketContext}
        {...props}
      />
    </>
  )
}

export default Checkout
