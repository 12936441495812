import React from 'react'
import { Link } from 'react-scroll'
import { object, string, shape, array } from 'prop-types'

const CategoryListItem = ({ classes, category }) => {
  const { sideBarLink, activeIndicator, sideBarSubMenuLink } = classes
  const { id, name, subMenus } = category

  return (
    <li key={id}>
      <Link
        key={id}
        to={id}
        smooth={true}
        className={sideBarLink}
        duration={700}
        spy={true}
      >
        <span className={activeIndicator} /> {name}
      </Link>

      {subMenus.map(sub => (
        <Link
          key={sub.id}
          to={sub.id}
          smooth={true}
          className={sideBarSubMenuLink}
          duration={700}
          spy={true}
        >
          - {sub.name}
        </Link>
      ))}
    </li>
  )
}

CategoryListItem.propTypes = {
  classes: object,
  category: shape({
    id: string.isRequired,
    name: string.isRequired,
    subMenus: array.isRequired,
  }).isRequired,
}

export default CategoryListItem
