import React, { useContext, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { OrderContext } from '@context/Order.context'
import { BasketContext } from '@context/Basket.context'
import { ModalContext } from '@context/Modal.context'

const Content = ({
  head: Head,
  component: Component,
  onEnter,
  path,
  ...props
}) => {
  const orderContext = useContext(OrderContext)
  const basketContext = useContext(BasketContext)
  const modalContext = useContext(ModalContext)

  useEffect(() => {
    if (orderContext.ready) {
      onEnter &&
        onEnter({ ...props, orderContext, basketContext, modalContext })
    }
  }, [orderContext.ready, path])

  return (
    <>
      {Head && <Head {...props} />}
      <Component {...props} />
    </>
  )
}

const CustomRoute = ({ path, product, component, head, onEnter, ...rest }) => {
  return (
    <Route
      {...rest}
      path={path}
      render={props => {
        const compProps = {
          component,
          head,
          onEnter,
          ...props,
        }
        compProps.location.product = product
        return <Content {...compProps} path={path} />
      }}
    />
  )
}

export default CustomRoute
