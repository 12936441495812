import button from '@styles/sharedJSS/buttons'

const styles = theme => ({
  wrapper: {
    position: 'relative',
    minHeight: '100vh',
  },
  title: {
    fontSize: '50px',
    padding: '4em 1em 1em 1em',
    textAlign: 'center',
    color: 'white',
  },
  home: {
    padding: '0 1em',
    display: 'block',
    textAlign: 'center',
    fontSize: '25px',
  },
  cta: {
    ...button(theme),
    fontSize: '25px',
  },
})

export default styles
