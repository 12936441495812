import React, { useContext, useEffect } from 'react'
import withStyles from 'react-jss'
import styles from './paymentMethodSectionStyles'
import cx from 'classnames'
import { OrderContext } from '@context/Order.context'
import { flatMap } from 'lodash'
import { Array, Set } from 'window-or-global'
import { getMarketplace } from '@config/config'

const PaymentMethodSection = ({ classes, outlet }) => {
  const { attributes, updateOrder } = useContext(OrderContext)
  const { paymentMethod: chosenPaymentMethod, fulfilmentChosen } = attributes

  const assignDefaultOptionIfOnlyOne = () => {
    const availablePaymentMethods = getAvailablePaymentMethods()

    const paymentMethodLabels = Array.from(
      new Set(flatMap(availablePaymentMethods, method => method.label))
    )
    if (paymentMethodLabels.length === 1) {
      setPaymentMethod(availablePaymentMethods[0])
    }
  }

  const getAvailablePaymentMethods = () => {
    if (fulfilmentChosen && fulfilmentChosen.id.toUpperCase() === 'NETWORK') {
      return [
        {
          id: 'CARD_ON_DELIVERY',
          label: 'Card',
        },
      ]
    }

    if (fulfilmentChosen && fulfilmentChosen.id.toUpperCase() === 'TABLE') {
      return [{ id: 'CARD_ON_DELIVERY', label: 'Card' }]
    }

    const availablePaymentMethods = outlet.paymentMethods
    const availableOptions = [
      {
        id: 'CASH_ON_COLLECTION',
        label: getMarketplace().cashCollectionText,
      },
      {
        id: 'CARD_ON_COLLECTION',
        label: 'Card',
      },
      {
        id: 'CARD_ON_DELIVERY',
        label: 'Card',
      },
      {
        id: 'CASH_ON_DELIVERY',
        label: getMarketplace().cashText,
      },
    ].filter(({ id }) => availablePaymentMethods.includes(id))
    return availableOptions.filter(methodAvailable => {
      return methodAvailable.id
        .toUpperCase()
        .includes(fulfilmentChosen.id.toUpperCase())
    })
  }

  const setPaymentMethod = paymentMethod => {
    updateOrder({
      paymentMethod,
    })
  }

  useEffect(() => {
    assignDefaultOptionIfOnlyOne()
  }, [outlet.paymentMethods.map(({ id }) => id).join('-'), fulfilmentChosen.id])

  const availablePaymentMethods = getAvailablePaymentMethods()

  return (
    <fieldset>
      {availablePaymentMethods.map(paymentMethod => {
        const isActive =
          chosenPaymentMethod && chosenPaymentMethod.id === paymentMethod.id
        return (
          <div
            key={paymentMethod.id}
            className={cx(classes.fieldRow, {
              [classes.active]: isActive,
              [classes.inactive]: !isActive,
            })}
            onClick={() => {
              setPaymentMethod(paymentMethod)
            }}
          >
            <input
              id={paymentMethod.id}
              name="paymentMethod"
              type="radio"
              className={classes.hiddenInput}
              checked={isActive}
            />
            <span
              className={cx(classes.activeIndicator, {
                [classes.activeIndicatorisActive]: isActive,
              })}
            />
            <label htmlFor={paymentMethod.id} className={classes.label}>
              {paymentMethod.label}
            </label>
          </div>
        )
      })}
    </fieldset>
  )
}

export default withStyles(styles)(PaymentMethodSection)
