import React, { Fragment } from 'react'
import times from 'lodash/times'
import { ReactSVG } from 'react-svg'
import star from '@images/stars/star.svg'
import halfStar from '@images/stars/star-half.svg'
import emptyStar from '@images/stars/star-empty.svg'
import injectSheet from 'react-jss'
import { object, string, number } from 'prop-types'

const style = {
  svgStar: {
    width: '1rem',
    marginRight: '2px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  starsInRow: {
    display: 'flex',
    flexDirection: 'row',
  },
}

const handleMouseClickSAFELY = () => void 0

const StarsRating = ({
  classes,
  value,
  stars = 6,
  isInteractive = false,
  handleMouseClick,
}) => {
  // ensure no error triggers if not interactive
  if (!isInteractive) handleMouseClick = handleMouseClickSAFELY

  // full stars
  const nmbFullStars = Math.floor(value / 1)

  // half stars
  let nmbHalfStars = 0
  const remainder = value % 1
  if (remainder > 0.2 && remainder <= 0.7) {
    // convert .4 into one half a star
    nmbHalfStars = 1
  } else if (remainder > 0.7) {
    // convert .9 into a full star
    nmbFullStars += 1
  }

  // empty stars
  const nmbEmptyStars = stars - nmbFullStars - nmbHalfStars

  return (
    <div className={classes.container}>
      <div className={classes.starsInRow}>
        {times(nmbFullStars, index => (
          <ReactSVG
            key={index}
            className={classes.svgStar}
            src={star}
            onClick={() => handleMouseClick(index)}
          />
        ))}
        {times(nmbHalfStars, index => (
          <ReactSVG key={index} className={classes.svgStar} src={halfStar} />
        ))}
        {times(nmbEmptyStars, index => (
          <ReactSVG
            key={index}
            className={classes.svgStar}
            src={emptyStar}
            onClick={() => handleMouseClick(nmbFullStars + index)}
          />
        ))}
      </div>
    </div>
  )
}

StarsRating.propTypes = {
  classes: object,
  title: string,
  value: number,
  stars: number,
}

export default injectSheet(style)(StarsRating)
