import React from 'react'
import injectSheet from 'react-jss'

const DiscountRow = ({ classes, discount }) => {
  const { name, discountPercentage, discountAmount, minimumSubtotalGross } =
    discount

  let discountPrase = ''
  if (discountPercentage) {
    discountPrase = `${discountPercentage}% off`
  } else if (discountAmount) {
    discountPrase = `£${Number(discountAmount / 100).toFixed(2)} off`
  }
  if (minimumSubtotalGross) {
    discountPrase =
      discountPrase +
      ` orders over £${Number(minimumSubtotalGross / 100).toFixed(2)}`
  } else {
    discountPrase = discountPrase + ' on any order value'
  }

  let discountLozengeContent = discountPercentage
    ? `${discountPercentage}% OFF`
    : `£${(discountAmount / 100).toFixed(2)} OFF`

  return (
    <div className={classes.singleDiscountRow}>
      <h6 className={classes.discountTitle}>{name}</h6>
      <span className={classes.discountDesc}>{discountPrase}</span>
      <span className={classes.discountLozenge}>{discountLozengeContent}</span>
    </div>
  )
}

const Discounts = ({ classes, discounts }) =>
  discounts && discounts.length ? (
    <div className={classes.discountWrapper}>
      {discounts.map(discount => (
        <DiscountRow key={discount.id} discount={discount} classes={classes} />
      ))}
    </div>
  ) : null

const styles = theme => ({
  discountWrapper: {
    paddingTop: '5px',
  },
  singleDiscountRow: {
    padding: '1.2rem',
    lineHeight: '1.5rem',
    position: 'relative',
    '@media print': {
      display: 'none',
    },
  },
  discountTitle: {
    color: '#00bf94',
    fontSize: '14px',
    fontWeight: 700,
    marginBottom: '3px',
    marginRight: '90px',
  },
  discountDesc: {
    color: theme.bc5,
    opacity: 0.7,
    fontSize: '13px',
    fontWeight: 500,
  },
  discountLozenge: {
    color: '#00bf94',
    border: `2px solid #00bf94`,
    padding: '2px 8px',
    fontSize: '11px',
    fontWeight: 700,
    top: '14px',
    right: '1.2rem',
    position: 'absolute',
    display: 'block',
    borderRadius: '5px',
  },
})

export default injectSheet(styles)(Discounts)
