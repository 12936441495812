import React, { useContext } from 'react'
import PerfectScrollBar from '@components/PerfectScrollBar/PerfectScrollBar'
import CartItem from './CartItems/CartItem'
import { BasketContext } from '@context/Basket.context'

const CartItems = ({ classes, canEdit }) => {
  const { items, removeItem } = useContext(BasketContext)

  return (
    <PerfectScrollBar
      options={{
        className: classes.cartItems,
        options: { suppressScrollX: true },
      }}
    >
      {items.length && items.length > 0 ? (
        items.map((item, i) => (
          <CartItem
            item={item}
            classes={classes}
            key={i}
            removeSingleItem={item => removeItem(item.id)}
            canEdit={canEdit}
          />
        ))
      ) : (
        <p className={classes.noItemsMessage}>Your basket is empty.</p>
      )}
    </PerfectScrollBar>
  )
}

export default CartItems
