import React, { useContext } from 'react'
import Query from '@components/Query/Query'
import gql from 'graphql-tag'
import startCase from 'lodash/startCase'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import { getMarketplace } from '@config/config'
import { OrderContext } from '@context/Order.context'

const GET_RESTAURANT = gql`
  query getrestaurants($id: String!) {
    outlet(id: $id) {
      id
      restaurant {
        id
        name
      }
    }
  }
`

const RestaurantCheckoutHead = ({
  match: {
    params: { outletId },
  },
  marketplace = getMarketplace(),
}) => {
  const { deliveryZone } = useContext(OrderContext)
  const { merchantType } = marketplace

  return (
    <Query
      query={GET_RESTAURANT}
      variables={{ id: outletId }}
      context={{ version: 2 }}
    >
      {data => {
        const name = get(data, 'outlet.restaurant.name', '')
        return (
          <Helmet>
            <title>{`${startCase(name)} Checkout | ${
              deliveryZone && deliveryZone.name
                ? `${deliveryZone.name.replace(/-/g, ' ')} ${
                    merchantType === 'RETAIL' ? 'Businesses' : 'Takeaways'
                  } | `
                : ''
            }Order from ${marketplace.name}`}</title>
          </Helmet>
        )
      }}
    </Query>
  )
}

export default RestaurantCheckoutHead
