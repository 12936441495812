import React, { useContext } from 'react'
import { withStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import styles from './deliveryZoneModalStyles'
import { object, string } from 'prop-types'
import { getMarketplace } from '@config/config'
import BodyLocker from '../../utils/BodyLocker'
import { OrderContext } from '@context/Order.context'

const DeliveryZonesModalEl = ({
  classes,
  cuisine,
  close,
  persistPath = false,
}) => {
  const { updateOrder } = useContext(OrderContext)
  const { deliveryZones = [] } = getMarketplace()
  return (
    <div className={classes.modalContainer}>
      <div className={classes.titleArea}>
        <h2>{cuisine ? `Ordering ${cuisine} from` : 'Ordering from'}</h2>
      </div>

      <ul className={classes.inner}>
        {deliveryZones.map(({ id, name }) => {
          const nameFix = name.toLowerCase().replace(/ /g, '-')
          return (
            <li key={id} className={classes.button}>
              <Link
                to={
                  persistPath
                    ? location.pathname
                        .split('/')
                        .map((p, i) => (i === 1 ? `${nameFix}-takeaways` : p))
                        .join('/')
                    : {
                        pathname: `/${nameFix}-takeaways`,
                        search: cuisine
                          ? `?cuisine=${cuisine.toLowerCase()}`
                          : '',
                      }
                }
                onClick={() => {
                  BodyLocker.unlock()
                  if (close) {
                    close()
                  }
                  updateOrder({
                    deliveryZone: { id, name: nameFix },
                  })
                }}
              >
                {name}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

DeliveryZonesModalEl.propTypes = {
  classes: object,
  cuisine: string,
}

const DeliveryZonesModal = withStyles(styles)(DeliveryZonesModalEl)
export default DeliveryZonesModal
