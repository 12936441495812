import React from 'react'
import ReactDOM from 'react-dom'
import { ModalContext } from './index'
import { CSSTransition } from 'react-transition-group'
import injectSheet from 'react-jss'
import styles from '@components/ModalWrapper/modalWrapperStyles'

const Modal = ({ classes }) => {
  const {
    modalContent,
    closeModal,
    modalOpen,
    closeOnClickOutside,
  } = React.useContext(ModalContext)

  return ReactDOM.createPortal(
    <CSSTransition
      in={modalOpen}
      unmountOnExit
      timeout={300}
      classNames={{
        enter: classes.modalEnter,
        enterActive: classes.modalEnterActive,
        enterDone: classes.modalEnterDone,
        exit: classes.modalExit,
        exitActive: classes.modalExitActive,
        exitDone: classes.modalExitDone,
      }}
    >
      <div
        className={classes.modalOverlay}
        onClick={() => {
          if (closeOnClickOutside) {
            closeModal()
          }
        }}
      >
        <div className={classes.modalView}>{modalContent}</div>
      </div>
    </CSSTransition>,

    document.querySelector('#modal-root')
  )
}

export default injectSheet(styles)(Modal)
