import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { string, object, func, bool, shape, number } from 'prop-types'
import { withStyles } from 'react-jss'
import OptionsPopover from './OptionsPopover'
import styles from './optionsPopoverStyles'

const OptionsPopoverWrapper = ({
  classes,
  isVisible,
  close,
  outletId,
  menuItem,
}) => (
  <div
    className={classes.modalOverlay}
    id="overlay"
    onClick={e => {
      if (e.target.id === 'overlay') close()
    }}
  >
    <div className={classes.modalWrapperDiv}>
      <CSSTransition
        in={isVisible}
        timeout={300}
        classNames={{
          enter: classes.modalEnter,
          enterActive: classes.modalEnterActive,
          enterDone: classes.modalEnterDone,
          exit: classes.modalExit,
          exitActive: classes.modalExitActive,
          exitDone: classes.modalExitDone,
        }}
        unmountOnExit
      >
        <OptionsPopover
          menuItem={menuItem}
          classes={classes}
          close={close}
          outletId={outletId}
        />
      </CSSTransition>
    </div>
  </div>
)

OptionsPopoverWrapper.propTypes = {
  classes: object,
  isVisible: bool,
  close: func,
  item: shape({
    id: string,
    name: string,
    price: number,
  }),
}
export default withStyles(styles)(OptionsPopoverWrapper)
