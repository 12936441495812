import fontStyles from '@styles/sharedJSS/fonts'
import colors from '@styles/sharedJSS/colors'

export const styles = {
  jacket: {
    position: 'relative',
  },

  content: {
    position: 'relative',
    padding: '0 0 0rem 0',

    '@media screen and (min-width: 768px)': {
      padding: '0 0 5rem 0',
    },
  },

  heading: {
    ...fontStyles.h3,
    color: colors.bc5,
    marginBottom: '3.6rem',

    '&:last-child': {
      marginBottom: 0,
    },

    '@media screen and (min-width: 768px)': {
      display: 'inline-block',
    },
  },

  headingLarge: {
    ...fontStyles.h2,
  },

  copy: {
    ...fontStyles.p,
    color: colors.bc5,
    marginBottom: '6rem',

    '&:last-child': {
      marginBottom: 0,
    },

    '& span': {
      display: 'inline',
      color: colors.bc3,
    },
  },

  list: {
    ...fontStyles.p,
    listStyle: 'decimal inside',
  },

  listItem: {
    marginBottom: '6rem',

    '@media screen and (min-width: 768px)': {
      marginBottom: '12rem',
    },

    '&:last-child': {
      marginBottom: 0,
    },
  },

  button: {
    padding: '1.6rem 2.4rem',
    display: 'inline-block',
    color: 'white',
    fontWeight: 800,
    backgroundColor: colors.bc3,
    fontSize: '1.4rem',
    borderRadius: '60px',
    textTransform: 'uppercase',
    boxShadow: `0px 0px 40px rgba(0, 0, 0, 0.20)`,
    textAlign: 'center',

    '@media screen and (min-width: 768px)': {
      textAlign: 'left',
    },

    '&:focus': { outline: 'none' },
  },

  download: {
    ...fontStyles.h3,
    color: colors.bc3,
    marginBottom: '3.6rem',
    display: 'block',

    '&:last-child': {
      marginBottom: 0,
    },
  },
}
