import { MEDIUM_MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from '@utils/breakpoints'
import Color from 'color'

const styles = theme => ({
  restaurantListItem: {
    position: 'relative',
    display: 'block',
    backgroundColor: 'white',
    padding: '1rem',
    lineHeight: '2.6rem',
    cursor: 'pointer',
    transition: `all 0.2s ease`,
    borderBottom: `1px solid ${Color(theme.bc5)
      .rgb()
      .alpha(0.1)
      .string()}`,

    '&:hover': {
      boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
    },

    '@media only screen and (min-width: 768px)': {
      padding: '1.2rem 1.2rem 1.2rem 1.2rem',
      border: 'none',
      marginBottom: '1.8rem',
      borderRadius: '0.4rem',
      boxShadow: `0px 0px 2rem rgba(0, 0, 0, 0.1)`,
    },
  },
  active: {
    opacity: 1,
  },
  inactive: {
    opacity: 0.5,
  },

  restaurantIcon: {
    display: 'inline-block',
    width: '8rem',
    height: '8rem',
    backgroundColor: theme.bc4,
    borderRadius: '4px',
    marginRight: '1.5rem',

    '@media only screen and (min-width: 48em)': {
      borderRadius: '0.4rem',
      height: '8rem',
      width: '8rem',
      marginBottom: 0,
    },
  },

  restaurantTitleContainer: {
    marginLeft: '1.5rem',
    verticalAlign: 'top',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,

    '@media only screen and (min-width: 48em)': {
      marginLeft: '2rem',
    },
  },

  restaurantTitle: {
    lineHeight: '16px',

    '& button': {
      color: theme.bc5,
      fontWeight: 600,
      padding: 0,
      background: 'none',
      border: 'none',
      fontSize: '1.6rem',
      textAlign: 'left',
      lineHeight: '21px',

      '@media only screen and (min-width: 48em)': {
        fontSize: '2rem',
        marginBottom: 0,
      },
    },

    '& p': {
      color: theme.bc5,
      opacity: 0.5,
      display: 'none',

      '@media only screen and (min-width: 48em)': {
        display: 'block',
      },
    },
  },

  cuisineslbl: {
    color: Color(theme.bc3)
      .rgb()
      .alpha(0.8)
      .string(),
    fontSize: '1.2rem',
    fontWeight: 600,
    lineHeight: '2rem',
    paddingTop: '1px',

    '@media only screen and (min-width: 48em)': {
      lineHeight: 'inherit',
    },
  },

  restaurantStats: {
    [`@media screen and (max-width: ${MEDIUM_MOBILE_BREAKPOINT}px)`]: {
      flexDirection: 'row',
      '& > div': {
        marginRight: '2.4rem',
      },
    },
    [`@media screen and (min-width: ${MEDIUM_MOBILE_BREAKPOINT}px)`]: {
      flexGrow: 1,
      flexBasis: 0,
    },
  },

  fulfilmentMethods: {
    [`@media screen and (max-width: ${MEDIUM_MOBILE_BREAKPOINT}px)`]: {
      display: 'none',
    },
    [`@media screen and (min-width: ${MEDIUM_MOBILE_BREAKPOINT}px)`]: {
      flexGrow: 1,
      flexBasis: 0,
    },
  },

  svgIcon: {
    verticalAlign: 'middle',
    marginRight: '0.7rem',

    '& svg': {
      width: '1.4rem',
      height: '1.4rem',
      fill: Color(theme.bc5)
        .rgb()
        .alpha(0.5)
        .string(),
    },
  },

  lozengeContainer: {
    top: '0',
    right: '0',
    margin: '0 0 0 0',
    position: 'absolute',
    maxWidth: '8rem',

    [`@media screen and (min-width: ${MEDIUM_MOBILE_BREAKPOINT}px)`]: {
      position: 'initial',
      maxWidth: 'initial',
    },
  },

  restaurantContainer: {
    display: 'flex',
    position: 'relative',
    fontSize: '1.2rem',
    fontWeight: 600,
  },

  restaurantInfoContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',

    [`@media screen and (min-width: ${MEDIUM_MOBILE_BREAKPOINT}px)`]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'initial',
    },
    [`@media screen and (min-width: ${TABLET_BREAKPOINT}px)`]: {
      alignItems: 'center',
    },
  },

  offersTag: {
    backgroundColor: '#3EBB42',
    color: 'white',
    position: 'absolute',
    margin: '-5px 0 0 -5px',
    padding: '0 2px 0 2px',
    fontWeight: 700,
    fontSize: '10px',
    width: '5rem',
    textAlign: 'center',
    borderRadius: '5px',
  },

  titleGroup: {
    display: 'flex',
    flexDirection: 'column',

    [`@media screen and (min-width: ${MEDIUM_MOBILE_BREAKPOINT}px)`]: {
      flexGrow: 2,
      flexShrink: 0,
      flexBasis: 0,
      marginRight: 'auto',
    },
  },

  dealsRow: {
    height: '28px',
    margin: '0px',
    border: '1px dashed #ffe594',
    paddingLeft: '10px',
    marginBottom: '12px',
    backgroundColor: '#fdf9ef',
    borderRadius: '4px',

    [`@media screen and (min-width: ${MEDIUM_MOBILE_BREAKPOINT}px)`]: {
      margin: '-12px -12px 12px -12px',
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
    },

    '@media print': {
      display: 'none',
    },
  },

  dealText: {
    color: '#eab300',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '26px',

    '& svg': {
      fill: '#ffd13e',
      height: '19px',
      width: '18px',
      float: 'left',
      marginTop: '3px',
      marginLeft: '-4px',
    },

    [`@media screen and (min-width: ${MEDIUM_MOBILE_BREAKPOINT}px)`]: {
      lineHeight: '27px',
      marginLeft: '-8px',
      '& svg': {
        width: '25px',
        height: '25px',
        marginTop: '-13px',
        marginLeft: '-21px',
      },
    },

    '@media print': {
      display: 'none',
    },
  },

  menuLink: {
    display: 'none',

    [`@media screen and (min-width: ${TABLET_BREAKPOINT}px)`]: {
      display: 'block',
      marginRight: '1.2rem',
    },
  },
})

export default styles
