export default theme => ({
  status: {
    margin: 'auto',
    display: 'inline-block',
    textAlign: 'center',
    padding: '3px 7px',
    fontSize: '1rem',
    fontWeight: 700,
    borderRadius: '5px',
    lineHeight: '15px',
    textTransform: 'uppercase',
    border: '1px solid #ff9800',
    color: '#ff9800',
    backgroundColor: '#fff',
  },
})
