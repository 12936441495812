// Imports
// ------
import React from 'react'
import injectSheet from 'react-jss'
import styles from '../GeneralContent/generalContentStyles'
import Header from '@components/Header/Header'
import Hero from '../GeneralContent/Hero/Hero'
import Content from '../GeneralContent/Content/Markdown'

import { getMarketplace } from '@config/config'
import GET_LEGAL from './queries/getLegal.query'
import Query from '@components/Query/Query'

// Component
// ------
class PrivacyEl extends React.PureComponent {
  render() {
    return (
      <>
        <Header branded />
        <Hero title="Privacy Policy" />
        <Query query={GET_LEGAL} variables={{ key: getMarketplace().key }}>
          {({ marketplace: { marketplace } }) => (
            <div className="wrapper">
              <Content>{marketplace.legalPrivacy}</Content>
            </div>
          )}
        </Query>
      </>
    )
  }
}

const Privacy = injectSheet(styles)(PrivacyEl)
export default Privacy
