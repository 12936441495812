import checkoutFormStyles from '../checkoutFormStyles'

const styles = {
  ...checkoutFormStyles,
  cardRow: {
    ...checkoutFormStyles.clickableRow,
    justifyContent: 'space-between',
    '&:hover $rowAction': {
      opacity: 1,
    },
  },
  emptyNotice: {
    fontSize: '1.3rem',
    fontStyle: 'italic',
    color: '#9e9e9e',
  },
}

export default styles
