import moment from 'moment'

// Note: a "valid" discount needs at least one week day selected
export const getDiscountApplied = (subTotalPence, discountsList) => {
  const discountsSet = new Set(discountsList)
  const discounts = Array.from(discountsSet)
  const now = moment()

  return discounts.reduce(
    (
      acc,
      {
        id,
        discountPercentage,
        discountAmount,
        minimumSubtotalGross,
        daysOfWeek,
        name,
        customerEnrolled,
        startDate,
        endDate,
      }
    ) => {
      let isAvailableToday = false // derived from the intersection of both time constraints
      if (daysOfWeek) {
        isAvailableToday = daysOfWeek.includes(now.isoWeekday())
      }
      if (startDate && endDate) {
        isAvailableToday =
          isAvailableToday &&
          now.isBetween(moment(startDate), moment(endDate), 'd', '[]')
      }

      const isCustomerSpendingEnough = subTotalPence >= minimumSubtotalGross

      if ((isAvailableToday && isCustomerSpendingEnough) || customerEnrolled) {
        let discount = 0
        if (discountPercentage) {
          discount = discountPercentage * (subTotalPence / 100)
        }
        if (discountAmount) {
          discount = discountAmount
        }

        if (discount > acc.discount) {
          acc = {
            id,
            name,
            discount: Math.floor(discount),
            customerEnrolled,
          }
        }
      }

      return acc
    },
    {
      id: '',
      name: '',
      discount: 0,
      customerEnrolled: false,
    }
  )
}
