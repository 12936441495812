// Imports
// ------
import root from 'window-or-global'

// Exports
// ------
class BodyLocker {
  scrollY = 0
  getCurrentLockPos = () => this.scrollY
  lock = () => {
    const El = document.querySelector('body')
    this.scrollY = root.scrollY
    El.style.top = `${-this.scrollY}px`
    El.style.position = 'fixed'
    El.style.overflow = 'hidden'
  }

  unlock = () => {
    const El = document.querySelector('body')
    El.style.position = 'initial'
    El.style.overflow = 'initial'
    El.style.top = 'initial'
    root.scrollTo(0, this.scrollY)
    this.scrollY = 0
  }
}

let inst = new BodyLocker()
export default inst
