import React, { useContext, useEffect, useState } from 'react'
import { withStyles } from 'react-jss'
import PropTypes from 'prop-types'
import styles from './registerStyles'
import { client } from '@services/client'
import { Mutation } from 'react-apollo'
import REGISTER from './register.mutation'
import Loading from '@components/LoadingSpinner/LoadingSpinner'
import { getMarketplace } from '@config/config'
import { toast } from 'react-toastify'
import ls from '@utils/localStorage'
import { TOAST_TIMEOUT } from '@utils/helpers'
import { Link, withRouter } from 'react-router-dom'
import BodyLocker from '../../utils/BodyLocker'
import Button from '@components/Button/Button'
import { GTMContext } from '@context/GTM.context'
import PhoneInput from 'react-phone-number-input/input'

const DEFAULT_FIELD_VALUES = {
  email: {
    value: '',
    errors: [],
    isDirty: false,
  },
  password: { value: '', errors: [], isDirty: false },
  confirmpassword: { value: '', errors: [], isDirty: false },
  firstName: { value: '', errors: [], isDirty: false },
  lastName: { value: '', errors: [], isDirty: false },
  phoneNumber: { value: '', errors: [], isDirty: false },
}

const PHONE_NUMBER_REGEX =
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/

const Register = ({
  classes,
  enrolmentKey = '',
  location,
  history,
  closeRegisterAndAcceptDiscountModal,
}) => {
  const { pushDataToGTM } = useContext(GTMContext)
  const [fields, setFields] = useState(DEFAULT_FIELD_VALUES)

  const validators = {
    phoneNumber: val => {
      const errors = []
      if (!PHONE_NUMBER_REGEX.test(val)) {
        errors.push(
          'Phone number is invalid. Expected phone number to be formatted as either 01234567890 or +441234567890.'
        )
      }
      return errors
    },
    confirmpassword: val => {
      const errors = []
      if (val !== fields.password.value) {
        errors.push('Passwords do not match.')
      }
      return errors
    },
  }

  const canSubmit = Object.values(fields).every(
    ({ isDirty, errors }) => !isDirty || !errors.length
  )

  useEffect(() => {
    pushDataToGTM({ event: 'registration_started' })
  }, [])

  const close = () => {
    history.push('/')

    if (closeRegisterAndAcceptDiscountModal) {
      closeRegisterAndAcceptDiscountModal()
    }

    client.writeData({
      data: {
        registerOpen: false,
      },
    })
    BodyLocker.unlock()
  }

  const handleBlur = event => {
    const {
      target: { name: fieldName, value: fieldValue },
    } = event
    const defaultValue = DEFAULT_FIELD_VALUES[fieldName].value
    const existingField = fields[fieldName]
    setFields({
      ...fields,
      [fieldName]: {
        ...existingField,
        isDirty: fieldValue !== defaultValue,
      },
    })
  }

  const handleChange = event => {
    const {
      target: { name: fieldName, value: fieldValue },
    } = event
    const validator = validators[fieldName]
    const existingField = fields[fieldName]
    setFields({
      ...fields,
      [fieldName]: {
        ...existingField,
        value: fieldValue,
        errors: validator ? validator(fieldValue) : [],
      },
    })
  }

  const handleSubmit = (e, register) => {
    e.preventDefault()
    const fieldsWithValuesOnly = Object.entries(fields).reduce(
      (acc, [fieldName, { value }]) => {
        acc[fieldName] = value
        return acc
      },
      {}
    )
    register({
      variables: {
        ...fieldsWithValuesOnly,
        phoneNumber: '+' + fieldsWithValuesOnly.phoneNumber,
        marketplaceKey: getMarketplace().key,
        enrolmentKey,
      },
    })
  }

  const renderErrors = fieldName =>
    fields[fieldName].isDirty &&
    fields[fieldName].errors.map(errorText => (
      <p key={errorText} className={classes.error}>
        {errorText}
      </p>
    ))

  return (
    <Mutation
      mutation={REGISTER}
      onCompleted={({ register }) => {
        ls.set('jwt', register.token)

        pushDataToGTM({
          event: 'sign_up',
        })

        const { pathname } = location
        if (pathname === '/login') {
          history.push('/')
        }
        if (pathname === '/register-and-accept-discount') {
          history.push('/')
          closeRegisterAndAcceptDiscountModal()
          toast.success(
            'Accepted, your discount will be automatically applied at checkout.',
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 4000,
            }
          )
        }
        close()
      }}
      onError={error => {
        for (const gqlError of error.graphQLErrors) {
          if (gqlError.code === 'VALIDATION_ERROR') {
            for (const validationError of gqlError.data.errors) {
              toast.error(validationError, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: TOAST_TIMEOUT,
              })
            }
          } else {
            toast.error(gqlError.message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: TOAST_TIMEOUT,
            })
          }
        }
      }}
    >
      {(register, { loading }) => {
        return (
          <form
            onSubmit={event => handleSubmit(event, register)}
            className={classes.container}
          >
            <Loading show={loading} />
            <div className={classes.formJacket}>
              {/* Title */}
              <div className={classes.titleJacket}>
                <h2 className={classes.title}>Register</h2>
                <button type="button" onClick={close} className={classes.close}>
                  Close
                </button>
              </div>

              {/* First Name */}
              <div className={classes.field}>
                <label htmlFor="firstName" className={classes.label}>
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  className={classes.input}
                  autoComplete="firstName"
                  onChange={handleChange}
                  value={fields.firstName.value}
                  onBlur={handleBlur}
                />
                {renderErrors('firstName')}
              </div>
              {/* Last Name */}
              <div className={classes.field}>
                <label htmlFor="lastName" className={classes.label}>
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  autoComplete="lastName"
                  className={classes.input}
                  onChange={handleChange}
                  value={fields.lastName.value}
                  onBlur={handleBlur}
                />
                {renderErrors('lastName')}
              </div>

              {/* Email */}
              <div className={classes.field}>
                <label htmlFor="email" className={classes.label}>
                  Your Email
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  autoComplete="email"
                  className={classes.input}
                  onChange={handleChange}
                  value={fields.email.value}
                  onBlur={handleBlur}
                />
                {renderErrors('email')}
              </div>

              {/* Contact Number */}
              <div className={classes.field}>
                <label htmlFor="phoneNumber" className={classes.label}>
                  Contact Number
                </label>

                <PhoneInput
                  value={fields.phoneNumber.value}
                  name="phoneNumber"
                  country="GB"
                  className={classes.input}
                  onChange={value => {
                    setFields({
                      ...fields,
                      phoneNumber: {
                        ...fields.phoneNumber,
                        value,
                      },
                    })
                  }}
                />
                {renderErrors('phoneNumber')}
              </div>

              {/* Password */}
              <div className={classes.field}>
                <label htmlFor="password" className={classes.label}>
                  Choose a Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  autoComplete="password"
                  className={classes.input}
                  onChange={handleChange}
                  value={fields.password.value}
                  onBlur={handleBlur}
                />
                {renderErrors('password')}
              </div>

              {/* Confirm Password */}
              <div className={classes.field}>
                <label htmlFor="confirmpassword" className={classes.label}>
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirmpassword"
                  name="confirmpassword"
                  autoComplete="confirmpassword"
                  className={classes.input}
                  onChange={handleChange}
                  value={fields.confirmpassword.value}
                  onBlur={handleBlur}
                />
                {renderErrors('confirmpassword')}
              </div>

              {/* Terms & Conditions */}
              <div className={classes.field}>
                <p className={classes.termsText}>
                  By clicking "Register" you are accepting our{' '}
                  <Link to="/terms/" onClick={close}>
                    Terms &amp; Conditions
                  </Link>
                </p>
              </div>

              <Button
                type="submit"
                className={classes.submit}
                appearance="positive"
                fullWidth
                capitalize
                disabled={!canSubmit}
              >
                Register
              </Button>
            </div>
          </form>
        )
      }}
    </Mutation>
  )
}

Register.propTypes = {
  history: PropTypes.object,
  enrolmentKey: PropTypes.string,
  closeRegisterAndAcceptDiscountModal: PropTypes.func,
  classes: PropTypes.object,
}

export default withRouter(withStyles(styles)(Register))
