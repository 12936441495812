const styles = theme => ({
  subLabel: {
    lineHeight: '20px',
  },
  chosenTimeLbl: {
    color: '#4d4d4d',
    padding: '12px 15px 9px',
    fontSize: '12px',
    fontWeight: 700,
    borderBottom: '1px solid #cfdbe0',
    boxShadow: '0px 2px 1px #0000000f',
    cursor: 'pointer',
    position: 'relative',

    '&:hover': {
      backgroundColor: '#f5f7fb',
    },
  },

  change: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '15px',

    color: theme.positive,
  },

  chosenTimeLblDisabled: {
    cursor: 'not-allowed',
    backgroundColor: 'transparent !important',
  },

  displayedTime: {
    fontSize: '12px',
    fontWeight: 800,
  },

  displayedTimePreOrder: {
    color: '#ff9800',
  },

  subLblTitle: {
    opacity: 0.8,
    display: 'block',
  },
  noTimes: {
    display: 'block',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: 'red',
  },
})

export default styles
