import React, { Component } from 'react'
import { withStyles } from 'react-jss'
import styles from './footerStyles'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import Twitter from '@components/SocialIcons/Twitter'
import Facebook from '@components/SocialIcons/Facebook'
import Instagram from '@components/SocialIcons/Instagram'
import { getMarketplace } from '@config/config'
import { compact, isEmpty } from 'lodash'
import googlePlayImage from './google-play-badge.png'
import appStoreImage from './app-store-badge.svg'

class FooterEl extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      name: marketplaceName,
      socialURLFacebook,
      socialURLInstagram,
      socialURLTwitter,
      storeURLApple,
      storeURLGooglePlay,
      contactAddress,
      supportEmail,
      supportPhone,
      legalTerms,
      legalPrivacy,
      legalCookies,
      legalPolicy,
      legalAllergy,
      footerLinks,
    } = getMarketplace()

    const { classes } = this.props
    const {
      location: { product },
    } = this.props.history
    const noMargin = ['fourZeroFour', 'home'].some(
      productName => productName === product
    )

    const { firstLine, secondLine, city, postcode, country } = contactAddress
    const { name: countryName } = country

    const addressString = compact([
      firstLine,
      secondLine,
      city,
      countryName,
      postcode,
    ]).join(', ')
    return (
      <footer className={cx(classes.footer, { [classes.noMargin]: noMargin })}>
        <div className={classes.footerContainer}>
          <div className={classes.footerItem}>
            <h5 className={classes.title}>Apps & Social</h5>
            <ul className={classes.footerList}>
              {!isEmpty(storeURLApple) && (
                <li>
                  <a
                    href={storeURLApple}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={appStoreImage}
                      className={classes.appleStoreLogo}
                    />
                  </a>
                </li>
              )}
              {!isEmpty(storeURLGooglePlay) && (
                <li>
                  <a
                    href={storeURLGooglePlay}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={googlePlayImage}
                      style={{
                        width: '164px',
                        height: '70px',
                        marginTop: '-15px',
                        marginLeft: '-10px',
                      }}
                    />
                  </a>
                </li>
              )}
              <div className={classes.socialMediaIcons}>
                {!isEmpty(socialURLFacebook) && (
                  <span className={classes.socialIcon}>
                    <a
                      href={socialURLFacebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Facebook />
                    </a>
                  </span>
                )}
                {!isEmpty(socialURLTwitter) && (
                  <span className={classes.socialIcon}>
                    <a
                      href={socialURLTwitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Twitter />
                    </a>
                  </span>
                )}
                {!isEmpty(socialURLInstagram) && (
                  <span className={classes.socialIcon}>
                    <a
                      href={socialURLInstagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Instagram />
                    </a>
                  </span>
                )}
              </div>
            </ul>
          </div>

          {footerLinks.length ? (
            <div className={classes.footerItem}>
              <h5 className={classes.title}>Explore</h5>
              <ul className={classes.footerList}>
                {footerLinks.map(link => (
                  <li key={link.title}>
                    <a
                      href={link.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}

          {/* Legal */}
          <div className={classes.footerItem}>
            <h5 className={classes.title}>Policies</h5>

            <ul className={classes.footerList}>
              {!isEmpty(legalTerms) && (
                <li>
                  <Link to="/terms/">Terms &amp; Conditions</Link>
                </li>
              )}
              {!isEmpty(legalPrivacy) && (
                <li>
                  <Link to="/privacy/">Privacy Policy</Link>
                </li>
              )}
              {!isEmpty(legalCookies) && (
                <li>
                  <Link to="/cookies/">Cookie Policy</Link>
                </li>
              )}
              {!isEmpty(legalPolicy) && (
                <li>
                  <Link to="/misc/">Misc Policies</Link>
                </li>
              )}
              {!isEmpty(legalAllergy) && (
                <li>
                  <Link to="/allergy/">Allergy Policies</Link>
                </li>
              )}
            </ul>
          </div>

          {/* Contact Information */}
          <div className={classes.footerItem}>
            <h5 className={classes.title}>Contact</h5>

            <p className={classes.address}>
              <strong>{marketplaceName}</strong>
              {addressString}
              {!isEmpty(supportEmail) && (
                <>
                  <br />
                  <br />
                  Email:
                  <br /> {supportEmail}
                </>
              )}
              {!isEmpty(supportPhone) && (
                <>
                  <br />
                  <br />
                  Phone:
                  <br /> {supportPhone}
                </>
              )}
            </p>
          </div>
        </div>
      </footer>
    )
  }
}

const Footer = withStyles(styles)(withRouter(FooterEl))
export default Footer
