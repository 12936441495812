export default theme => ({
  container: {
    padding: '1rem',
  },
  question: {
    fontSize: '1.6rem',
    fontWeight: '500',
    marginBottom: '1rem',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})
