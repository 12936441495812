import React from 'react'
import { withStyles } from 'react-jss'
import Hero from '@components/Hero/Hero'
import styles from './FourZeroFourStyles'
import { Link } from 'react-router-dom'

const FourZeroFour = ({ classes }) => (
  <Hero branded>
    <div className={classes.wrapper}>
      <h1 className={classes.title}>
        Oops, we can't find the page you're looking for.
      </h1>

      <Link to="/" className={classes.home}>
        <button className={classes.cta}>Go Home</button>
      </Link>
    </div>
  </Hero>
)

export default withStyles(styles)(FourZeroFour)
