// Imports
// ------
import React from 'react'
import injectSheet from 'react-jss'
import { styles } from '../contentStyles'

// Component
// ------
class CopyEl extends React.PureComponent {
  render() {
    const { copy } = this.props.classes

    return <p className={copy}>{this.props.children}</p>
  }
}

const Copy = injectSheet(styles)(CopyEl)
export default Copy
