import React, { Component } from 'react'
import { withStyles } from 'react-jss'
import styles from './detailsStyles'
import cx from 'classnames'
import get from 'lodash/get'
import { object } from 'prop-types'
import Query from '@components/Query/Query'
import GET_OUTLET_DETAILS from './queries/getDetails.query'
import { penceToPounds } from '@utils/helpers'
import { formatOpenTimes } from '@utils/deliveryWindows'

class DetailsEl extends Component {
  constructor(props) {
    super(props)
  }

  openingtimeBracket(bracket, i, totalBrackets) {
    const { dayName, times, dayListingRow, bordered, gap } = this.props.classes

    const rowClass =
      i !== totalBrackets - 1
        ? cx(dayListingRow, bordered)
        : cx(dayListingRow, gap)

    return (
      <li className={rowClass} key={i}>
        <span className={dayName}>{bracket.day}</span>
        <span className={times}>{bracket.timeBracket}</span>
      </li>
    )
  }

  render() {
    const {
      detailsWrapper,
      detailHeader,
      section,
      para,
      dayName,
      times,
      dayListingRow,
      bordered,
    } = this.props.classes
    const { match, location } = this.props

    const showInactive =
      new URLSearchParams(location.search).get('showInactive') === 'true'

    return (
      <Query
        query={GET_OUTLET_DETAILS}
        variables={{ id: match.params.outletId, showInactive }}
        context={{ version: 2 }}
      >
        {data => {
          const outlet = get(data, 'outlet', {})
          const {
            outletAddress = {},
            openingTimes = [],
            restaurant = {},
          } = outlet
          const formattedOpeningTimes = formatOpenTimes(openingTimes)

          return (
            <div>
              <div className="col-xs-12">
                <div className={detailsWrapper}>
                  <div className={detailHeader}>
                    <h6>Address</h6>
                  </div>
                  <div className={section}>
                    <p className={para}>
                      {outletAddress.firstLine}
                      <br />
                      {outletAddress.secondLine}
                      <br />
                      {outletAddress.city}
                      <br />
                      {outletAddress.postcode}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xs-12">
                <div className={detailsWrapper}>
                  <div className={detailHeader}>
                    <h6>About</h6>
                  </div>
                  <div className={section}>
                    <p className={para}>{restaurant.description}</p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12">
                <div className={detailsWrapper}>
                  <div className={detailHeader}>
                    <h6>Minimum Order Values</h6>
                  </div>
                  <div className={section}>
                    <ul>
                      <li className={cx(dayListingRow, bordered)}>
                        <span className={dayName}>Min. Delivery Subtotal</span>
                        <span className={times}>
                          £{penceToPounds(outlet.deliveryMinimumOrderValue)}
                        </span>
                      </li>
                      <li className={cx(dayListingRow, bordered)}>
                        <span className={dayName}>
                          Min. Collection Subtotal
                        </span>
                        <span className={times}>
                          £{penceToPounds(outlet.collectionMinimumOrderValue)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xs-12">
                <div className={detailsWrapper}>
                  <div className={detailHeader}>
                    <h6>Ordering Times</h6>
                  </div>
                  <div className={section}>
                    <ul>
                      {formattedOpeningTimes.map((time, i) =>
                        this.openingtimeBracket(
                          time,
                          i,
                          formattedOpeningTimes.length
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </Query>
    )
  }
}

DetailsEl.propTypes = {
  classes: object,
  match: object,
}

const Details = withStyles(styles)(DetailsEl)
export default Details
