export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '3rem',
    fontSize: '1.4rem',
    alignItems: 'center',
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600,
    marginBottom: '1rem',
  },
  formContainer: {
    border: '1px solid lightgray',
    borderRadius: '5px',
    padding: '1rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
  },
  starsContainer: {
    transform: 'scale(3)',
    transformOrigin: 'left',
    margin: '1.4rem 0 1.6rem 0',
  },
  reviewTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  reviewTextClass: {
    margin: '1rem 0',
    resize: 'none',
    overflow: 'auto',
    height: '15rem',
    border: '1px solid lightgray',
    borderRadius: '5px',
  },
  reviewTextPostedClass: {
    margin: '1rem 0',
    resize: 'none',
    overflow: 'auto',
    height: '15rem',
    border: '1px solid lightgray',
    borderRadius: '5px',
    color: 'gray',
    padding: '1rem',
  },
  charCount: {
    color: 'lightgray',
  },
  charCountLimit: {
    color: 'red',
  },
  submitBtnContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: '1rem 0',
  },
  submitBtn: {
    padding: '1rem',
    border: '2px solid gray',
    borderRadius: '20px',
    '& :hover': {
      color: 'green',
    },
  },
  replyContainer: {
    margin: '1rem 0 0 2rem',
  },
  replyTitle: {
    fontWeight: 600,
  },
}
