import fontStyles from '@styles/sharedJSS/fonts'
import colors from '@styles/sharedJSS/colors'

export const styles = {
  jacket: {
    position: 'relative',
    zIndex: 1,
    padding: '10rem 0 6rem',
    background: '#1b1b1b',
    marginBottom: '6rem',

    '@media screen and (min-width: 768px)': {
      padding: '13rem 0 10rem',
      marginBottom: '12rem',
      marginTop: '-10rem',
    },
  },

  content: {
    position: 'relative',
    zIndex: 2,
  },

  titleContainer: {
    textAlign: 'center',
  },

  title: {
    ...fontStyles.h1,
    color: 'white',
  },

  imageContainer: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'hidden',
    opacity: 0.4,
  },

  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
  },
}
