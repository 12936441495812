import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import injectSheet from 'react-jss'
import styles from './requestResetStyles'

import ls from '@utils/localStorage'
import RESET_PASSWORD_REQUEST from './request.mutation'
import { client } from '@services/client'
import { getMarketplace } from '@config/config'
import { toast } from 'react-toastify'
import BodyLocker from '../../utils/BodyLocker'
import Button from '@components/Button/Button'

class RequestResetEl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      success: false,
    }
  }

  close = () => {
    this.props.close()
    BodyLocker.unlock()
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = (event, resetRequest) => {
    event.preventDefault()
    const resetMutation = resetRequest({
      variables: {
        email: this.state.email,
        marketplaceKey: getMarketplace().key,
      },
    })
    resetMutation.then(success => {
      this.setState({
        success: true,
      })
    })
    resetMutation.catch(error => {
      error.graphQLErrors.map((err, i) => {
        let message = 'Problem request password reset'
        switch (err.code) {
          case 'CLIENT_ERROR':
          case 'AUTHENTICATION_ERROR':
            message = err.message
            break
          default:
            message =
              err.data && err.data.errors
                ? err.data.errors.join(', ')
                : 'Problem request password reset'
            break
        }
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        })
      })
    })
  }

  render() {
    const {
      container,
      titleJacket,
      title,
      close,
      field,
      input,
      label,
      submit,
      successPara,
      loginMessage,
    } = this.props.classes
    const { success, email } = this.state

    return success ? (
      <div className={container}>
        <div className={titleJacket}>
          <h2 className={title}>Reset Sent</h2>
        </div>
        <div className={field}>
          <p className={successPara}>
            Password reset request has been sent to <strong>{email}</strong>{' '}
            <br />
            Please check your inbox for reset instructions.
          </p>
        </div>
        <div className={field}>
          <button className={submit} onClick={this.close}>
            OK
          </button>
        </div>
      </div>
    ) : (
      <Mutation mutation={RESET_PASSWORD_REQUEST}>
        {resetRequest => (
          <form
            onSubmit={event => this.handleSubmit(event, resetRequest)}
            className={container}
          >
            {/* Title */}
            <div className={titleJacket}>
              <h2 className={title}>Password Reset</h2>
              <button type="button" onClick={this.close} className={close}>
                Close
              </button>
            </div>

            <p className={loginMessage}>
              <span>
                Please enter your email address to receive reset instructions.
              </span>
            </p>

            {/* Username / Email */}
            <div className={field}>
              <label htmlFor="email" className={label}>
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                className={input}
                required
                onChange={this.handleChange}
              />
            </div>

            {/* Submit */}
            <div className={field}>
              <Button type="submit" className={submit}>
                Request Reset
              </Button>
            </div>
          </form>
        )}
      </Mutation>
    )
  }
}

const RequestReset = injectSheet(styles)(RequestResetEl)
export default RequestReset
