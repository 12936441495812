import { MOBILE_BREAKPOINT } from '@utils/breakpoints'

const styles = theme => ({
  isActive: {
    color: theme.bc3,
  },

  sidebarMobileTitle: {
    display: 'block',
    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      display: 'none',
    },
  },

  sidebarDesktopTitle: {
    display: 'none',
    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      display: 'block',
    },
  },

  activeIndicator: {
    float: 'left',
    height: '16px',
    display: 'inline-block',
    width: '16px',
    marginTop: '10px',
    backgroundColor: '#eaeff2',
    marginRight: '13px',
    borderRadius: '100%',
    transition: `all 0.5s ease`,
  },
  isOpen: {
    maxHeight: '100%',
    overflow: 'hidden',
    marginTop: '15px',
    '@media screen and (min-width: 768px)': {
      marginTop: 0,
    },
  },
  isClosed: {
    [`@media screen and (max-width: ${MOBILE_BREAKPOINT}px)`]: {
      maxHeight: '0px',
      overflow: 'hidden',
    },
  },
  triangle: {
    float: 'right',
    '& svg': {
      width: '11px',
      height: '10px',
      fill: '#98aebf',
      '@media screen and (min-width: 768px)': {
        display: 'none',
      },
    },
  },
  triangleUp: {
    transform: 'rotate(-180deg)',
  },
  indicatorActive: {
    backgroundColor: theme.bc3,
  },

  inactiveState: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },

  restaurantsNumber: {
    color: '#000',
    opacity: 0.4,
  },
})

export default styles
