import React, { useState } from 'react'
import styles from './header.styles'
import { withStyles } from 'react-jss'
import Hero from '../Hero/Hero'
import cx from 'classnames'
import Button from '@components/Button/Button'
import DeliveryTypeRouter from '@components/DeliverToPostcode/DeliveryTypeRouter'

const Header = ({
  classes,
  featureOrdering,
  heroText,
  heroTextSecondary,
  openDeliveryZoneModal,
  orderMode,
}) => {
  const [click, setClick] = useState(0)
  return (
    <Hero branded>
      <div className={cx('wrapper', classes.wrapper)}>
        <div className="container-fluid">
          <div
            className="row"
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '100px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h1 className={classes.title} style={{ textAlign: 'center' }}>
                {heroText}
              </h1>
              <p className={classes.strapline} style={{ textAlign: 'center' }}>
                {heroTextSecondary}
              </p>
              {featureOrdering && (
                <>
                  {orderMode === 'POSTCODE' ? (
                    <>
                      <div style={{ zIndex: 2 }}>
                        <DeliveryTypeRouter click={click} />
                      </div>
                      {/* close dropdown if clicked anywhere on the page - this can't be done in the DeliveryToPostcode component so needs to be passed down as a param */}
                      <div
                        style={{
                          width: '100vw',
                          height: '50vh',
                          position: 'absolute',
                          zIndex: 1,
                        }}
                        onClick={() => {
                          setClick(click + 1)
                        }}
                      ></div>
                    </>
                  ) : (
                    <Button
                      onClick={openDeliveryZoneModal}
                      className={classes.cta}
                      appearance="brandColor"
                    >
                      Start My Order
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Hero>
  )
}

export default withStyles(styles)(Header)
