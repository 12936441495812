import React from 'react'
import { Query } from 'react-apollo'
import GET_MY_REVIEWS from './queries/getMyReviews'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import get from 'lodash/get'
import ReviewsList from './ReviewsList'

const Reviews = props => {
  return (
    <section>
      <Query query={GET_MY_REVIEWS}>
        {({ data, loading, error }) => {
          if (loading) return <LoadingSpinner show={true} />
          if (error) return <div>Error</div>

          const reviews = get(data, 'myReviews.reviews', [])
          return <ReviewsList reviews={reviews} {...props} />
        }}
      </Query>
    </section>
  )
}

export default Reviews
