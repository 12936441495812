import { MOBILE_BREAKPOINT } from '@utils/breakpoints'
import fontStyles from '@styles/sharedJSS/fonts'

const styles = theme => ({
  jacket: {
    padding: '2.4rem',

    '@media screen and (min-width: 768px)': {
      padding: '4.8rem',
    },
  },

  fieldRow: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',

    [`@media screen and (min-width: ${768}px)`]: {
      marginTop: '15px',
      padding: '0 0.5rem',
    },
  },
  title: {
    ...fontStyles.h2,
    color: theme.bc3,
    marginBottom: '2.4rem',

    '@media screen and (min-width: 768px)': {
      marginBottom: '3.6rem',
    },
  },
  subtitle: {
    ...fontStyles.h4,
    color: theme.bc3,
    marginBottom: '0.4rem',

    '@media screen and (min-width: 768px)': {
      marginBottom: '0.6rem',
      marginTop: '1.4rem',
    },
  },
  label: {
    marginRight: 'auto',
    color: theme.bc3,
    cursor: 'pointer',
    fontSize: '15px',
    fontWeight: 500,
    maxWidth: '80%',
    lineHeight: 'normal',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      lineHeight: 'normal',
    },
  },
  buttonContainer: {
    marginTop: '3.6rem',
  },

  button: {
    padding: '1.6rem 2.4rem',
    display: 'block',
    color: 'white',
    width: '100%',
    fontWeight: 800,
    backgroundColor: theme.bc3,
    fontSize: '1.4rem',
    borderRadius: '60px',
    textTransform: 'uppercase',
    boxShadow: `0px 0px 40px rgba(0, 0, 0, 0.20)`,
    textAlign: 'center',

    '@media screen and (min-width: 768px)': {
      textAlign: 'left',
      display: 'inline-block',
      width: 'auto',
    },

    '&:focus': { outline: 'none' },
  },
})

export default styles
