import React from 'react'
import Query from '@components/Query/Query'
import GET_MARKETING_PREFERENCES from '@components/UserAccount/MarketingPreferences/queries/getMarketingPreferences.query'
import { Mutation } from 'react-apollo'
import UPDATE_MARKETING_PREFERENCES from '@components/UserAccount/MarketingPreferences/mutations/updateMarketingPreferences.mutation'
import MarketingPreferences from '@components/UserAccount/MarketingPreferences/MarketingPreferences'
import { toast } from 'react-toastify'
import { TOAST_TIMEOUT } from '@utils/helpers'

const MarketingPreferencesContainer = () => (
  <Query query={GET_MARKETING_PREFERENCES}>
    {({
      myDetails: {
        customer: {
          optOutNewRestaurantMarketing,
          optOutMenuMarketing,
          optOutPromotionMarketing,
          optOutMarketingSMS,
          optOutMarketingEmail,
          optOutMarketingPush,
        },
      },
    }) => (
      <Mutation
        mutation={UPDATE_MARKETING_PREFERENCES}
        context={{ version: 2 }}
        onCompleted={() =>
          toast.success(
            'Marketing Preferences Updated. Please allow 48 hours for the update to synchronize across all systems.',
            {
            position: toast.POSITION.TOP_CENTER,
            autoClose: TOAST_TIMEOUT,
          })
        }
      >
        {updateMarketingPreferences => (
          <MarketingPreferences
            initialValues={{
              newRestaurantMarketing: !optOutNewRestaurantMarketing,
              menuMarketing: !optOutMenuMarketing,
              promotionsMarketing: !optOutPromotionMarketing,
              marketingSMS: !optOutMarketingSMS,
              marketingEmail: !optOutMarketingEmail,
              marketingPush: !optOutMarketingPush,
            }}
            handleSubmit={values => {
              updateMarketingPreferences({
                variables: {
                  optOutNewRestaurantMarketing: !values.newRestaurantMarketing,
                  optOutMenuMarketing: !values.menuMarketing,
                  optOutPromotionMarketing: !values.promotionsMarketing,
                  optOutMarketingSMS: !values.marketingSMS,
                  optOutMarketingEmail: !values.marketingEmail,
                  optOutMarketingPush: !values.marketingPush,
                },
              })
            }}
          />
        )}
      </Mutation>
    )}
  </Query>
)

export default MarketingPreferencesContainer
