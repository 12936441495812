import React, { Component } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import CustomRoute from '@components/App/CustomRoute'
import { withStyles } from 'react-jss'
import styles from './singleRestaurantStyles'
import cx from 'classnames'
import first from 'lodash/first'
import map from 'lodash/map'
import join from 'lodash/join'

import { ReactSVG } from 'react-svg'
import clockSVG from '@images/clock.svg'
import coinSVG from '@images/coins.svg'
import starSVG from '@images/star.svg'

import Hero from '@components/Hero/Hero'
import Menu from './Menu/Menu'
import SideBar from './SideBar/SideBar'
import Cart from '../Cart/Cart'
import Details from './Details/Details'
import { config, getMarketplace } from '@config/config'
import { getDeliveryZoneFromURL } from '@utils/helpers'

import { penceToPounds } from '@utils/helpers'
import StarsRating from '@components/Stars/Stars'
import { averageRatings } from '@utils/averageRatings'
import RestaurantStatus from '@components/RestaurantStatus/RestaurantStatus'
import FulfilmentStatus from './FulfilmentStatus/FulfilmentStatus'
import { OrderContext } from '@context/Order.context'
import moment from 'moment'
import {
  getCurrentTimeDeliveryLength,
  getPrepTime,
} from '@utils/deliveryWindows'

class SingleRestaurantEl extends Component {
  static contextType = OrderContext
  constructor(props) {
    super(props)

    this.tabsArr = [
      {
        id: 'menu',
        title: 'Menu',
      },
      {
        id: 'info',
        title: 'Info',
      },
    ]

    this.state = {
      activeTab: this.tabsArr[0],
      deliveryZonesModalOpen: false,
      deliveryZone: this.props.deliveryZone,
      outletMatchesCurrentOrder: true,
    }
  }

  componentDidMount() {
    const deliveryZone = getDeliveryZoneFromURL(this.props.match)
    this.setState({
      deliveryZone,
    })
  }

  switchActiveTab = (e, tab) => {
    const showInactive =
      new URLSearchParams(this.props.location.search).get('showInactive') ===
      'true'
    this.props.history.push(
      `${this.props.match.url}/${tab.id}/${
        showInactive ? '?showInactive=true' : ''
      }`
    )
  }

  tabs = () => {
    const { tabContainer, tabView, tabViewItems, tabViewLink, activeTab } =
      this.props.classes

    return (
      <div className={cx('container-fluid', tabContainer)}>
        <div className="row">
          <div className="col-xs-12">
            <ul className={tabView}>
              <div className={tabViewItems}>
                {this.tabsArr.map((tab, i) => {
                  const { pathname } = this.props.location
                  const { title, id } = tab
                  const isActive = pathname.includes(id)
                  return (
                    <li key={i}>
                      <a
                        className={cx(tabViewLink, { [activeTab]: isActive })}
                        onClick={e => this.switchActiveTab(e, tab)}
                      >
                        {title}
                      </a>
                    </li>
                  )
                })}
              </div>
            </ul>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { classes, restaurant } = this.props
    const {
      listWrapper,
      menuView,
      restaurantListItem,
      restaurantIcon,
      restaurantIconImage,
      restaurantTitleContainer,
      restaurantTitle,
      cuisineslbl,
      restaurantStats,
      svgIcon,
    } = classes

    const { deliveryZone } = this.state
    const { orderMode, allowReviews } = getMarketplace()
    const isSingle = orderMode === 'SINGLE'
    const {
      name,
      cuisines,
      outlets,
      image,
      tableDeal,
      collectionDeal,
      deliveryDeal,
    } = restaurant
    const outlet = first(outlets)
    const {
      id: outletId,
      reviews = [],
      isOpen,
      isOnline,
      paymentMethods = [],
      preorderStatus,
      closedUntil,
      allowPreorders,
      daysOfferedInAdvanceMax,
      asapAllowed,
    } = outlet

    const offersDelivery = paymentMethods.some(method =>
      method.toLowerCase().includes('delivery')
    )

    let timeToShow = offersDelivery
      ? `${
          getPrepTime(outlet) +
          getCurrentTimeDeliveryLength(outlet, moment().format())
        } Mins`
      : `${getPrepTime(outlet)} Mins`

    const fulfilment = this.context.attributes.fulfilmentChosen.id

    const isTableService = fulfilment === 'table'

    return (
      <div className={cx('wrapper', listWrapper)}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-sm-3">
              <SideBar
                deliveryZone={deliveryZone}
                match={this.props.match}
                history={this.props.history}
              />
            </div>

            <div className="col-xs-12 col-sm-9 col-md-6">
              <div className={menuView}>
                <div className="container-fluid">
                  {isTableService && tableDeal && (
                    <div className={cx(classes.dealsRow)}>
                      <ReactSVG
                        src={starSVG}
                        className={classes.svgIcon}
                        wrapper="span"
                      />
                      <span className={classes.dealText}>{tableDeal}</span>
                    </div>
                  )}
                  {fulfilment === 'delivery' && deliveryDeal && (
                    <div className={cx(classes.dealsRow)}>
                      <ReactSVG
                        src={starSVG}
                        className={classes.svgIcon}
                        wrapper="span"
                      />
                      <span className={classes.dealText}>{deliveryDeal}</span>
                    </div>
                  )}
                  {fulfilment === 'collection' && collectionDeal && (
                    <div className={cx(classes.dealsRow)}>
                      <ReactSVG
                        src={starSVG}
                        className={classes.svgIcon}
                        wrapper="span"
                      />
                      <span className={classes.dealText}>{collectionDeal}</span>
                    </div>
                  )}

                  <div className={cx('row middle-xs', restaurantListItem)}>
                    <div className="col-xs-12 col-sm-9">
                      <div className="row middle-md">
                        {!isSingle && (
                          <div className={restaurantIcon}>
                            {image && (
                              <img
                                id="image"
                                alt={`${name} menu`}
                                title={`${name} menu`}
                                src={`${config.apiUrl}/${image}`}
                                className={restaurantIconImage}
                              />
                            )}
                          </div>
                        )}
                        <div className={restaurantTitleContainer}>
                          <h3 className={restaurantTitle}>{name}</h3>
                          {allowReviews && (
                            <StarsRating value={averageRatings(reviews)} />
                          )}
                          <p className={cuisineslbl}>
                            {join(map(cuisines, 'name'), ', ')}
                          </p>
                          <div className={cx('visible-xs', restaurantStats)}>
                            <span title="Average Delivery Time">
                              <ReactSVG
                                src={clockSVG}
                                className={svgIcon}
                                wrapper="span"
                              />
                              {outlet.isOpen && outlet.isOnline
                                ? timeToShow
                                : outlet.openingTimeString}
                            </span>

                            <span title="Minimum Order Value">
                              <ReactSVG
                                src={coinSVG}
                                className={svgIcon}
                                wrapper="span"
                              />
                              {`£${penceToPounds(
                                outlet.deliveryMinimumOrderValue
                              )}`}
                            </span>
                          </div>
                          <div className={classes.status}>
                            <RestaurantStatus
                              isOpen={isOpen}
                              isOnline={isOnline}
                              outletId={outletId}
                              preorderStatus={preorderStatus}
                              closedUntil={closedUntil}
                              allowPreorders={allowPreorders}
                              asapAllowed={asapAllowed}
                              daysOfferedInAdvanceMax={daysOfferedInAdvanceMax}
                            />
                            {(isOpen || isOnline) && (
                              <div className={classes.fulfilmentStatus}>
                                <FulfilmentStatus
                                  paymentMethods={paymentMethods}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Restaurant Statistics / Information */}
                    <div
                      className={cx(
                        'col-xs-12 col-sm-3 hidden-xs',
                        restaurantStats
                      )}
                    >
                      <span title="Average Delivery Time">
                        <ReactSVG
                          src={clockSVG}
                          className={svgIcon}
                          wrapper="span"
                        />
                        {outlet.isOpen && outlet.isOnline
                          ? timeToShow
                          : outlet.openingTimeString}
                      </span>

                      <span title="Minimum Order Value">
                        <ReactSVG
                          src={coinSVG}
                          className={svgIcon}
                          wrapper="span"
                        />
                        {`£${penceToPounds(outlet.deliveryMinimumOrderValue)}`}
                      </span>
                    </div>
                  </div>
                </div>
                {this.tabs()}
                <Switch>
                  <CustomRoute
                    path={`${this.props.match.path}/menu`}
                    component={Menu}
                  />
                  <CustomRoute
                    path={`${this.props.match.path}/info`}
                    component={Details}
                  />
                  <Redirect
                    path={this.props.match.path}
                    to={`${this.props.match.path}/menu`}
                  />
                </Switch>
              </div>
            </div>

            {/* Cart / Checkout */}
            <div className="col-xs-12 col-md-3">
              <Cart checkoutButton canEdit />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const SingleRestaurantComp = withStyles(styles)(SingleRestaurantEl)

const SingleRestaurant = props => (
  <>
    <Hero {...props} />
    <SingleRestaurantComp {...props} />
  </>
)

export default SingleRestaurant
