import fontStyles from '@styles/sharedJSS/fonts'
import Color from 'color'

const styles = theme => ({
  // Order & Delivery Containers
  // ------
  deliveryContainer: {
    margin: '2.4rem 0 2.4rem 0',
    borderRadius: '0.4rem',
    background: 'white',
  },

  statusPending: {
    padding: '1.2rem 0 1.2rem 0',
    color: theme.warning,
    background: Color(theme.warning)
      .rgb()
      .alpha(0.1)
      .string(),
  },

  statusPreparing: {
    padding: '1.2rem 0 1.2rem 0',
    color: theme.bc3,
    background: Color(theme.bc3)
      .rgb()
      .alpha(0.1)
      .string(),
  },

  statusReady: {
    padding: '1.2rem 0 1.2rem 0',
    color: theme.bc1,
    background: Color(theme.bc1)
      .rgb()
      .alpha(0.1)
      .string(),
  },

  statusRejected: {
    padding: '1.2rem 0 1.2rem 0',
    color: theme.negative,
    background: Color(theme.negative)
      .rgb()
      .alpha(0.1)
      .string(),
  },

  statusComplete: {
    padding: '1.2rem 0 1.2rem 0',
    color: theme.bc5,
    background: Color(theme.bc5)
      .rgb()
      .alpha(0.1)
      .string(),
  },

  deliveryContent: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'column',
    textAlign: 'center',

    '@media screen and (min-width: 768px)': {
      flexFlow: 'row',
      paddingRight: '1.2rem',
      alignItems: 'center',
      textAlign: 'left',
    },
  },

  deliveryStatus: {
    display: 'flex',
    flexFlow: 'column',

    '@media screen and (min-width: 768px)': {
      flexFlow: 'row',
      marginLeft: '1.2rem',
      alignItems: 'center',
    },

    '& main': {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-start',
    },

    '& i': {
      display: 'inline-block',
      marginRight: '1.2rem',
    },

    '& h6': {
      ...fontStyles.small,
      display: 'inline-block',
      fontWeight: 700,
      marginBottom: '0.6rem',

      '@media screen and (min-width: 768px)': {
        marginBottom: '0rem',
      },
    },

    '& p': {
      fontSize: '1.2rem',
      lineHeight: 1.4,
      fontWeight: 500,
    },
  },

  orderViewContainer: {
    marginTop: '2.4rem',
    display: 'flex',
    flexFlow: 'column',
  },

  orderNameTimes: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  orderTimesContainer: {
    position: 'relative',
    marginTop: '2.4rem',
    width: '100%',

    '@media screen and (min-width: 768px)': {
      width: '50%',
      padding: '0 2.4rem',
    },
  },

  orderTimesContainerLeft: {
    textAlign: 'center',

    '@media screen and (min-width: 768px)': {
      textAlign: 'right',
    },
  },

  orderTimesContainerRight: {
    textAlign: 'center',

    '@media screen and (min-width: 768px)': {
      textAlign: 'left',
    },
  },

  orderTimesLabel: {
    ...fontStyles.small,
    colors: theme.bc5,
    opacity: 0.5,
    marginBottom: '0.6rem',
  },

  nameDateSummary: {
    position: 'relative',
    padding: '2.4rem',
    background: Color(theme.bc5)
      .rgb()
      .alpha(0.02)
      .string(),
    marginLeft: '2.4rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexGrow: 1,
  },

  nameAndDate: {
    position: 'relative',
  },

  orderDate: {
    ...fontStyles.small,
    display: 'block',
    opacity: 0.5,
    marginTop: '1.2rem',
  },

  orderTimes: {
    display: 'flex',
    width: '100%',
    flexFlow: 'column',

    '@media screen and (min-width: 768px)': {
      flexFlow: 'row',
    },
  },

  // Icons
  icon: {
    width: '3.6rem',
    height: '3.6rem',
    fill: theme.bc3,
    stroke: theme.bc3,
    marginBottom: '1.2rem',

    '@media screen and (min-width: 768px)': {
      marginBottom: 0,
    },
  },

  iconOrderPending: {
    fill: theme.warning,
    stroke: theme.warning,
  },

  iconOrderPreparing: {
    fill: theme.bc3,
    stroke: theme.bc3,
  },

  iconOrderRejected: {
    fill: theme.negative,
    stroke: theme.negative,
  },

  iconOrderComplete: {
    fill: theme.bc5,
    stroke: theme.bc5,
  },

  secondaryIcons: {
    width: '3rem',
    padding: '1.2rem',
    fill: theme.bc3,
    stroke: theme.bc3,
    '@media screen and (min-width: 768px)': {
      marginBottom: 0,
    },
  },

  sidebarIcons: {
    width: '3rem',
    height: '3rem',
    margin: '25px 20px 0 0',
  },

  deliveryUpdated: {
    fontWeight: 500,
    display: 'flex',
    flexFlow: 'column',
    textAlign: 'center',
    marginTop: '1.2rem',

    '@media screen and (min-width: 768px)': {
      marginRight: '1.2rem',
      textAlign: 'right',
      marginTop: 0,
    },

    '& span': {
      display: 'inline-block',
      fontSize: '1.2rem',
      lineHeight: 1.4,
      fontWeight: 500,
    },

    '& strong': {
      ...fontStyles.small,
      display: 'inline-block',
      fontWeight: 700,
    },
  },

  deliveryProgress: {
    display: 'block',
    marginTop: '1.2rem',
    padding: '0 1.2rem',
    width: '100%',
    height: '1.2rem',
    position: 'relative',
  },

  deliveryProgressBackground: {
    display: 'block',
    position: 'absolute',
    zIndex: 1,
    top: '0',
    left: '1.2rem',
    right: '1.2rem',
    bottom: '0',
    background: 'white',
  },

  '@keyframes slider': {
    '0%': {
      left: '-100%',
    },

    '100%': {
      left: '100%',
    },
  },

  deliveryProgressBar: {
    display: 'block',
    position: 'relative',
    zIndex: 2,
    width: '33.333%',
    height: '1.2rem',
    background: theme.bc3,
    overflow: 'hidden',

    '&:after': {
      content: '""',
      display: 'block',
      width: '2px',
      height: '3.6rem',
      background: 'white',
      position: 'absolute',
      left: '0',
      top: '-1.2rem',
      filter: 'blur(0.5rem)',
      zIndex: 1,
      animationName: 'slider',
      animationDuration: '1s',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
    },
  },

  progressPending: {
    background: theme.warning,
    width: '12%',
  },

  progressPreparing: {
    background: theme.positive,
    width: '33.333%',
  },

  progressRejected: {
    background: theme.negative,
    width: '100%',
  },

  progressReady: {
    background: theme.positive,
    width: '80%',
  },

  progressComplete: {
    background: theme.positive,
    width: '100%',
  },

  orderTimesDate: {
    ...fontStyles.small,
    colors: theme.bc5,
    marginBottom: '0.6rem',
    opacity: '0.6',
  },

  contactAddress: {
    ...fontStyles.small,
    marginBottom: '0.6rem',
    opacity: '0.6',
    display: 'flex',
    flexDirection: 'column',
  },

  restaurantDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    padding: '3rem',
  },

  restaurantLogoJacket: {
    width: '12rem',
    height: '12rem',
    overflow: 'hidden',
    borderRadius: '4px',
    margin: '1rem',

    '@media screen and (min-width: 768px)': {
      width: '16rem',
      height: '16rem',
    },
  },

  restaurantLogo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
    padding: '1rem',
  },

  restaurantName: {
    ...fontStyles.h2,
    color: theme.bc5,
    display: 'block',
    margin: '1rem',
  },

  /////////////// ORDER DETAILS MAIN ///////////
  orderPageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  orderTotalsContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2rem 2rem 3rem 3rem',
  },

  orderSummaryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '3.5rem 2.5rem 0 2.5rem',
    alignItems: 'center',
    ...fontStyles.xsmall,
    textAlign: 'right',
    color: theme.bc5,
  },

  orderSummaryLabel: {
    ...fontStyles.h4,
    opacity: '0.8',
  },

  orderNumberContainer: {
    opacity: '0.8',
  },

  orderDetailsContainerMain: {
    display: 'flex',
    flexDirection: 'row',
    '@media only screen and (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
    },
    borderRadius: '0.4rem',
    background: 'white',
    boxShadow: '0px 0px 2rem rgba(0, 0, 0, 0.1)',
  },

  orderDetailsContainer: {
    padding: '10px',
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.4rem',
    borderRight: `1px solid ${Color(theme.bc5)
      .rgb()
      .alpha(0.1)
      .string()}`,
    '@media only screen and (max-width: 768px)': {
      width: '100%',
      borderBottom: `1px solid ${Color(theme.bc5)
        .rgb()
        .alpha(0.1)
        .string()}`,
      borderRight: 'none',
      justifyContent: 'center',
      padding: '3rem',
      margin: '1rem',
    },
  },

  orderDetails: {
    position: 'relative',
    borderTop: `0.5px solid ${Color(theme.bc5)
      .rgb()
      .alpha(0.1)
      .string()}`,
    flexGrow: 1,
  },

  orderDetailsList: {
    listStyle: 'none',

    '@media screen and (min-width: 768px)': {
      padding: '3rem',
    },
  },

  // Single Order View
  // ------
  singleItemContainer: {
    display: 'block',
    marginBottom: '2.4rem',

    '&:last-child': {
      marginBottom: 0,
    },
  },

  singleItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  singleItemName: {
    ...fontStyles.small,
    color: theme.bc5,
  },

  singleItemPrice: {
    ...fontStyles.small,
    color: theme.bc5,
    opacity: 0.6,
  },

  singleItemSubList: {
    display: 'block',
    padding: '0 1.2rem',
  },

  subItem: {
    position: 'relative',
    display: 'flex',
  },

  subItemName: {
    ...fontStyles.small,
    color: theme.bc5,
    opacity: 0.4,
  },

  subItemPrice: {
    ...fontStyles.small,
    color: theme.bc1,
    marginLeft: '1.2rem',
  },

  // Order Totals
  // ------
  orderTotalsList: {
    listStyle: 'none',
    display: 'flex',
    flexFlow: 'column',
    borderTop: `1px solid ${Color(theme.bc5)
      .rgb()
      .alpha(0.1)
      .string()}`,
    padding: '2.4rem',

    '@media screen and (min-width: 768px)': {
      padding: '3rem',
    },
  },

  orderTotalsListItem: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.2rem',

    '&:last-child': {
      marginBottom: 0,
    },
  },

  orderTotalsListItemLabel: {
    ...fontStyles.small,
    color: theme.bc5,
    opaacity: 0.8,
  },

  orderTotalsListItemCost: {
    ...fontStyles.small,
    color: theme.bc5,
    opacity: 0.6,
    paddingLeft: '2px',
  },

  orderTotalsListItemLabelTotal: {
    ...fontStyles.p,
    fontSize: '16px',
    color: theme.bc5,
    marginTop: '1.2rem',
  },

  orderTotalsListItemTotalCost: {
    ...fontStyles.p,
    color: theme.bc5,
    marginTop: '1.2rem',
  },

  // In case we decide to have a see more/see less in any other components. Toggle between this and expandedSummary
  collapsedSummary: {
    height: '150px',
    overflow: 'hidden',
    borderBottom: `1px solid ${Color(theme.bc5)
      .rgb()
      .alpha(0.1)
      .string()}`,
    boxShadow: '0px -22px 12px 5px white inset',
  },

  heightToggleContainer: {
    zIndex: '-1',
    borderTop: `0.5px solid ${Color(theme.bc5)
      .rgb()
      .alpha(0.1)
      .string()}`,
    opacity: '0.6',
    display: 'flex',
    justifyContent: 'center',
  },

  heightToggleInner: {
    ...fontStyles.xsmall,
    width: '80px',
    textAlign: 'center',
    marginTop: '-10px',
    background: 'white',
    zIndex: '1',
  },

  //////// SIDE CONTAINERS (FULFILLMENT AND NOTES) /////////

  orderDetailsSideContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
    // justifyContent: 'space-between',
    width: '40%',
    '@media only screen and (max-width: 768px)': {
      width: '100%',
      margin: '1rem',
    },
  },

  orderDetailsSideBox: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '0.4rem',
    // margin: '1.3rem 0rem 1.3rem 0rem',
    padding: '1rem 3rem 2rem 3rem',
    borderBottom: `1px solid ${Color(theme.bc5)
      .rgb()
      .alpha(0.1)
      .string()}`,
  },

  contactSideBox: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '0.4rem',
    // margin: '1.3rem 0rem 1.3rem 0rem',
    padding: '4rem',
  },
})
export default styles
