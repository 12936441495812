import React, { useContext } from 'react'
import { getMarketplace } from '@config/config'
import Categories from '../Categories/Categories'
import StickySideBar, {
  BackLink,
} from '@components/StickySideBar/StickySideBar'
import { OrderContext } from '@context/Order.context'

const SideBar = ({ history, match }) => {
  const { isSingleMode, isPostcodeMode } = getMarketplace()
  const { attributes } = useContext(OrderContext)
  const { deliveryDestination = { type: 'POSTCODE', destination: '' } } =
    attributes

  const zoneName = isPostcodeMode
    ? deliveryDestination.destination
    : attributes.deliveryZone && attributes.deliveryZone.name
  const noZone = zoneName === 'undefined' || !zoneName

  const goBack = e => {
    e.preventDefault()
    if (noZone) {
      history.push('/')
      return
    }
    if (isPostcodeMode) {
      history.push('/takeaways')
      return
    }
    history.push(`/${zoneName}-takeaways`)
  }

  return (
    <StickySideBar
      title={
        !isSingleMode && (
          <>
            <h2 className="hidden-xs">Menu</h2>
            <BackLink
              className="hidden-xs"
              onClick={goBack}
              linkText={noZone ? 'Back To Homepage' : 'Back to Outlets'}
            />

            <BackLink className="visible-xs" onClick={goBack} linkText="Back" />
          </>
        )
      }
    >
      <Categories
        outletId={match.params.outletId}
        singleOrderMode={isSingleMode}
      />
    </StickySideBar>
  )
}

export default SideBar
