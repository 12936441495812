import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import { object, string } from 'prop-types'
import styles from './allergyModalStyles'
import { ReactSVG } from 'react-svg'
import phoneSVG from '@images/phone.svg'

const AllergyModal = ({ classes, name, phoneNumber }) => {
  const {
    titleArea,
    bodyText,
    inner,
    callPrompt,
    callIcon,
    callLink,
    callText,
    callNumber,
  } = classes

  return (
    <Fragment>
      <div className={titleArea}>
        <h2>Allergy Advice</h2>
      </div>
      <div className={inner}>
        <p className={bodyText}>
          If you or someone you're ordering for has a food allergy or
          intolerance please call the business.
          <br />
          <br />
          Do not place allergy requests in your order notes.
        </p>
      </div>

      <div className={callPrompt}>
        <a className={callLink} href={`tel:${phoneNumber}`}>
          <ReactSVG src={phoneSVG} className={callIcon} wrapper="span" />
          <div>
            <span className={callText}>{`Call ${name}`}</span>
            <span className={callNumber}>{phoneNumber}</span>
          </div>
        </a>
      </div>
    </Fragment>
  )
}

AllergyModal.propTypes = {
  classes: object,
  name: string.isRequired,
  phoneNumber: string.isRequired,
}

export default injectSheet(styles)(AllergyModal)
