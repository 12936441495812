import gql from 'graphql-tag'

const discountFragment = gql`
  fragment Discount_fragment on Discount {
    id
    name
    discountPercentage
    discountAmount
    startDate
    endDate
    daysOfWeek
    minimumSubtotalGross
    customerEnrolled
  }
`

export const GET_OUTLET_AND_RESTAURANT_INFO = gql`
  query getOutletAndRestaurantInfo($outletId: String!) {
    outlet(id: $outletId, showInactive: true) {
      id
      name
      description
      isOpen
      isOnline
      preorderStatus

      restaurant {
        id
        name
        collectionDeal
        deliveryDeal
        tableDeal
        discounts {
          ...Discount_fragment
        }
      }
    }

    customerDiscountForBusiness(outletId: $outletId) {
      ...Discount_fragment
    }
  }
  ${discountFragment}
`
