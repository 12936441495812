import React from 'react'
import moment from 'moment'
import { Date } from 'window-or-global'

const INPUT_DATE_FORMAT_STR = 'YYYY-MM-DD'

const DatePicker = ({
  classes,
  selectedDate,
  setSelectedDate,
  minDate,
  maxDate,
}) => {
  return (
    <ul className={classes.day}>
      <li className={classes.dayTitle}>
        {moment(selectedDate).format('dddd Do')}
        <label>
          <span className={classes.dayPickerLabel}>Choose Date:</span>
          <input
            type="date"
            max={maxDate.format(INPUT_DATE_FORMAT_STR)}
            min={minDate.format(INPUT_DATE_FORMAT_STR)}
            value={moment(selectedDate).format(INPUT_DATE_FORMAT_STR)}
            onChange={({ target: { value } }) => {
              if (value) {
                const newSelectedDate = new Date(value)
                newSelectedDate.setHours(0, 0, 0, 0)
                setSelectedDate(newSelectedDate)
              }
            }}
          />
        </label>
      </li>
    </ul>
  )
}

export default DatePicker
