import fontStyles from '@styles/sharedJSS/fonts'
import colors from '@styles/sharedJSS/colors'

export const styles = {
  jacket: {
    position: 'relative',
    '& h1': {
      ...fontStyles.h1,
      marginBottom: '3.6rem',

      '&:last-child': {
        marginBottom: 0,
      },

      '@media screen and (min-width: 768px)': {
        display: 'inline-block',
      },
    },

    '& h2': {
      ...fontStyles.h2,
      marginBottom: '3.6rem',
    },

    '& h3': {
      ...fontStyles.h3,
      marginBottom: '3.6rem',
    },

    '& h4': {
      ...fontStyles.h4,
      marginBottom: '3.6rem',
    },

    '& h5': {
      ...fontStyles.h4,
      marginBottom: '3.6rem',
    },

    '& h6': {
      ...fontStyles.h4,
      marginBottom: '3.6rem',
    },

    '& blockquote': {
      marginBottom: '3rem',
    },

    '& p': {
      ...fontStyles.p,
      color: colors.bc5,
      marginBottom: '3rem',

      '&:last-child': {
        marginBottom: 0,
      },

      '& span': {
        display: 'inline',
        color: colors.bc3,
      },

      '& strong': {
        fontWeight: 'bold',
      },

      '& em': {
        fontStyle: 'italic',
      },
    },

    '& code': {
      ...fontStyles.p,
    },

    '& ul': {
      ...fontStyles.p,
      listStyle: 'decimal inside',
    },

    '& li': {
      '&:last-child': {
        marginBottom: '3em',
      },
    },

    '& button': {
      padding: '1.6rem 2.4rem',
      display: 'inline-block',
      color: 'white',
      fontWeight: 800,
      backgroundColor: colors.bc3,
      fontSize: '1.4rem',
      borderRadius: '60px',
      textTransform: 'uppercase',
      boxShadow: `0px 0px 40px rgba(0, 0, 0, 0.20)`,
      textAlign: 'center',

      '@media screen and (min-width: 768px)': {
        textAlign: 'left',
      },

      '&:focus': { outline: 'none' },
    },
  },
}
