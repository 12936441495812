import gql from 'graphql-tag'

const REGISTER = gql`
  mutation register(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $marketplaceKey: String!
    $enrolmentKey: String!
  ) {
    register(
      email: $email
      password: $password
      marketplaceKey: $marketplaceKey
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      enrolmentKey: $enrolmentKey
    ) {
      customer {
        id
        email
        firstName
        lastName
        phoneNumber
      }
      token
    }
  }
`

export default REGISTER
