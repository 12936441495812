// format Monday Tuesday Wednesday etc. to Mon Tues Wed
const daysFormatted = {
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thur',
  Friday: 'Fri',
  Saturday: 'Sat',
  Sunday: 'Sun',
}

export const getFormattedDay = preorderFrom => {
  const calendarDay = preorderFrom.replace('Order From ', '')

  // check is not date, return abbreviated day if it is, or preorderFrom if not
  const formattedDay = daysFormatted[calendarDay]

  return formattedDay ? `ORDER FROM ${formattedDay}` : preorderFrom
}
