import React, { useRef, useContext, useState, useEffect } from 'react'
import cx from 'classnames'
import {
  get,
  map,
  sumBy,
  kebabCase,
  reduce,
  flatten,
  size,
  remove,
  isEmpty,
  filter,
  find,
} from 'lodash'
import { ReactSVG } from 'react-svg'
import { string, object, func, shape, number, arrayOf } from 'prop-types'
import plus from '@images/large-add.svg'
import minus from '@images/large-delete.svg'
import OptionCategory from './OptionCategory'
import { penceToPounds } from '@utils/helpers'
import OUTLET_SUMMARY from '../../queries/OutletSummary.query'
import { Query } from 'react-apollo'
import { scroller } from 'react-scroll'
import cancel from '@images/cancel.svg'
import AWSImage from '@components/AWSImage/AWSImage'
import PerfectScrollBar from '@components/PerfectScrollBar/PerfectScrollBar'
import { withRouter } from 'react-router-dom'
import { OrderContext } from '@context/Order.context'
import { BasketContext } from '@context/Basket.context'
import { getMarketplace } from '@config/config'

const OptionsPopover = ({
  match,
  history,
  classes,
  menuItem,
  outletId,
  close,
}) => {
  const { attributes, updateOrder } = useContext(OrderContext)
  const { addItem, clearItems } = useContext(BasketContext)
  const outletMatch = attributes.outletId
    ? outletId === attributes.outletId
    : true
  const removeErrorTimeout = useRef(null)
  const { allowSingleItemOrderNotes } = getMarketplace()

  const [quantity, setQuantity] = useState(1)
  const [optionList, setOptionList] = useState({})
  const [singleItemNotes, setItemNotes] = useState('')

  useEffect(() => {
    const optionList = reduce(
      menuItem.options,
      (acc, val) => {
        acc[val.id] = {
          minOptions: val.minOptions,
          maxOptions: val.maxOptions,
          options: [],
          name: val.name,
          id: val.id,
        }
        return acc
      },
      {}
    )
    setOptionList(optionList)
  }, [])

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity => quantity - 1)
    }
  }

  const increaseQuantity = () => {
    setQuantity(quantity => quantity + 1)
  }

  const addOption = (option, categoryId) => {
    setOptionList(currentOptionList => {
      const category = currentOptionList[categoryId]
      if (!category) {
        return currentOptionList
      }
      return {
        ...currentOptionList,
        [categoryId]: {
          ...category,
          options: [...category.options, option],
          get error() {
            if (category.error) {
              return this.options.length < category.minOptions
            }

            return false
          },
          set error(error) {
            category.error = error
          },
        },
      }
    })
  }

  const removeOption = (option, categoryId) => {
    let currentSelections = optionList[categoryId].options

    const alreadySelected = find(currentSelections, { id: option.id })
    if (alreadySelected) {
      remove(currentSelections, opt => {
        return opt.id === option.id
      })
    } else {
      currentSelections.shift()
    }
    setOptionList(currentOptionList => {
      const category = get(currentOptionList, categoryId)
      return {
        ...currentOptionList,
        [categoryId]: {
          ...category,
          options: currentSelections,
        },
      }
    })
  }

  const addItemToBasket = () => {
    const optionItems = Object.values(optionList)
      .sort((optionA, optionB) => {
        const positionA = menuItem.optionPositions.length
          ? menuItem.optionPositions.findIndex(
              optionId => optionId === optionA.id
            )
          : menuItem.options.findIndex(option => option.id === optionA.id)
        const positionB = menuItem.optionPositions.length
          ? menuItem.optionPositions.findIndex(
              optionId => optionId === optionB.id
            )
          : menuItem.options.findIndex(option => option.id === optionB.id)
        return positionA - positionB
      })
      .flatMap(option => {
        const orderedOptionsItems = option.options.sort(
          (optionItemA, optionItemB) => {
            const parentOpt = menuItem.options.find(
              item => item.id === option.id
            )
            if (!parentOpt) {
              return 0
            }
            const positionA =
              optionItemA.position !== null
                ? optionItemA.position
                : parentOpt.optionItems.findIndex(
                    optionItem => optionItem.id === optionItemA.id
                  )
            const positionB =
              optionItemB.position !== null
                ? optionItemB.position
                : parentOpt.optionItems.findIndex(
                    optionItem => optionItem.id === optionItemB.id
                  )
            return positionA - positionB
          }
        )
        return orderedOptionsItems
      })

    addItem(
      {
        menuItem,
        optionItems,
        singleItemNotes,
      },
      Number(quantity)
    )
  }

  const validate = () => {
    if (removeErrorTimeout.current) {
      clearTimeout(removeErrorTimeout.current)
    }

    if (size(optionList)) {
      map(optionList, (opt, key) => {
        const category = get(optionList, key)
        if (opt.options.length < opt.minOptions) {
          category.error = true
        } else {
          category.error = false
        }
        setOptionList(options => ({
          ...options,
          [key]: { ...category },
        }))
      })
    }
    const errors = filter(optionList, option => option.error)
    const hasErrors = errors.length > 0

    if (hasErrors) {
      scroller.scrollTo(kebabCase(errors[0].name), {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'OptionsMenuScrollWrapper',
      })
    }

    return !hasErrors
  }

  const getFullCostWithOptions = () => {
    const thisItemCost = menuItem.price
    const optionItems = flatten(map(optionList, 'options'))
    const optionItemsCost = sumBy(optionItems, 'price')
    return (thisItemCost + optionItemsCost) * quantity
  }

  const goToOutlet = (e, outlet) => {
    e.preventDefault()
    const { deliveryZone } = match.params
    const link = `/${deliveryZone}-takeaways/${kebabCase(
      outlet.restaurant.name
    )}/${outlet.id}/menu`
    history.push(link)
    window.location.reload()
  }

  const clearOrder = () => {
    updateOrder({ outletId: null })
    clearItems()
  }

  const showErrors = () => {
    const errors = filter(optionList, option => option.error)

    return errors.length > 0 ? (
      <div className={classes.optionError}>
        {errors.map(error => {
          return (
            <span key={error.name} className={classes.singleErrorSpan}>
              <strong>{error.name}</strong> requires{' '}
              <strong>{error.maxOptions}</strong> selection
              {error.maxOptions !== 1 ? 's' : ''}
            </span>
          )
        })}
      </div>
    ) : null
  }

  const {
    headerWithDescription,
    headerNoDescription,
    itemDescriptionText,
    actionButtons,
    goToBtn,
    clearBtn,
    titleArea,
    titleAreaWithOptions,
    instructionsWrapper,
    instructionsWrapperBordered,
    instructionsHeader,
    instructionsInput,
  } = classes

  const hasOptions = menuItem.options.length > 0
  const titleClass = !isEmpty(itemDescriptionText)
    ? headerWithDescription
    : headerNoDescription
  const titleAreaClass = hasOptions ? titleAreaWithOptions : titleArea
  const newPrice = getFullCostWithOptions()

  return !outletMatch ? (
    <Query
      query={OUTLET_SUMMARY}
      variables={{ id: attributes.outletId }}
      context={{ version: 2 }}
    >
      {({ data }) => {
        const outlet = get(data, 'outlet', {
          id: 0,
          restaurant: {
            name: '',
          },
        })
        return (
          <div className={classes.modalView}>
            <div className={classes.inProgressWrapper}>
              <h3 className={titleClass}>Order in progress</h3>
              <p className={itemDescriptionText}>
                You have already started an order with {outlet.restaurant.name}
              </p>
              <div className={actionButtons}>
                <a className={clearBtn} href="#_" onClick={clearOrder}>
                  Clear existing order
                </a>
                <a
                  className={goToBtn}
                  href="#_"
                  onClick={e => goToOutlet(e, outlet)}
                >
                  Return to {outlet.restaurant.name}
                </a>
              </div>
            </div>
          </div>
        )
      }}
    </Query>
  ) : (
    <div
      className={cx(classes.modalView, {
        [classes.modalViewFullScreen]: hasOptions,
      })}
    >
      {/* Menu Options*/}
      <PerfectScrollBar
        options={{
          className: classes.optionListWrapper,
          options: { suppressScrollX: true },
          id: 'OptionsMenuScrollWrapper',
        }}
      >
        {menuItem.image && (
          <div className={classes.menuImageWrapper}>
            <AWSImage
              id="image"
              alt={menuItem.name}
              title={menuItem.name}
              className={classes.menuImage}
              imageKey={menuItem.image}
              edits={{
                resize: {
                  width: 400,
                  height: 400,
                  fit: 'cover',
                },
              }}
            />
          </div>
        )}

        {/* Title */}
        <div className={titleAreaClass}>
          <div
            className={cx(classes.itemCostLozenge, {
              [classes.itemCostLozengeWithImage]: menuItem.image,
            })}
          >
            <span>£{penceToPounds(newPrice)}</span>
          </div>
          <h2 className={titleClass}>{menuItem.name}</h2>
          <p className={itemDescriptionText}>{menuItem.description}</p>
        </div>

        {menuItem.options.map(cat => (
          <OptionCategory
            category={cat}
            classes={classes}
            key={cat.id}
            addOption={addOption}
            removeOption={removeOption}
            chosenOptions={get(optionList, cat.id)}
          />
        ))}

        <div
          className={
            menuItem.options.length > 0
              ? instructionsWrapper
              : instructionsWrapperBordered
          }
        >
          {allowSingleItemOrderNotes ? (
            <>
              <h6 className={instructionsHeader}>Special Instructions</h6>
              <input
                type="text"
                className={instructionsInput}
                name="orderNotes"
                placeholder="Add an item note (e.g. extra sauce, no onions)"
                onChange={({ target: { value } }) => {
                  setItemNotes(value)
                }}
              />
            </>
          ) : null}
        </div>
      </PerfectScrollBar>

      {/* Options Footer */}
      <div
        className={cx(classes.optionFooter, {
          [classes.optionFooterWithOptions]: hasOptions,
        })}
      >
        {showErrors()}

        <div className={classes.qtyBtns}>
          <span
            className={cx(classes.footerButton, classes.addIcon)}
            onClick={decreaseQuantity}
          >
            <ReactSVG
              src={minus}
              className={classes.qtyControlIcon}
              wrapper="span"
            />
          </span>

          <span className={classes.quantity}>{quantity}</span>

          <span
            className={cx(classes.footerButton, classes.addIcon)}
            onClick={increaseQuantity}
          >
            <ReactSVG
              src={plus}
              className={classes.qtyControlIcon}
              wrapper="span"
            />
          </span>
        </div>
        <div className={classes.footerOptionActions}>
          <div
            className={classes.addToBasket}
            onClick={() => {
              const isValid = validate()
              if (isValid) {
                addItemToBasket()
                close()
              }
            }}
          >
            Add to Order
          </div>
          <button type="button" onClick={close} className={classes.cancelBtn}>
            <ReactSVG src={cancel} wrapper="span" className="visible-xs" />
            <span className="hidden-xs">Cancel</span>
          </button>
        </div>
      </div>
    </div>
  )
}

OptionsPopover.propTypes = {
  classes: object,
  close: func,
  menuItem: shape({
    name: string,
    description: string,
    price: number,
    image: string,
    options: arrayOf(
      shape({
        id: string,
        name: string,
        maxOptions: number,
        minOptions: number,
        optionItems: arrayOf(
          shape({ id: string, name: string, price: number })
        ),
      })
    ),
  }),
}

const WithRouterWrapper = withRouter(props => <OptionsPopover {...props} />)

export default WithRouterWrapper
