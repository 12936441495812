import { MOBILE_BREAKPOINT } from '@utils/breakpoints'
import checkoutFormStyles from '../checkoutFormStyles'

const styles = theme => ({
  ...checkoutFormStyles(theme),
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '1.6rem',
    cursor: 'pointer',

    '&:hover $actionSvg': {
      opacity: 1,
    },
  },

  emptyNotice: {
    fontSize: '1.4rem',
  },

  inactive: {
    opacity: 0.6,
    '&:hover': {
      opacity: 0.8,
    },
  },

  active: {
    opacity: 1,
  },

  actionSvg: {
    display: 'inline-block',
    transition: 'opacity 0.2s ease',
  },

  rowActionIcon: {
    width: '16px',
    border: '2px solid red',
    height: '16px',
    display: 'block',
    borderRadius: '100%',
    textAlign: 'center',
    transition: 'all 0.2s ease',

    '& svg': {
      height: '12px',
      width: '10px',
      fill: 'red',
      verticalAlign: 'top',
      transition: 'all 0.2s ease',
    },

    '&:hover': {
      backgroundColor: 'red',

      '& svg': {
        fill: '#fff',
      },
    },
  },

  label: {
    marginRight: 'auto',
    color: theme.bc3,
    cursor: 'pointer',
    fontSize: '15px',
    fontWeight: 500,
    maxWidth: '80%',
    lineHeight: 'normal',
    display: 'flex',
    flexWrap: 'wrap',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      lineHeight: 'normal',
    },

    '& span': {
      display: 'block',
      marginRight: '0.5rem',
    },
  },

  addressError: {
    color: 'red',
    fontWeight: '600',
    fontSize: '14px',
    marginTop: '10px',
  },

  reSearchLink: {
    fontWeight: 500,
    paddingTop: '5px',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
})

export default styles
