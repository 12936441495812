import color from 'color'

const styles = theme => ({
  container: {
    position: 'relative',
    color: '#4d4d4d',
    padding: '12px 15px 9px',
    fontSize: '12px',
    fontWeight: 600,
    borderBottom: '1px solid #cfdbe0',
    boxShadow: '0px 2px 1px #0000000f',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#f5f7fb',
    },
  },

  title: {
    fontSize: '12px',
    fontWeight: 700,
    height: '40px',
  },

  titleLabel: {
    opacity: 0.8,
    lineHeight: '20px',
  },

  table: {
    lineHeight: '20px',
    fontWeight: 800,
  },

  change: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '15px',

    color: theme.positive,
  },

  modalTitle: {
    padding: '15px',
    position: 'relative',
    lineHeight: '20px',
    borderBottom: `1px solid ${theme.lightBorder}`,
    color: color(theme.neutral)
      .rgb()
      .darken(0.4)
      .toString(),
    fontSize: '17px',
    fontWeight: 500,
  },

  tableChoice: {
    display: 'flex',
    alignItems: 'center',
    color: color(theme.neutral)
      .rgb()
      .darken(0.4)
      .toString(),
    cursor: 'pointer',
    padding: '10px 15px',
    fontSize: '14px',
    fontWeight: 500,
    borderBottom: `1px dotted ${theme.lightBorder}`,
    '&:hover': {
      backgroundColor: color(theme.lightBorder)
        .rgb()
        .lighten(0.08)
        .toString(),
    },
  },
})

export default styles
