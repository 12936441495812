import React, { useContext } from 'react'
import { Mutation } from 'react-apollo'
import { ARCHIVE_USER_ADDRESS } from '../queries/getUserDetails.query'
import cx from 'classnames'
import { ReactSVG } from 'react-svg'
import removeSVG from '@images/large-delete.svg'
import Alert from '@components/Alert/Alert'
import { OrderContext } from '@context/Order.context'

const AddressItem = ({ address, classes, refetch, error, isActive }) => {
  const { updateOrder } = useContext(OrderContext)

  const removeAddress = archiveAddress => {
    const remove = archiveAddress({
      variables: {
        id: address.id,
      },
    })
    remove.then(() => {
      refetch()
      updateOrder({ address: null })
    })
  }

  return (
    <Mutation mutation={ARCHIVE_USER_ADDRESS}>
      {archiveAddress => (
        <>
          <a
            className={cx(
              classes.container,
              isActive ? classes.active : classes.inactive
            )}
            onClick={e => {
              e.preventDefault()
              updateOrder({ address: address.id })
            }}
          >
            <span
              className={cx(classes.activeIndicator, {
                [classes.activeIndicatorisActive]: isActive,
              })}
            />

            <span className={classes.label}>
              <span>{address.firstLine},</span>

              {Boolean(address.secondLine.length) && (
                <span>{`${address.secondLine},`}</span>
              )}

              {address.thirdLine && Boolean(address.thirdLine.length) && (
                <span>{`${address.thirdLine},`}</span>
              )}
              <span>{address.city},</span>
              <span>{address.postcode}</span>
            </span>

            <Alert
              confirm={() => removeAddress(archiveAddress)}
              question="Are you sure you want to delete this address?"
            >
              <span className={classes.actionSvg}>
                <ReactSVG
                  src={removeSVG}
                  className={classes.rowActionIcon}
                  wrapper="span"
                />
              </span>
            </Alert>
          </a>

          {<p className={classes.addressError}>{error ? error : ''}</p>}
        </>
      )}
    </Mutation>
  )
}

export default AddressItem
