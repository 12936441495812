import { button } from '@styles/sharedJSS/buttons'
import color from 'color'

export default theme => ({
  default: {
    cursor: 'pointer',
    ...button(theme),
  },
  square: {
    borderRadius: 0,
  },
  small: {
    padding: '1rem',
    fontWeight: '600',
  },
  capitalize: {
    textTransform: 'capitalize',
    fontWeight: 600,
    fontSize: '18px',
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  fullWidth: {
    width: '100%',
  },

  // appearance prop maps to these class names
  brandColor: {
    transition: 'opacity 0.25s ease-in-out',
    backgroundColor: theme.bc3,
    get color() {
      return color(theme.bc3).isLight() ? theme.bc5 : '#fff'
    },

    '&:hover': {
      backgroundColor: theme.bc3,
      opacity: 0.8,
    },
  },
  positive: {
    backgroundColor: theme.positive,
    transition: 'opacity .25s ease-in-out',

    '&:hover': {
      opacity: 0.9,
      backgroundColor: theme.positive,
    },
  },
  negative: {
    backgroundColor: theme.warning,
  },
  // end of appearance class names
})
