import gql from 'graphql-tag'

export const GET_USER = gql`
  query getUser {
    myDetails {
      message
      customer {
        id
        email
        firstName
        lastName
        phoneNumber
        deliveryAddress {
          id
          firstLine
          secondLine
          thirdLine
          city
          postcode
          default
        }
      }
    }
  }
`

export const ARCHIVE_USER_ADDRESS = gql`
  mutation archiveAddress($id: String!) {
    archiveAddress(id: $id) {
      message
    }
  }
`

export const SET_DEFAULT_USER_ADDRESS = gql`
  mutation setDefaultAddress($id: String!) {
    setDefaultAddress(id: $id) {
      message
    }
  }
`

export const ADD_USER_ADDRESS = gql`
  mutation addAddress(
    $firstLine: String!
    $secondLine: String
    $thirdLine: String
    $city: String!
    $postcode: String!
    $default: Boolean
  ) {
    addAddress(
      firstLine: $firstLine
      secondLine: $secondLine
      thirdLine: $thirdLine
      city: $city
      postcode: $postcode
      default: $default
    ) {
      address {
        id
        firstLine
        secondLine
        thirdLine
        city
        postcode
        default
      }
    }
  }
`
