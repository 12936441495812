import React, { useContext } from 'react'
import { withStyles } from 'react-jss'
import styles from './featuredOutlet.styles'
import AWSImage from '@components/AWSImage/AWSImage'
import RestaurantStatus from '@components/RestaurantStatus/RestaurantStatus'
import OpeningTimes from './OpeningTimes/OpeningTimes'
import { navigateToOutlet } from '@utils/helpers'
import MenuLinkButton from '@components/MenuLinkButton'
import { withRouter } from 'react-router-dom'
import RestaurantStats from '@components/RestaurantStats/RestaurantStats'
import { GTMContext } from '@context/GTM.context'
import { OrderContext } from '@context/Order.context'
import { BasketContext } from '@context/Basket.context'

const FeaturedOutlet = ({ outlet, classes, match, history }) => {
  const gtm = useContext(GTMContext)
  const { attributes, replaceOrder, updateOrder } = useContext(OrderContext)
  const { items, clearItems } = useContext(BasketContext)

  const restaurant = outlet.restaurant
  const navigateToOutletWrapper = () => {
    navigateToOutlet({
      match,
      history,
      outlet,
      pushDataToGTM: gtm.pushDataToGTM,
      attributes,
      replaceOrder,
      updateOrder,
      items,
      clearItems,
    })
  }

  return (
    <div
      className="col-xs-12 col-sm-6 col-md-4"
      onClick={navigateToOutletWrapper}
    >
      <div className={classes.container}>
        <div className={classes.top}>
          <div className={classes.image}>
            <AWSImage
              id="image"
              alt={restaurant.name}
              title={restaurant.name}
              imageKey={restaurant.image}
              edits={{
                resize: {
                  width: 400,
                  height: 400,
                  fit: 'cover',
                },
              }}
            />
          </div>

          <div className={classes.info}>
            <div className={classes.restaurantName}>{restaurant.name}</div>
            <div className={classes.cuisines}>
              {restaurant.cuisines.map(({ name }) => name).join(', ')}
            </div>
            <RestaurantStatus
              isOpen={outlet.isOpen}
              isOnline={outlet.isOnline}
              preorderStatus={outlet.preorderStatus}
              closedUntil={outlet.closedUntil}
              allowPreorders={outlet.allowPreorders}
              daysOfferedInAdvanceMax={outlet.daysOfferedInAdvanceMax}
              asapAllowed={outlet.asapAllowed}
            />
          </div>
        </div>

        <div className={classes.openingInfo}>
          <OpeningTimes openingTimes={outlet.openingTimes} />

          <RestaurantStats
            outlet={outlet}
            className={classes.restaurantStats}
          />
        </div>

        <MenuLinkButton
          isOpen={outlet.isOpen}
          isOnline={outlet.isOnline}
          className={classes.menuLink}
          preorderStatus={outlet.preorderStatus}
          allowPreorders={outlet.allowPreorders}
          closedUntil={outlet.closedUntil}
          daysOfferedInAdvanceMax={outlet.daysOfferedInAdvanceMax}
        />
      </div>
    </div>
  )
}

export default withStyles(styles)(withRouter(FeaturedOutlet))
