import React from 'react'
import Query from '@components/Query/Query'
import kebabCase from 'lodash/kebabCase'
import { get } from 'lodash'
import { Redirect } from 'react-router-dom'
import { getMarketplace } from '@config/config'
import RestaurantMenuHead from '../PageHeads/RestaurantMenuHead'
import RestaurantsListHead from '../PageHeads/RestaurantsListHead'
import { GET_OUTLET_BY_NAME } from './queries/getOutletByName.query'

const OutletRedirect = ({
  match: {
    params: { restaurantName = '', deliveryZone = '' },
  },
}) => {
  const { key, isListMode } = getMarketplace()
  const cleanRestaurantName = restaurantName.replace(/-/g, ' ')
  return (
    <Query
      query={GET_OUTLET_BY_NAME}
      variables={{
        key: key,
        restaurantName: cleanRestaurantName,
      }}
    >
      {data => {
        const outlet = get(data, 'marketplace.marketplace.outlets[0]')
        if (outlet) {
          const { restaurant, id } = outlet
          return (
            <>
              <RestaurantMenuHead
                match={{ params: { deliveryZone } }}
                restaurant={restaurant}
              />
              <Redirect
                to={{
                  pathname: `${
                    isListMode ? `/${deliveryZone}-takeaways` : ''
                  }/${kebabCase(restaurant.name)}/${id}/menu`,
                  state: { status: 301 },
                }}
              />
            </>
          )
        } else {
          return (
            <>
              <RestaurantsListHead match={{ params: { deliveryZone } }} />
              <Redirect
                to={{
                  pathname: isListMode
                    ? `/${deliveryZone}-takeaways`
                    : '/takeaways',
                  state: { status: 301 },
                }}
              />
            </>
          )
        }
      }}
    </Query>
  )
}

export default OutletRedirect
