import Color from 'color'

const styles = theme => ({
  modalOverlay: {
    transition: `all 0.3s ease`,
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.70)',
  },
  modalView: {
    backgroundColor: 'white',
    width: '500px',
    minHeight: '500px',
    margin: 'auto',
    marginTop: '100px',
    borderRadius: '4px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.60)',
    transition: `all 0.3s ease`,
  },
  titleArea: {
    padding: '15px',
    position: 'relative',
    lineHeight: '20px',
    borderBottom: `1px solid ${theme.lightBorder}`,
    '& h2': {
      color: Color(theme.neutral).rgb().darken(0.4).toString(),
      fontSize: '17px',
      fontWeight: 500,
    },

    '& p': {
      color: theme.neutral,
      fontSize: '13px',
      fontWeight: 400,
    },
  },

  scrollableArea: {
    height: '500px',
    overflow: 'scroll',
  },

  timeLink: {
    display: 'flex',
    alignItems: 'center',
    color: Color(theme.neutral).rgb().darken(0.4).toString(),
    cursor: 'pointer',
    padding: '10px 15px',
    fontSize: '14px',
    fontWeight: 500,
    borderBottom: `1px dotted ${theme.lightBorder}`,
    '&:hover': {
      backgroundColor: Color(theme.lightBorder).rgb().lighten(0.08).toString(),
    },
  },

  day: {
    overflow: 'hidden',
  },

  dayTitle: {
    color: theme.bc3,
    backgroundColor: '#fff',
    padding: '9px 15px 10px 13px',
    fontSize: '14px',
    fontWeight: 600,
    position: 'relative',
    zIndex: '10',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(29, 86, 163, 0.15)',
  },

  dayPickerLabel: {
    marginRight: '6px',
    fontWeight: '400',
  },

  daySplitter: {
    height: '16px',
    backgroundColor: '#f4f4f4',
    borderTop: '1px solid #e9e9e9',
  },

  activeIcon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '3px',
    marginRight: 'auto',
    fill: '#108050',
  },

  timeBracketTitle: {
    padding: '9px 15px 9px 13px',
    fontSize: '10px',
    color: theme.neutral,
    fontWeight: 600,
    borderBottom: `1px solid ${Color(theme.bc3).rgb().alpha(0.3).string()}`,
  },

  asapRow: {
    color: theme.bc3,
    cursor: 'pointer',
    padding: '9px 15px 0px 13px',
    display: 'block',
    fontSize: '14px',
    paddingTop: '18px',
    fontWeight: 600,
    paddingBottom: '18px',
    borderBottom: `1px solid ${Color(theme.bc3).rgb().alpha(0.3).string()}`,
    marginBottom: '2px',

    '&:hover': {
      backgroundColor: Color(theme.lightBorder).rgb().lighten(0.08).toString(),
    },
  },
  subLbl: {
    float: 'right',
    fontStyle: 'italic',
    color: Color(theme.bc3).rgb().alpha(0.3).string(),
    fontSize: '12px',
  },
})

export default styles
