import React, { useRef, useEffect, useState } from 'react'
import cx from 'classnames'
import { withStyles } from 'react-jss'
import { styles, backLinkStyles } from './stickySideBarStyles'
import { ReactSVG } from 'react-svg'
import backImage from '@images/left-arrow.svg'

export const BackLink = withStyles(backLinkStyles)(
  ({ classes, onClick, alt, linkText, className }) => (
    <a
      className={cx(classes.backLink, className)}
      href="#_"
      alt={alt}
      onClick={onClick}
    >
      <ReactSVG
        src={backImage}
        className={cx(classes.backSvg)}
        wrapper="span"
      />
      {linkText}
    </a>
  )
)

const SideBar = ({
  classes,
  children,
  noStick,
  noBackgroundOnStick,
  title,
  titleClassName,
}) => {
  const containerRef = useRef()
  const [observer, setObserver] = useState(null)

  useEffect(() => {
    if (!noStick && containerRef.current) {
      const newObserver = new IntersectionObserver(
        ([e]) => {
          const stuck = e.intersectionRatio < 1
          e.target.classList.toggle('stuck', stuck)
          e.target.classList.toggle(classes.leftSideBarIsSticky, stuck)
        },
        { threshold: [1] }
      )
      newObserver.observe(containerRef.current)
      setObserver(newObserver)
    }

    return () => {
      if (observer) {
        observer.disconnect()
      }
      setObserver(null)
    }
  }, [containerRef.current])

  return (
    <div
      ref={containerRef}
      className={cx(classes.leftSidebar, {
        [classes.noStick]: noStick,
        [classes.noBackgroundOnStick]: noBackgroundOnStick,
      })}
    >
      {title && (
        <div className={cx(classes.titleArea, titleClassName)}>{title}</div>
      )}

      {children}
    </div>
  )
}

export default withStyles(styles)(SideBar)
