import gql from 'graphql-tag'

export const GET_ADDRESS_DELIVERY_COST_V2 = gql`
  query deliveryEstimateFromCustomerAddressV2(
    $addressId: String!
    $outletId: String!
    $preorderFor: DateTime
  ) {
    deliveryEstimateForAddress(
      addressId: $addressId
      outletId: $outletId
      preorderFor: $preorderFor
    ) {
      id
      cost
      expiryAt
      type
    }
  }
`
