import gql from 'graphql-tag'

export const GET_DELIVERY_ESTIMATE_FOR_POSTCODE = gql`
  query deliveryEstimateForPostcode($outletId: String!, $postcode: String!) {
    deliveryEstimateForPostcode(outletId: $outletId, postcode: $postcode) {
      id
      cost
      expiryAt
      type
    }
  }
`

export const GET_DELIVERY_ESTIMATE_FOR_ZONE = gql`
  query deliveryEstimateForZone($outletId: String!, $deliveryZoneId: String!) {
    deliveryEstimateForZone(
      outletId: $outletId
      deliveryZoneId: $deliveryZoneId
    ) {
      id
      cost
      expiryAt
      type
    }
  }
`

export const GET_DELIVERY_ESTIMATE_FROM_CUSTOMER_COORDINATES = gql`
  query deliveryEstimatesFromCustomerCoordinates(
    $outletIds: [String!]!
    $longitude: Float!
    $latitude: Float!
  ) {
    deliveryEstimatesFromCustomerCoordinates(
      outletIds: $outletIds
      longitude: $longitude
      latitude: $latitude
    ) {
      outletId
      estimatedCost
    }
  }
`
