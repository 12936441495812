import React, { useState, useContext } from 'react'
import { withStyles } from 'react-jss'
import styles from './menuStyles'
import cx from 'classnames'
import { object } from 'prop-types'
import OptionsPopoverWrapper from './OptionsPopover/OptionsPopoverWrapper'
import { CSSTransition } from 'react-transition-group'
import get from 'lodash/get'
import _ from 'lodash'
import BodyLocker from '../../../utils/BodyLocker'
import { Switch, Route } from 'react-router-dom'
import { MOBILE_BREAKPOINT } from '@utils/breakpoints'
import Query from '@components/Query/Query'
import { GET_OUTLET_AND_RESTAURANT_INFO } from './queries/getOutletAndRestaurantInfo.query'
import { OrderContext } from '@context/Order.context'

import Discounts from '@components/SingleRestaurant/Menu/Discounts'
import DealsRow from '@components/SingleRestaurant/Menu/DealsRow'
import MenuSections, {
  MenuSection,
} from '@components/SingleRestaurant/Menu/MenuSections'
import ls from '@utils/localStorage'
import { GET_MENU_ITEM_GROUPS } from '@root/shared/queries/getMenuItemGroups.query'

const Menu = ({ classes, match, history }) => {
  const { attributes } = useContext(OrderContext)
  const isTableService = attributes.fulfilmentChosen.id === 'table'

  const [item, setItem] = useState({})
  const [showOptionsModal, setShowOptionsModal] = useState(false)
  const [showOptionsModalInner, setShowOptionsModalInner] = useState(false)

  const openModal = item => {
    setShowOptionsModal(true)
    setItem(item)
    BodyLocker.lock()
  }

  const closeModal = () => {
    setShowOptionsModal(false)
    BodyLocker.unlock()
  }

  const toggleInnerModal = show => {
    setShowOptionsModalInner(show)
  }

  const rowClickAction = e => {
    e.persist()
    const { currentTarget } = e
    currentTarget.style.backgroundColor = '#eaeaea'
    setTimeout(() => {
      currentTarget.style.backgroundColor = '#fff'
    }, 500)
  }

  const menuSectionClicked = (e, parentMenu) => {
    rowClickAction(e)
    const isMobileDevice = window.innerWidth < MOBILE_BREAKPOINT
    if (isMobileDevice) {
      history.push(parentMenu.path)
    }

    const lock = isMobileDevice
    BodyLocker[lock ? 'lock' : 'unlock']
  }

  const backToMenu = () => {
    BodyLocker.unlock()
    history.push(match.url)
  }

  const {
    menu,
    modalEnter,
    modalEnterActive,
    modalEnterDone,
    modalExit,
    modalExitActive,
    modalExitDone,
    outletDesc,
    menuType,
  } = classes

  return (
    <div className="container-fluid">
      <Query
        // we just did this query in the DirtyBasketMenuProvider
        // so it should always hit the local cache, but just in case
        // don't pass down fetchPolicy="cache-only"
        query={GET_MENU_ITEM_GROUPS}
        variables={{
          outletId: match.params.outletId,
          get fulfilmentMethods() {
            if (
              attributes.fulfilmentChosen &&
              attributes.fulfilmentChosen.id.toUpperCase() !== 'NETWORK'
            ) {
              return [attributes.fulfilmentChosen.id.toUpperCase()]
            }
            return ['DELIVERY']
          },
          showInactive:
            new URLSearchParams(location.search).get('showInactive') === 'true',
        }}
        context={{ version: 2 }}
      >
        {menuItemGroupQueryData => (
          <Query
            query={GET_OUTLET_AND_RESTAURANT_INFO}
            variables={{
              outletId: match.params.outletId,
            }}
            context={{ version: 2 }}
          >
            {outletInfoQueryData => {
              const {
                description = false,
                isOpen = false,
                isOnline = false,
                restaurant = {},
              } = get(outletInfoQueryData, 'outlet', {})
              const menuItemGroups = get(
                menuItemGroupQueryData,
                'menuItemGroupsForOutlet',
                null
              )

              const customerDiscountForBusiness = get(
                outletInfoQueryData,
                'customerDiscountForBusiness',
                null
              )

              // add personal business discounts on top of the regular ones
              let discounts = get(restaurant, 'discounts', [])
              if (customerDiscountForBusiness) {
                discounts = discounts.concat(customerDiscountForBusiness)
              }

              const openAndOnline = isOpen && isOnline
              const { collectionDeal, deliveryDeal, tableDeal } = get(
                outletInfoQueryData,
                'outlet.restaurant',
                {}
              )

              const topLevels = _.filter(menuItemGroups, group => {
                return group.parentMenu === null
              })

              const sortedMenu = topLevels.map(topLevel => {
                const { id, menuItems } = topLevel
                const subMenu = _.filter(menuItemGroups, item => {
                  if (!item.parentMenu) return false
                  return item.parentMenu.id === id
                })

                return {
                  header: {
                    ...topLevel,
                    path: `${match.url}/${
                      topLevel.name
                        .replace(/[\W\s]/gi, '-') // remove any none word characters
                        .replace(/-+/gi, '-') // replace any groups of hyphens with a single hyphen
                    }/${topLevel.id}`,
                  },
                  subMenu,
                  menuItems,
                }
              })

              return (
                <div className={cx('row', menu)}>
                  <DealsRow
                    openAndOnline={openAndOnline}
                    collectionDeal={collectionDeal}
                    deliveryDeal={deliveryDeal}
                    tableDeal={tableDeal}
                  />

                  <p className={menuType}>
                    ({ls.get('order').fulfilmentChosen.label} Menu)
                  </p>

                  {!isTableService && <Discounts discounts={discounts} />}

                  {!!description && (
                    <div className={outletDesc}>{description}</div>
                  )}

                  <MenuSections
                    menuSections={sortedMenu}
                    outletRestaurantName={restaurant.name}
                    openModal={openModal}
                    menuSectionClicked={menuSectionClicked}
                    backToMenu={backToMenu}
                  />

                  <Switch>
                    {sortedMenu.map(menuObj => (
                      <Route key={menuObj.header.id} path={menuObj.header.path}>
                        <MenuSection
                          menuObj={menuObj}
                          outletRestaurantName={restaurant.name}
                          openModal={openModal}
                          menuSectionClicked={menuSectionClicked}
                          backToMenu={backToMenu}
                          isActive
                        />
                      </Route>
                    ))}
                  </Switch>

                  <CSSTransition
                    in={showOptionsModal}
                    timeout={300}
                    classNames={{
                      enter: modalEnter,
                      enterActive: modalEnterActive,
                      enterDone: modalEnterDone,
                      exit: modalExit,
                      exitActive: modalExitActive,
                      exitDone: modalExitDone,
                    }}
                    onEntering={() => toggleInnerModal(true)}
                    onExiting={() => toggleInnerModal(false)}
                    unmountOnExit
                  >
                    <OptionsPopoverWrapper
                      close={closeModal}
                      isVisible={showOptionsModalInner}
                      outletId={match.params.outletId}
                      menuItem={item}
                    />
                  </CSSTransition>
                </div>
              )
            }}
          </Query>
        )}
      </Query>
    </div>
  )
}

Menu.propTypes = {
  classes: object,
  match: object,
}

export default withStyles(styles)(Menu)
