import { MOBILE_BREAKPOINT } from '@utils/breakpoints'
import restaurantRowStyles from '@styles/sharedJSS/restaurantRow'

const styles = theme => ({
  ...restaurantRowStyles(theme),

  sidebarListCategories: {
    extend: 'sidebarList',
    display: 'none',
    position: 'relative',
    minHeight: '10rem',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      display: 'block',
    },
  },
  sideBarSubMenuLink: {
    fontSize: '12px',
    lineHeight: '32px',
    display: 'block',
    opacity: 0.7,
    cursor: 'pointer',
    transition: 'opacity 0.2s ease',
    fontWeight: 500,
    paddingLeft: '10px',
    color: `${theme.bc3} !important`,
    '&:hover': {
      opacity: 1,
    },
  },
  firstItem: {
    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      borderRadius: '0.4rem',
      margin: 0,
    },
  },
})

export default styles
