import React, { Component } from 'react'
import { object, number, string, shape, arrayOf, func, array } from 'prop-types'
import OptionRow from './OptionRow'
import { last, get, kebabCase } from 'lodash'
import cx from 'classnames'
import { Element } from 'react-scroll'

class OptionCategory extends Component {
  state = {
    options: [],
  }

  addAfterRemovingLast = async (option, id) => {
    const { chosenOptions, addOption, removeOption } = this.props
    const { options } = chosenOptions
    const lastItem = last(options)
    await removeOption(lastItem, id)
    await addOption(option, id)
  }

  add = async option => {
    const { id } = this.props.category
    const { chosenOptions, addOption } = this.props
    const { maxOptions, options } = chosenOptions

    if (maxOptions !== 0 && options.length === maxOptions) {
      await this.addAfterRemovingLast(option, id)
    } else {
      await addOption(option, id)
    }
  }

  remove = async option => {
    const { removeOption } = this.props
    const { id } = this.props.category
    await removeOption(option, id)
  }

  pluralizeWord = (word, num) => {
    return num === 1 ? word : `${word}s`
  }

  optionLabel = category => {
    const { minOptions, maxOptions } = category
    if (minOptions !== 0 && maxOptions !== 0 && minOptions === maxOptions) {
      return `Choose ${maxOptions} ${this.pluralizeWord('option', maxOptions)}`
    }
    let label = 'Choose'
    if (minOptions !== 0) {
      if (maxOptions !== 0) {
        label = label + ` ${minOptions} to ${maxOptions} options`
      } else {
        label =
          label +
          ` at least ${minOptions} ${this.pluralizeWord('option', minOptions)}`
      }
    } else {
      if (maxOptions !== 0) {
        label =
          label +
          ` up to ${maxOptions} ${this.pluralizeWord('option', maxOptions)}`
      } else {
        label = label + ` any options`
      }
    }
    return label
  }

  render() {
    const {
      optionsCategory,
      optionsCategoryError,
      optionsCategoryWrapper,
      optionHelper,
      optionList,
    } = this.props.classes
    const { category, chosenOptions } = this.props
    const hasError = get(chosenOptions, 'error', false)
    const categoryClass = hasError
      ? cx(optionsCategory, optionsCategoryError)
      : optionsCategory

    return (
      <Element name={kebabCase(category.name)}>
        <div className={optionsCategoryWrapper}>
          <div className={categoryClass}>
            <h6>{category.name}</h6>
            <span className={optionHelper}>{this.optionLabel(category)}</span>
          </div>
          <ul className={optionList}>
            {category.optionItems.map(option => (
              <OptionRow
                option={option}
                classes={this.props.classes}
                key={option.id}
                addOption={this.add}
                removeOption={this.remove}
                selectedOptions={get(chosenOptions, 'options', [])}
              />
            ))}
          </ul>
        </div>
      </Element>
    )
  }
}

OptionCategory.propTypes = {
  classes: object,
  category: shape({
    name: string,
    minOptions: number,
    optionItems: arrayOf(
      shape({
        id: string,
        name: string,
        price: number,
      })
    ),
  }),
  chosenOptions: shape({
    minOptions: number,
    maxOptions: number,
    options: array,
  }),
  addOption: func,
  removeOption: func,
}

export default OptionCategory
