import { getMarketplace } from '@config/config'
import React from 'react'
import DeliverToPostcode from './DeliverToPostcode'
import OldDeliverToPostcode from './OldDeliverToPostcode'

const DeliveryTypeRouter = ({ click }) => {
  const { allowLocationSearch } = getMarketplace()
  return allowLocationSearch ? (
    <DeliverToPostcode
      click={click}
      onSubmitCallBack={({ history }) => {
        history.push('/takeaways')
      }}
    />
  ) : (
    <OldDeliverToPostcode
      onSubmitCallBack={({ history }) => {
        history.push('/takeaways')
      }}
    />
  )
}

export default DeliveryTypeRouter
