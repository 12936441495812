import gql from 'graphql-tag'

export const LOGIN_MODAL_OPEN = gql`
  query openLogin {
    loginOpen @client
  }
`

export const FORGOTTEN_PASS_OPEN = gql`
  query openForgottenPass {
    forgottenPassOpen @client
  }
`

export const REGISTER_MODAL_OPEN = gql`
  query openRegister {
    registerOpen @client
  }
`

export const CART_OPEN_QUERY = gql`
  query openCartModal {
    cartOpen @client
  }
`

export const CURRENT_BASKET = gql`
  query basket {
    basket @client {
      quantity
      id
      menuItem {
        name
        id
        price
      }
      optionItems {
        name
        id
        price
      }
    }
  }
`

export const ADD_TO_CURRENT_BASKET = gql`
  mutation AddTobasket(
    $quantity: Int
    $id: Int
    $menuItem: JSON
    $optionItems: JSON
  ) {
    basket(
      quantity: $quantity
      id: $id
      menuItem: $menuItem
      optionItems: $optionItems
    ) @client {
      quantity
      id
      menuItem
      optionItems
    }
  }
`

export const CURRENT_JOURNEY = gql`
  query currentState {
    currentState @client {
      outletId
      deliveryZone
    }
  }
`
