// Imports
// ------
import React from 'react'
import { withStyles } from 'react-jss'
import styles from './helpContactStyles'
import Header from '@components/Header/Header'
import Hero from '../GeneralContent/Hero/Hero'
import BodyLocker from '../../utils/BodyLocker'
import { getMarketplace } from '@config/config'
import { isEmpty } from 'lodash'
import GET_FAQS from './queries/getFAQs.query'
import Query from '@components/Query/Query'
import Markdown from 'react-markdown'

// Component
// ------
class HelpContactEl extends React.PureComponent {
  componentDidMount() {
    BodyLocker.unlock()
  }

  render() {
    const {
      sectionTitle,
      faqsContainer,
      contactContainer,
      contactUsPara,
      emailLink,
      faqsList,
      faqTitle,
      faqContent,
    } = this.props.classes

    const { supportEmail } = getMarketplace()

    return (
      <>
        <Header branded />
        <Hero title="Help &amp; Contact" />

        <main className="wrapper">
          <div className="container-fluid">
            <div className="row">
              {/* FAQs */}

              <div className="col-xs-12 col-lg-offset-1 col-lg-5">
                <section className={faqsContainer}>
                  <h2 className={sectionTitle}>Frequently Asked Questions</h2>

                  <ul className={faqsList}>
                    <Query
                      query={GET_FAQS}
                      variables={{ key: getMarketplace().key }}
                    >
                      {({
                        marketplace: {
                          marketplace: { faqs },
                        },
                      }) =>
                        faqs.map(faq => (
                          <li key={faq.id}>
                            <h3 className={faqTitle}>{faq.question}</h3>
                            <Markdown className={faqContent}>
                              {faq.answer}
                            </Markdown>
                          </li>
                        ))
                      }
                    </Query>
                  </ul>
                </section>
              </div>

              {/* Contact Form */}
              <div className="col-xs-12 col-lg-offset-1 col-lg-4">
                {!isEmpty(supportEmail) && (
                  <section className={contactContainer}>
                    <h2 className={sectionTitle}>Get In Touch</h2>

                    <p className={contactUsPara}>
                      If you have any enquiries please contact us via email:
                    </p>
                    <p className={contactUsPara}>
                      <a className={emailLink} href={`mailto:${supportEmail}`}>
                        {supportEmail}
                      </a>
                    </p>
                    {/* <Form /> */}
                  </section>
                )}
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}

const HelpContact = withStyles(styles)(HelpContactEl)
export default HelpContact
