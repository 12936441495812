import gql from 'graphql-tag'

const IS_VOUCHER_VALID = gql`
  query isVoucherValid(
    $key: String!
    $marketplaceId: String!
    $outletId: String
  ) {
    isVoucherValid(
      key: $key
      marketplaceId: $marketplaceId
      outletId: $outletId
    ) {
      id
      name
      minimumSubtotalGross
      discountAmount
      discountPercentage
      daysOfWeek
      startDate
      endDate
    }
  }
`

export default IS_VOUCHER_VALID
