import React, { useContext } from 'react'
import { OutletListContext } from '@components/OutletList/OutletList.context'
import { OrderContext, OrderDeliveryMethods } from '@context/Order.context'
import {
  GET_OUTLETS_FOR_POSTCODE,
  GET_OUTLETS_FOR_DELIVERY_ZONE,
  GET_OUTLETS_FOR_COORDINATES,
} from '@components/OutletList/getOutlets.query.js'
import Query from '@components/Query/Query'
import Filter from '@components/Filter/Filter'
import { withStyles } from 'react-jss'
import { SMALL_MOBILE_BREAKPOINT, MOBILE_BREAKPOINT } from '@utils/breakpoints'
import cx from 'classnames'

const FulfilmentMethods = ({ classes, queryVariables, skipQuery }) => {
  const { isPostcodeMode } = useContext(OutletListContext)
  const { attributes, updateOrder } = useContext(OrderContext)

  return (
    <Query
      query={
        isPostcodeMode
          ? queryVariables.coordinates
            ? GET_OUTLETS_FOR_COORDINATES
            : GET_OUTLETS_FOR_POSTCODE
          : GET_OUTLETS_FOR_DELIVERY_ZONE
      }
      variables={queryVariables}
      context={{ version: 2 }}
      skip={skipQuery}
    >
      {({
        outletsForPostcode = [],
        outletsForDeliveryZone = [],
        outletsForCoordinates = [],
      }) => {
        const outlets = isPostcodeMode
          ? queryVariables.coordinates
            ? outletsForCoordinates
            : outletsForPostcode
          : outletsForDeliveryZone

        const offerDelivery = outlets.some(outlet =>
          outlet.availableFulfillmentMethods.includes('DELIVERY')
        )
        const offerCollection = outlets.some(outlet =>
          outlet.availableFulfillmentMethods.includes('COLLECTION')
        )
        const offerTable = outlets.some(outlet =>
          outlet.availableFulfillmentMethods.includes('TABLE')
        )

        if (!attributes.fulfilmentChosen) {
          switch (true) {
            case offerDelivery:
              updateOrder({ fulfilmentChosen: OrderDeliveryMethods.delivery })
              break
            case offerCollection:
              updateOrder({ fulfilmentChosen: OrderDeliveryMethods.collection })
              break
            case offerTable:
              updateOrder({ fulfilmentChosen: OrderDeliveryMethods.table })
              break
          }
        }

        const onChange = e => {
          updateOrder({
            fulfilmentChosen: OrderDeliveryMethods[e.target.value],
          })
        }

        const activeFulfilmentMethod = attributes.fulfilmentChosen
          ? attributes.fulfilmentChosen.id
          : null

        return (
          <Filter title="Order For" titleClassName={classes.title}>
            <div className={classes.container}>
              {offerDelivery && (
                <Filter.Radio
                  name="fulfilmentMethod"
                  value="delivery"
                  label="Delivery"
                  checked={activeFulfilmentMethod === 'delivery'}
                  onChange={onChange}
                  className={cx(classes.radio, {
                    [classes.radioChecked]:
                      activeFulfilmentMethod === 'delivery' ||
                      activeFulfilmentMethod === 'network',
                  })}
                />
              )}

              {offerCollection && (
                <Filter.Radio
                  name="fulfilmentMethod"
                  value="collection"
                  label="Collection"
                  checked={activeFulfilmentMethod === 'collection'}
                  onChange={onChange}
                  className={cx(classes.radio, {
                    [classes.radioChecked]:
                      activeFulfilmentMethod === 'collection',
                  })}
                />
              )}

              {offerTable && (
                <Filter.Radio
                  name="fulfilmentMethod"
                  value="table"
                  label={
                    <span>
                      Table <span className={classes.service}>Service</span>
                    </span>
                  }
                  checked={activeFulfilmentMethod === 'table'}
                  onChange={onChange}
                  className={cx(classes.radio, {
                    [classes.radioChecked]: activeFulfilmentMethod === 'table',
                  })}
                />
              )}
            </div>
          </Filter>
        )
      }}
    </Query>
  )
}

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',

    [`@media screen and (min-width: ${SMALL_MOBILE_BREAKPOINT}px)`]: {
      justifyContent: 'space-evenly',
    },

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      display: 'block',
    },
  },
  title: {
    [`@media screen and (max-width: ${MOBILE_BREAKPOINT}px)`]: {
      display: 'none',
    },
  },
  service: {
    display: 'none',

    [`@media screen and (min-width: ${SMALL_MOBILE_BREAKPOINT}px)`]: {
      display: 'inline',
    },
  },
  radio: {
    [`@media screen and (max-width: ${MOBILE_BREAKPOINT}px)`]: {
      border: '1px solid #ccc',
      padding: '5px 10px',
      borderRadius: '20px',
      fontWeight: 600,
      flexGrow: 1,
      flexBasis: 0,
      justifyContent: 'center',
      maxWidth: '30%',

      '& input': {
        width: 0,
        border: 0,
        margin: 0,
        padding: 0,
        opacity: 0,
      },
    },
  },
  radioChecked: {
    [`@media screen and (max-width: ${MOBILE_BREAKPOINT}px)`]: {
      backgroundColor: theme.bc3,
      color: '#fff',
      border: 0,
    },
  },
})

export default withStyles(styles)(FulfilmentMethods)
