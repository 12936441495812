import React from 'react'
import { withStyles } from 'react-jss'
import restaurantRowStyles from '../../styles/sharedJSS/restaurantRow'
import cx from 'classnames'

const sharedContainerStyles = {
  position: 'relative',
  appearance: 'none',
  outline: 'none',
  margin: '0 8px 0 0',
  width: '20px',
  height: '20px',
  border: '2px solid #bac3c3',
  transition: 'ease-out 0.15s',
  transitionProperty: 'border',
  cursor: 'pointer',
}

const sharedCheckedStyles = theme => ({
  content: '""',
  position: 'absolute',
  top: '3px',
  left: '3px',
  width: '10px',
  height: '10px',
  backgroundColor: theme.bc3,
})

const filterOptionStyles = theme => ({
  container: { display: 'flex', height: '37px', alignItems: 'center' },
  radio: {
    ...sharedContainerStyles,
    borderRadius: '100%',

    '&:checked': {
      borderColor: theme.bc3,

      '&:after': {
        ...sharedCheckedStyles(theme),
        borderRadius: '100%',
      },
    },
  },
  checkbox: {
    ...sharedContainerStyles,

    '&:checked': {
      borderColor: theme.bc3,

      '&:after': {
        ...sharedCheckedStyles(theme),
      },
    },
  },
  label: {
    fontSize: '14px',
    cursor: 'pointer',
  },
})

const FilterRadio = withStyles(filterOptionStyles)(
  ({ classes, className, name, value, label, checked, onChange }) => (
    <div className={cx(classes.container, className)}>
      <input
        className={classes.radio}
        type="radio"
        id={value}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={value} className={classes.label}>
        {label}
      </label>
    </div>
  )
)

const FilterCheckbox = withStyles(filterOptionStyles)(
  ({ classes, value, label, checked, onChange }) => (
    <div className={classes.container}>
      <input
        className={classes.checkbox}
        id={value}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <label className={classes.label} htmlFor={value}>
        {label}
      </label>
    </div>
  )
)

const styles = theme => ({
  container: {
    ...restaurantRowStyles(theme).sidebarList,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})
const Filter = withStyles(styles)(
  ({ classes, title, onClick, children, titleClassName }) => {
    return (
      <section className={classes.container} onClick={onClick}>
        <h4 className={cx(classes.title, titleClassName)}>{title}</h4>
        {children}
      </section>
    )
  }
)

export default Object.assign(Filter, {
  Radio: FilterRadio,
  Checkbox: FilterCheckbox,
})
