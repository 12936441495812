import React, { useContext } from 'react'
import { OutletListContext } from '@components/OutletList/OutletList.context'
import {
  GET_OUTLETS_FOR_POSTCODE,
  GET_OUTLETS_FOR_DELIVERY_ZONE,
  GET_COLLECTION_OUTLETS_FOR_POSTCODE,
  GET_OUTLETS_FOR_COORDINATES,
} from '@components/OutletList/getOutlets.query.js'
import cx from 'classnames'
import { getMarketplace } from '@config/config'
import get from 'lodash/get'
import GET_CUISINES from './getCuisines.query'
import Query from '@components/Query/Query'
import { ReactSVG } from 'react-svg'
import triangleDown from '@images/triangle-down.svg'
import Filter from '@components/Filter/Filter'
import { withStyles } from 'react-jss'
import styles from './cuisineFilter.styles'
import { OrderContext } from '@root/context/Order.context'

const CuisineFilter = ({ queryVariables, classes }) => {
  const {
    activeCuisines,
    filterCuisine,
    isPostcodeMode,
    setCuisinesOpen,
    cuisinesOpen,
  } = useContext(OutletListContext)

  const { attributes } = useContext(OrderContext)

  return (
    <Query
      query={GET_CUISINES}
      variables={{ key: getMarketplace().key }}
      context={{ version: 2 }}
    >
      {data => {
        const cuisines = get(data, 'marketplace.cuisines', [])
        const getSubTitle = () => {
          const wording =
            activeCuisines.length > 1
              ? `${activeCuisines.length} cuisines`
              : get(activeCuisines, '[0].name')

          return activeCuisines.length > 0
            ? `Showing outlets filtered by ${wording}`
            : `Showing all outlets`
        }

        return (
          <Filter
            onClick={() => setCuisinesOpen(!cuisinesOpen)}
            title={
              <>
                <span className={classes.sidebarMobileTitle}>
                  {getSubTitle()}
                </span>
                <span className={classes.sidebarDesktopTitle}>Categories</span>
                <ReactSVG
                  src={triangleDown}
                  className={cx(
                    classes.triangle,
                    classes[cuisinesOpen ? 'triangleUp' : 'triangleDown']
                  )}
                  wrapper="span"
                />
              </>
            }
          >
            <Query
              query={
                isPostcodeMode
                  ? attributes.fulfilmentChosen.id === 'collection'
                    ? GET_COLLECTION_OUTLETS_FOR_POSTCODE
                    : queryVariables.coordinates
                    ? GET_OUTLETS_FOR_COORDINATES
                    : GET_OUTLETS_FOR_POSTCODE
                  : GET_OUTLETS_FOR_DELIVERY_ZONE
              }
              variables={queryVariables}
              context={{ version: 2 }}
            >
              {({
                outletsForPostcode = [],
                outletsForDeliveryZone = [],
                outletsOfferingCollection = [],
                outletsForCoordinates = [],
              }) => {
                const outlets = isPostcodeMode
                  ? attributes.fulfilmentChosen.id === 'collection'
                    ? outletsOfferingCollection
                    : queryVariables.coordinates
                    ? outletsForCoordinates
                    : outletsForPostcode
                  : outletsForDeliveryZone
                return (
                  <div
                    className={classes[cuisinesOpen ? 'isOpen' : 'isClosed']}
                  >
                    {cuisines.map(cuisine => {
                      const isActive = activeCuisines.find(
                        ({ id }) => id === cuisine.id
                      )

                      const numberOfrestaurantsOfferingCuisine = outlets.reduce(
                        (acc, { restaurant: { cuisines } }) => {
                          if (
                            cuisines.some(({ name }) => cuisine.name === name)
                          ) {
                            acc++
                          }
                          return acc
                        },
                        0
                      )

                      if (!numberOfrestaurantsOfferingCuisine > 0) {
                        return null
                      }

                      return (
                        <Filter.Checkbox
                          key={cuisine.id}
                          value={cuisine.id}
                          label={
                            <>
                              {cuisine.name}{' '}
                              <span className={classes.restaurantsNumber}>
                                ({numberOfrestaurantsOfferingCuisine})
                              </span>
                            </>
                          }
                          checked={isActive}
                          onChange={() => filterCuisine(cuisine)}
                        />
                      )
                    })}
                  </div>
                )
              }}
            </Query>
          </Filter>
        )
      }}
    </Query>
  )
}

export default withStyles(styles)(CuisineFilter)
