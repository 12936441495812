import gql from 'graphql-tag'

export const GET_MENU_ITEM_GROUPS = gql`
  query getMenuItemGroups(
    $outletId: ID!
    $fulfilmentMethods: [MenuItemGroupFulfillmentMethod!]!
    $showInactive: Boolean
  ) {
    menuItemGroupsForOutlet(
      outletId: $outletId
      fulfillmentMethods: $fulfilmentMethods
      showInactive: $showInactive
    ) {
      id
      addOnItemsMenu
      description
      fulfillmentMethods
      image
      name
      position
      showMenuThumbnails
      parentMenu {
        id
        name
      }
      menuItems(showInactive: $showInactive) {
        id
        ageRestricted
        description
        image
        isDairyFree
        isGlutenFree
        isVegan
        isVegetarian
        name
        price
        soldOut
        parentMenus {
          id
        }
        options {
          id
          name
          maxOptions
          minOptions
          optionItems {
            id
            name
            price
            soldOut
            position
            parentOption {
              id
            }
          }
        }
        optionPositions
      }
    }
  }
`
