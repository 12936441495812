import React, { useContext } from 'react'
import styles from './basketIconStyles'
import { ReactSVG } from 'react-svg'
import injectSheet from 'react-jss'

import BasketSVG from '@images/basket.svg'
import cx from 'classnames'
import { client } from '@services/client'
import { withRouter } from 'react-router-dom'
import { TABLET_BREAKPOINT } from '@utils/breakpoints'
import { OrderContext } from '@context/Order.context'
import { BasketContext } from '@context/Basket.context'
import { getMarketplace } from '@config/config'

const BasketIcon = props => {
  const { classes, history } = props
  const { attributes } = useContext(OrderContext)
  const { items } = useContext(BasketContext)
  const { isListMode } = getMarketplace()

  const openCartView = () => {
    const { deliveryZone, restaurantName, outletId } = attributes
    const cleanText = text => {
      return (text || '').replace(/ /g, '-')
    }
    const cartUrl = `${
      isListMode ? `/${cleanText(deliveryZone.name)}-takeaways` : ''
    }/${cleanText(restaurantName)}/${outletId}/menu`

    history.push(cartUrl)

    if (window.innerWidth < TABLET_BREAKPOINT) {
      client.writeData({
        data: {
          cartOpen: true,
        },
      })
    }
  }

  const basketCount = items.length

  const { basketIcon, basketIconNumber, showBasketCount } = classes

  if (basketCount <= 0) {
    return null
  }

  return (
    <button
      className={basketIcon}
      onClick={openCartView}
      alt="Checkout your order"
    >
      <ReactSVG src={BasketSVG} wrapper="span" />
      <span
        className={cx(basketIconNumber, {
          [showBasketCount]: basketCount > 0,
        })}
      >
        {basketCount}
      </span>
    </button>
  )
}

export default injectSheet(styles)(withRouter(BasketIcon))
