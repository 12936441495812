import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import ADD_REVIEW from './mutations/addReview'
import injectSheet from 'react-jss'
import StarsRating from '@components/Stars/Stars'
import { styles } from './ReviewForm.style'
import { toast } from 'react-toastify'
import each from 'lodash/each'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import { Redirect } from 'react-router-dom'

const REVIEW_MAXLENGTH = 300

class ReviewForm extends Component {
  // store as a class prop rather than
  // as a state component
  // for performance reasons
  ratingsComponent = ''

  constructor(props) {
    super(props)
    this.state = {
      foodQuality: 0,
      restaurantService: 0,
      deliveryTime: 0,
      reviewText: '',
      loading: false,
      redirect: false,
    }
  }

  updateRatingsComponent = e => {
    this.ratingsComponent = e.currentTarget.id
  }

  handleReviewText = e =>
    this.setState({
      reviewText: e.target.value,
    })

  handleMouseClick = indexInArrayOfStars => {
    switch (this.ratingsComponent) {
      case 'foodQuality':
        this.setState({
          foodQuality: ++indexInArrayOfStars,
        })
        break
      case 'restaurantService':
        this.setState({
          restaurantService: ++indexInArrayOfStars,
        })
        break
      case 'deliveryTime':
        this.setState({
          deliveryTime: ++indexInArrayOfStars,
        })
        break
      default:
        break
    }
  }

  createReview = (event, addReview) => {
    event.preventDefault()

    const { orderId, anonymous, token } = this.props

    this.setState({
      loading: true,
    })

    const addReviewOutcome = addReview({
      variables: {
        orderId,
        anonymous,
        token,
        ...this.state,
      },
    })

    // handle error
    addReviewOutcome.catch(error => {
      each(error.graphQLErrors, errorObj => {
        toast.error(errorObj.message, {
          position: toast.POSITION.TOP_CENTER,
        })
      })
      this.resetForm()
    })

    // handle success
    addReviewOutcome.then(() => {
      toast.success('Successfully created review.', {
        position: toast.POSITION.TOP_CENTER,
      })
      this.setState({
        loading: false,
        redirect: true,
      })
    })
  }

  resetForm = () => {
    this.setState({
      foodQuality: 0,
      restaurantService: 0,
      deliveryTime: 0,
      reviewText: '',
      loading: false,
    })
  }

  render() {
    const {
      classes: {
        container,
        title,
        formContainer,
        starsContainer,
        reviewTextContainer,
        reviewTextClass,
        charCount,
        charCountLimit,
        submitBtnContainer,
        submitBtn,
      },
      anonymous,
    } = this.props

    const {
      foodQuality,
      restaurantService,
      deliveryTime,
      reviewText,
      loading,
      redirect,
    } = this.state

    let noCharsLeft = reviewText.length === REVIEW_MAXLENGTH

    return loading ? (
      <LoadingSpinner show={true} />
    ) : (
      <section>
        {redirect ? (
          anonymous ? (
            <Redirect to="/" />
          ) : (
            <Redirect to="/account/reviews" />
          )
        ) : (
          <Mutation mutation={ADD_REVIEW}>
            {addReview => (
              <section className={container}>
                <p className={title}>Review your order</p>
                <div className={formContainer}>
                  <div>
                    <label htmlFor="foodQuality">Food quality</label>
                    <div
                      name="foodQuality"
                      id="foodQuality"
                      className={starsContainer}
                      onMouseEnter={this.updateRatingsComponent}
                    >
                      <StarsRating
                        value={foodQuality}
                        isInteractive={true}
                        handleMouseClick={this.handleMouseClick}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="restaurantService">
                      Restaurant service
                    </label>
                    <div
                      id="restaurantService"
                      name="restaurantService"
                      className={starsContainer}
                      onMouseEnter={this.updateRatingsComponent}
                    >
                      <StarsRating
                        value={restaurantService}
                        isInteractive={true}
                        handleMouseClick={this.handleMouseClick}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="deliveryTime">Delivery time</label>
                    <div
                      id="deliveryTime"
                      name="deliveryTime"
                      className={starsContainer}
                      onMouseEnter={this.updateRatingsComponent}
                    >
                      <StarsRating
                        value={deliveryTime}
                        isInteractive={true}
                        handleMouseClick={this.handleMouseClick}
                      />
                    </div>
                  </div>
                  <div className={reviewTextContainer}>
                    <label htmlFor="reviewText">
                      Leave a review&nbsp;
                      <span
                        className={noCharsLeft ? charCountLimit : charCount}
                      >
                        (
                        {`${REVIEW_MAXLENGTH - reviewText.length}${
                          noCharsLeft ? ' characters left' : ''
                        }`}
                        )
                      </span>
                    </label>
                    <textarea
                      name="reviewText"
                      className={reviewTextClass}
                      onChange={this.handleReviewText}
                      placeholder="Leave your review here..."
                      maxLength={REVIEW_MAXLENGTH}
                      value={reviewText}
                    />
                  </div>
                  <div className={submitBtnContainer}>
                    <button
                      className={submitBtn}
                      onClick={e => this.createReview(e, addReview)}
                    >
                      Post Review
                    </button>
                  </div>
                </div>
              </section>
            )}
          </Mutation>
        )}
      </section>
    )
  }
}

export default injectSheet(styles)(ReviewForm)
