// Imports
// ------
import React from 'react'
import injectSheet from 'react-jss'
import { styles } from '../contentStyles'
import cx from 'classnames'

// Component
// ------
class HeadingEl extends React.PureComponent {
  render() {
    const { heading, headingLarge } = this.props.classes

    // Does it have the prop of large?
    if (this.props.large) {
      return (
        <h2 className={cx(heading, headingLarge)}>{this.props.children}</h2>
      )
    }

    // What do we do if it hasnt got "Large"?
    if (!this.props.large) {
      return <h3 className={heading}>{this.props.children}</h3>
    }
  }
}

const Heading = injectSheet(styles)(HeadingEl)
export default Heading
