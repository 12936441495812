import React from 'react'
import { GET_FEATURED_OUTLETS } from './queries/getFeaturedOutlets.query'
import { Query } from 'react-apollo'
import get from 'lodash/get'
import sampleSize from 'lodash/sampleSize'
import { getMarketplace } from '@config/config'
import FeaturedOutlet from './FeaturedOutlet/FeaturedOutlet'
import { withStyles } from 'react-jss'
import styles from './featuredOutlets.styles'

const FeaturedOutlets = ({ classes, wrapperClassName }) => {
  const marketplace = getMarketplace()
  return (
    <Query
      query={GET_FEATURED_OUTLETS}
      variables={{ marketplaceId: marketplace.id }}
      context={{ version: 2 }}
    >
      {({ data }) => {
        const featuredOutlets = get(data, 'featuredOutlets', [])
        if (featuredOutlets.length) {
          return (
            <section className={wrapperClassName}>
              <div className="wrapper">
                <h2 className={classes.title}>Now on {marketplace.name}</h2>
                <div className="row center-xs">
                  {sampleSize(featuredOutlets, 3).map(outlet => (
                    <FeaturedOutlet key={outlet.id} outlet={outlet} />
                  ))}
                </div>
              </div>
            </section>
          )
        }
        return null
      }}
    </Query>
  )
}

export default withStyles(styles)(FeaturedOutlets)
