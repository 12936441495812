import React from 'react'
import pick from 'lodash/pick'
import { getMarketplace } from '@config/config'
import EnterPostcode from '@components/EnterPostcode/EnterPostcode'
import { getDeliveryZoneFromURL } from '@utils/helpers'
import DeliveryZonesModal from '../DeliveryZones/DeliveryZonesModal'

export default props => {
  // when deep linking to outlets we need to collect the outletId and deliveryZone from
  // the url and assign them to the order so we can get information about delivery
  // etc and load the cart.
  // There are some conditions though if the order has an outletId dont assign a new
  // one in or we loose the client asking if you would like to start a new order with X.
  // The basket must be cleared if we start a new Order by assigning the outletId as
  // anything previously in the basket would relate to the wrong outlet

  const { isPostcodeMode, isListMode } = getMarketplace()
  const { modalContext, orderContext, basketContext, match } = props

  const orderInfo = pick(match.params, ['outletId'])
  const { outletId } = orderInfo
  const {
    outletId: currentOutletId,
    deliveryDestination = { type: 'POSTCODE', destination: '' },
  } = orderContext.attributes
  const isTakeawayList =
    props.match.path === '/:deliveryZone-takeaways' ||
    props.match.path === '/takeaways'
  const isNewOutlet = !currentOutletId && outletId
  const isDifferentOutlet = currentOutletId !== outletId

  const queryParams = new URLSearchParams(window.location.search)
  const isPreviewMode = queryParams.get('showInactive')

  if (!isTakeawayList && (isNewOutlet || isDifferentOutlet)) {
    orderContext.updateOrder({ outletId })
    basketContext.clearItems()
  }

  if (match.params.restaurant && !orderContext.attributes.restaurantName) {
    orderContext.updateOrder({ restaurantName: match.params.restaurant })
  }
  if (
    isPostcodeMode &&
    (isTakeawayList || match.params.restaurant) &&
    !isPreviewMode &&
    ((deliveryDestination.type === 'POSTCODE' &&
      !deliveryDestination.destination) ||
      (deliveryDestination.type === 'COORDINATES' &&
        !deliveryDestination.destination.lat))
  ) {
    if (!orderContext.attributes.restaurantName) {
      orderContext.updateOrder({ restaurantName: match.params.restaurant })
    }
    modalContext.openModal(<EnterPostcode />, { closeOnClickOutside: false })
  }

  if (isListMode) {
    const deliveryZone = getDeliveryZoneFromURL(match)

    if (deliveryZone) {
      orderContext.updateOrder({
        deliveryZone: { id: deliveryZone.id, name: deliveryZone.name },
      })
    } else if (!isPreviewMode) {
      modalContext.openModal(
        <DeliveryZonesModal
          close={() => modalContext.closeModal()}
          persistPath={true}
        />,
        {
          closeOnClickOutside: false,
        }
      )
    }
  }
}
