import React, { useState } from 'react'
import { Mutation } from 'react-apollo'
import ACCEPT_DISCOUNT from './acceptDiscount.mutation'
import withStyles from 'react-jss'
import { getMarketplace } from '@config/config'
import Button from '@components/Button/Button'
import { styles } from './acceptDiscount.styles'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { parseIncomingError } from '@utils/incomingErrorParser'

const AcceptDiscountModal = ({
  classes,
  enrolmentKey,
  closeAcceptDiscountModal,
  history,
  location,
}) => {
  const searchParams = new URLSearchParams(`${location.search}`)
  const businessName = searchParams.get('businessName')

  const [enrolled, setEnrolled] = useState(false)
  const { brandColor, brandSecondaryColor } = getMarketplace()

  return (
    <div className={classes.container}>
      <Mutation
        mutation={ACCEPT_DISCOUNT}
        onCompleted={() => {
          setEnrolled(true)
          closeAcceptDiscountModal()
          history.push('/')
          toast.success(
            'Accepted, your discount will be automatically applied at checkout.',
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 4000,
            }
          )
        }}
        onError={err => {
          toast.error(
            parseIncomingError(
              err,
              'There was a problem with this discount activation. Please contact the business to request a new code.'
            ),
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 4000,
            }
          )
          history.push('/')
          closeAcceptDiscountModal()
        }}
        context={{ version: 2 }}
      >
        {acceptDiscount => (
          <form
            onSubmit={async e => {
              e.preventDefault()
              await acceptDiscount({
                variables: {
                  enrolmentKey,
                },
              })
            }}
          >
            <div className={classes.titleJacket}>
              <h3
                className={classes.title}
                style={{
                  color: brandColor,
                }}
              >
                Discount Enrolment
              </h3>
              <button
                type="button"
                onClick={() => {
                  history.push('/')
                  closeAcceptDiscountModal()
                }}
                className={classes.close}
              >
                Close
              </button>
            </div>
            <p className={classes.acceptMessage}>
              <span>
                Accept to activate your permanent discount from {businessName}.
              </span>
              <br />
              <span>
                This will allow you to benefit from the permanent discount
                you've been invited to.
              </span>
            </p>
            <Button
              type="submit"
              className={classes.submit}
              disabled={enrolled}
              style={{
                backgroundColor: brandSecondaryColor,
              }}
            >
              {enrolled ? 'Successfully Enrolled' : 'Accept'}
            </Button>
            )
          </form>
        )}
      </Mutation>
    </div>
  )
}

export default withStyles(styles)(withRouter(AcceptDiscountModal))
