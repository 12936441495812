import React, { useState, useEffect, useContext } from 'react'
import ls from '@utils/localStorage'
import { GTMContext } from '@context/GTM.context'
import times from 'lodash/times'
import sumBy from 'lodash/sumBy'
import uniqueId from 'lodash/uniqueId'
import { flatMap } from 'lodash'

export const BasketContext = React.createContext({
  items: [],
  subTotal: 0,
  costOfAddOnItems: 0,
  removeItem: _ => {},
  removeAddOnItems: _ => {},
  removeItemWithOptionId: _ => {},
  addItem: () => {},
  clearItems: () => {},
})

export const BasketContextProvider = ({ children }) => {
  const gtm = useContext(GTMContext)
  const [items, setItems] = useState([])
  const [subTotal, setSubTotal] = useState(0)
  const [costOfAddOnItems, setCostOfAddOnItems] = useState(0)

  useEffect(() => {
    const subTotal = items.reduce((total, item) => {
      // do not include add on items in the subTotal so discounts are calculated correctly
      if (!item.addOnItem) {
        total += item.menuItem.price
        const optionTotal = sumBy(item.optionItems, 'price')
        total += optionTotal
      }
      return total
    }, 0)

    setSubTotal(subTotal)

    const costOfAddOn = items.reduce((total, item) => {
      if (item.addOnItem) total += item.menuItem.price
      return total
    }, 0)
    setCostOfAddOnItems(costOfAddOn)
  }, [items.map(item => item.id).join('')])

  useEffect(() => {
    const localBasketItems = ls.get('basket')
    if (localBasketItems && localBasketItems.length) {
      setItems(localBasketItems)
    } else {
      ls.set('basket', [])
    }
  }, [])

  const removeItem = id => {
    setItems(items => {
      const itemsWithoutId = items.filter(item => {
        if (item.id === id) {
          gtm.pushDataToGTM({
            event: 'remove_from_cart',
            ecommerce: {
              items: {
                item_name: item.menuItem.name,
                item_id: item.menuItem.id,
                price: item.menuItem.price,
              },
            },
          })
        }
        return item.id !== id
      })
      ls.set('basket', itemsWithoutId)
      return itemsWithoutId
    })
  }

  const removeAddOnItems = () => {
    setItems(items => {
      const nonAddOnItems = items.filter(item => !item.addOnItem)
      ls.set('basket', nonAddOnItems)
      return nonAddOnItems
    })
  }

  const removeItemWithOptionId = id => {
    setItems(items => {
      const itemsWithoutOptionId = items.filter(item => {
        const itemOptions = flatMap(item.optionItems, option => option.id)
        return !itemOptions.includes(id)
      })

      ls.set('basket', itemsWithoutOptionId)
      return itemsWithoutOptionId
    })
  }

  const addItem = (item, quantity = 1) => {
    times(quantity, () =>
      setItems(items => {
        const newItems = [...items, { ...item, id: uniqueId('basket-item-') }]
        ls.set('basket', newItems)
        return newItems
      })
    )
    gtm.pushDataToGTM({
      event: 'add_to_cart',
      ecommerce: {
        items: {
          item_name: item.menuItem.name,
          item_id: item.menuItem.id,
          price: item.menuItem.price,
          quantity: quantity,
        },
      },
    })
  }

  const clearItems = () => {
    gtm.pushDataToGTM({
      event: 'remove_from_cart',
      ecommerce: {
        items: items.reduce((acc, item) => {
          const menuItem = item.menuItem
          const existingItem = acc.find(
            ecomItem => ecomItem.item_id === menuItem.id
          )
          if (existingItem) {
            existingItem.quantity = existingItem.quantity + 1
          } else {
            const item = {
              item_name: menuItem.name,
              item_id: menuItem.id,
              price: menuItem.price,
              quantity: 1,
            }
            acc = [...acc, item]
          }
          return acc
        }, []),
      },
    })

    setItems([])
    ls.set('basket', [])
  }

  return (
    <BasketContext.Provider
      value={{
        items,
        subTotal,
        costOfAddOnItems,
        removeItem,
        removeItemWithOptionId,
        removeAddOnItems,
        addItem,
        clearItems,
      }}
    >
      {children}
    </BasketContext.Provider>
  )
}
