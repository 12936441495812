import React, { useCallback, useContext, useEffect, useState } from 'react'
import { getMarketplace } from '@config/config'
import IS_VOUCHER_VALID from './queries/isVoucherValid.query'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import { toast } from 'react-toastify'
import { Query } from 'react-apollo'
import get from 'lodash/get'
import { VoucherContext } from '@context/Voucher.context'
import Button from '@components/Button/Button'
import { Icon } from '@blueprintjs/core'
import { BasketContext } from '@context/Basket.context'
import { penceToPounds } from '@utils/helpers'

const MIN_DISCOUNTCODE_LENGTH = 3

const VoucherSection = ({ classes, outletId }) => {
  const { key, discount, applyVoucherDiscount, removeDiscount } =
    useContext(VoucherContext)
  const { subTotal } = useContext(BasketContext)

  const [voucherCode, setVoucherCode] = useState('')
  const [renderFetchingComponent, setRenderFetchingComponent] = useState(false)
  const [isVoucherSectionVisible, setIsVoucherSectionVisible] = useState(false)

  const { id: marketplaceId } = getMarketplace()

  const handleCodeTyping = e =>
    setVoucherCode(e.currentTarget.value.toUpperCase())

  useEffect(() => {
    if (
      discount &&
      discount.minimumSubtotalGross &&
      discount.minimumSubtotalGross > subTotal
    ) {
      removeDiscount()
    }
  }, [])

  // TODO: this can be replaced by a lazy query once we move to @apollo/react-hooks
  const FetchVoucherData = useCallback(() => {
    return voucherCode.length > MIN_DISCOUNTCODE_LENGTH ? (
      <Query
        context={{ version: 2 }}
        query={IS_VOUCHER_VALID}
        variables={{
          key: voucherCode,
          marketplaceId,
          outletId,
        }}
        fetchPolicy="no-cache"
      >
        {response => {
          const { loading, error, data } = response
          if (error) {
            toast.error(
              get(
                error,
                'message',
                'There was an error while checking the discount code'
              ).replace(/GraphQL error:\s?/, ''),
              {
                position: toast.POSITION.TOP_CENTER,
              }
            )
            setTimeout(() => {
              setRenderFetchingComponent(false)
            }, 500)
          }

          if (get(data, 'isVoucherValid')) {
            const { isVoucherValid: discount } = data
            const { minimumSubtotalGross } = discount

            // voucher might be valid but the minimum to spend is not yet met
            if (minimumSubtotalGross && minimumSubtotalGross > subTotal) {
              const toastWarningMessage = `This discount requires a minimum subtotal of £${penceToPounds(
                minimumSubtotalGross
              )}, please add more items to your basket. This discount does not accept other offers or discounts to meet it's minimum requirement.`

              toast.warn(toastWarningMessage, {
                position: toast.POSITION.TOP_CENTER,
              })
              setVoucherCode('')
              return null
            } else {
              toast.success('The discount code was successfully applied.', {
                position: toast.POSITION.TOP_CENTER,
              })

              setTimeout(() => {
                setRenderFetchingComponent(false)
                applyVoucherDiscount({
                  discount,
                  key: voucherCode,
                })
              }, 500)
            }
          }

          return <LoadingSpinner show={loading} />
        }}
      </Query>
    ) : null
  }, [voucherCode])

  return (
    <div className={classes.formSection}>
      <div
        className={classes.inRow}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setIsVoucherSectionVisible(!isVoucherSectionVisible)
        }}
      >
        <h3 className={classes.pageHeader}>{`${
          key ? 'Remove' : 'Add'
        } Discount Code `}</h3>
        <Icon
          icon={isVoucherSectionVisible ? 'chevron-up' : 'chevron-down'}
          style={{ marginLeft: '1rem' }}
        />
      </div>
      <div hidden={!isVoucherSectionVisible} className={classes.formSection}>
        <div className={classes.inRow}>
          {renderFetchingComponent && <FetchVoucherData />}
          <input
            type="text"
            placeholder={key || 'Discount Code'}
            value={voucherCode}
            onChange={handleCodeTyping}
            className={classes.inputField}
            disabled={!!key}
          />
          <Button
            onClick={e => {
              e.preventDefault()
              if (key) {
                removeDiscount()
                setVoucherCode('')
              } else {
                setRenderFetchingComponent(true)
              }
            }}
            disabled={!key && voucherCode.length < MIN_DISCOUNTCODE_LENGTH}
            appearance={key ? 'negative' : 'positive'}
            square={true}
            className={classes.confirmButton}
          >
            {key ? 'Remove' : 'Apply'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default VoucherSection
