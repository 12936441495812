import React, { useContext } from 'react'
import { get } from 'lodash'
import injectSheet from 'react-jss'
import { object, string } from 'prop-types'
import styles from './categoriesStyles'
import CategoryListItem from './CategoryListItem'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import cx from 'classnames'
import { Query } from 'react-apollo'
import { OrderContext } from '@context/Order.context'
import { GET_MENU_ITEM_GROUPS } from '@shared/queries/getMenuItemGroups.query'

const Categories = ({ classes, outletId, singleOrderMode }) => {
  const { attributes } = useContext(OrderContext)

  return (
    <Query
      query={GET_MENU_ITEM_GROUPS}
      variables={{
        outletId,
        get fulfilmentMethods() {
          if (attributes.fulfilmentChosen) {
            return [attributes.fulfilmentChosen.id.toUpperCase()]
          }
          return ['DELIVERY']
        },
      }}
      context={{ version: 2 }}
    >
      {({ data, loading }) => {
        const menuItemGroups = get(data, 'menuItemGroupsForOutlet', [])

        const { parentMenus, subMenus } = menuItemGroups.reduce(
          (acc, menuItemGroup) => {
            if (!menuItemGroup.addOnItemsMenu) {
              acc[menuItemGroup.parentMenu ? 'subMenus' : 'parentMenus'].push(
                menuItemGroup
              )
            }
            return acc
          },
          { parentMenus: [], subMenus: [] }
        )

        const sortedMenuItemGroups = parentMenus.map(parentMenu => {
          const parentMenuSubMenus = subMenus.filter(
            subMenu => subMenu.parentMenu.id === parentMenu.id
          )
          return {
            ...parentMenu,
            subMenus: parentMenuSubMenus,
          }
        })

        return (
          <div
            className={cx(classes.sidebarListCategories, {
              [classes.firstItem]: singleOrderMode,
            })}
          >
            <h4>Categories</h4>
            <LoadingSpinner show={loading} />
            <ul>
              {sortedMenuItemGroups.map(sortedMenuItemGroup => (
                <CategoryListItem
                  key={sortedMenuItemGroup.id}
                  category={sortedMenuItemGroup}
                  classes={classes}
                />
              ))}
            </ul>
          </div>
        )
      }}
    </Query>
  )
}

Categories.propTypes = {
  classes: object,
  outletId: string,
}

export default injectSheet(styles)(Categories)
