import React, { useState, useEffect } from 'react'
import ls from '@utils/localStorage'
import moment from 'moment'

const momentFormat = 'DD/MM/YYYY/HH:mm'
const currentTime = () => moment().format(momentFormat)

export const OrderDeliveryMethods = {
  delivery: {
    id: 'delivery',
    label: 'Delivery',
  },
  collection: {
    id: 'collection',
    label: 'Collection',
  },
  table: {
    id: 'table',
    label: 'Table',
  },
}

export const OrderContext = React.createContext({
  ready: false,
  attributes: { fulfilmentChosen: OrderDeliveryMethods.delivery },
  updateOrder: (_, __) => {},
  replaceOrder: () => {},
})

export const OrderContextProvider = ({ children }) => {
  const [ready, setReady] = useState(false)
  const [attributes, setAttributes] = useState({
    lastUpdate: currentTime(),
    fulfilmentChosen: OrderDeliveryMethods.delivery,
  })

  useEffect(() => {
    const localStorageOrder = ls.get('order')
    if (localStorageOrder) {
      const isOrderOver12HoursOld =
        localStorageOrder.lastUpdate &&
        moment(localStorageOrder.lastUpdate, momentFormat).isBefore(
          moment().subtract('12', 'hours')
        )

      if (localStorageOrder.lastUpdate && !isOrderOver12HoursOld) {
        setAttributes({
          ...localStorageOrder,
          ...(localStorageOrder.selectedDeliverySlot && {
            selectedDeliverySlot: new Date(
              localStorageOrder.selectedDeliverySlot
            ),
          }),
          ...(localStorageOrder.selectedDeliveryWindow && {
            selectedDeliveryWindow: {
              start: localStorageOrder.selectedDeliveryWindow.start
                ? new Date(localStorageOrder.selectedDeliveryWindow.start)
                : null,
              end: new Date(localStorageOrder.selectedDeliveryWindow.end),
            },
          }),
        })
      }
    } else {
      ls.set('order', attributes)
    }
    setReady(true)
  }, [])

  const updateOrder = (orderAttributes = {}) => {
    setAttributes(attributes => {
      const newAttributes = {
        ...attributes,
        ...orderAttributes,
        lastUpdate: currentTime(),
      }
      ls.set('order', newAttributes)
      return newAttributes
    })
  }

  const replaceOrder = (orderAttributes = {}) => {
    setAttributes(() => {
      const newAttributes = {
        fulfilmentChosen: OrderDeliveryMethods.delivery,
        lastUpdate: currentTime(),
        ...orderAttributes,
      }
      ls.set('order', newAttributes)
      return newAttributes
    })
  }

  return (
    <OrderContext.Provider
      value={{ ready, attributes, updateOrder, replaceOrder }}
    >
      {children}
    </OrderContext.Provider>
  )
}
