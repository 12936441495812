import gql from 'graphql-tag'

const USER_DETAILS = gql`
  query getUserDetails {
    myDetails {
      customer {
        id
        email
        firstName
        lastName
        phoneNumber
      }
    }
  }
`

export default USER_DETAILS
