// Imports
// ------
import React from 'react'
import injectSheet from 'react-jss'
import { styles } from './heroStyles'

// Images
// ------
import $backgroundImage from '@images/hero-image.jpg'
import { getMarketplace, AWS_CDN_URL } from '@config/config'

// Component
// ------
class HeroEl extends React.PureComponent {
  render() {
    const {
      jacket,
      content,
      titleContainer,
      title,
      imageContainer,
      image,
    } = this.props.classes
    const { heroImage, title: marketplaceTitle } = getMarketplace()

    return (
      <section className={jacket}>
        {/* Image */}
        <figure className={imageContainer}>
          <img
            className={image}
            src={`${AWS_CDN_URL}${heroImage}`}
            alt={`Banner / Hero - ${marketplaceTitle}`}
          />
        </figure>

        {/* Content */}
        <main className={content}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-12 col-sm-offset-1 col-sm-10">
                <div className={titleContainer}>
                  <h1 className={title}>{this.props.title}</h1>
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
    )
  }
}

const Hero = injectSheet(styles)(HeroEl)
export default Hero
