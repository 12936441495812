import React from 'react'
import injectSheet from 'react-jss'
import { Query as ApolloQuery } from 'react-apollo'
import { toast } from 'react-toastify'
import styles from './loaderStyles'
import debounce from 'lodash/debounce'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'

const toastError = debounce(error => {
  toast.error(error, {
    position: toast.POSITION.TOP_CENTER,
  })
}, 150)

const QueryEl = ({ classes, children, ...props }) => (
  <ApolloQuery {...props}>
    {({ loading, error, data = {} }) => {
      if (loading) {
        return (
          <div className={classes.loader}>
            <LoadingSpinner show />
          </div>
        )
      }

      if (error) {
        if (typeof error.message === 'string') {
          toastError(error.message)
        }
        // rare edge case that this will be logged but its better than putting an object in a toast
        // eslint-disable-next-line no-console
        console.error(error)
        return children({})
      }

      return children(data)
    }}
  </ApolloQuery>
)

export default injectSheet(styles)(QueryEl)
