import Button from '@components/Button/Button'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import Query from '@components/Query/Query'
import { GET_USER } from '@components/UserAccount/Addresses/queries/getUserDetails.query'
import React, { useContext } from 'react'
import { Mutation } from 'react-apollo'
import AddressSection from './AddressSection/AddressSection'
import AgeRestriction from './AgeRestriction/AgeRestriction'
import CardSection from './CardSection/CardSection'
import PaymentMethodSection from './PaymentMethodSection/PaymentMethodSection'
import {
  POST_ORDER_POSTCODE,
  POST_ORDER_TO_TABLE,
  POST_ORDER_ZONE,
} from './queries/postOrder.query'
import cx from 'classnames'
import Cart from '@components/Cart/Cart'
import { bool, func, object, string } from 'prop-types'
import VoucherSection from './VoucherSection/VoucherSection'
import { VoucherContext } from '@context/Voucher.context'
import { toast } from 'react-toastify'
import GET_OUTLET_INFO from '@components/Cart/queries/getOutletInfo.query'

const CheckoutFormContent = ({
  isOrderToTable,
  orderMode,
  sendPurchaseToGTM,
  outlet,
  needsAddress,
  classes,
  needsCard,
  conditionallySetCardToken,
  checkoutText,
  getNotesSectionTitle,
  hasAgeResrictedItems,
  setCustomerDeliveryNotes,
  placingOrder,
  addOrderMutation,
  setPlacingOrder,
}) => {
  const { key, removeDiscount } = useContext(VoucherContext)

  return (
    <Query query={GET_USER} fetchPolicy="network-only">
      {({ loading, data: userData }) => (
        <>
          <Mutation
            mutation={
              isOrderToTable
                ? POST_ORDER_TO_TABLE
                : orderMode === 'POSTCODE'
                ? POST_ORDER_POSTCODE
                : POST_ORDER_ZONE
            }
            context={{ version: isOrderToTable ? 2 : 1 }}
            onCompleted={data => {
              if (
                (data.addOrder && data.addOrder.order !== null) ||
                data.orderToTable !== null
              ) {
                sendPurchaseToGTM(data, isOrderToTable)
                if (key) {
                  removeDiscount()
                }
              }
            }}
            onError={error => {
              setPlacingOrder(false)
              error.message = error.message.replace('GraphQL error:', '')
              toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 6000,
              })
            }}
            refetchQueries={[
              {
                query: GET_OUTLET_INFO,
                variables: { outletId: outlet.id },
                context: { version: 2 },
              },
            ]}
          >
            {addOrder => (
              <form
                onSubmit={event => {
                  event.persist()
                  event.preventDefault()
                  addOrderMutation({
                    mutation: addOrder,
                    outlet,
                    needsAddress,
                    isOrderToTable,
                    event,
                  })
                }}
              >
                <div className={classes.sections}>
                  {needsAddress && (
                    <div className={classes.formSection}>
                      <h3 className={classes.pageHeader}>Delivery Address</h3>
                      <AddressSection classes={classes} userData={userData} />
                    </div>
                  )}

                  <div className={classes.formSection}>
                    <h3 className={classes.pageHeader}>Payment Method</h3>
                    <PaymentMethodSection classes={classes} outlet={outlet} />
                  </div>
                  {needsCard && (
                    <div className={classes.formSection}>
                      <h3 className={classes.pageHeader}>Payment Details</h3>
                      <CardSection
                        setCardToken={conditionallySetCardToken}
                        classes={classes}
                      />
                      <div className={classes.emptyNotice}>{checkoutText}</div>
                    </div>
                  )}

                  {!isOrderToTable && (
                    <VoucherSection classes={classes} outletId={outlet.id} />
                  )}

                  <div className={cx(classes.formSection)}>
                    <h3 className={classes.pageHeader}>
                      {getNotesSectionTitle()} Notes
                    </h3>
                    <div className={cx('row', classes.fieldRow)}>
                      <div className="col-xs-12">
                        <textarea
                          className={classes.notesSection}
                          onChange={setCustomerDeliveryNotes}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={cx(classes.formSection, classes.cartSummary)}>
                    <h3 className={classes.pageHeader}>Order Summary</h3>
                    <Cart forceOpen summaryOnly />
                  </div>

                  {hasAgeResrictedItems ? <AgeRestriction /> : null}
                </div>

                <div className={classes.formFooter}>
                  <Button
                    type="submit"
                    className={classes.confirmButton}
                    appearance="positive"
                    capitalize
                    fullWidth
                    disabled={placingOrder}
                  >
                    Place Order
                  </Button>
                </div>
              </form>
            )}
          </Mutation>
          <LoadingSpinner show={loading} />

          <LoadingSpinner
            show={placingOrder}
            loadingMessage="Placing order, please wait"
            fullscreen
          />
        </>
      )}
    </Query>
  )
}

CheckoutFormContent.propTypes = {
  isOrderToTable: bool,
  orderMode: string,
  sendPurchaseToGTM: func,
  outlet: object,
  needsAddress: bool,
  classes: object,
  needsCard: bool,
  conditionallySetCardToken: object,
  checkoutText: string,
  getNotesSectionTitle: func,
  hasAgeResrictedItems: bool,
  setCustomerDeliveryNotes: func,
  placingOrder: bool,
  addOrderMutation: func,
  setPlacingOrder: func,
  setAddressError: func,
}

export default CheckoutFormContent
