import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'react-jss'
import cx from 'classnames'
import checkSVG from '@images/check.svg'
import { ReactSVG } from 'react-svg'
import Color from 'color'

const FulfilmentMethods = ({
  classes,
  offersDelivery,
  offersCollection,
  className,
}) => (
  <div className={cx(classes.container, className)}>
    <div
      className={cx(classes.option, {
        [classes.optionActive]: offersCollection,
      })}
    >
      <ReactSVG src={checkSVG} className={classes.svgIcon} wrapper="span" />
      Collection
    </div>
    <div
      className={cx(classes.option, { [classes.optionActive]: offersDelivery })}
    >
      <ReactSVG src={checkSVG} className={classes.svgIcon} wrapper="span" />
      Delivery
    </div>
  </div>
)

FulfilmentMethods.propTypes = {
  offersDelivery: PropTypes.bool,
  offersCollection: PropTypes.bool,
}

const styles = theme => ({
  container: {
    lineHeight: '22px',
    fontSize: '1.2rem',
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    marginTop: '0',
    marginRight: '1rem',
  },

  option: {
    textAlign: 'left',
    whiteSpace: 'nowrap',
    opacity: 0.5,
  },

  optionActive: {
    color: theme.bc1,
    opacity: 1,

    '& svg': {
      fill: theme.bc1,
    },
  },

  svgIcon: {
    verticalAlign: 'middle',
    marginRight: '0.7rem',

    '& svg': {
      width: '1.4rem',
      height: '1.4rem',
      fill: Color(theme.bc5)
        .rgb()
        .alpha(0.5)
        .string(),
    },
  },
})

export default withStyles(styles)(FulfilmentMethods)
