import gql from 'graphql-tag'

const GET_LEGAL = gql`
  query getLegalPrivacy($key: String!) {
    marketplace(key: $key) {
      marketplace {
        id
        legalPrivacy
      }
    }
  }
`

export default GET_LEGAL
