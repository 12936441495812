import React, { Component } from 'react'
import ResetRequest from '@components/ResetPassword/RequestReset'
import Modal from '@components/ModalWrapper/ModalWrapper'
import { Query } from 'react-apollo'
import { FORGOTTEN_PASS_OPEN } from '../Query/LocalStates'
import { client } from '@services/client'
import BodyLocker from '../../utils/BodyLocker'

class ForgottenPasswordModal extends Component {
  close() {
    client.writeData({
      data: {
        forgottenPassOpen: false,
      },
    })
    BodyLocker.unlock()
  }
  render() {
    return (
      <Query query={FORGOTTEN_PASS_OPEN}>
        {({ data }) => (
          <Modal open={data.forgottenPassOpen} close={this.close}>
            <ResetRequest close={this.close} />
          </Modal>
        )}
      </Query>
    )
  }
}

export default ForgottenPasswordModal
