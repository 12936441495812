import React from 'react'
import { withStyles } from 'react-jss'
import styles from './checkboxStyles'
import cx from 'classnames'

const Checkbox = ({ classes, checked, id, onChange }) => (
  <>
    <input
      id={id}
      className={classes.hiddenInput}
      type="checkbox"
      checked={checked}
      onChange={e => onChange(e.target.checked)}
    />
    <span onClick={() => onChange(!checked)}>
      <svg
        className={classes.svg}
        xmlns="http://www.w3.org/2000/svg"
        width="1.8rem"
        height="1.8rem"
        viewBox="0 0 32 32"
      >
        <title>checkbox</title>
        <g
          className={cx(classes.container, {
            [classes.containerActive]: checked,
          })}
          strokeLinecap="square"
          strokeLinejoin="miter"
          strokeWidth="2"
        >
          <rect
            className={classes.checkBox}
            x="2"
            y="2"
            width="28"
            height="28"
            strokeMiterlimit="10"
          />

          <polyline
            className={cx(classes.checkMark, {
              [classes.checkMarkActive]: checked,
            })}
            points="9 17 13 21 23 11"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    </span>
  </>
)

export default withStyles(styles)(Checkbox)
