import React from 'react'
import CustomRoute from './CustomRoute'
import { Redirect, Switch } from 'react-router-dom'

import Home from '@components/Home/Home'
import FourZeroFour from '@components/FourZeroFour/FourZeroFour'

import OutletList from '@components/OutletList/OutletList'
import SingleRestaurant from '@components/SingleRestaurant/SingleRestaurant'
import Checkout from '@components/Checkout/Checkout'
import Account from '@components/UserAccount/UserAccountMain'
import Terms from '@components/Terms/Terms'
import Privacy from '@components/Privacy/Privacy'
import Misc from '@components/Misc/Misc'
import Cookies from '@components/Cookies/Cookies'
import Allergy from '@components/Allergy/Allergy'
import HelpContact from '@components/HelpContact/HelpContact'
import AddReviewUnauthenticated from '@components/AddReviewUnauthenticated/addReviewUnauthenticated'

// redirects with logic
import OutletRedirect from './Redirects/OutletRedirect'
import RestaurantOutletRedirect from './Redirects/RestaurantOutletRedirect'

// page meta
import RestaurantMenuHead from './PageHeads/RestaurantMenuHead'
import RestaurantCheckoutHead from './PageHeads/RestaurantCheckoutHead'
import RestaurantsListHead from './PageHeads/RestaurantsListHead'

import orderInfoCallBack from './orderInfoCallBack'

const RestaurantOutletRedirectToSingleRestaurant = routerProps => (
  <RestaurantOutletRedirect {...routerProps}>
    {props => <SingleRestaurant {...props} />}
  </RestaurantOutletRedirect>
)

const RestaurantOutletRedirectToRestaurantMenuHead = routerProps => (
  <RestaurantOutletRedirect {...routerProps}>
    {props => <RestaurantMenuHead {...props} />}
  </RestaurantOutletRedirect>
)

const RedirectToRestaurantCheckout = ({ match }) => (
  <Redirect
    to={`/${match.params.outletId}/${match.params.restaurant}-checkout`}
  />
)

const RedirectToOutlet = ({ match }) => (
  <Redirect to={`/${match.params.restaurant}/${match.params.outletId}`} />
)

const RedirectToTakeaways = () => <Redirect to="/takeaways" />

const RoutesWithDeliveryZone = () => (
  <Switch>
    <CustomRoute
      path="/:deliveryZone-takeaways/:outletId/:restaurant-checkout"
      component={Checkout}
      exact
      head={RestaurantCheckoutHead}
      onEnter={orderInfoCallBack}
    />

    <CustomRoute
      path="/:deliveryZone-takeaways/:restaurant/:outletId"
      component={RestaurantOutletRedirectToSingleRestaurant}
      head={RestaurantOutletRedirectToRestaurantMenuHead}
      onEnter={orderInfoCallBack}
    />

    <CustomRoute
      path="/:deliveryZone-takeaways"
      component={OutletList}
      exact
      head={RestaurantsListHead}
      onEnter={orderInfoCallBack}
    />
    {/* if none above match - redirect home */}
    <Redirect to="/" />
  </Switch>
)

const RoutesWithoutDeliveryZone = () => (
  <Switch>
    <CustomRoute
      path="/:deliveryZone-takeaways/:outletId/:restaurant-checkout"
      exact
      component={RedirectToRestaurantCheckout}
      onEnter={orderInfoCallBack}
    />
    <CustomRoute
      path="/:outletId/:restaurant-checkout"
      component={Checkout}
      exact
      head={RestaurantCheckoutHead}
      onEnter={orderInfoCallBack}
    />

    <CustomRoute
      path="/:deliveryZone-takeaways/:restaurant/:outletId"
      component={RedirectToOutlet}
      onEnter={orderInfoCallBack}
    />
    <CustomRoute
      path="/:restaurant/:outletId"
      component={RestaurantOutletRedirectToSingleRestaurant}
      head={RestaurantOutletRedirectToRestaurantMenuHead}
      onEnter={orderInfoCallBack}
    />

    <CustomRoute
      path="/takeaways"
      component={OutletList}
      head={RestaurantsListHead}
      onEnter={orderInfoCallBack}
      exact
    />
    <CustomRoute
      path="/:deliveryZone-takeaways"
      exact
      component={RedirectToTakeaways}
      onEnter={orderInfoCallBack}
    />
    {/* if none above match - redirect home */}
    <Redirect to="/" />
  </Switch>
)

const MainRoutes = ({ marketplace = {} }) => {
  const { orderMode } = marketplace
  const isListMode = orderMode === 'LIST'
  return (
    <Switch>
      <CustomRoute
        product="home"
        path="/"
        component={Home}
        exact
        title=""
        description=""
      />
      <CustomRoute path="/login" component={Home} exact />
      <CustomRoute path="/password-reset" component={Home} exact />
      <CustomRoute path="/misc" component={Misc} exact />
      <CustomRoute path="/cookies" component={Cookies} exact />
      <CustomRoute path="/help" component={HelpContact} exact />
      <CustomRoute path="/privacy" component={Privacy} exact />
      <CustomRoute path="/terms" component={Terms} exact />
      <CustomRoute path="/allergy" component={Allergy} exact />
      <CustomRoute path="/account/*" component={Account} exact />
      <CustomRoute path="/accept-discount" component={Home} exact />
      <CustomRoute path="/register-and-accept-discount" component={Home} />

      {isListMode ? <RoutesWithDeliveryZone /> : <RoutesWithoutDeliveryZone />}

      {/* Old route style that we redirect to outlet or takeaways page */}
      <CustomRoute
        path="/:deliveryZone-takeaways/:restaurantName"
        component={OutletRedirect}
        onEnter={orderInfoCallBack}
      />

      <CustomRoute
        path="/add-review/:orderId/:token"
        component={AddReviewUnauthenticated}
        exact
        head={RestaurantCheckoutHead}
      />

      <Redirect path="/my-account" to="/login" exact />
      <CustomRoute product="fourZeroFour" path="/*" component={FourZeroFour} />
    </Switch>
  )
}

export default MainRoutes
