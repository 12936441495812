import fontStyles from '@styles/sharedJSS/fonts'
import colors from '@styles/sharedJSS/colors'

const styles = {
  cartFooterRow: {
    ...fontStyles.small,
    display: 'flex',
    justifyContent: 'space-between',
    color: 'black',
    fontWeight: 400,
    fontSize: '14px',
  },

  cartTotalFooterRow: {
    display: 'flex',
    justifyContent: 'space-between',
    color: colors.bc5,
    fontWeight: 600,
    fontSize: '16px',
  },

  cartPriceTag: {
    marginLeft: '0.5rem',
    flexShrink: 0,
    color: colors.bc1,
    fontWeight: 600,
  },
}

export default styles
