import gql from 'graphql-tag'

export const GET_ADD_ON_MENUS = gql`
  query addOnMenuItems(
    $outletId: ID!
    $fulfilmentMethods: [MenuItemGroupFulfillmentMethod!]!
    $basketTotal: Int!
  ) {
    addOnMenuItems(
      outletId: $outletId
      fulfilmentMethods: $fulfilmentMethods
      basketTotal: $basketTotal
    ) {
      id
      name
      addOnMaxPrice
      price
      isVegetarian
      isVegan
      isGlutenFree
      isDairyFree
      ageRestricted
    }
  }
`
