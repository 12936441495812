import React, { useState } from 'react'
import { withStyles } from 'react-jss'
import styles from './ageRestrictionStyles'
import { getMarketplace } from '@config/config'
import cx from 'classnames'
import Checkbox from '@components/Checkbox/Checkbox'

const AgeRestriction = ({ classes }) => {
  const { ageRestrictionText } = getMarketplace()
  const [selected, setSelected] = useState(false)
  return (
    <div className={classes.formSection}>
      <h3 className={classes.pageHeader}>
        Basket contains age restricted items
      </h3>

      {!!ageRestrictionText && (
        <h4 className={classes.subLabel}>{ageRestrictionText}</h4>
      )}

      <div className={classes.fieldRow}>
        <Checkbox
          id="ageRestriction"
          checked={selected}
          onChange={setSelected}
        />
        <label htmlFor="ageRestriction" className={classes.label}>
          I am over 18 and have valid ID
        </label>
      </div>
    </div>
  )
}
export default withStyles(styles)(AgeRestriction)
