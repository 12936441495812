import React from 'react'
import { withStyles } from 'react-jss'
import cx from 'classnames'
import PropTypes from 'prop-types'
import styles from './button.styles'
import { conditionalPropType } from '@utils/helpers'

const Button = props => {
  const Element = props.elementType
  const elementProps = {
    className: cx(
      props.classes.default,
      {
        // states can be combined
        [props.classes.square]: props.square,
        [props.classes.small]: props.small,
        [props.classes.disabled]: props.disabled,
        [props.classes.fullWidth]: props.fullWidth,
        [props.classes.capitalize]: props.capitalize,
      },
      // appearance cant be combined it is one or another
      props.classes[props.appearance],
      props.className
    ),
    style: props.style,
  }

  switch (props.elementType) {
    case 'a':
      elementProps.href = props.href
      elementProps.target = props.target
      if (props.target === '_blank') {
        elementProps.rel = 'noopener noreferrer'
      }
      break
    case 'button':
      elementProps.onClick = props.onClick
      elementProps.type = props.type
      elementProps.disabled = props.disabled
      break
  }

  return <Element {...elementProps}>{props.children}</Element>
}

Button.defaultProps = {
  elementType: 'button',

  // element type 'button' default props
  type: 'button',

  // element type 'a' default props
  target: '_self',
}

Button.propTypes = {
  elementType: PropTypes.oneOf(['button', 'a']),
  className: PropTypes.string,
  appearance: PropTypes.oneOf(['negative', 'positive', 'brandColor']),
  disabled: PropTypes.bool,
  style: PropTypes.object,
  square: PropTypes.bool,
  small: PropTypes.bool,
  fullWidth: PropTypes.bool,
  capitalize: PropTypes.bool,

  // element type button prop types
  type: PropTypes.string,
  onClick: conditionalPropType(
    props =>
      props.elementType === 'button' && typeof props.onClick !== 'function',
    'Function required'
  ),

  // element type a prop types
  href: conditionalPropType(
    props => props.elementType === 'a' && props.href !== 'string',
    'String required'
  ),
  target: PropTypes.string,
}

export default withStyles(styles)(Button)
