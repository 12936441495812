import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { withStyles } from 'react-jss'
import { object } from 'prop-types'
import styles from './loginStyles'
import ls from '@utils/localStorage'
import LOGIN from './login.mutation'
import { client } from '@services/client'
import { getMarketplace } from '@config/config'
import { toast } from 'react-toastify'
import BodyLocker from '../../utils/BodyLocker'
import cx from 'classnames'
import Button from '@components/Button/Button'
import { GTMContext } from '@context/GTM.context'
import { window } from 'window-or-global'

class LoginEl extends Component {
  static contextType = GTMContext

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
    }
  }

  componentDidMount = () => {
    this.context.pushDataToGTM({ event: 'login_started' })
  }

  close() {
    client.writeData({
      data: {
        loginOpen: false,
      },
    })
    BodyLocker.unlock()
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = (event, login) => {
    event.preventDefault()
    const loginMutation = login({
      variables: {
        email: this.state.email,
        password: this.state.password,
        marketplaceKey: getMarketplace().key,
      },
    })
    loginMutation.catch(error => {
      error.graphQLErrors.map(err => {
        let message = 'Problem logging in'
        switch (err.code) {
          case 'CLIENT_ERROR':
          case 'AUTHENTICATION_ERROR':
            message = err.message
            break
          default:
            message =
              err.data && err.data.errors
                ? err.data.errors.join(', ')
                : 'Problem logging in'
            break
        }
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        })
      })
    })
  }

  goToAccount = e => {
    e.preventDefault()
    this.close()
    this.props.history.push('/account/orders')
  }

  openForgottenPass = e => {
    e.preventDefault()
    client.writeData({
      data: {
        loginOpen: false,
        forgottenPassOpen: true,
      },
    })
  }

  goToReg = e => {
    e.preventDefault()
    client.writeData({
      data: {
        loginOpen: false,
        registerOpen: true,
      },
    })
  }

  render() {
    const {
      container,
      titleJacket,
      title,
      close,
      field,
      input,
      label,
      submit,
      forgotPassword,
      centeredTitle,
      actionButtons,
      goToBtn,
    } = this.props.classes

    return this.props.loggedIn ? (
      <div className={container}>
        <div className={titleJacket}>
          <h2 className={cx(title, centeredTitle)}>You're logged in!</h2>
        </div>
        <div className={actionButtons}>
          <a className={goToBtn} href="#_" onClick={this.goToAccount}>
            Go to My Account
          </a>
        </div>
      </div>
    ) : (
      <Mutation
        mutation={LOGIN}
        onCompleted={({ login }) => {
          ls.set('jwt', login.token)

          this.context.pushDataToGTM({
            event: 'login',
          })

          const { pathname } = this.props.location
          if (pathname === '/login') {
            this.props.history.push('/')
          }
          window.location.reload()
          this.close()
        }}
      >
        {login => (
          <form
            onSubmit={event => this.handleSubmit(event, login)}
            className={container}
          >
            {/* Title */}
            <div className={titleJacket}>
              <h2 className={title}>Login</h2>
              {!this.props.location.pathname.includes('account') ? (
                <button type="button" onClick={this.close} className={close}>
                  Close
                </button>
              ) : null}
            </div>

            {/* Username / Email */}
            <div className={field}>
              <label htmlFor="email" className={label}>
                Email
              </label>
              <input
                type="text"
                id="email"
                name="email"
                className={input}
                onChange={this.handleChange}
                autoFocus
              />
            </div>

            {/* Password */}
            <div className={field}>
              <label htmlFor="password" className={label}>
                Password
              </label>

              <input
                type="password"
                id="password"
                name="password"
                className={input}
                onChange={this.handleChange}
              />
            </div>

            <div className={forgotPassword}>
              <a href="#_" onClick={this.openForgottenPass}>
                Forgot password?
              </a>
            </div>

            {/* Submit */}
            <div className={field}>
              <Button
                className={submit}
                type="submit"
                appearance="positive"
                fullWidth
                capitalize
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </Mutation>
    )
  }
}

LoginEl.propTypes = {
  history: object,
}

const Login = withStyles(styles)(LoginEl)
export default Login
