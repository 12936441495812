// Imports
// ------
import React from 'react'
import injectSheet from 'react-jss'
import styles from './singleOrderStyles'
import cx from 'classnames'

// Component
// ------
class ProgressBarEl extends React.PureComponent {
  render() {
    const {
      deliveryProgress,
      deliveryProgressBackground,
      deliveryProgressBar,
      progressPending,
      progressPreparing,
      progressRejected,
      progressComplete,
      progressReady,
    } = this.props.classes

    const status = this.props.status
    const deliveryProgressBarClasses = cx(deliveryProgressBar, {
      [progressPending]: status === 'PENDING',
      [progressPreparing]: status === 'PREPARING',
      [progressRejected]: status === 'REJECTED',
      [progressReady]: status === 'READY',
      [progressComplete]: status === 'COMPLETE',
    })

    return (
      <em className={deliveryProgress}>
        <span className={deliveryProgressBackground} />
        <span className={deliveryProgressBarClasses} />
      </em>
    )
  }
}

const ProgressBar = injectSheet(styles)(ProgressBarEl)
export default ProgressBar
