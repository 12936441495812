import React from 'react'
import injectSheet from 'react-jss'
import StarsRating from '@components/Stars/Stars'
import { styles } from './ReviewForm.style'

const ReviewDisplay = ({ classes, review, outletName }) => {
  const {
    foodQuality,
    restaurantService,
    deliveryTime,
    reviewText,
    reply,
  } = review

  const {
    container,
    title,
    formContainer,
    starsContainer,
    reviewTextContainer,
    reviewTextPostedClass,
    replyContainer,
    replyTitle,
  } = classes

  return (
    <section className={container}>
      <p className={title}>Your Review</p>
      <div className={formContainer}>
        <div>
          <label htmlFor="foodQuality">Food quality</label>
          <div name="foodQuality" id="foodQuality" className={starsContainer}>
            <StarsRating value={foodQuality} />
          </div>
        </div>
        <div>
          <label htmlFor="restaurantService">Restaurant service</label>
          <div
            id="restaurantService"
            name="restaurantService"
            className={starsContainer}
          >
            <StarsRating value={restaurantService} />
          </div>
        </div>
        <div>
          <label htmlFor="deliveryTime">Delivery time</label>
          <div id="deliveryTime" name="deliveryTime" className={starsContainer}>
            <StarsRating value={deliveryTime} />
          </div>
        </div>
        <div className={reviewTextContainer}>
          <label htmlFor="reviewText">Review</label>
          <div name="reviewText" className={reviewTextPostedClass}>
            <div>{reviewText}</div>
            {reply && (
              <div className={replyContainer}>
                <div className={replyTitle}>{`${outletName} replied:`}</div>
                <div>{reply}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default injectSheet(styles)(ReviewDisplay)
