import fontStyles from '@styles/sharedJSS/fonts'

const styles = theme => ({
  title: {
    ...fontStyles.h2,
    textAlign: 'center',
    color: theme.bc3,
    fontSize: '2.8rem',
    marginBottom: '2.4rem',
    padding: '0 2.4rem',

    '@media screen and (min-width: 768px)': {
      padding: '0',
      fontSize: '3.6rem',
      marginBottom: '3.6rem',
    },
  },
})

export default styles
