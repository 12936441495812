import gql from 'graphql-tag'

const GET_OUTLET = gql`
  query getOutlet($key: String!) {
    marketplace(key: $key) {
      marketplace {
        id
        deliveryZones {
          id
          name
          outlets {
            id
            restaurant {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default GET_OUTLET
