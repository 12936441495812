// Imports
// ------
import React from 'react'
import injectSheet from 'react-jss'
import { styles } from '../contentStyles'

// Component
// ------
class ButtonEl extends React.PureComponent {
  render() {
    const { button } = this.props.classes

    return (
      <a href={this.props.to} className={button}>
        {this.props.children}
      </a>
    )
  }
}

const Button = injectSheet(styles)(ButtonEl)
export default Button
