import React, { useEffect } from 'react'
import injectSheet from 'react-jss'
import styles from './loadingSpinnerStyles'
import { CSSTransition } from 'react-transition-group'
import cx from 'classnames'
import BodyLocker from '@utils/BodyLocker'

const LoadingSpinner = ({
  show,
  fullscreen,
  classes,
  loadingMessage,
  noBackground,
}) => {
  useEffect(
    () => {
      if (fullscreen) {
        if (show) {
          BodyLocker.lock()
        } else {
          BodyLocker.unlock()
        }
      }
    },
    [show, fullscreen]
  )

  return (
    <CSSTransition
      in={show}
      timeout={300}
      classNames={{
        enter: classes.loadingEnter,
        enterActive: classes.loadingEnterActive,
        enterDone: classes.loadingEnterDone,
        exit: classes.loadingExit,
        exitActive: classes.loadingExitActive,
        exitDone: classes.loadingExitDone,
      }}
      unmountOnExit
    >
      <div
        className={cx(classes.loaderBg, {
          [classes.loaderFullscreen]: fullscreen,
          [classes.loaderNoBg]: noBackground,
        })}
      >
        {loadingMessage && (
          <div className={classes.loadingMessage}>{loadingMessage}</div>
        )}

        <div className={classes.loader}>Loading...</div>
      </div>
    </CSSTransition>
  )
}

export default injectSheet(styles)(LoadingSpinner)
