import React, { useState, useEffect, useContext, useRef } from 'react'
import { BasketContext } from '@context/Basket.context'
import { OrderContext } from '@context/Order.context'
import Modal from '@components/ModalWrapper/ModalWrapper'
import withStyles from 'react-jss'
import Button from '@components/Button/Button'
import { Query } from 'react-apollo'
import { GET_MENU_ITEM_GROUPS } from '@shared/queries/getMenuItemGroups.query'
import get from 'lodash/get'
import { flatMap } from 'lodash'

const DirtyBasketAlert = ({
  classes,
  menuItemsAvailableForCurrentFulfilment,
  loadingMenu,
  error,
}) => {
  const [dirtyItems, setDirtyItems] = useState([])
  const [soldOutItems, setSoldOutItems] = useState([])
  const [soldOutOptions, setSoldOutOptions] = useState([])
  const { attributes, updateOrder } = useContext(OrderContext)
  const { items, removeItem, removeItemWithOptionId } =
    useContext(BasketContext)
  const prevFulfilmentMethod = useRef(attributes.fulfilmentChosen)

  useEffect(() => {
    if (!loadingMenu && !error) {
      //check items in basket are available for the current fulfillment.
      const dirtyItems = items.filter(item => {
        const menuItemId = get(item, 'menuItem.id')
        if (menuItemId) {
          const itemAvailable =
            item.addOnItem ||
            menuItemsAvailableForCurrentFulfilment.find(
              item => item.id === menuItemId
            )
          return !itemAvailable
        }
      })
      setDirtyItems(dirtyItems)

      //check items in basket available for current fulfillment are not sold out.
      const soldOutItems = items.filter(item => {
        const menuItemId = get(item, 'menuItem.id')
        if (menuItemId) {
          const itemAvailable = menuItemsAvailableForCurrentFulfilment.find(
            item => item.id === menuItemId
          )
          if (itemAvailable) return itemAvailable.soldOut
        }
      })
      setSoldOutItems(soldOutItems)

      const optionItems = flatMap(
        menuItemsAvailableForCurrentFulfilment,
        menuItems =>
          flatMap(menuItems.options, option =>
            flatMap(option.optionItems, optionItem => ({
              ...optionItem,
              menuName: menuItems.name,
            }))
          )
      )

      const soldOutOptions = optionItems.filter(option => option.soldOut)
      const basketItemOptions = flatMap(items, basketItem =>
        basketItem.optionItems.map(option => option.id)
      )

      const soldOutInBasket = soldOutOptions.filter(soldOutOption =>
        basketItemOptions.includes(soldOutOption.id)
      )
      setSoldOutOptions(soldOutInBasket)

      if (dirtyItems.length === 0) {
        prevFulfilmentMethod.current = attributes.fulfilmentChosen
      }
    }
  }, [
    menuItemsAvailableForCurrentFulfilment.map(item => item.id).join('-'),
    items.map(item => item.id).join('-'),
    loadingMenu,
    error,
  ])

  const tableService = attributes.fulfilmentChosen.id === 'table'
  const optionsOrItems =
    soldOutItems.length > 0
      ? soldOutOptions.length > 0
        ? 'items and options'
        : 'items'
      : 'options'

  return dirtyItems.length > 0 ? (
    <Modal open={dirtyItems.length > 0} close={() => {}}>
      <div className={classes.container}>
        <h1 className={classes.title}>
          {`Your basket contains items not available to order ${
            tableService
              ? 'to table'
              : `for ${attributes.fulfilmentChosen.label}`
          }`}
        </h1>

        <ul>
          {dirtyItems.map(dirtyItem => (
            <li key={dirtyItem.id} className={classes.dirtyItem}>
              {dirtyItem.menuItem.name}
            </li>
          ))}
        </ul>

        <Button
          fullWidth
          small
          onClick={() => {
            dirtyItems.forEach(item => removeItem(item.id))
            prevFulfilmentMethod.current = attributes.fulfilmentChosen
          }}
        >
          Remove Unavailable Items
        </Button>

        {prevFulfilmentMethod.current.id !== attributes.fulfilmentChosen.id ? (
          <Button
            fullWidth
            small
            style={{ marginTop: '5px' }}
            onClick={() => {
              updateOrder({ fulfilmentChosen: prevFulfilmentMethod.current })
              prevFulfilmentMethod.current = attributes.fulfilmentChosen
            }}
          >
            Order for {prevFulfilmentMethod.current.label} instead
          </Button>
        ) : null}
      </div>
    </Modal>
  ) : soldOutItems.length > 0 || soldOutOptions.length > 0 ? (
    <Modal
      open={soldOutItems.length > 0 || soldOutOptions.length > 0}
      close={() => {}}
    >
      <div className={classes.container}>
        <h1 className={classes.title}>
          Some {optionsOrItems} in your basket are no longer available
        </h1>

        <ul className={classes.soldOutItems}>
          {soldOutItems.map(item => (
            <li key={item.id} className={classes.dirtyItem}>
              {item.menuItem.name}
            </li>
          ))}
          {soldOutOptions.map(option => (
            <li key={option.id} className={classes.dirtyItem}>
              {option.name} ({option.menuName})
            </li>
          ))}
        </ul>
        <Button
          className={classes.topButton}
          fullWidth
          small
          onClick={() => {
            soldOutItems.forEach(item => removeItem(item.id))
            soldOutOptions.forEach(soldOutItem =>
              removeItemWithOptionId(soldOutItem.id)
            )
            setSoldOutOptions([])
            prevFulfilmentMethod.current = attributes.fulfilmentChosen
          }}
        >
          Remove Unavailable {optionsOrItems}
        </Button>
      </div>
    </Modal>
  ) : null
}

const DirtyBasketMenuProvider = props => {
  const { attributes } = useContext(OrderContext)
  const skip = !attributes.outletId

  return (
    <Query
      query={GET_MENU_ITEM_GROUPS}
      variables={{
        // TODO theres a bug right here, somehow it runs this query still when the outletId is null
        // https://korelogicltd.sentry.io/issues/4001301978/?project=4504831655542784
        outletId: attributes.outletId,
        get fulfilmentMethods() {
          if (
            attributes.fulfilmentChosen &&
            attributes.fulfilmentChosen.id.toUpperCase() !== 'NETWORK'
          ) {
            return [attributes.fulfilmentChosen.id.toUpperCase()]
          }
          return ['DELIVERY']
        },
      }}
      context={{ version: 2 }}
      skip={skip}
      fetchPolicy="network-only"
    >
      {({ data, loading, error = null }) => {
        const menuItemGroupsForOutlet = get(data, 'menuItemGroupsForOutlet', [])
        const menuItems = menuItemGroupsForOutlet.reduce(
          (acc, menuItemGroupForOutlet) => {
            if (
              menuItemGroupForOutlet.menuItems &&
              menuItemGroupForOutlet.menuItems.length
            ) {
              acc = [...acc, ...menuItemGroupForOutlet.menuItems]
            }
            return acc
          },
          []
        )
        return (
          <DirtyBasketAlert
            menuItemsAvailableForCurrentFulfilment={menuItems}
            loadingMenu={loading}
            error={!!error || skip}
            {...props}
          />
        )
      }}
    </Query>
  )
}

const styles = {
  container: {
    padding: '20px',
  },
  title: {
    fontSize: '20px',
    marginBottom: '8px',
    fontWeight: 600,
    padding: '10px',
  },
  dirtyItems: {
    fontSize: '15px',
    listStyle: 'disc',
    marginLeft: '10px',
    marginBottom: '20px',
  },
  dirtyItem: {
    fontSize: '15px',
    marginBottom: '8px',
    marginLeft: '10px',
    padding: '5px',
  },
  topButton: {
    marginBottom: '8px',
    marginTop: '20px',
  },
}

export default withStyles(styles)(DirtyBasketMenuProvider)
