import React, { useContext } from 'react'
import { OrderContext } from '@context/Order.context'
import { withStyles } from 'react-jss'
import cx from 'classnames'
import { MOBILE_BREAKPOINT, SMALL_MOBILE_BREAKPOINT } from '@utils/breakpoints'
import Alert from '@components/Alert/Alert'
import { BasketContext } from '@root/context/Basket.context'

const FulfilmentMethodPicker = ({ classes, deliveryMethods = [], canASAP }) => {
  const { attributes, updateOrder } = useContext(OrderContext)
  const { fulfilmentChosen = {} } = attributes

  const allowsBoth = deliveryMethods.length > 1
  const phrase = allowsBoth ? '' : 'Only'
  const { items, removeAddOnItems } = useContext(BasketContext)

  return (
    <div className={cx(classes.deliveryMethods, 'col-xs-12')}>
      {deliveryMethods.map(deliveryMethod => {
        const isActive =
          deliveryMethod.id === fulfilmentChosen.id ||
          deliveryMethods.length === 1 ||
          (deliveryMethod.id === 'delivery' &&
            fulfilmentChosen.id === 'network')

        if (deliveryMethod.available) {
          return (
            <div className={classes.deliveryMethod} key={deliveryMethod.id}>
              <Alert
                confirm={() => {
                  removeAddOnItems()
                  if (
                    deliveryMethod.id.toUpperCase() !==
                    fulfilmentChosen.id.toUpperCase()
                  ) {
                    updateOrder({
                      fulfilmentChosen: deliveryMethod,
                      selectedDeliverySlot: null,
                      selectedDeliveryWindow: null,
                      asap: canASAP,
                    })
                  }
                }}
                question={
                  <p>
                    Changing fulfilment will remove special discount items
                    marked with 'Offer'
                  </p>
                }
                condition={Boolean(items.find(({ addOnItem }) => addOnItem))}
              >
                <a
                  className={cx(classes.lozenge, {
                    [classes.lozengeActive]: isActive,
                  })}
                >
                  <span className={classes.label}>
                    {deliveryMethod.label} {phrase}
                  </span>
                </a>
              </Alert>
            </div>
          )
        }
        return null
      })}
    </div>
  )
}

const styles = theme => ({
  deliveryMethods: {
    display: 'flex',
    width: '100%',
  },

  deliveryMethod: {
    flexGrow: '1',
    flexBasis: 0,
  },

  lozenge: {
    display: 'block',
    color: theme.bc3,
    cursor: 'pointer',

    border: '1px solid #ccc',
    padding: '10px',
    margin: '5px',
    borderRadius: '20px',
    fontWeight: 600,
    textAlign: 'center',

    [`@media screen and (min-width: ${SMALL_MOBILE_BREAKPOINT}px)`]: {
      margin: '10px',
    },

    '&:hover': {
      color: theme.bc3,
      backgroundColor: '#ecf1f8',
    },

    '&:after': {
      display: 'none',
    },

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      border: 0,
      borderBottom: '1px solid #ecf1f8',
      borderRadius: 0,
      margin: 0,
      fontWeight: 'initial',
      padding: '10px',
      paddingBottom: '9px',

      '&:after': {
        display: 'block',
      },
    },
  },

  lozengeActive: {
    color: 'white',
    position: 'relative',
    backgroundColor: theme.bc3,

    '&:hover': {
      color: 'white',
      backgroundColor: theme.bc3,
    },

    '&:after': {
      width: '6px',
      height: '6px',
      content: "''",
      bottom: '-4px',
      position: 'absolute',
      left: '50%',
      marginLeft: '-6px',
      borderTop: '1px solid white',
      borderLeft: '1px solid white',
      transform: 'rotate(45deg)',
      backgroundColor: 'white',
    },
  },

  label: {
    fontSize: '13px',
    fontWeight: 600,
  },
})

export default withStyles(styles)(FulfilmentMethodPicker)
