const styles = {
  menu: {
    backgroundColor: 'white',
    marginTop: ' 0.5rem',

    '& > div': {
      width: '100%',
      // position: 'relative',
    },
  },

  opacityDown: {
    opacity: 0.5,
  },

  modalEnter: {
    opacity: 0,
  },

  modalEnterActive: {
    opacity: 1,
  },

  modalEnterDone: {
    opacity: 1,
  },

  modalExit: {
    opacity: 1,
  },

  modalExitActive: {
    opacity: 0,
  },

  modalExitDone: {
    opacity: 0,
  },

  outletDesc: {
    color: '#6f6f6f',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '18px',
    padding: '1.2rem',
    marginBottom: '5px',

    '@media print': {
      display: 'none',
    },
  },

  menuType: {
    display: 'none',
    '@media print': {
      display: 'block',
      marginTop: '-104px',
      marginLeft: '268px',
      fontSize: '17px',
      fontWeight: 'bold',
    },
  },
}

export default styles
