import React, { useState, useEffect } from 'react'
import loadjs from 'loadjs'

export const GTMContext = React.createContext({
  setGoogleAnalyticsID: () => {},
  pushDataToGTM: () => {},
})

export const GTMContextProvider = ({ children }) => {
  const [dataLayerCache, setDataLayerCache] = useState([])

  const [googleAnalyticsID, _setGoogleAnalyticsID] = useState('')
  const setGoogleAnalyticsID = googleAnalyticsID => {
    _setGoogleAnalyticsID(googleAnalyticsID ? googleAnalyticsID : '')
  }

  const pushDataToGTM = data => {
    const dataLayer = window.dataLayer
    if (dataLayer) {
      if (dataLayerCache.length) {
        dataLayerCache.forEach(data =>
          dataLayer.push({
            ...data,
            google_analytics_website_measurement_id: googleAnalyticsID,
          })
        )
        setDataLayerCache([])
      }

      dataLayer.push({
        ...data,
        google_analytics_website_measurement_id: googleAnalyticsID,
      })
    } else {
      const cachedData = [...dataLayerCache, data]
      setDataLayerCache(cachedData)
    }
  }

  useEffect(() => {
    const bundleName = 'gtm'
    if (googleAnalyticsID && !loadjs.isDefined(bundleName)) {
      loadjs(
        [`https://www.googletagmanager.com/gtm.js?id=GTM-5NXPCJG`],
        bundleName
      )
      loadjs.ready(bundleName, () => {
        pushDataToGTM({
          google_analytics_website_measurement_id: googleAnalyticsID,
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        })
      })
    }
  }, [googleAnalyticsID])

  return (
    <GTMContext.Provider value={{ setGoogleAnalyticsID, pushDataToGTM }}>
      {children}
    </GTMContext.Provider>
  )
}
