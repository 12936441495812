import checkoutFormStyles from '../checkoutFormStyles'

const styles = {
  ...checkoutFormStyles,

  fieldRow: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',

    '@media screen and (min-width: 768px)': {
      marginTop: '15px',
      padding: '0 0.5rem',
    },
  },
}

export default styles
