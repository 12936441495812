// Imports
// ------
import React from 'react'
import injectSheet from 'react-jss'
import { styles } from './markdownStyles'
import Markdown from 'react-markdown'
// Component
// ------
class ContentEl extends React.PureComponent {
  render() {
    const { jacket, content } = this.props.classes

    return (
      <section className={jacket}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-sm-offset-1 col-sm-10">
              <Markdown className={content}>{this.props.children}</Markdown>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const Content = injectSheet(styles)(ContentEl)
export default Content
