import React, { Fragment, useState } from 'react'
import { capitalize } from 'lodash'
import { Collapse, Icon } from '@blueprintjs/core'

const Day = ({
  openByDefault = false,
  fulfilmentDayWithTimeBrackets,
  classes,
  setFulfilmentTime,
  selectedFulfilmentTime,
  displayHeader = true,
}) => {
  const { day: fulfilmentDay, times: fulfilmentTimeBrackets } =
    fulfilmentDayWithTimeBrackets
  const selectedFulfilmentTimeIndex = selectedFulfilmentTime
    ? fulfilmentTimeBrackets.findIndex(
        fulfilmentTimeBracket =>
          fulfilmentTimeBracket.end.valueOf() ===
          selectedFulfilmentTime.valueOf()
      )
    : -1
  const [isOpen, setIsOpen] = useState(
    openByDefault || selectedFulfilmentTimeIndex > -1
  )
  const toggleIsOpen = () => setIsOpen(!isOpen)

  return (
    <li className={classes.day}>
      {displayHeader && (
        <div className={classes.dayTitle} onClick={toggleIsOpen}>
          {capitalize(
            fulfilmentDay.calendar(null, {
              sameDay: '[Today]',
              nextDay: '[Tomorrow]',
              nextWeek: 'dddd Do',
              sameElse: 'dddd Do',
            })
          )}

          {selectedFulfilmentTimeIndex > -1 && (
            <Icon className={classes.activeIcon} icon="dot" />
          )}

          <Icon icon={isOpen ? 'chevron-up' : 'chevron-down'} />
        </div>
      )}

      <Collapse isOpen={isOpen}>
        <ul>
          {fulfilmentTimeBrackets.map((fulfilmentTimeBracket, index) => {
            const fulfilmentTimeEnd = fulfilmentTimeBracket.end
            const fulfilmentTimeStart = fulfilmentTimeBracket.start

            return (
              <>
                <li
                  key={fulfilmentTimeEnd.format()}
                  onClick={() => setFulfilmentTime(fulfilmentTimeBracket)}
                >
                  <a className={classes.timeLink}>
                    {fulfilmentTimeStart ? (
                      <Fragment>
                        {fulfilmentTimeStart.format('HH:mm')}
                        {' - '}
                      </Fragment>
                    ) : null}
                    {fulfilmentTimeEnd.format('HH:mm')}
                    {index === selectedFulfilmentTimeIndex && (
                      <Icon className={classes.activeIcon} icon="dot" />
                    )}
                  </a>
                </li>
              </>
            )
          })}
        </ul>
      </Collapse>
    </li>
  )
}

export default Day
