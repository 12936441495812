import fontStyles from '@styles/sharedJSS/fonts'
import colors from '@styles/sharedJSS/colors'

const styles = {
  container: {
    padding: '3.6rem',
  },

  titleJacket: {
    position: 'relative',
    display: 'block',
    marginBottom: '2.4rem',

    '@media screen and (min-width: 375px)': {
      marginBottom: '3.6rem',
    },
  },

  title: {
    ...fontStyles.h2,
    color: colors.bc3,
  },

  close: {
    ...fontStyles.small,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
    padding: '0',
    background: 'none',
    border: 'none',
    color: colors.bc5,
    transition: 'all .25s ease-in-out',

    '&:hover': {
      color: colors.bc3,
      cursor: 'pointer',
    },
  },

  field: {
    position: 'relative',
    display: 'block',
    marginBottom: '3.6rem',

    '&:last-child': {
      marginBottom: 0,
    },

    '&:nth-child(3)': {
      marginBottom: '2.4rem',
    },
  },

  input: {
    ...fontStyles.small,
    backgroundColor: 'rgba(000,000,000,0.05)',
    boxShadow: 'none',
    padding: '0 1.2rem',
    border: 'none',
    width: '100%',
    height: '4.5rem',
    borderRadius: '4px',
    color: colors.bc5,

    '&:focus': {
      outline: 'none',
    },
  },

  label: {
    ...fontStyles.small,
    display: 'block',
    marginBottom: '0.2rem',
  },
  loginMessage: {
    borderBottom: '1px dashed #ccc',
    marginBottom: '14px',
    paddingBottom: '11px',

    '& strong': {
      color: 'white',
      backgroundColor: 'red',
      padding: '1px 5px',
      borderRadius: '3px',
      fontWeight: 700,
      fontSize: '11px',
    },
    '& span': {
      display: 'block',
      fontSize: '12px',
      lineHeight: '16px',
      paddingTop: '7px',
      fontWeight: 500,
      color: '#656565',
    },
  },
  submit: {
    ...fontStyles.p,
    transition: 'all .25s ease-in-out',
    display: 'block',
    width: '100%',
    padding: '20px',
    backgroundColor: colors.bc1,
    borderRadius: '53px',
    border: 'none',
    color: 'white',
    fontWeight: 600,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: colors.bc2,
    },
  },
  successPara: {
    ...fontStyles.small,
    '& strong': {
      fontWeight: 700,
    },
  },
  forgotPassword: {
    display: 'block',
    marginBottom: '2.4rem',
    textAlign: 'center',

    '& a': {
      ...fontStyles.small,
      color: colors.bc3,
    },
  },

  successWrapper: {},
}

export default styles
