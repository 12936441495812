import checkoutFormStyles from '../../checkoutFormStyles'

const styles = theme => ({
  ...checkoutFormStyles(theme),
  modalOverlay: {
    backgroundColor: '#000',
    transition: `all 0.3s ease`,
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.70)',
  },
  modalView: {
    backgroundColor: '#fff',
    width: '400px',
    margin: 'auto',
    marginTop: '100px',
    borderRadius: '4px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.60)',
    transition: `all 0.3s ease`,
    position: 'relative',
  },
  titleArea: {
    padding: '15px',
    position: 'relative',
    lineHeight: '20px',

    '& h2': {
      color: '#444444',
      fontSize: '17px',
      fontWeight: 500,
    },

    '& p': {
      color: '#636363',
      fontSize: '13px',
      fontWeight: 400,
    },
  },

  cardForm: {
    padding: '5px 15px',
  },
  addressForm: {
    padding: '0px 15px 15px 15px',
  },
})

export default styles
