const styles = theme => ({
  loaderBg: {
    position: 'absolute',
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 12,
    backgroundColor: 'rgba(255, 255, 255, 0.80)',
    transition: `all 0.4s ease`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  loaderFullscreen: {
    position: 'fixed',
    zIndex: 99999,
  },

  loaderNoBg: {
    backgroundColor: 'transparent',
  },

  loadingMessage: {
    fontSize: '1.6rem',
    marginBottom: '2rem',
    marginTop: '-4rem',
    height: '2rem',
    fontWeight: '400',
    color: '#444444',
  },

  '@keyframes loading': {
    '0%': {
      '-webkit-transform': 'rotate(0deg)',
      transform: 'rotate(0deg)',
    },

    '100%': {
      '-webkit-transform': 'rotate(360deg)',
      transform: 'rotate(360deg)',
    },
  },

  loader: {
    borderRadius: '50%',
    width: '4rem',
    height: '4rem',
    fontSize: '1rem',
    position: 'relative',
    textIndent: '-9999em',

    borderTop: '3px solid rgba(0, 0, 0, 0.2)',
    borderRight: '3px solid rgba(0, 0, 0, 0.2)',
    borderBottom: '3px solid rgba(0, 0, 0, 0.2)',
    borderLeft: `3px solid ${theme.bc3}`,

    transform: 'translateZ(0)',

    animation: '$loading 1.1s infinite linear',

    '&:after': {
      borderRadius: '50%',
      width: '4rem',
      height: '4rem',
    },
  },

  loadingEnter: {
    opacity: 0,
  },

  loadingEnterActive: {
    opacity: 1,
  },

  loadingEnterDone: {
    opacity: 1,
  },

  loadingExit: {
    opacity: 1,
  },

  loadingExitActive: {
    opacity: 0,
  },

  loadingExitDone: {
    opacity: 0,
  },
})

export default styles
