const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
    width: '40vw',
    height: '60px',
    margin: '0px',
    padding: '0px',
    overflow: 'hidden',
    borderRadius: '40px',
    maxWidth: '670px',
    minWidth: '620px',
    cursor: 'pointer',
    zIndex: 1,
    backgroundColor: '#f5f5f5',

    '@media screen and (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '80vw',
      width: '80vw',
      height: 'auto',
      borderRadius: '20px',
      zIndex: 0,
    },
  },

  popoverForm: {
    display: 'flex',
    flexDirection: 'row',
    width: '40vw',
    height: '60px',
    backgroundColor: '#f5f5f5',
    margin: '0px',
    padding: '0px',
    overflow: 'hidden',
    borderRadius: '40px',
    maxWidth: '670px',
    minWidth: '620px',
    cursor: 'pointer',
    zIndex: 1,

    '@media screen and (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '80vw',
      width: '80vw',
      height: '245px',
      borderRadius: '20px',
      zIndex: 0,
    },
  },

  firstRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    borderRight: 'solid grey 0.25px',
    margin: '7px',
    justifyContent: 'space-between',
    paddingRight: '15px',

    '@media screen and (max-width: 768px)': {
      width: '100%',
      padding: '15px 10px',
      marginLeft: '0px',
      borderRight: 'none',
    },
  },

  secondRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '40%',
    textAlign: 'left',
    margin: '7px',
    justifyContent: 'space-between',
    paddingRight: '15px',

    '@media screen and (max-width: 768px)': {
      width: '100%',
      paddingBottom: '20px',
      padding: '5px 10px',
      marginLeft: '1px',
    },
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },

  svg: {
    display: 'flex',
    alignSelf: 'center',
    padding: '5px 10px',
  },

  subtitle: {
    fontSize: '1rem',
    opacity: 0.5,
    fontWeight: 600,
    padding: '7px 3px',
    textAlign: 'left',
  },

  title: {
    fontSize: '1.6rem',
    fontWeight: 600,
    paddingLeft: '3px',
  },

  arrowSvg: {
    alignSelf: 'center',
    opacity: 0.5,

    '@media screen and (max-width: 768px)': {
      marginRight: '25px',
    },
  },

  arrowSvg2: {
    alignSelf: 'center',
    opacity: 0.5,
    marginRight: '-15px',

    '@media screen and (max-width: 768px)': {
      marginRight: '25px',
    },
  },

  button: {
    display: 'flex',
    width: '10%',
    backgroundColor: 'black',
    margin: '8px',
    borderRadius: '50px',
    color: 'white',
    fontSize: '18px',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: theme.bc3,
    },

    '@media screen and (max-width: 768px)': {
      justifyContent: 'center',
      alignSelf: 'center',
      textAlign: 'center',
      width: '96%',
      height: '60px',
      paddingBottom: '20px',
      padding: '5px 10px',
      borderRadius: '20px',
      alignContent: 'center',
      marginTop: '8vh',
    },
  },

  disabledButton: {
    display: 'flex',
    justifyContent: 'center',
    width: '10%',
    backgroundColor: 'black',
    margin: '8px',
    paddingRight: '10px',
    borderRadius: '50px',
    color: 'white',
    fontSize: '18px',
    fontWeight: 600,
    opacity: '0.5',

    '@media screen and (max-width: 768px)': {
      width: '96%',
      paddingBottom: '20px',
      padding: '5px 10px',
      borderRadius: '20px',
    },
  },

  buttonText: {
    display: 'flex',
    alignSelf: 'center',
    marginLeft: '29%',

    '@media screen and (max-width: 768px)': {
      marginLeft: '0px',
      marginTop: '15px',
    },
  },

  firstDropdown: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    marginTop: '62px',
    backgroundColor: 'white',
    width: '20vw',
    maxWidth: '280px',
    minWidth: '270px',
    borderRadius: '5px',
    padding: '0px',
    marginLeft: '1.4vw',
    cursor: 'pointer',

    '@media screen and (max-width: 768px)': {
      zIndex: 2,
      minWidth: '80vw',
      width: '80vw',
      height: '165px',
      borderRadius: '0',
      marginLeft: '0px',
      marginTop: '81px',
      position: 'absolute',
      backgroundColor: '#ffffff',
    },
  },

  firstDropdownPopover: {
    display: 'flex',
    flexDirection: 'column',
    width: '20vw',
    position: 'absolute',
    maxWidth: '280px',
    minWidth: '270px',
    marginTop: '65px',
    borderRadius: '5px',
    padding: '0px',
    marginLeft: '1.4vw',
    cursor: 'pointer',
    backgroundColor: '#f5f5f5',

    '@media screen and (max-width: 768px)': {
      zIndex: 2,
      minWidth: '80vw',
      width: '80vw',
      height: '162px',
      borderRadius: '20px',
      marginLeft: '0px',
      marginTop: '81px',
      position: 'absolute',
    },
  },

  secondDropdown: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    backgroundColor: 'white',
    width: '14vw',
    maxWidth: '270px',
    minWidth: '250px',
    borderRadius: '5px',
    marginTop: '62px',
    padding: '0px',
    marginLeft: '32rem',
    cursor: 'pointer',

    '@media screen and (max-width: 768px)': {
      minWidth: '80vw',
      width: '80vw',
      borderRadius: '0',
      marginLeft: '0px',
      backgroundColor: '#ffffff',
      zIndex: 2,
      marginTop: '165px',
      position: 'absolute',
    },
  },

  secondDropdownPopover: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f5f5f5',
    position: 'absolute',
    width: '14vw',
    maxWidth: '270px',
    minWidth: '250px',
    borderRadius: '5px',
    marginTop: '65px',
    padding: '0px',
    marginLeft: '32rem',
    cursor: 'pointer',

    '@media screen and (max-width: 768px)': {
      minWidth: '80vw',
      width: '80vw',
      borderRadius: '20px',
      marginLeft: '0px',
      backgroundColor: '#f5f5f5',
      zIndex: 2,
      marginTop: '165px',
      position: 'absolute',
    },
  },

  dropdownDiv: {
    display: 'flex',
    height: '55px',
    margin: '0px 0px',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px',

    '&:hover': {
      backgroundColor: '#f5f5f5',
      borderRadius: '5px',
    },
  },

  dropdownDivNoBorder: {
    display: 'flex',
    height: '50px',
    margin: '0px',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px',

    '&:hover': {
      backgroundColor: '#f5f5f5',
      borderRadius: '5px',
    },
  },

  geoError: {
    fontSize: '1.6rem',
    fontWeight: 600,
    paddingLeft: '7px',
    color: 'red',
    opacity: '0.6',
    marginTop: '10px',
  },

  input: {
    display: 'flex',
    width: '100%',
    boxShadow: 'none',
    fontSize: '1.6rem',
    border: 'none',
    fontWeight: 600,
    paddingLeft: '3px',
    marginTop: '-2px',
    background: 'transparent',

    '&:focus': {
      outline: `0.1px solid transparent`,
    },
  },

  label: {
    position: 'absolute',
    left: '-90000px',
    opacity: 0,
  },

  dropdownContainer: {
    overflow: 'hidden',
    '@media screen and (max-width: 768px)': {
      zIndex: 2,
    },
  },
})

export default styles
