import gql from 'graphql-tag'

// gets user cards
export const GET_CARDS = gql`
  query getCards {
    cards {
      message
      cards {
        id
        last4
      }
    }
  }
`

// adds user cards
export const ADD_CARD = gql`
  mutation addCard($token: String!) {
    addCard(token: $token) {
      message
      card {
        id
        last4
      }
    }
  }
`

// adds user cards
export const DELETE_CARD = gql`
  mutation deleteCard($cardId: String!) {
    deleteCard(cardId: $cardId) {
      message
    }
  }
`
