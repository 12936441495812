// Exports
// ------
export const button = theme => ({
  padding: '2.4rem 3.6rem',

  display: 'inline-block',
  color: 'white',
  fontWeight: 800,
  backgroundColor: theme.bc3,
  fontSize: '1.4rem',
  borderRadius: '6rem',
  textTransform: 'uppercase',
  transition: 'background-color 0.25s ease-in-out',

  '&:hover': {
    backgroundColor: theme.bc1,
  },
})

export const buttonWhite = theme => ({
  padding: '2.4rem 3.6rem',
  display: 'inline-block',
  fontWeight: 800,
  backgroundColor: 'rgba(255,255,255,1)',
  color: theme.bc5,
  fontSize: '1.4rem',
  borderRadius: '6rem',
  textTransform: 'uppercase',
  transition: '0.25s ease-in-out',
  transitionProperty: 'background-color, color',

  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.2)',
    color: 'white',
  },

  '&:focus': { outline: '0 !important' },
})

export const buttonIcon = theme => ({
  ...button(theme),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transitionProperty: 'opacity',

  '@media screen and (min-width: 768px)': {
    padding: '2.4rem',
    width: 'max-content',
  },

  '&:hover': {
    opacity: 0.9,
  },
})

export const buttonSecondary = theme => ({
  padding: '1.8rem 2.6rem',

  display: 'inline-block',
  color: 'white',
  fontWeight: 800,
  backgroundColor: theme.bc3,
  fontSize: '1.4rem',
  borderRadius: '6rem',
  textTransform: 'uppercase',
  boxShadow: '0 0 4rem rgba(0, 0, 0, 0.20)',
  transition: '0.25s ease-in-out',
  transitionProperty: 'background-color, opacity',

  '&:hover': {
    backgroundColor: theme.bc3,
    opacity: 0.7,
  },
})

export default button
