// Imports
// ------
import React from 'react'
import injectSheet from 'react-jss'
import styles from '../GeneralContent/generalContentStyles'
import Header from '@components/Header/Header'
import Hero from '../GeneralContent/Hero/Hero'
import Heading from '../GeneralContent/Content/Heading/Heading'
import Copy from '../GeneralContent/Content/Copy/Copy'
import { List, ListItem } from '../GeneralContent/Content/List/List'
import Button from '../GeneralContent/Content/Button/Button'
import { getMarketplace } from '@config/config'
import GET_LEGAL from './queries/getLegal.query'
import Query from '@components/Query/Query'
import Content from '../GeneralContent/Content/Markdown'

// Component
// ------
const AllergyEl = () => (
  <>
    <Header branded />
    <Hero title="Allergy Policy" />
    <Query query={GET_LEGAL} variables={{ key: getMarketplace().key }}>
      {({ marketplace: { marketplace } }) => (
        <div className="wrapper">
          <Content>{marketplace.legalAllergy}</Content>
        </div>
      )}
    </Query>
  </>
)

const Allergy = injectSheet(styles)(AllergyEl)
export default Allergy
