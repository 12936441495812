import gql from 'graphql-tag'

export const GET_OUTLET_BY_NAME = gql`
  query getDeliveryZonesApp($key: String!, $restaurantName: String!) {
    marketplace(key: $key) {
      message
      marketplace {
        id
        outlets(where: { restaurant: { name_contains: $restaurantName } }) {
          id
          restaurant {
            id
            name
          }
        }
      }
    }
  }
`
