import fontStyles from '@styles/sharedJSS/fonts'
import Color from 'color'
import { buttonSecondary } from '@styles/sharedJSS/buttons'

const styles = theme => ({
  modalOverlay: {
    transition: `all 0.3s ease`,
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 10000,
    backgroundColor: 'rgba(0, 0, 0, 0.70)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  modalView: {
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: '0.4rem',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.60)',
    transition: `all 0.3s ease`,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',

    width: '48rem',
    maxWidth: '100vw',
  },

  modalViewFullScreen: {
    height: '100%',
    maxHeight: '100vh',
  },

  itemDescriptionText: {
    color: Color(theme.bc5).rgb().alpha(0.75).string(),
    fontSize: '1.3rem',
    fontWeight: 400,
    whiteSpace: 'pre-wrap',

    textAlign: 'center',
    lineHeight: '1.8rem',
    marginBottom: '1.5rem',

    '@media screen and (min-width: 1024px)': {
      padding: '0 1.2rem',
      marginBottom: '1.2rem',
    },
  },

  titleText: {
    color: theme.bc5,
    fontSize: '2.2rem',
    fontWeight: 600,
    textAlign: 'center',
    marginTop: '0.7rem',
    marginBottom: '0.7rem',
  },

  headerWithDescription: {
    extend: 'titleText',
  },
  headerNoDescription: {
    extend: 'titleText',
    marginTop: '2.5rem',
    marginBottom: '1.8rem',
  },

  optionsCategoryWrapper: {
    position: 'relative',
    width: '100%',
  },

  optionsCategory: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    color: theme.bc3,
    padding: '1rem 1.5rem 1rem 1.5rem',
    fontSize: '1.5rem',
    borderBottom: `1px solid ${theme.bc3}`,
    fontWeight: 600,
  },

  optionsCategoryError: {
    borderBottom: `1px solid ${theme.negative}`,
    color: theme.negative,
  },
  instructionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  instructionsWrapperBordered: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '20px',
    borderTop: `1px dotted ${Color(theme.neutral).lighten(0.6).toString()}`,
  },

  instructionsHeader: {
    fontSize: '15px',
    fontWeight: 600,
    borderBottom: '1px solid black',
    padding: '10px',
  },

  instructionsInput: {
    border: 'none',
    padding: '10px',
    margin: '5px',
  },

  itemCostLozenge: {
    color: theme.bc3,
    display: 'block',
    padding: '0.6rem 1.4rem 0.6rem',
    zIndex: 5,
    fontSize: '1.4rem',
    fontWeight: 700,
    borderRadius: '4rem',
    border: `2px solid ${theme.bc3}`,
    margin: 'auto',
    maxWidth: '110px',
    textAlign: 'center',
    marginTop: '8px',
    background: 'white',
  },

  itemCostLozengeWithImage: {
    '@media screen and (min-width: 1200px)': {
      marginTop: '-19px',
    },
  },

  optionItem: {
    display: 'block',
    fontSize: '1.4rem',
    color: theme.bc5,
    fontWeight: 500,
    borderBottom: `1px dotted ${Color(theme.neutral).lighten(0.6).toString()}`,
    padding: '1.2rem 1.5rem',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.bc4,
    },

    '&:last-child': {
      borderBottom: 0,
    },
  },

  price: {
    color: theme.bc1,
    marginLeft: '1rem',
    fontWeight: 600,
    fontSize: '1.1rem',
  },

  checkIcon: {
    float: 'right',
    marginTop: '-2px',

    '& svg': {
      width: '1.7rem',
      height: '1.7rem',
      fill: theme.bc1,
    },
  },

  optionHelper: {
    float: 'right',
    marginTop: '-1.1rem',
    fontSize: '1rem',
  },

  optionListWrapper: {
    position: 'relative',
    maxHeight: '100%',
  },

  optionFooter: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    justifyContent: 'space-around',
    alignItems: 'center',

    padding: '1.2rem 0',
    textAlign: 'center',
    background: 'white',
    borderRadius: ' 0 0 0.4rem 0.4rem',
    minHeight: '9rem',

    '@media screen and (min-width: 768px)': {
      padding: '1.2rem 0.5rem',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },

  optionFooterWithOptions: {
    boxShadow: `0px -4px 5px ${Color(theme.bc5).rgb().alpha(0.1).string()}`,
    borderTop: `1px solid ${Color(theme.bc5).rgb().alpha(0.2).string()}`,
  },

  qtyBtns: {
    fontSize: '27px',
    fontWeight: 500,
    width: '40%',

    '@media screen and (min-width: 768px)': {
      marginBottom: '1.5rem',
    },
  },

  qtyControlIcon: {
    display: 'inline-block',
    lineHeight: '25px',
    position: 'relative',
    top: '-1px',

    '& svg': {
      fill: theme.bc1,
      width: '1.8rem',
      height: '1.8rem',
      strokeWidth: '2px',
    },
  },

  quantity: {
    width: '4.6rem',
    display: 'inline-block',
  },

  footerButton: {
    width: '3.4rem',
    padding: '0.5rem auto 0.5rem',
    height: '3.4rem',
    border: `2px solid ${theme.bc1}`,
    display: 'inline-block',
    borderRadius: '100%',
    verticalAlign: 'middle',
    fontSize: '3.6rem',
    lineHeight: '2.4rem',
    color: theme.bc1,
    cursor: 'pointer',
    transition: `all 0.2s ease`,

    '&:hover': {
      backgroundColor: theme.bc1,
      color: 'white',

      '& svg': {
        fill: 'white',
      },
    },
  },

  addToBasket: {
    ...buttonSecondary(theme),
    display: 'block',
    padding: '1.8rem 2rem',
    marginBottom: '1rem',
    cursor: 'pointer',
  },

  cancelBtn: {
    ...fontStyles.p,
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    fontSize: '1.7rem',
    textAlign: 'center',
    color: Color(theme.bc5).rgb().alpha(0.5).string(),
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    transition: 'all .25s ease-in-out',

    '&:hover': {
      color: Color(theme.bc5).rgb().alpha(1).string(),
    },

    '@media screen and (min-width: 768px)': {
      position: 'initial',
    },
  },

  optionError: {
    backgroundColor: theme.negative,
    padding: '1rem 1.5rem',
    position: 'absolute',
    bottom: '100%',
    left: 0,
    right: 0,
    textAlign: 'left',
  },
  singleErrorSpan: {
    color: 'white',
    display: 'block',
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
    fontWeight: 500,

    '& strong': {
      fontWeight: 700,
    },
  },
  modalEnter: {
    marginTop: '0',
  },

  modalEnterActive: {
    marginTop: '0',
  },

  modalEnterDone: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '0',
  },

  modalExit: {
    marginTop: '0',
  },

  modalExitActive: {
    marginTop: '0',
  },

  modalExitDone: {
    marginTop: '0',
  },

  error: {
    fontSize: '16px',
    padding: '0.6rem 1.5rem 0.5rem 1.5rem',
    color: theme.negative,
  },

  inProgressWrapper: {
    padding: '2rem',
    paddingBottom: '0px',
  },

  actionButtons: {
    display: 'flex',
    marginBottom: '2.5rem',
    flexWrap: 'wrap',

    '@media screen and (min-width: 768px)': {
      justifyContent: 'space-evenly',
    },
  },
  modalWrapperDiv: {
    height: '100%',
    maxHeight: '100%',
    maxWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',

    '@media screen and (min-width: 768px)': {
      justifyContent: 'center',
      height: 'auto',
    },
  },

  actionButton: {
    padding: '1.5rem',
    fontSize: '1.6rem',
    textAlign: 'center',
    marginBottom: '1.5rem',
    borderRadius: '5rem',
    fontWeight: 600,
    opacity: 0.8,
    width: '100%',
    transition: `all 0.2s ease`,

    '&:hover ': {
      opacity: 1,
    },
  },
  clearBtn: {
    extend: 'actionButton',
    border: `1px solid ${theme.bc3}`,
    color: theme.bc3,
    width: '100%',
  },
  goToBtn: {
    extend: 'actionButton',
    backgroundColor: theme.bc3,
    color: 'white',
    width: '100%',
  },

  titleArea: {
    position: 'relative',
    lineHeight: '2rem',

    '& h2': {
      color: theme.bc5,
      fontSize: '1.7rem',
      fontWeight: 500,

      '@media screen and (min-width: 1024px)': {
        fontSize: '2.4rem',
        margin: '2.4rem 0 1.2rem 0',
        textAlign: 'center',
      },
    },
  },

  titleAreaWithOptions: {
    extend: 'titleArea',
    paddingBottom: '10px',
    borderBottom: `1px solid ${Color(theme.bc5).rgb().alpha(0.1).string()}`,
  },

  menuImageWrapper: {
    display: 'block',
    width: '100%',
    height: '30vh',
    backgroundColor: '#f3f2f2',
    borderRadius: '0 0 5px 5px',
    overflow: 'hidden',
    boxShadow: '0px 0px 2px #00000073',

    flexShrink: 0,
    margin: '0 auto !important',

    '@media screen and (min-width: 768px)': {
      height: 'initial',
    },
  },

  menuImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
  },

  soldOutOptionItemLozenge: {
    color: '#fff',
    padding: '0px 5px',
    fontSize: '7px',
    fontWeight: 700,
    borderRadius: '5px',
    textTransform: 'uppercase',
    display: 'inline-block',
    backgroundColor: theme.negative,
    marginLeft: '10px',
    lineHeight: '15px',
  },
})

export default styles
