import heroImage from '@images/hero-image.jpg'

const styles = {
  hero: {
    position: 'relative',
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: '14rem',
    paddingTop: '6rem',

    '@media screen and (min-width: 768px)': {
      minHeight: '20rem',
      position: 'relative',
      paddingTop: 0,
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: `rgba(0, 0, 0, 0.70)`,
    },
  },

  branded: {
    position: 'relative',
    minHeight: 'auto',

    '&:before': {
      backgroundColor: `rgba(0,0,0, 0.4)`,
    },
  },
}

export default styles
