// Imports
// ------
import React, { Component } from 'react'
import { withStyles } from 'react-jss'
import { object } from 'prop-types'
import styles from './homeStyles'
import DeliveryZonesModal from '@components/DeliveryZones/DeliveryZonesModal'
import Modal from '@components/ModalWrapper/ModalWrapper'
import BodyLocker from '../../utils/BodyLocker'
import { getMarketplace, AWS_CDN_URL } from '@config/config'
import GET_SINGLE_OUTLET from './queries/getSingleOutlet.query'
import Cart from '@components/Cart/Cart'
import FeaturedOutlets from './FeaturedOutlets/FeaturedOutlets'
import { OrderContext } from '@context/Order.context'
import Button from '@components/Button/Button'

import { client } from '@services/client'
// Images
// ------
import chefImage from '@images/chef.png'

import _ from 'lodash'
import SetNewPassword from '@components/SetNewPassword/SetNewPassword'
import Header from './Header'
import AcceptDiscountModal from '@components/AcceptDiscount/AcceptDiscountModal'
import Register from '@components/Register/Register'

class HomeEl extends Component {
  static contextType = OrderContext
  constructor(props) {
    super(props)
    this.state = {
      deliveryZonesModalOpen: false,
      cuisine: '',
      resetPassParams: {
        show: false,
        cid: '',
        token: '',
      },
      acceptDiscount: {
        show: false,
        enrolmentKey: '',
      },
      registerAndAcceptDiscount: {
        show: false,
        enrolmentKey: '',
      },
    }
  }

  componentDidMount() {
    if (this.props.location.search) {
      const searchParams = new URLSearchParams(`${this.props.location.search}`)
      const cid = searchParams.get('cid')
      const token = searchParams.get('token')
      const enrolmentKey = searchParams.get('enrolmentKey')

      if (cid && token) {
        this.setState({
          resetPassParams: {
            show: true,
            cid,
            token,
          },
        })
      }

      if (enrolmentKey) {
        const { pathname } = this.props.location
        const pathToStateMapping = {
          '/accept-discount': {
            acceptDiscount: {
              show: true,
              enrolmentKey,
            },
          },
          '/register-and-accept-discount': {
            registerAndAcceptDiscount: {
              show: true,
              enrolmentKey,
            },
          },
        }

        if (pathname in pathToStateMapping) {
          this.setState(pathToStateMapping[pathname])
        }
      }
    }

    BodyLocker.unlock()
  }

  openDeliveryZoneModal = () => {
    const { orderMode, key } = getMarketplace()
    if (orderMode === 'SINGLE') {
      client
        .query({
          query: GET_SINGLE_OUTLET,
          variables: {
            key,
          },
        })
        .then(data => {
          const { deliveryZones } = _.get(
            data,
            'data.marketplace.marketplace',
            {}
          )
          const {
            id: deliveryZoneId,
            name,
            outlets,
          } = _.find(deliveryZones, zone => {
            return zone.outlets.length > 0
          })
          const { id, restaurant, phone: outletPhone } = _.first(outlets)

          this.context.updateOrder({
            outletId: id,
            outletPhone,
            restaurantName: restaurant.name,
            restaurantId: restaurant.id,
            deliveryZone: { id: deliveryZoneId, name },
          })

          this.props.history.push(
            `${_.kebabCase(name)}-takeaways/${_.kebabCase(
              restaurant.name
            )}/${id}/menu`
          )
          return
        })
      return
    }
    this.setState({
      deliveryZonesModalOpen: true,
    })

    BodyLocker.lock()
  }

  closeDeliveryZoneModal = () => {
    this.setState({
      deliveryZonesModalOpen: false,
    })
    BodyLocker.unlock()
  }

  closeNewPassModal = () => {
    this.setState({
      resetPassParams: {
        ...this.state.resetPassParams,
        show: false,
      },
    })
  }

  closeAcceptDiscountModal = () => {
    this.setState({
      acceptDiscount: {
        ...this.state.acceptDiscount,
        show: false,
      },
    })
  }

  closeRegisterAndAcceptDiscountModal = () => {
    this.setState({
      registerAndAcceptDiscount: {
        ...this.state.registerAndAcceptDiscount,
        show: false,
      },
    })
  }

  render() {
    const { classes } = this.props
    const {
      appSection,
      subHeader,
      subStrapline,
      subStraplineWrapper,
      buttonWrapper,
      appImage,
      newSection,
      ownersSection,
      ownersImage,
      ownersStrapline,
      cart,
    } = classes

    const {
      brandColor,
      storeURLApple,
      storeURLGooglePlay,
      featurePromotions,
      appImage: appLargeImage,
      featureRecruit,
      featureOrdering,
      allowOnboarding,
      heroText,
      heroTextSecondary,
      appText,
      appTextSecondary,
      orderMode,
      key,
    } = getMarketplace()

    const {
      deliveryZonesModalOpen,
      cuisine,
      resetPassParams,
      acceptDiscount,
      registerAndAcceptDiscount,
    } = this.state
    return (
      <>
        {/* Hero Section w/ Header */}
        <Header
          featureOrdering={featureOrdering}
          heroText={heroText}
          heroTextSecondary={heroTextSecondary}
          brandColor={brandColor}
          orderMode={orderMode}
          openDeliveryZoneModal={this.openDeliveryZoneModal}
        />

        {/* Order on the App */}
        <section className={appSection}>
          <div className={'wrapper'}>
            <div className="container-fluid">
              <div className="row middle-md">
                <div className="col-xs-12 col-sm-offset-1 col-sm-5 col-md-4">
                  <div className={subStraplineWrapper}>
                    <h2
                      className={subHeader}
                      style={{
                        color: brandColor,
                      }}
                    >
                      {appText}
                    </h2>
                    <p className={subStrapline}>{appTextSecondary}</p>
                    <div className={buttonWrapper}>
                      {!!storeURLApple && (
                        <Button
                          elementType="a"
                          href={storeURLApple}
                          target="_blank"
                          appearance="brandColor"
                          className={classes.appStore}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32"
                          >
                            <path d="M28.893,24.01c-0.705,1.562-1.042,2.258-1.95,3.638c-1.265,1.927-3.05,4.327-5.262,4.347 c-1.965,0.018-2.47-1.278-5.137-1.263c-2.667,0.015-3.223,1.287-5.188,1.268c-2.212-0.02-3.902-2.187-5.168-4.115 c-3.54-5.388-3.91-11.712-1.727-15.073c1.552-2.39,4-3.787,6.302-3.787c2.343,0,3.817,1.285,5.755,1.285 c1.88,0,3.025-1.287,5.735-1.287c2.048,0,4.218,1.115,5.765,3.042C22.952,14.842,23.775,22.075,28.893,24.01L28.893,24.01z" />
                            <path d="M20.484,5.651c0.985-1.263,1.732-3.047,1.46-4.87c-1.608,0.11-3.488,1.133-4.587,2.467 c-0.997,1.21-1.82,3.005-1.5,4.75C17.612,8.053,19.429,7.005,20.484,5.651L20.484,5.651z" />
                          </svg>
                          <span>App Store</span>
                        </Button>
                      )}

                      {!!storeURLGooglePlay && (
                        <Button
                          elementType="a"
                          href={storeURLGooglePlay}
                          target="_blank"
                          className={classes.playStore}
                          appearance="brandColor"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32"
                          >
                            <path d="M8,24c0,0.552,0.448,1,1,1h2v4.5c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5V25h4v4.5 c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5V25h2c0.552,0,1-0.448,1-1V11H8V24z" />
                            <path d="M5.5,11C4.672,11,4,11.672,4,12.5v7C4,20.328,4.672,21,5.5,21S7,20.328,7,19.5v-7 C7,11.672,6.328,11,5.5,11z" />{' '}
                            <path d="M26.5,11c-0.828,0-1.5,0.672-1.5,1.5v7c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5v-7 C28,11.672,27.328,11,26.5,11z" />
                            <path d="M20.153,3.172l1.263-1.895c0.153-0.229,0.091-0.54-0.139-0.693 c-0.231-0.153-0.542-0.091-0.693,0.139l-1.319,1.979C18.267,2.254,17.164,2,16,2s-2.267,0.254-3.265,0.701l-1.319-1.979 c-0.153-0.229-0.463-0.292-0.693-0.139s-0.292,0.464-0.139,0.693l1.263,1.895C9.543,4.576,8,7.105,8,10h16 C24,7.105,22.457,4.576,20.153,3.172z M13,7c-0.552,0-1-0.448-1-1c0-0.552,0.448-1,1-1s1,0.448,1,1C14,6.552,13.552,7,13,7z M19,7 c-0.552,0-1-0.448-1-1c0-0.552,0.448-1,1-1s1,0.448,1,1C20,6.552,19.552,7,19,7z" />
                          </svg>

                          <span>Play Store</span>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-offset-1 col-sm-4 col-md-offset-2">
                  <div className={appImage}>
                    <img src={`${AWS_CDN_URL}${appLargeImage}`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {featurePromotions && <FeaturedOutlets wrapperClassName={newSection} />}

        {featureRecruit && (
          <section className={ownersSection}>
            <div className="wrapper">
              <div className="container-fluid">
                <div className="row middle-md">
                  <div className="col-xs-12 col-sm-offset-1 col-sm-5 col-md-4">
                    <h2 className={subHeader}>Business Owner?</h2>
                    <p className={ownersStrapline}>
                      We'd love to have you on board!
                    </p>
                    <p className={ownersStrapline}>
                      Leave us your details and we'll be in touch.
                    </p>
                    {!allowOnboarding && featureRecruit && (
                      <Button
                        elementType="a"
                        href="/help/"
                        appearance="brandColor"
                      >
                        Get in touch
                      </Button>
                    )}
                    {allowOnboarding && (
                      <Button
                        elementType="a"
                        href={`${process.env.MANAGEMENT_URL}/onboarding?onboarding_marketplace=${key}`}
                        appearance="brandColor"
                      >
                        Sign Up Your Business
                      </Button>
                    )}
                  </div>

                  <div className="col-xs-12 col-sm-6">
                    <img src={chefImage} className={ownersImage} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        <Modal open={resetPassParams.show} close={this.closeNewPassModal}>
          <SetNewPassword {...resetPassParams} close={this.closeNewPassModal} />
        </Modal>

        {/* Select a delivery Zone Modal */}
        <Modal
          open={deliveryZonesModalOpen}
          close={this.closeDeliveryZoneModal}
        >
          <DeliveryZonesModal cuisine={cuisine} />
        </Modal>

        <Modal open={acceptDiscount.show} close={this.closeAcceptDiscountModal}>
          <AcceptDiscountModal
            enrolmentKey={acceptDiscount.enrolmentKey}
            closeAcceptDiscountModal={this.closeAcceptDiscountModal}
          />
        </Modal>

        <Modal
          open={registerAndAcceptDiscount.show}
          close={this.closeRegisterAndAcceptDiscountModal}
        >
          <Register
            enrolmentKey={registerAndAcceptDiscount.enrolmentKey}
            closeRegisterAndAcceptDiscountModal={
              this.closeRegisterAndAcceptDiscountModal
            }
          />
        </Modal>
        {this.context.attributes && this.context.attributes.outletId && (
          <div className={cart}>
            <Cart checkoutButton menuButton />
          </div>
        )}
      </>
    )
  }
}

HomeEl.propTypes = {
  classes: object,
}

const Home = withStyles(styles)(HomeEl)
export default Home
