import React from 'react'
import { Query } from 'react-apollo'
import { GET_REVIEW_FOR_ORDER } from './queries/getReviewForOrder'
import get from 'lodash/get'
import ReviewDisplay from './ReviewDisplay'
import ReviewForm from './ReviewForm'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'

const Review = ({ match }) => {
  const { orderid } = match.params

  return (
    <Query query={GET_REVIEW_FOR_ORDER} variables={{ orderId: orderid }}>
      {({ data, loading, error }) => {
        if (error) return <div>Error</div>
        if (loading) return <LoadingSpinner show={true} />

        const outletName = get(data, 'myOrders.orders[0]outlet.name')
        const review = get(data, 'myOrders.orders[0]review')

        return !!review ? (
          <ReviewDisplay review={review} outletName={outletName} />
        ) : (
          <ReviewForm orderId={orderid} />
        )
      }}
    </Query>
  )
}

export default Review
