import React, { useContext } from 'react'
import DeliverToPostcode from '@components/DeliverToPostcode/DeliverToPostcode'
import styles from './EnterPostcode.styles'
import { withStyles } from 'react-jss'
import { ModalContext } from '@context/Modal.context'
import OldDeliverToPostcode from '../DeliverToPostcode/OldDeliverToPostcode'
import { getMarketplace } from '../../../config/config'

const EnterPostcode = ({ classes }) => {
  const { allowLocationSearch } = getMarketplace()
  const { closeModal } = useContext(ModalContext)
  return (
    <div className={classes.container}>
      <h1 className={classes.title}>
        Check service is available in your area.
      </h1>
      {allowLocationSearch ? (
        <DeliverToPostcode onSubmitCallBack={closeModal} />
      ) : (
        <OldDeliverToPostcode onSubmitCallBack={closeModal} />
      )}
    </div>
  )
}

export default withStyles(styles)(EnterPostcode)
