import gql from 'graphql-tag'

const ACCEPT_DISCOUNT = gql`
  mutation acceptDiscount($enrolmentKey: String!) {
    acceptDiscount(enrolmentKey: $enrolmentKey) {
      id
      discountPercentage
      customerEnrolled
      enrolmentKey
    }
  }
`

export default ACCEPT_DISCOUNT
