import React, { useContext } from 'react'
import cx from 'classnames'
import { BasketContext } from '@context/Basket.context'

const ClearPopover = ({ classes, isOpen, close }) => {
  const { clearItems } = useContext(BasketContext)
  const {
    clearBasketPopover,
    popoverTitle,
    popoverButtons,
    popoverButton,
    clearBtn,
  } = classes

  const clearBasket = () => {
    clearItems()
    close()
  }

  return (
    isOpen && (
      <div className={clearBasketPopover}>
        <h6 className={popoverTitle}>
          Are you sure you want to clear your basket?
        </h6>
        <div className={popoverButtons}>
          <a
            href="#_"
            className={cx(popoverButton, clearBtn)}
            onClick={clearBasket}
          >
            Yes, clear
          </a>
          <a href="#_" className={cx(popoverButton)} onClick={close}>
            Cancel
          </a>
        </div>
      </div>
    )
  )
}

export default ClearPopover
