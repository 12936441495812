// Imports
// ------
import React from 'react'
import injectSheet from 'react-jss'
import styles from './singleOrderStyles'
import cx from 'classnames'

// Exports
// ------
class IconEl extends React.PureComponent {
  render() {
    const {
      icon,
      iconOrderPending,
      iconOrderPreparing,
      iconOrderComplete,
      iconOrderReady,
      iconOrderRejected,
    } = this.props.classes

    const orderType = this.props.orderType
    const status = this.props.status
    const orderStatusClasses = cx(icon, {
      [iconOrderPending]: status === 'PENDING',
      [iconOrderPreparing]: status === 'PREPARING',
      [iconOrderReady]: status === 'READY',
      [iconOrderComplete]: status === 'COMPLETE',
      [iconOrderRejected]: status === 'REJECTED' || status === 'CANCELLED',
    })

    return (
      <>
        {status === 'PENDING' && (
          <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            className={orderStatusClasses}
          >
            <g strokeLinecap="square" strokeWidth="2">
              <circle cx="16" cy="16" fill="none" r="15" />
              <circle cx="16" cy="24.5" r="1.5" stroke="none" />
              <path
                d="M12.908,7.79C15.7,6.555,19.32,6.7,20.555,8.7s.382,4.324-1.735,6.118S16,17.588,16,19"
                fill="none"
              />
            </g>
          </svg>
        )}

        {status === 'PREPARING' && (
          <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            className={orderStatusClasses}
          >
            <g strokeLinecap="square" strokeWidth="2">
              <path
                d="M16,20h0A11,11,0,0,1,27,31v0a0,0,0,0,1,0,0H5a0,0,0,0,1,0,0v0A11,11,0,0,1,16,20Z"
                fill="none"
              />
              <circle
                cx="16"
                cy="24"
                r="1"
                stroke="none"
                strokeLinecap="butt"
              />
              <circle
                cx="16"
                cy="28"
                r="1"
                stroke="none"
                strokeLinecap="butt"
              />
              <path d="M21,12v3a5,5,0,0,1-5,5h0a5,5,0,0,1-5-5V12" fill="none" />
              <path
                d="M21,2a2.971,2.971,0,0,0-1.8.617,3.967,3.967,0,0,0-6.392,0A3,3,0,1,0,11,8v4H21V8a3,3,0,0,0,0-6Z"
                fill="none"
              />
            </g>
          </svg>
        )}

        {(status === 'REJECTED' || status === 'CANCELLED') && (
          <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            className={orderStatusClasses}
          >
            <g strokeLinecap="square" strokeWidth="2">
              <polygon fill="none" points="2 29 16 3 30 29 2 29" />
              <line fill="none" x1="16" x2="16" y1="13" y2="20" />
              <circle cx="16" cy="24" r="1" stroke="none" />
            </g>
          </svg>
        )}

        {status === 'READY' && orderType === 'COLLECTION' && (
          <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            className={orderStatusClasses}
          >
            <g strokeLinecap="square" strokeWidth="2">
              <polygon fill="none" points="28,31 28,31 4,31 4,7 28,7 " />
              <path
                d="M11,12V6 c0-2.761,2.239-5,5-5h0c2.761,0,5,2.239,5,5v6"
                fill="none"
              />
            </g>
          </svg>
        )}

        {status === 'READY' &&
          (orderType === 'DELIVERY' ||
            orderType === 'NETWORK' ||
            orderType === 'TABLE') && (
            <svg
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              className={orderStatusClasses}
            >
              <g strokeLinecap="square" strokeWidth="2">
                <polyline fill="none" points="7 13 7 9 31 9 31 29 7 29 7 26" />
                <path
                  d="M14,9V6a5,5,0,0,1,5-5h0a5,5,0,0,1,5,5V9"
                  fill="none"
                  strokeLinecap="butt"
                />
                <line fill="none" x1="1" x2="11" y1="22" y2="22" />
                <line fill="none" x1="3" x2="13" y1="17" y2="17" />
              </g>
            </svg>
          )}

        {status === 'COMPLETE' && (
          <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            className={orderStatusClasses}
          >
            <g strokeLinecap="square" strokeWidth="2">
              <path
                d="M7,13v16c0,1.105,0.895,2,2,2h0 c1.105,0,2-0.895,2-2V13"
                fill="none"
                strokeLinecap="butt"
              />
              <path
                d="M24,20v9c0,1.105,0.895,2,2,2h0 c1.105,0,2-0.895,2-2v-9"
                fill="none"
                strokeLinecap="butt"
              />
              <path
                d="M14,1v9 c0,1.657-1.343,3-3,3H7c-1.657,0-3-1.343-3-3V1"
                fill="none"
              />
              <line fill="none" x1="9" x2="9" y1="1" y2="9" />
              <path d="M28,20h-7V8 c0-3.866,3.134-7,7-7h0V20z" fill="none" />
            </g>
          </svg>
        )}
      </>
    )
  }
}
const Icon = injectSheet(styles)(IconEl)
export default Icon
