import gql from 'graphql-tag'

const order = `
      message
      requireAction
      paymentIntentClientSecret
      order {
        id
        estimatedDeliveryDate
        estimatedCompletionTime
        customerOrderNotes
        deliveryNotes
        netTotal
        grossTotal
        customerDeliveryAddress
        paymentMethod
        fulfillmentMethod
        fulfillmentCharge
        orderItems {
          id
          netTotal
          menuItem
        }
      }

`

export const POST_ORDER_ZONE = gql`
  mutation addOrder(
    $outlet: String!
    $orderItems: [AddOrderItemInput!]!
    $asap: Boolean
    $paymentToken: String
    $deliveryZone: String!
    $customerOrderNotes: String
    $customerDeliveryNotes: String
    $selectedDeliverySlot: DateTime
    $fulfillmentMethod: OrderFulfillmentMethods!
    $paymentMethod: PaymentMethod!
    $customerDeliveryAddress: String
    $selectedDeliveryWindow: Json
    $discountId: String
    $voucherKey: String
  ) {
    addOrder(
      outlet: $outlet
      orderItems: $orderItems
      asap: $asap
      paymentToken: $paymentToken
      deliveryZone: $deliveryZone
      customerOrderNotes: $customerOrderNotes
      customerDeliveryAddress: $customerDeliveryAddress
      customerDeliveryNotes: $customerDeliveryNotes
      selectedDeliverySlot: $selectedDeliverySlot
      fulfillmentMethod: $fulfillmentMethod
      paymentMethod: $paymentMethod
      selectedDeliveryWindow: $selectedDeliveryWindow
      discountId: $discountId
      voucherKey: $voucherKey
    ) {
      ${order},
      message
    }
  }
`

export const POST_ORDER_POSTCODE = gql`
  mutation addOrder(
    $outlet: String!
    $orderItems: [AddOrderItemInput!]!
    $asap: Boolean
    $paymentToken: String
    $customerOrderNotes: String
    $customerDeliveryNotes: String
    $selectedDeliverySlot: DateTime
    $fulfillmentMethod: OrderFulfillmentMethods!
    $paymentMethod: PaymentMethod!
    $customerDeliveryAddress: String
    $selectedDeliveryWindow: Json
    $discountId: String
    $voucherKey: String
  ) {
    addOrder(
      outlet: $outlet
      orderItems: $orderItems
      asap: $asap
      paymentToken: $paymentToken
      customerOrderNotes: $customerOrderNotes
      customerDeliveryAddress: $customerDeliveryAddress
      customerDeliveryNotes: $customerDeliveryNotes
      selectedDeliverySlot: $selectedDeliverySlot
      fulfillmentMethod: $fulfillmentMethod
      paymentMethod: $paymentMethod
      selectedDeliveryWindow: $selectedDeliveryWindow
      discountId: $discountId
      voucherKey: $voucherKey
    ) {
      ${order},
      message
    }
  }
`

export const POST_ORDER_TO_TABLE = gql`
  mutation orderToTable($orderData: orderToTableInput!) {
    orderToTable(orderData: $orderData) {
      id
      requireAction
      paymentIntentClientSecret
      customerOrderNotes
      netTotal
      grossTotal
      fulfillmentCharge
      orderItems {
        id
        netTotal
        menuItem
      }
    }
  }
`
