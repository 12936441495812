import React, { useContext, useState, useEffect } from 'react'
import styles from './deliverToPostcode.styles'
import { withStyles } from 'react-jss'
import { OrderContext } from '@context/Order.context'
import { withRouter } from 'react-router-dom'
import { POSTCODE_REGEX_UK } from '@utils/helpers'
import { ReactSVG } from 'react-svg'
import deliverySVG from '@images/cheeseburger.svg'
import dineInSVG from '@images/romantic-restaurant.svg'
import collectionSVG from '@images/bag-20.svg'
import postcodeSVG from '@images/property-location.svg'
import nearMeSVG from '@images/gps.svg'
import toggle from '@images/down-arrow.svg'
import savedAddress from '@images/fav-property.svg'
import { getMarketplace } from '@config/config'
import { navigator } from 'window-or-global'
import FulfilmentDropdown from './FulfilmentDropdown'
import LocationDropdown from './LocationDropdown'

const WHERE_TYPES = {
  COORDINATES: {
    type: 'COORDINATES',
    label: 'Find near me',
    svg: nearMeSVG,
  },
  POSTCODE: {
    type: 'POSTCODE',
    label: 'Search by postcode',
    svg: postcodeSVG,
  },
  SAVED_ADDRESSES: {
    type: 'SAVED_ADDRESSES',
    label: 'Saved addresses',
    svg: savedAddress,
  },
}

const DeliverToPostcode = ({
  classes,
  history,
  onSubmitCallBack,
  click,
  isPopover = false,
}) => {
  const { attributes, updateOrder } = useContext(OrderContext)
  const { defaultSearch } = getMarketplace()
  const [postcode, setPostcode] = useState('')
  const [fulfilmentType, setFulfilmentType] = useState('Delivery')
  const [whereType, setWhereType] = useState(
    defaultSearch === 'COORDINATES'
      ? WHERE_TYPES.COORDINATES
      : WHERE_TYPES.POSTCODE
  )
  const [showDropdown, setShowDropdown] = useState('')
  const [geolocation, setGeolocation] = useState({
    lat: '',
    lng: '',
  })
  const [geoError, setGeoError] = useState(false)
  const [savedAddressCount, setSavedAddressCount] = useState(0)
  const { COORDINATES, POSTCODE, SAVED_ADDRESSES } = WHERE_TYPES
  const { deliveryDestination = { type: 'POSTCODE', destination: '' } } =
    attributes

  useEffect(() => {
    //if postcode is cached, set to postcode, otherwise if default search type is location, search location when loading page.
    if (deliveryDestination.type === 'POSTCODE')
      setPostcode(deliveryDestination.destination)
    if (whereType === COORDINATES) findLocation()
  }, [])

  useEffect(() => {
    setShowDropdown('')
  }, [click])

  const findLocation = () => {
    navigator.geolocation.getCurrentPosition(success, error)
  }

  const handleDropdown = target => {
    showDropdown === target ? setShowDropdown('') : setShowDropdown(target)
  }

  const success = pos => {
    const crd = pos.coords
    setGeolocation({ lat: crd.latitude, lng: crd.longitude })
  }

  const isDisabled = () => {
    return (
      ((whereType === POSTCODE || whereType === SAVED_ADDRESSES) &&
        postcode &&
        !postcode.length) ||
      (whereType === COORDINATES && geoError) ||
      (whereType === COORDINATES && (!geolocation.lat || !geolocation.lng))
    )
  }

  const error = () => {
    setGeoError(true)
  }

  const handlePostcodeChange = ({ target: { value } }) => {
    const cleanValue = value ? value.toUpperCase() : value
    setWhereType(POSTCODE)
    setPostcode(cleanValue)
  }

  const handleFormSubmit = e => {
    e.preventDefault()
    const fulfilmentChosen =
      fulfilmentType === 'Delivery'
        ? { id: 'delivery', label: 'Delivery' }
        : fulfilmentType === 'Collection'
        ? { id: 'collection', label: 'Collection' }
        : { id: 'table', label: 'Table' }

    updateOrder({
      deliveryDestination:
        whereType === POSTCODE || whereType === SAVED_ADDRESSES
          ? { type: 'POSTCODE', destination: postcode }
          : { type: 'COORDINATES', destination: geolocation },
      fulfilmentChosen,
    })

    if (onSubmitCallBack) {
      onSubmitCallBack({ history })
    }
  }

  const calculateMarginTop = () => {
    // only apply on mobile/tablet
    if (screen.width > 768) return {}
    // if fulfilment dropdown open
    if (showDropdown === 'fulfilment') {
      return { marginTop: '84px' }
    }
    // if where dropdown open
    if (showDropdown === 'COORDINATES') {
      // each saved address container adds 55px height
      // plus 107 height of dropdown container
      return { marginTop: `${savedAddressCount * 55 + 107}px` }
    }
    // if no dropdown open, return 0
    return { marginTop: '0' }
  }

  return (
    <>
      <div style={{ height: '15vh', alignContent: 'center' }}>
        <div className={classes.dropdownContainer}>
          {/* Fulfilment dropdown */}
          <FulfilmentDropdown
            showDropdown={showDropdown}
            classes={classes}
            setFulfilmentType={setFulfilmentType}
            handleDropdown={handleDropdown}
            isPopover={isPopover}
          />
          {/* Location dropdown */}
          <LocationDropdown
            showDropdown={showDropdown}
            classes={classes}
            setWhereType={setWhereType}
            WHERE_TYPES={WHERE_TYPES}
            handleDropdown={handleDropdown}
            setPostcode={setPostcode}
            findLocation={findLocation}
            isPopover={isPopover}
            setSavedAddressCount={setSavedAddressCount}
          />
        </div>

        <form
          onSubmit={handleFormSubmit}
          className={isPopover ? classes.popoverForm : classes.form}
        >
          <div
            className={classes.firstRow}
            onClick={() => {
              handleDropdown('fulfilment')
            }}
          >
            <div className={classes.row}>
              <ReactSVG
                src={
                  fulfilmentType === 'Delivery'
                    ? deliverySVG
                    : fulfilmentType === 'Dine in'
                    ? dineInSVG
                    : collectionSVG
                }
                wrapper="span"
                className={classes.svg}
              />
              <div className={classes.column} style={{ flexWrap: 'nowrap' }}>
                <p className={classes.subtitle}>What can we help you find?</p>
                <p className={classes.title}>{fulfilmentType}</p>
              </div>
            </div>
            <ReactSVG src={toggle} className={classes.arrowSvg} />
          </div>

          <div
            className={classes.secondRow}
            onClick={() => {
              if (whereType !== POSTCODE) {
                handleDropdown('COORDINATES')
              }
            }}
          >
            <label htmlFor="POSTCODE">
              <div
                className={classes.row}
                onClick={() => {
                  handleDropdown('COORDINATES')
                }}
              >
                <ReactSVG
                  src={whereType.svg}
                  wrapper="span"
                  className={classes.svg}
                />
                <div className={classes.column}>
                  {whereType === COORDINATES && geoError ? (
                    <p className={classes.geoError}>
                      Please enable location access
                    </p>
                  ) : (
                    <>
                      <p className={classes.subtitle}>Where?</p>
                      {whereType === POSTCODE ||
                      whereType === SAVED_ADDRESSES ? (
                        <input
                          id="POSTCODE"
                          name="POSTCODE"
                          type="text"
                          value={postcode}
                          onChange={handlePostcodeChange}
                          title="Valid full postcode e.g. S1 2LR"
                          pattern={POSTCODE_REGEX_UK}
                          className={classes.input}
                          placeholder={'Enter postcode'}
                          autoFocus
                        />
                      ) : (!geolocation.lat || !geolocation.lng) &&
                        !geoError ? (
                        <p className={classes.title} style={{ opacity: 0.7 }}>
                          Finding location...
                        </p>
                      ) : (
                        <p className={classes.title}>{whereType.label}</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </label>

            <ReactSVG
              src={toggle}
              className={classes.arrowSvg2}
              onMouseDown={e => e.preventDefault()}
              onClick={() => {
                handleDropdown('COORDINATES')
              }}
            />
          </div>
          <button
            className={isDisabled() ? classes.disabledButton : classes.button}
            type="submit"
            disabled={isDisabled()}
            style={calculateMarginTop()}
          >
            <div className={classes.buttonText}>
              {screen.width > 768 ? 'Go' : 'Search'}
            </div>
          </button>
        </form>
      </div>
    </>
  )
}

export default withStyles(styles)(withRouter(DeliverToPostcode))
