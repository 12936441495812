// Imports
// ------
import React from 'react'
import injectSheet from 'react-jss'
import { styles } from '../contentStyles'

// Component
// ------
class ListEl extends React.PureComponent {
  render() {
    const { list } = this.props.classes

    return <ol className={list}>{this.props.children}</ol>
  }
}

class ListItemEl extends React.PureComponent {
  render() {
    const { listItem } = this.props.classes

    return <li className={listItem}>{this.props.children}</li>
  }
}

const List = injectSheet(styles)(ListEl)
const ListItem = injectSheet(styles)(ListItemEl)

export { List, ListItem }
