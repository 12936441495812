import React from 'react'
import App from '@components/App'
import ReactDOM from 'react-dom'

ReactDOM.render(<App />, document.getElementById('root'))

// Hot Module Replacement
if (module.hot) {
  module.hot.accept()
}
