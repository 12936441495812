import colors from '@styles/sharedJSS/colors'

const styles = theme => ({
  detailsWrapper: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    marginTop: '0.5rem',
  },

  detailHeader: {
    color: theme.bc3,
    padding: '1.2rem 1.2rem 0px 1.2rem',
    fontSize: '14px',
    fontWeight: 600,
  },

  section: {
    padding: '1.2rem 1.2rem',
  },

  para: {
    color: '#636363',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '18px',
  },

  dayListingRow: {
    display: 'flex',
    padding: '1.2rem 0px',

    fontSize: '15px',
    justifyContent: 'space-between',
    color: '#545e65',
  },

  bordered: {
    borderBottom: '1px dotted #d0dbe1',
  },

  gap: {
    marginBottom: '2.4rem',

    '&:last-child': {
      marginBottom: '0',
    },
  },

  times: {
    fontWeight: 600,
  },
})

export default styles
