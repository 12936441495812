import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from '@utils/helpers'
import Color from 'color'

const restaurantRowStyles = theme => ({
  listWrapper: {
    marginTop: '-5rem',
    position: 'relative',
    marginBottom: '3rem',

    [`@media screen and (min-width: ${TABLET_BREAKPOINT}px)`]: {
      marginTop: '-6rem',
    },
  },

  sidebarList: {
    margin: '1rem 0rem 1rem 0rem',
    backgroundColor: 'white',
    boxShadow: '0px 30px 30px rgba(0, 0, 0, 0.10)',
    padding: '0.45rem 1.3rem 0.45rem',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      borderRadius: '0.4rem',
      marginTop: `3rem`,
      backgroundColor: 'transparent',
      boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
      padding: '0',
      margin: '2rem 0',
    },
  },

  sideBarLink: {
    color: `${theme.bc3} !important`,
    display: 'block',
    fontWeight: 500,
    fontSize: '14px',
    transition: `color 0.5s ease`,
    cursor: 'pointer',
    '&:hover': {
      color: Color(theme.bc3)
        .lighten(0.2)
        .toString(),
    },
  },
})

export default restaurantRowStyles
