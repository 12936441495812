import React, { Component } from 'react'
import Register from '@components/Register/Register'
import Modal from '@components/ModalWrapper/ModalWrapper'
import { Query } from 'react-apollo'
import { REGISTER_MODAL_OPEN } from '../Query/LocalStates'
import { withRouter } from 'react-router-dom'

class RegisterModal extends Component {
  render() {
    return (
      <Query query={REGISTER_MODAL_OPEN}>
        {({ data }) => (
          <Modal open={data.registerOpen} close={() => {}}>
            <Register {...this.props} />
          </Modal>
        )}
      </Query>
    )
  }
}

export default withRouter(RegisterModal)
