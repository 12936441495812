import React from 'react'
import get from 'lodash/get'
import cx from 'classnames'
import { shape, string, number, arrayOf, object } from 'prop-types'
import { penceToPounds } from '@utils/helpers'
import CartOption from './CartOpion'
import { ReactSVG } from 'react-svg'
import closeSVG from '@images/close.svg'
import _ from 'lodash'

const CartItem = ({ item, classes, removeSingleItem, canEdit }) => {
  const {
    cartItem,
    itemPriceTag,
    cartPriceTag,
    cartItemTitle,
    removeItemIcon,
    itemOption,
  } = classes
  const { id, name, price } = get(item, 'menuItem')
  const sumOfOptions = _.sumBy(item.optionItems, 'price')
  return (
    <div className={cartItem} key={id}>
      <span className={cx(itemPriceTag, cartPriceTag)}>{`£${penceToPounds(
        price + sumOfOptions
      )}`}</span>
      <span className={cartItemTitle}>
        {canEdit && (
          <button
            className={removeItemIcon}
            onClick={() => removeSingleItem(item)}
          >
            <ReactSVG src={closeSVG} wrapper="span" />
          </button>
        )}
        {name}
      </span>
      {item.optionItems.map(opt => (
        <CartOption option={opt} classes={classes} key={opt.id} />
      ))}
      {item.singleItemNotes ? (
        <p className={itemOption}>{item.singleItemNotes}</p>
      ) : null}
      {item.addOnItem ? <p className={itemOption}>Offer</p> : null}
    </div>
  )
}

CartItem.propTypes = {
  item: shape({
    menuItem: shape({
      name: string,
      id: string,
      desciption: string,
      price: number,
      singleItemNotes: string,
    }),
    optionItems: arrayOf(
      shape({
        id: string,
        name: string,
        price: number,
      })
    ),
  }),
  classes: object,
}

export default CartItem
