import gql from 'graphql-tag'

const UPDATE_MARKETING_PREFERENCES = gql`
  mutation updateMarketingPreferences(
    $optOutNewRestaurantMarketing: Boolean!
    $optOutMenuMarketing: Boolean!
    $optOutPromotionMarketing: Boolean!
    $optOutMarketingSMS: Boolean!
    $optOutMarketingEmail: Boolean!
    $optOutMarketingPush: Boolean!
  ) {
    updateMarketingPreferences(
      optOutNewRestaurantMarketing: $optOutNewRestaurantMarketing
      optOutMenuMarketing: $optOutMenuMarketing
      optOutPromotionMarketing: $optOutPromotionMarketing
      optOutMarketingSMS: $optOutMarketingSMS
      optOutMarketingEmail: $optOutMarketingEmail
      optOutMarketingPush: $optOutMarketingPush
    ) {
      id
      optOutNewRestaurantMarketing
      optOutMenuMarketing
      optOutPromotionMarketing
      optOutMarketingSMS
      optOutMarketingEmail
      optOutMarketingPush
    }
  }
`

export default UPDATE_MARKETING_PREFERENCES
