import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  LAPTOP_BREAKPOINT,
} from '@utils/breakpoints'
import restaurantRowStyles from '../../styles/sharedJSS/restaurantRow'

const styles = theme => ({
  ...restaurantRowStyles(theme),
  title: {
    cursor: 'pointer',
    transition: 'opacity .25s ease-in-out',

    '&:hover': {
      opacity: 0.5,
    },
  },

  titleAreaPostcode: {
    padding: 0,
  },

  listWrapperPostcode: {
    marginTop: '-7rem',
  },

  activeIndicator: {
    float: 'left',
    height: '16px',
    display: 'inline-block',
    width: '16px',
    marginTop: '10px',
    backgroundColor: '#eaeff2',
    marginRight: '13px',
    borderRadius: '100%',
    transition: `all 0.5s ease`,
  },

  indicatorActive: {
    backgroundColor: theme.bc1,
  },

  restaurantsLoading: {
    height: '100%',
    position: 'relative',
  },

  noResults: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.bc3,
    padding: '1.2rem',
    marginBottom: '20rem',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      paddingTop: '12.5rem',
    },
  },

  emptyList: {
    opacity: 0.8,
    marginTop: '2rem',
    fontSize: '2rem',
    fontWeight: 600,
  },

  noResultsInfo: {
    color: theme.bc1,
    marginTop: '1rem',
    fontSize: '1.6rem',
    lineHeight: '2rem',
    textAlign: 'center',
    maxWidth: '300px',
  },

  noResultsHomeLink: {
    marginTop: '0.8rem',
    fontSize: '1.2rem',
  },

  noneFound: {
    color: theme.bc3,
  },

  isActive: {
    color: theme.bc1,
  },

  defaultState: {
    transition: `all 0.5s ease`,
  },

  inactiveState: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },

  discountString: {
    display: 'block',
    lineHeight: '16px',
    paddingTop: '1px',
    color: '#2bb0b7',
    ' & svg': {
      paddingTop: '3px',
      width: '13px',
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '189px',

    [`@media screen and (min-width: ${LAPTOP_BREAKPOINT}px)`]: {
      display: 'inline-block',
      overflow: 'initial',
    },
  },

  cart: {
    [`@media screen and (min-width: ${TABLET_BREAKPOINT}px)`]: {
      display: 'none',
    },
  },
})

export default styles
