import gql from 'graphql-tag'

const GET_CUISINES = gql`
  query getCuisines($key: String!) {
    marketplace(key: $key) {
      cuisines {
        id
        name
      }
    }
  }
`

export default GET_CUISINES
