import React, { useContext } from 'react'
import startCase from 'lodash/startCase'
import { Helmet } from 'react-helmet'
import { getMarketplace } from '@config/config'
import { OrderContext } from '@context/Order.context'

const RestaurantsListHead = ({ marketplace = getMarketplace() }) => {
  const { deliveryZone } = useContext(OrderContext)
  const { merchantType } = marketplace
  const isRetailMarketplace = merchantType === 'RETAIL'

  const hasDeliveryZoneName = deliveryZone && deliveryZone.name
  const title = `${
    hasDeliveryZoneName
      ? `${startCase(deliveryZone.name.replace(/-/g, ' '))} `
      : ''
  }${
    isRetailMarketplace ? 'Products' : 'Takeaway & Restaurant Menus'
  } | Order Online from ${marketplace.name}`

  const content = `View all the ${
    isRetailMarketplace
      ? 'products from your favourite businesses'
      : 'menus from your favourite takeaways'
  } ${
    hasDeliveryZoneName ? ` in and around ${startCase(deliveryZone.name)}` : ''
  }. For exclusive ${
    !isRetailMarketplace && 'takeaways,'
  } offers & discounts order online or download the ${marketplace.name} app`

  return (
    <Helmet>
      <title>{title}</title>

      <meta name="description" content={content} />
    </Helmet>
  )
}

export default RestaurantsListHead
