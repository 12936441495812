import gql from 'graphql-tag'

const OUTLET_SUMMARY = gql`
  query details($id: String!) {
    outlet(id: $id) {
      id
      restaurant {
        id
        name
      }
    }
  }
`

export default OUTLET_SUMMARY
