import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './orderStyles'
import get from 'lodash/get'
import SingleOrderView from './SingleOrder/SingleOrder'
import OrderListView from './OrderList/OrderList'
import BodyLocker from '@utils/BodyLocker'

class OrdersEl extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    BodyLocker.unlock()
  }

  render() {
    const { orderListWrapper } = this.props.classes
    const hasOrderId = get(this.props, 'match.params.orderid', false)
    return (
      <div>
        {hasOrderId ? (
          <div>
            <SingleOrderView {...this.props} />
          </div>
        ) : (
          <div className={orderListWrapper}>
            <OrderListView {...this.props} />
          </div>
        )}
      </div>
    )
  }
}

const Orders = injectSheet(styles)(OrdersEl)
export default Orders
