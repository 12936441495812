import Color from 'color'
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from '@utils/breakpoints'

const styles = theme => ({
  container: {
    backgroundColor: 'white',
    overflow: 'hidden',
    boxShadow: '0px 0px 60px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.25s ease-in-out',
    marginLeft: '2.4rem',
    marginRight: '2.4rem',
    marginBottom: '2rem',
    padding: '1rem',
    borderTop: '4px solid #FFCC0E',
    cursor: 'pointer',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      margin: '0.5rem',
      marginBottom: '1.5rem',
    },

    '&:hover': {
      boxShadow: '0px 0px 80px rgba(0, 0, 0, 0.1)',
      transform: 'scale(1.02) translateY(-0.5rem)',
    },
  },

  top: {
    display: 'flex',
    borderBottom: '1px dashed rgba(0,0,0,0.1)',
    paddingBottom: '1rem',
  },

  image: {
    width: '20%',
    paddingTop: '20%',
    flexShrink: 0,
    backgroundColor: Color(theme.bc5)
      .rgb()
      .alpha(0.1)
      .string(),
    position: 'relative',
    borderRadius: '1rem',
    overflow: 'hidden',

    [`@media screen and (min-width: ${TABLET_BREAKPOINT}px)`]: {
      width: '30%',
      paddingTop: '30%',
    },

    '& img': {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      transition: 'all 0.2s ease-in-out',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center center',
    },
  },

  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '1rem',
    overflow: 'hidden',
  },

  restaurantName: {
    textAlign: 'left',
    textTransform: 'uppercase',
    display: 'block',
    fontWeight: 600,
    fontSize: '14px',
    color: theme.bc5,
    lineHeight: '2rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },

  cuisines: {
    textAlign: 'left',
    display: 'block',
    fontWeight: 400,
    color: Color(theme.bc5)
      .rgb()
      .alpha(0.8)
      .string(),
    fontSize: '12px',
    lineHeight: '20px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      fontSize: '14px',
    },
  },

  openingInfo: {
    display: 'flex',
    margin: '1rem',
  },

  restaurantStats: {
    marginRight: 'auto',
  },

  menuLink: {
    width: '100%',
  },
})

export default styles
