const styles = theme => ({
  hiddenInput: {
    width: 0,
    height: 0,
    opacity: 0,
    margin: 0,
    padding: 0,
    outline: 0,
  },

  svg: {
    borderRadius: '0.3rem',
    marginRight: '1.2rem',
  },

  container: {
    transition: 'fill 0.2s ease',
    fill: 'white',
    stroke: theme.bc3,
  },

  containerActive: {
    fill: theme.bc3,
  },

  checkbox: {
    fill: 'none',
  },

  checkMark: {
    transition: 'opacity 0.2s ease',
    opacity: 0,
    fill: 'none',
    stroke: 'white',
  },

  checkMarkActive: {
    opacity: 1,
  },
})

export default styles
