// Imports
// ------
import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './singleOrderStyles'
import { GET_SINGLE_ORDER } from '../getOrders.query'
import { Query } from 'react-apollo'
import cx from 'classnames'
import config from '@config/config'
import { capitalize, find, get } from 'lodash'
import { penceToPounds, cleanOrderStatusForClient } from '@utils/helpers'
import moment from 'moment'
import { getMarketplace } from '@config/config'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import phoneSVG from '@images/phone-2.svg'
import pencilSVG from '@images/pencil.svg'
import cutlerySVG from '@images/cutlery.svg'
import deliverySVG from '@images/deliveryIcon.svg'
import collectionSVG from '@images/collectionIcon.svg'

// Custom
// ------
import Icon from './singleOrderIcons'
import ProgressBar from './singleOrderProgress'
import { ReactSVG } from 'react-svg'

// Component
// ------
class SingleOrderViewEl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAll: false,
    }
  }

  getSubItem = item => {
    const { subItem, subItemName, subItemPrice } = this.props.classes

    return (
      <li key={item.id} className={subItem}>
        <main className={subItemName}>{item.name}</main>
        <summary className={subItemPrice}>
          + &#163;
          {penceToPounds(item.price)}
        </summary>
      </li>
    )
  }

  getSingleItem = item => {
    const {
      singleItemContainer,
      singleItem,
      singleItemName,
      singleItemPrice,
      singleItemSubList,
      subItemName,
    } = this.props.classes

    const optionItems = get(item, 'optionItems')

    return (
      <li key={item.id} className={singleItemContainer}>
        <main className={singleItem}>
          <header className={singleItemName}>{item.menuItem.name}</header>
          <summary className={singleItemPrice}>
            &#163;
            {penceToPounds(item.menuItem.price)}
          </summary>
        </main>
        {optionItems && (
          <ul className={singleItemSubList}>
            {optionItems.map(subItem => this.getSubItem(subItem))}
          </ul>
        )}
        {item.singleItemNotes && (
          <div className={singleItemSubList}>
            <p className={subItemName}>{item.singleItemNotes}</p>
          </div>
        )}
      </li>
    )
  }

  getSingleOrderView(order) {
    const {
      deliveryContainer,
      restaurantDetails,
      restaurantLogoJacket,
      restaurantLogo,
      restaurantName,
      orderDetails,
      deliveryContent,
      deliveryStatus,
      deliveryUpdated,
      statusPending,
      statusPreparing,
      statusRejected,
      statusReady,
      statusComplete,
      orderTimesDate,
      orderDetailsList,
      orderTotalsList,
      orderTotalsListItem,
      orderTotalsListItemLabel,
      orderTotalsListItemCost,
      orderTotalsListItemLabelTotal,
      orderTotalsListItemTotalCost,
      orderPageContainer,
      orderDetailsContainerMain,
      orderDetailsContainer,
      orderDetailsSideContainer,
      orderDetailsSideBox,
      expandedSummary,
      orderTotalsContainer,
      orderNameTimes,
      orderTimes,
      orderTimesContainerLeft,
      orderTimesContainer,
      orderTimesContainerRight,
      orderTimesLabel,
      sidebarIcons,
      contactAddress,
    } = this.props.classes

    const $logoSrc = `${config.config.apiUrl}/${order.outlet.restaurant.image}`
    const orderUpdatedOn = moment(order.updatedAt)
    const orderPlacedOn = moment(order.createdAt)
    const {
      fulfillmentMethod,
      fulfillmentCharge,
      partnerCharge,
      subtotalGross,
      grossTotal,
      discountAmount,
    } = order

    const whichDeliveryTime = order.estimatedDeliveryDate
      ? order.estimatedDeliveryDate
      : order.estimatedCompletionTime

    const deliveryTimeMoment = moment(whichDeliveryTime)
    let deliveryTime = deliveryTimeMoment.calendar(null, {
      sameDay: '[Today at] HH:mm',
      nextDay: '[Tomorrow at] HH:mm',
      nextWeek: 'dddd [at] HH:mm',
      sameElse: 'DD/MM/YYYY [at] HH:mm',
    })
    if (
      order.selectedDeliveryWindow &&
      order.selectedDeliveryWindow.start &&
      order.selectedDeliveryWindow.end
    ) {
      if (
        order.fulfillmentMethod === 'DELIVERY' ||
        order.fulfillmentMethod === 'NETWORK'
      ) {
        if (order.asap) {
          deliveryTime = moment(order.selectedDeliveryWindow.end).calendar(
            null,
            {
              sameDay: '[Today by] HH:mm',
              nextDay: '[Tomorrow by] HH:mm',
              nextWeek: 'dddd [by] HH:mm',
              sameElse: 'DD/MM/YYYY [by] HH:mm',
            }
          )
        } else {
          const deliveryStart = moment(
            order.selectedDeliveryWindow.start
          ).calendar(null, {
            sameDay: '[Today at] HH:mm',
            nextDay: '[Tomorrow at] HH:mm',
            nextWeek: 'dddd [at] HH:mm',
            sameElse: 'DD/MM/YYYY [at] HH:mm',
          })

          const deliveryEnd = moment(order.selectedDeliveryWindow.end).format(
            'HH:mm'
          )
          deliveryTime = `(${deliveryStart} - ${deliveryEnd})`
        }
      }
    }

    const cleanOrderStatus = cleanOrderStatusForClient(order.orderStatus)
    const justDisplayStatus =
      cleanOrderStatus === 'PENDING' ||
      cleanOrderStatus === 'PREPARING' ||
      cleanOrderStatus === 'REJECTED' ||
      cleanOrderStatus === 'COMPLETE' ||
      cleanOrderStatus === 'CANCELLED'

    const deliveryOrNetwork =
      order.fulfillmentMethod === 'DELIVERY' ||
      order.fulfillmentMethod === 'NETWORK'

    const marketplace = getMarketplace()
    const isSingleOutlet = marketplace.orderMode === 'SINGLE'

    const { firstLine, secondLine, city, postcode } = deliveryOrNetwork
      ? order.customerDeliveryAddress
      : order.outlet.outletAddress

    return (
      <div className={orderPageContainer}>
        <div className={deliveryContainer}>
          <section
            className={cx({
              [statusPending]:
                cleanOrderStatus === 'PENDING' ||
                cleanOrderStatus === 'AUTH_ACTION_PENDING',
              [statusPreparing]: cleanOrderStatus === 'PREPARING',
              [statusRejected]:
                cleanOrderStatus === 'REJECTED' ||
                cleanOrderStatus === 'CANCELLED' ||
                cleanOrderStatus === 'AUTH_ACTION_FAILURE',
              [statusReady]: cleanOrderStatus === 'READY',
              [statusComplete]: cleanOrderStatus === 'COMPLETE',
            })}
          >
            <div className={deliveryContent}>
              <div className={deliveryStatus}>
                <i>
                  <Icon
                    status={cleanOrderStatus}
                    orderType={order.fulfillmentMethod}
                  />
                </i>

                <main>
                  {/* Title */}
                  {justDisplayStatus && <h6>{cleanOrderStatus}</h6>}
                  {order.fulfillmentMethod === 'COLLECTION' &&
                    cleanOrderStatus === 'READY' && <h6>READY TO COLLECT</h6>}
                  {deliveryOrNetwork && cleanOrderStatus === 'READY' && (
                    <h6>OUT FOR DELIVERY</h6>
                  )}
                  {order.fulfillmentMethod === 'TABLE' &&
                    cleanOrderStatus === 'READY' && <h6>ON ITS WAY TO YOU</h6>}
                  {/* Information - Text */}
                  {(cleanOrderStatus === 'PENDING' ||
                    cleanOrderStatus === 'AUTH_ACTION_PENDING') && (
                    <p>
                      Waiting for {order.outlet.restaurant.name} to accept your
                      order...
                    </p>
                  )}
                  {order.fulfillmentMethod === 'COLLECTION' &&
                    cleanOrderStatus === 'READY' && (
                      <p>
                        You order is ready to collect. <br /> It will be ready
                        for you on arrival.
                      </p>
                    )}
                  {order.fulfillmentMethod === 'TABLE' &&
                    cleanOrderStatus === 'READY' && (
                      <p>
                        Your order is on its way to you. It will arrive at table{' '}
                        {order.tableSnapshot.friendlyName} shortly.
                      </p>
                    )}
                  {deliveryOrNetwork && cleanOrderStatus === 'READY' && (
                    <p>
                      You order is on it's way. <br /> Estimated delivery time
                      is {deliveryTime}.
                    </p>
                  )}
                  {cleanOrderStatus === 'PREPARING' && (
                    <p>
                      {order.outlet.restaurant.name} are preparing your order.
                      <br />
                      {order.fulfillmentMethod !== 'TABLE'
                        ? `Estimated 
                        ${
                          deliveryOrNetwork ? 'delivery' : 'collection'
                        } time is 
                        ${deliveryTime}.`
                        : null}
                    </p>
                  )}
                  {cleanOrderStatus === 'REJECTED' && (
                    <p>
                      {order.outlet.restaurant.name} marked your order as
                      rejected. <br /> Please see below for more information.
                    </p>
                  )}
                  {cleanOrderStatus === 'CANCELLED' && (
                    <p>
                      {order.outlet.restaurant.name} marked your order as
                      cancelled. <br /> Please see below for more information.
                    </p>
                  )}
                  {cleanOrderStatus === 'AUTH_ACTION_FAILURE' && (
                    <p>
                      Payment could not be authorised. Please try ordering
                      again.
                    </p>
                  )}
                  {cleanOrderStatus === 'COMPLETE' && (
                    <p>This order has been marked as complete. Enjoy!</p>
                  )}
                </main>
              </div>

              <p className={deliveryUpdated}>
                <strong>Last Update:</strong>
                <span>
                  {orderUpdatedOn.calendar(null, {
                    sameDay: '[Today at] HH:mm',
                    lastDay: '[Yesterday at] HH:mm',
                    lastWeek: '[Last] dddd [at] HH:mm',
                    sameElse: 'DD/MM/YYYY [at] HH:mm',
                  })}
                </span>
              </p>
            </div>

            {cleanOrderStatus !== 'COMPLETE' &&
              cleanOrderStatus !== 'REJECTED' &&
              cleanOrderStatus !== 'CANCELLED' && (
                <ProgressBar
                  status={cleanOrderStatus}
                  typeOfPurchase={order.fulfillmentMethod}
                />
              )}
          </section>
        </div>

        <main className={orderDetailsContainerMain}>
          <section className={orderDetailsContainer}>
            <header className={restaurantDetails}>
              {!isSingleOutlet && (
                <figure className={restaurantLogoJacket}>
                  <img className={restaurantLogo} src={$logoSrc} />
                </figure>
              )}

              <main className={orderNameTimes}>
                <h3 className={restaurantName}>
                  {order.outlet.restaurant.name}
                </h3>
                <div className={orderTimes}>
                  <div
                    className={cx(orderTimesContainerLeft, orderTimesContainer)}
                  >
                    <p className={orderTimesLabel}>Order Placed</p>
                    <time className={orderTimesDate}>
                      {orderPlacedOn.format('DD/MM/YYYY [at] HH:mm')}
                    </time>
                  </div>

                  <div
                    className={cx(
                      orderTimesContainerRight,
                      orderTimesContainer
                    )}
                  >
                    <p className={orderTimesLabel}>Order Number</p>
                    <time className={orderTimesDate}>{order.orderNumber}</time>
                  </div>
                </div>
              </main>
            </header>

            <div>
              <main className={orderDetails}>
                <div className={expandedSummary}>
                  <ul className={orderDetailsList}>
                    {order.orderItems.map(item => this.getSingleItem(item))}
                  </ul>

                  <ul className={orderTotalsList}>
                    <li className={orderTotalsListItem}>
                      <p className={orderTotalsListItemLabel}>Subtotal</p>
                      <p className={orderTotalsListItemCost}>
                        &#163;
                        {penceToPounds(subtotalGross)}
                      </p>
                    </li>

                    {!!discountAmount && (
                      <li className={orderTotalsListItem}>
                        <p className={orderTotalsListItemLabel}>
                          {order.discount.name}
                        </p>
                        <p className={orderTotalsListItemCost}>
                          - &#163;
                          {penceToPounds(discountAmount)}
                        </p>
                      </li>
                    )}
                    {fulfillmentCharge > 0 && (
                      <li className={orderTotalsListItem}>
                        <p className={orderTotalsListItemLabel}>
                          {capitalize(
                            fulfillmentMethod !== 'NETWORK'
                              ? fulfillmentMethod
                              : 'Delivery'
                          )}
                        </p>
                        <p className={orderTotalsListItemCost}>
                          &#163;
                          {penceToPounds(fulfillmentCharge)}
                        </p>
                      </li>
                    )}

                    {!!partnerCharge && (
                      <li className={orderTotalsListItem}>
                        <p className={orderTotalsListItemLabel}>
                          {order.fulfillmentMethod === 'TABLE'
                            ? order.outlet.marketplace
                                .partnerTableChargeDescription
                            : order.outlet.marketplace.partnerChargeDescription}
                        </p>
                        <p className={orderTotalsListItemCost}>
                          &#163;
                          {penceToPounds(partnerCharge)}
                        </p>
                      </li>
                    )}
                  </ul>
                </div>

                <div className={orderTotalsContainer}>
                  <p className={orderTotalsListItemLabelTotal}>Total</p>
                  <p className={orderTotalsListItemTotalCost}>
                    &#163;
                    {penceToPounds(grossTotal)}
                  </p>
                </div>
              </main>
            </div>
          </section>
          <section className={orderDetailsSideContainer}>
            {cleanOrderStatus === 'REJECTED' ||
            cleanOrderStatus === 'CANCELLED' ? (
              <div
                className={orderDetailsSideBox}
                style={{ backgroundColor: '#EC615B1A' }}
              >
                <div className={sidebarIcons}>
                  <Icon status={'REJECTED'} />
                </div>
                <div>
                  <p
                    className={orderTotalsListItemLabelTotal}
                    style={{ color: '#EC615B' }}
                  >
                    {cleanOrderStatus === 'REJECTED'
                      ? 'Rejection'
                      : 'Cancellation'}{' '}
                    Notes
                  </p>
                  <div className={orderTimesDate} style={{ color: '#EC615B' }}>
                    <p>{order.cancellationNotes || 'None'}</p>
                  </div>
                </div>
              </div>
            ) : null}
            <div className={orderDetailsSideBox}>
              <ReactSVG
                src={
                  deliveryOrNetwork || fulfillmentMethod === 'TABLE'
                    ? deliverySVG
                    : collectionSVG
                }
                wrapper="span"
                className={sidebarIcons}
              />
              <div>
                <p className={orderTotalsListItemLabelTotal}>
                  {deliveryOrNetwork
                    ? 'Delivering To'
                    : fulfillmentMethod === 'COLLECTION'
                    ? 'Collect From'
                    : 'Table Service'}
                </p>
                <div className={orderTimesDate}>
                  {fulfillmentMethod === 'COLLECTION' && (
                    <p>{order.outlet.name}</p>
                  )}
                  {fulfillmentMethod === 'TABLE' ? (
                    <p>
                      {order.tableSnapshot.friendlyName
                        .toLowerCase()
                        .includes('table')
                        ? order.tableSnapshot.friendlyName
                        : 'Table ' + order.tableSnapshot.friendlyName}
                    </p>
                  ) : (
                    <p>
                      {firstLine} {secondLine}, <br /> {city}, <br />
                      {postcode}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className={orderDetailsSideBox}>
              <ReactSVG
                src={pencilSVG}
                wrapper="span"
                className={sidebarIcons}
              />
              <div>
                <p className={orderTotalsListItemLabelTotal}>
                  {deliveryOrNetwork
                    ? 'Delivery'
                    : fulfillmentMethod === 'COLLECTION'
                    ? 'Collection'
                    : 'Table'}{' '}
                  Notes
                </p>
                <div className={orderTimesDate}>
                  <p>
                    {order.customerDeliveryNotes ||
                      order.collectionNotes ||
                      'None'}
                  </p>
                </div>
              </div>
            </div>
            <div className={orderDetailsSideBox}>
              <ReactSVG
                src={cutlerySVG}
                wrapper="span"
                className={sidebarIcons}
              />
              <div>
                <p className={orderTotalsListItemLabelTotal}>Order Notes</p>
                <div className={orderTimesDate}>
                  <p>{order.customerOrderNotes || 'None'} </p>
                </div>
              </div>
            </div>
            <div className={orderDetailsSideBox}>
              <ReactSVG
                src={phoneSVG}
                wrapper="span"
                className={sidebarIcons}
              />
              <div>
                <p className={orderTotalsListItemLabelTotal}>Order Enquiries</p>
                <div className={contactAddress}>
                  <p>
                    {order.outlet.outletAddress.firstLine}, <br />
                    {order.outlet.outletAddress.city}, <br />
                    {order.outlet.outletAddress.postcode}
                  </p>
                  <br />
                  <p>{order.outlet.outletPhone}</p>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    )
  }

  render() {
    const { orderid } = this.props.match.params
    const ONE_MINUTES_IN_MILLISECONDS = 60000
    const THREE_MINUTES_IN_MILLISECONDS = 3 * ONE_MINUTES_IN_MILLISECONDS
    return (
      <Query
        query={GET_SINGLE_ORDER}
        variables={{ orderId: orderid }}
        pollInterval={ONE_MINUTES_IN_MILLISECONDS}
      >
        {({ data, loading, error, stopPolling, startPolling }) => {
          if (loading) return <LoadingSpinner show />
          if (error) return <div>Error</div>
          if (!data) return <div>No orders yet</div>

          const orders = get(data, 'myOrders.orders', [])
          const thisOrder = find(orders, order => {
            return orderid === order.id
          })
          if (!thisOrder) return <div>This order doesnt exist</div>

          const cleanOrderStatus = cleanOrderStatusForClient(
            thisOrder.orderStatus
          )
          const orderAccepted =
            cleanOrderStatus === 'PREPARING' || cleanOrderStatus === 'READY'
          const orderComplete =
            cleanOrderStatus === 'COMPLETE' ||
            cleanOrderStatus === 'REJECTED' ||
            cleanOrderStatus === 'CANCELLED'
          if (orderAccepted) {
            startPolling(THREE_MINUTES_IN_MILLISECONDS)
          } else if (orderComplete) {
            stopPolling()
          }
          return this.getSingleOrderView(thisOrder)
        }}
      </Query>
    )
  }
}

const SingleOrderView = injectSheet(styles)(SingleOrderViewEl)
export default SingleOrderView
