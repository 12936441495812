import restaurantRowStyles from '../../styles/sharedJSS/restaurantRow'
import colors from '@styles/sharedJSS/colors'
import Color from 'color'

const styles = theme => ({
  ...restaurantRowStyles(theme),

  userAccountColumn: {
    position: 'relative',
    background: 'white',
    boxShadow: '0px 0px 2rem rgba(0, 0, 0, 0.1)',
    borderRadius: '0.4rem 0.4rem 0 0',
    minHeight: '50rem',
    marginBottom: '0.5rem',

    '@media screen and (min-width: 768px)': {
      borderRadius: '0.4rem',
    },

    '@media screen and (min-width: 1024px)': {
      marginBottom: '6rem',
      borderRadius: '0.4rem 0.4rem 0 0',
    },
  },

  subHeader: {
    color: Color(theme.bc3).lighten(0.5).toString(),
    fontSize: '1.2rem',
    fontWeight: 600,
  },

  sideBarLinkActive: {
    extend: 'sideBarLink',
    fontWeight: 800,
    colors: theme.bc6,
    whiteSpace: 'nowrap',
  },
})

export default styles
