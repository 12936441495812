import moment from 'moment'
import React, { Fragment } from 'react'

const ASAPTime = ({ fulfilmentMethod, asapBracket, classes }) => (
  <Fragment>
    <span className={classes.subLblTitle}>{fulfilmentMethod.label} Time:</span>
    <span className={classes.displayedTime}>
      {moment(asapBracket).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'MMMM Do',
        sameElse: 'MMMM Do',
      })}{' '}
      (ASAP)
      {fulfilmentMethod.id.toUpperCase() === 'DELIVERY' ? ' by ' : ' '}
      {moment(asapBracket.end).format('HH:mm')}
    </span>
    <span className={classes.change}>Change?</span>
  </Fragment>
)

export default ASAPTime
