import gql from 'graphql-tag'

const GET_MY_REVIEWS = gql`
  query getMyReviews {
    myReviews {
      reviews {
        id
        createdAt
        foodQuality
        restaurantService
        deliveryTime
        order {
          id
          outlet {
            name
          }
        }
      }
    }
  }
`

export default GET_MY_REVIEWS
