import React, { useContext } from 'react'
import startCase from 'lodash/startCase'
import { Helmet } from 'react-helmet'
import { getMarketplace } from '@config/config'
import { OrderContext } from '@context/Order.context'

const RestaurantMenuHead = ({
  restaurant: { name },
  marketplace = getMarketplace(),
}) => {
  const { deliveryZone } = useContext(OrderContext)
  const { merchantType } = marketplace
  const isRetailMarketplace = merchantType === 'RETAIL'

  const title = `${startCase(name)} Menu${
    deliveryZone && deliveryZone.name
      ? ` | ${deliveryZone.name.replace(/-/g, ' ')} ${
          isRetailMarketplace ? 'Businesses' : 'Takeaways'
        }`
      : ''
  } | Order from ${marketplace.name}`

  return (
    <Helmet>
      <title>{title}</title>

      <meta
        name="description"
        content={`View the full ${
          isRetailMarketplace ? 'product list' : 'menu'
        } from ${startCase(name)} & Order ${
          !isRetailMarketplace && 'your favourite meal '
        }online with ${
          marketplace.name
        }. Order for delivery or collection and pay with cash or card.`}
      />
    </Helmet>
  )
}

export default RestaurantMenuHead
