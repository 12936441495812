export default {
  container: {
    padding: '3rem',
    height: '180px',
    marginTop: '50px',

    '& input': {
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    },

    '@media screen and (max-width: 768px)': {
      height: '100vh',
      marginTop: '70px',
    },
  },
  title: {
    paddingBottom: '2rem',
    fontSize: '24px',
  },
}
