import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'react-jss'
import Modal from '@components/ModalWrapper/ModalWrapper'
import Button from '@components/Button/Button'
import styles from './alert.styles'

const Alert = ({
  classes,
  children,
  question = 'Are you sure?',
  confirm,
  condition = true, // can pass false and user wont be asked to confirm
  cancel,
}) => {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)

  const closeModal = () => setOpen(false)

  const handleWrapperClick = () => {
    if (condition) {
      openModal()
    } else {
      confirm && confirm()
    }
  }

  const onConfirm = () => {
    confirm && confirm()
    closeModal()
  }

  const onCancel = () => {
    cancel && cancel()
    closeModal()
  }

  return (
    <>
      <div onClick={handleWrapperClick}>{children}</div>

      <Modal open={open} close={closeModal} small>
        <div className={classes.container}>
          <div className={classes.question}>{question}</div>
          <div className={classes.buttons}>
            <Button onClick={onConfirm} square small appearance="positive">
              Confirm
            </Button>
            <Button onClick={onCancel} square small appearance="negative">
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

Alert.propTypes = {
  children: PropTypes.node,
  confirm: PropTypes.func.isRequired,
  condition: PropTypes.bool,
  cancel: PropTypes.func,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default withStyles(styles)(Alert)
