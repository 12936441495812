import mean from 'lodash/mean'
import isPlainObject from 'lodash/isPlainObject'

/**
 * The function accepts a single or an array of
 * review partials and it returns the average.
 * The array is of variable length.
 * reviewPartialExample = {
 *  foodQuality: number
 *  restaurantService: number
 *  deliveryTime: number
 * }
 *
 */
export const averageRatings = reviews =>
  isPlainObject(reviews)
    ? averageOneRating(reviews)
    : mean(reviews.map(review => averageOneRating(review)))

/**
 * gql will add a prop of type string
 * with the value of the __typename
 * to the review response object
 * so we need to filter that out
 */
const averageOneRating = review =>
  mean(Object.values(review).filter(prop => Number.isFinite(prop)))
