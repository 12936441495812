const dev = {
  apiUrl: 'http://localhost:3001',
  apiUrlV2: 'http://localhost:3021',
  restApiUrl: 'http://localhost:3000',
}

const staging = {
  apiUrl: process.env.API_SERVICE_GRAPHQL_URL,
  apiUrlV2: process.env.API_SERVICE_GRAPHQL_URL,
  restApiUrl: process.env.REST_API_URL,
}

const prod = {
  apiUrl: process.env.API_SERVICE_GRAPHQL_URL,
  apiUrlV2: process.env.API_SERVICE_GRAPHQL_URL,
  restApiUrl: process.env.REST_API_URL,
  // apiUrl: 'https://redbox.systems',
  // apiUrlV2: 'https://redbox.systems',
  // restApiUrl: 'https://redbox.systems/apigw',
}

const selectConfig = () => {
  switch (process.env.NODE_ENV) {
    case 'staging':
      return staging
    case 'production':
      return prod
    default:
      return dev
  }
}

const config = selectConfig()
const AWS_CDN_URL = process.env.AWS_CDN_URL

let marketplaceConfig = {}

const setMarketplace = marketplace => {
  marketplaceConfig = {
    key: null,
    name: 'Redbox Systems',
    title: 'Marketplace Palatform',
    description: 'Marketplace Palatform',
    metaKeywords: '',
    metaDescription: '',
    brandColor: 'red',
    brandSecondaryColor: 'red',
    brandTertiaryColor: 'red',
    heroImage: null,
    heroText: '',
    heroTextSecondary: '',
    logoImage: null,
    appImage: null,
    appText: '',
    appTextSecondary: '',
    storeURLApple: null,
    storeURLGooglePlay: null,
    socialURLFacebook: null,
    socialURLTwitter: null,
    headerLinks: [],
    footerLinks: [],
    socialURLInstagram: null,
    trackingId: null,
    featureFAQ: false,
    featureRegister: false,
    featureLogin: false,
    featureOrdering: false,
    featureRecruit: false,
    allowOnboarding: false,
    featurePromotions: false,
    defaultSearch: 'POSTCODE',
    merchantType: 'RESTAURANT',
    supportPhone: '',
    supportEmail: '',
    contactAddress: {
      firstLine: '',
      secondLine: '',
      city: '',
      postcode: '',
      country: {
        name: '',
      },
    },
    legalTerms: null,
    legalPrivacy: null,
    legalCookies: null,
    legalPolicy: null,
    legalAllergy: null,
    partnerCharge: 0,
    partnerChargeDescription: 'Service Charge',
    partnerTableCharge: 0,
    partnerTableChargeDescription: 'Service Charge',
    checkoutText: '',
    cashText: '[CASH]',
    cashCollectionText: '[CASH ON COLLECTION]',
    get isPostcodeMode() {
      return marketplace.orderMode === 'POSTCODE'
    },
    get isListMode() {
      return marketplace.orderMode === 'LIST'
    },
    get isSingleMode() {
      return marketplace.orderMode === 'SINGLE'
    },
    ...marketplace,
  }
}

const getMarketplace = () => {
  return marketplaceConfig
}

module.exports = {
  config,
  setMarketplace,
  getMarketplace,
  AWS_CDN_URL,
}
