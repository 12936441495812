import gql from 'graphql-tag'

const UPDATE_USER_DETAILS = gql`
  mutation editMyDetails(
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
  ) {
    editMyDetails(
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
    ) {
      message
      customer {
        id
        firstName
        lastName
        phoneNumber
      }
    }
  }
`

export default UPDATE_USER_DETAILS
