import gql from 'graphql-tag'

export const GET_OUTLET_TABLES = gql`
  query ouletTables($outletId: String!) {
    outlet(id: $outletId) {
      id
      tables {
        id
        friendlyName
      }
    }
  }
`
