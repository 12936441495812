import gql from 'graphql-tag'

const ADD_REVIEW = gql`
  mutation addReview(
    $orderId: ID!
    $foodQuality: Int!
    $restaurantService: Int!
    $deliveryTime: Int!
    $reviewText: String
    $anonymous: Boolean
    $token: String
  ) {
    addReview(
      orderId: $orderId
      foodQuality: $foodQuality
      restaurantService: $restaurantService
      deliveryTime: $deliveryTime
      reviewText: $reviewText
      anonymous: $anonymous
      token: $token
    ) {
      message
      review {
        id
        approved
      }
    }
  }
`

export default ADD_REVIEW
