import React, { useContext } from 'react'
import { object, shape, string, array, number, bool } from 'prop-types'
import { ReactSVG } from 'react-svg'
import { withStyles } from 'react-jss'
import styles from './listItemStyles'
import join from 'lodash/join'
import tagSVG from '@images/tag.svg'
import starSVG from '@images/star.svg'
import { withRouter } from 'react-router-dom'

import _ from 'lodash'
import StarsRating from '@components/Stars/Stars'
import RestaurantStatus from '@components/RestaurantStatus/RestaurantStatus'
import { averageRatings } from '@utils/averageRatings'
import Alert from '@components/Alert/Alert'
import { getFulfilmentMethod, navigateToOutlet } from '@utils/helpers'
import FulfilmentMethods from '@components/FulfilmentMethods/FulfilmentMethods'
import RestaurantStats from '@components/RestaurantStats/RestaurantStats'
import MenuLinkButton from '@components/MenuLinkButton'
import { GTMContext } from '@context/GTM.context'
import { OrderContext } from '@context/Order.context'
import { BasketContext } from '@context/Basket.context'
import { config, getMarketplace } from '@config/config'

const ListItem = props => {
  const gtm = useContext(GTMContext)
  const { allowReviews } = getMarketplace()
  const { attributes, replaceOrder, updateOrder } = useContext(OrderContext)
  const { items, clearItems } = useContext(BasketContext)
  const isTableService = attributes.fulfilmentChosen.id === 'table'

  const { outlet, classes, match, history } = props
  const {
    id,
    paymentMethods = [],
    name,
    isOpen,
    isOnline,
    restaurant,
    reviews,
    preorderStatus,
    closedUntil,
    allowPreorders,
    daysOfferedInAdvanceMax,
    asapAllowed,
  } = outlet
  const outletId = id
  const currentOrderOutletId = attributes.outletId || null
  const orderOutletChanged =
    currentOrderOutletId && currentOrderOutletId !== outletId

  const basketHasItems = !!items.length
  // if user has items in the basket promt the user
  // that they are starting a new order with a new outlet
  const alertCondition = orderOutletChanged && basketHasItems

  const itemClicked = () =>
    navigateToOutlet({
      history,
      match,
      outlet,
      pushDataToGTM: gtm.pushDataToGTM,
      attributes,
      replaceOrder,
      updateOrder,
      items,
      clearItems,
    })

  const discountCount = restaurant.discounts.length > 0
  const discountDisplay =
    restaurant.discounts.length === 1
      ? _.get(restaurant, 'discounts[0].name', '')
      : `${restaurant.discounts.length} Discounts`

  const offersDelivery = getFulfilmentMethod(paymentMethods, 'delivery')
  const offersCollection = getFulfilmentMethod(paymentMethods, 'collection')

  const dealTextByFulfilment = {
    collection: restaurant.collectionDeal,
    delivery: restaurant.deliveryDeal,
    table: restaurant.tableDeal,
  }

  const dealText = dealTextByFulfilment[attributes.fulfilmentChosen.id]

  return (
    <div className={classes.restaurantListItem}>
      {(isOpen || isOnline) && dealText ? (
        <div className={classes.dealsRow}>
          <span className={classes.dealText}>
            <ReactSVG
              src={starSVG}
              className={classes.svgIcon}
              wrapper="span"
            />
            {dealText}
          </span>
        </div>
      ) : null}

      <Alert
        confirm={itemClicked}
        condition={alertCondition}
        question={
          <p>Would you like to start a new order with {restaurant.name}?</p>
        }
      >
        <div className={classes.restaurantContainer}>
          {restaurant.discounts.length && !isTableService ? (
            <div className={classes.offersTag}>OFFERS</div>
          ) : null}

          <div className={classes.restaurantIcon}>
            {restaurant.image && (
              <img
                alt={`${name} menu`}
                title={`${name} menu`}
                src={`${config.apiUrl}/${restaurant.image}`}
                className={classes.restaurantIcon}
              />
            )}
          </div>

          <div className={classes.restaurantInfoContainer}>
            <div className={classes.titleGroup}>
              <h3 className={classes.restaurantTitle}>
                <button type="button">{restaurant.name}</button>
                {allowReviews && (
                  <StarsRating value={averageRatings(reviews)} />
                )}
              </h3>

              <p className={classes.cuisineslbl}>
                {join(
                  restaurant.cuisines.map(cuisine => cuisine.name),
                  ', '
                )}
              </p>

              <div className={classes.lozengeContainer}>
                <RestaurantStatus
                  isOpen={isOpen}
                  isOnline={isOnline}
                  outletId={id}
                  preorderStatus={preorderStatus}
                  closedUntil={closedUntil}
                  allowPreorders={allowPreorders}
                  daysOfferedInAdvanceMax={daysOfferedInAdvanceMax}
                  asapAllowed={asapAllowed}
                />
              </div>

              {/* RED-2097 currently table service does not support discounts */}
              {discountCount > 0 && !isTableService && (
                <span className={classes.discountString}>
                  <ReactSVG
                    src={tagSVG}
                    className={classes.svgIcon}
                    wrapper="span"
                  />
                  {discountDisplay}
                </span>
              )}
            </div>
            <RestaurantStats
              outlet={outlet}
              className={classes.restaurantStats}
            />
            <FulfilmentMethods
              offersCollection={offersCollection}
              offersDelivery={offersDelivery}
              className={classes.fulfilmentMethods}
            />
            <MenuLinkButton
              isOpen={isOpen}
              isOnline={isOnline}
              className={classes.menuLink}
              preorderStatus={preorderStatus}
              allowPreorders={allowPreorders}
              closedUntil={closedUntil}
              daysOfferedInAdvanceMax={daysOfferedInAdvanceMax}
            />
          </div>
        </div>
      </Alert>
    </div>
  )
}

ListItem.propTypes = {
  outlet: shape({
    id: string,
    name: string,
    defaultDeliveryTime: number,
    deliveryMinimumOrderValue: number,
    allowCollection: bool,
    asapAllowed: bool,
    restaurant: shape({
      name: string,
      image: string,
      cuisines: array,
      closedUntil: string,
    }),
  }).isRequired,
  classes: object,
}

export default withStyles(styles)(withRouter(ListItem))
