const styles = {
  loader: {
    position: 'relative',
    textAlign: 'center',
    padding: '6rem',
    background: 'white',
    borderRadius: '0.4rem',
    width: '100%',
    height: '100%',
  },
}

export default styles
