import React from 'react'
import { withStyles } from 'react-jss'
import PropTypes from 'prop-types'
import styles from './fulfilmentStatusStyles'
import cx from 'classnames'

const FulfilmentStatus = ({ classes, paymentMethods }) => {
  const offersDelivery = paymentMethods.some(method =>
    method.toLowerCase().includes('delivery')
  )
  const offersCollection = paymentMethods.some(method =>
    method.toLowerCase().includes('collection')
  )

  const onlyOffersDelivery = offersDelivery && !offersCollection
  const onlyOffersCollection = offersCollection && !offersDelivery

  return onlyOffersDelivery || onlyOffersCollection ? (
    <div className={classes.status}>
      {onlyOffersDelivery && 'Delivery only'}
      {onlyOffersCollection && 'Collection only'}
    </div>
  ) : null
}

export default withStyles(styles)(FulfilmentStatus)
