import React from 'react'
import { boolean, object, string, shape, number, func } from 'prop-types'
import { ReactSVG } from 'react-svg'
import addSVG from '@images/add.svg'
import adultContent from '@images/adult-content.svg'
import { penceToPounds } from '@utils/helpers'
import cx from 'classnames'
import AWSImage from '@components/AWSImage/AWSImage'
import veganImage from '@images/allergies/vegan.png'
import dairyFreeImage from '@images/allergies/dairyfree.png'
import glutenFreeImage from '@images/allergies/glutenfree.png'
import vegitarianImage from '@images/allergies/vegetarian.png'
import { withStyles } from 'react-jss'
import styles from '@components/SingleRestaurant/Menu/MenuItem.styles'

const MenuItem = ({ classes, item, openModal }) => {
  return (
    <div
      className={classes.menuItem}
      onClick={() => {
        if (item.soldOut) return false
        openModal(item)
      }}
    >
      {item.soldOut && <span className={classes.soldOutLozenge}>Sold out</span>}
      <div
        className={cx(classes.menuItemInner, {
          [classes.opacityDown]: item.soldOut,
        })}
      >
        <div>
          <p className={classes.menuItemMainText}>
            {item.name}
            {item.isVegan && (
              <span className={classes.itemInformation} title="Vegan">
                <img
                  src={veganImage}
                  alt="Vegan"
                  title={`${item.name} is vegan`}
                />
              </span>
            )}
            {item.isGlutenFree && (
              <span className={classes.itemInformation} title="Gluten free">
                <img
                  src={glutenFreeImage}
                  alt="Gluten free"
                  title={`${item.name} is gluten free`}
                />
              </span>
            )}
            {item.isVegetarian && !item.isVegan && (
              <span className={classes.itemInformation} title="Vegetarian">
                <img
                  src={vegitarianImage}
                  alt="Vegetarian"
                  title={`${item.name} is vegetarian`}
                />
              </span>
            )}
            {item.isDairyFree && !item.isVegan && (
              <span className={classes.itemInformation} title="Dairy free">
                <img
                  src={dairyFreeImage}
                  alt="Dairy free"
                  title={`${item.name} is dairy free`}
                />
              </span>
            )}
            {item.ageRestricted && (
              <ReactSVG
                src={adultContent}
                className={classes.ageRestricted}
                wrapper="span"
              />
            )}
          </p>
          <p className={classes.menuItemDescriptionText}>{item.description}</p>

          <span className={classes.itemPrice}>
            {`£${penceToPounds(item.price)}`}
            <ReactSVG
              src={addSVG}
              className={classes.svgPriceIcon}
              wrapper="span"
            />
          </span>
        </div>

        {item.image && (
          <div className={classes.menuImageWrapper}>
            <AWSImage
              id="image"
              alt={item.name}
              title={item.name}
              className={classes.menuImage}
              imageKey={item.image}
              edits={{
                resize: {
                  width: 80,
                  height: 80,
                  fit: 'cover',
                },
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

MenuItem.propTypes = {
  classes: object,
  item: shape({
    name: string,
    description: string,
    price: number,
    id: string,
    ageRestricted: boolean,
  }),
  openModal: func,
}

export default withStyles(styles)(MenuItem)
