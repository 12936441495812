import React from 'react'
import { withStyles } from 'react-jss'
import styles from '@components/SingleRestaurant/Menu/SubMenus.styles'
import MenuItems from '@components/SingleRestaurant/Menu/MenuItems'

const SubMenus = ({ classes, subMenus, openModal }) =>
  subMenus.length > 0 ? (
    <div>
      {subMenus.map(sub => {
        return (
          <div className={classes.subMenuWrapper} key={sub.name} id={sub.id}>
            <span className={classes.subMenuTitle}>{sub.name}</span>
            <span className={classes.subMenuDescription}>
              {sub.description}
            </span>

            <MenuItems menuItems={sub.menuItems} openModal={openModal} />
          </div>
        )
      })}
    </div>
  ) : null

export default withStyles(styles)(SubMenus)
