import fontStyles from '@styles/sharedJSS/fonts'
import colors from '@styles/sharedJSS/colors'
import Color from 'color'

const sharedCSS = {
  position: 'relative',
  width: '100%',
  padding: '2.4rem',
  overflow: 'hidden',
  textAlign: 'center',

  '@media screen and (min-width: 768px)': {
    width: '50%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
  },
}

const styles = theme => ({
  mainContainer: {
    display: 'flex',
    flexFlow: 'column',
    marginBottom: '0.5rem',
    borderRadius: '4px',
    overflow: 'hidden',
    marginTop: '0.9rem',

    '@media screen and (min-width: 768px)': {
      flexFlow: 'row',
      minHeight: '45rem',
      margin: '-20px',
    },
  },

  loginContainer: {
    ...sharedCSS,
  },

  registerContainer: {
    ...sharedCSS,
    background: theme.bc3,
    color: 'white',
  },

  registerImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
    filter: 'grayscale(1)',
    opacity: 0.2,
  },

  title: {
    ...fontStyles.h2,
    marginBottom: '2.4rem',
    position: 'relative',
    zIndex: 2,
  },

  excerpt: {
    ...fontStyles.small,
    marginBottom: '2.4rem',
    position: 'relative',
    zIndex: 2,
    opacity: 0.75,
  },

  button: {
    padding: '1.2rem 2.4rem',
    display: 'inline-block',
    fontSize: '1.6rem',
    boxShadow: '0px 0px 5rem rgba(0, 0, 0, 0.1)',
    transition: 'all 0.2s ease-in-out',
    fontWeight: 800,
    borderRadius: '6rem',
    textTransform: 'uppercase',
    position: 'relative',
    zIndex: 2,

    '&:hover': {
      cursor: 'pointer',
    },
  },

  registerButton: {
    backgroundColor: 'white',
    color: theme.bc3,

    '&:hover': {
      backgroundColor: Color('white')
        .rgb()
        .alpha(0.1)
        .string(),
      color: 'white',
    },
  },

  loginButton: {
    backgroundColor: theme.bc3,
    color: 'white',

    '&:hover': {
      backgroundColor: theme.bc2,
    },
  },
})

export default styles
