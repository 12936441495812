import React, { useState, useEffect } from 'react'
import { config } from '@config/config'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import debounce from 'lodash/debounce'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

const CACHE = {}

const toastError = debounce(error => {
  toast.error(error, {
    position: toast.POSITION.TOP_CENTER,
  })
}, 150)

const buildUrl = (path, variables) => {
  const url = new URL(`${config.restApiUrl}/${path}`)
  for (const [key, value] of Object.entries(variables)) {
    const cleanValue = value instanceof Object ? JSON.stringify(value) : value
    url.searchParams.append(key, cleanValue)
  }

  return url
}

const FetchQuery = ({
  children,
  path,
  variables = {},
  handleLoading = false,
  onCompleted = () => {},
  onError = () => {},
}) => {
  const url = buildUrl(path, variables)
  const cachedData = CACHE[url.toString()]
  const [data, setData] = useState(cachedData ? cachedData : null)
  const [loading, setLoading] = useState(!cachedData)
  const [error, setError] = useState(false)

  const storeData = (url, data) => {
    CACHE[url.toString()] = data
    setData(data)
  }

  const fetchData = async () => {
    setError(false)
    if (!cachedData) {
      setLoading(true)
    }

    try {
      const request = await fetch(url)
      const requestData = await request.json()
      onCompleted(requestData.data)
      storeData(url, requestData.data)
    } catch (e) {
      onError(e)
      setError(e)
      setData(null)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (handleLoading && loading) {
    return (
      <div style={{ position: 'relative', height: '100%', minHeight: '6rem' }}>
        <LoadingSpinner show />
      </div>
    )
  }

  if (error) {
    toastError(error.message)
  }

  return children({ loading, error, data })
}

FetchQuery.propTypes = {
  children: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  variables: PropTypes.object,
  handleLoading: PropTypes.bool,
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
}

export default FetchQuery
