// Imports
// ------
import React from 'react'
import injectSheet from 'react-jss'
import styles from '../GeneralContent/generalContentStyles'
import Header from '@components/Header/Header'
import Hero from '../GeneralContent/Hero/Hero'
import Heading from '../GeneralContent/Content/Heading/Heading'
import Copy from '../GeneralContent/Content/Copy/Copy'
import { List, ListItem } from '../GeneralContent/Content/List/List'
import Button from '../GeneralContent/Content/Button/Button'
import { getMarketplace } from '@config/config'
import GET_LEGAL from './queries/getLegal.query'
import Query from '@components/Query/Query'
import Content from '../GeneralContent/Content/Markdown'

// Component
// ------
const CookiesEl = () => (
  <>
    <Header branded />
    <Hero title="Cookie Policy" />
    <Query query={GET_LEGAL} variables={{ key: getMarketplace().key }}>
      {({ marketplace: { marketplace } }) => (
        <div className="wrapper">
          <Content>{marketplace.legalCookies}</Content>
        </div>
      )}
    </Query>
  </>
)

const Cookies = injectSheet(styles)(CookiesEl)
export default Cookies
