import fontStyles from '@styles/sharedJSS/fonts'

const styles = theme => ({
  form: {
    maxWidth: 'calc(100vw - 2rem)',
    margin: '0 auto',
    display: 'flex',
    borderRadius: '5px',
    overflow: 'hidden',
  },

  findNearMeInput: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 'calc(100vw - 2rem)',
    margin: '0 auto',
    borderRadius: '5px',
    overflow: 'hidden',
  },

  input: {
    ...fontStyles.small,
    backgroundColor: '#f3f2f2',
    boxShadow: 'none',
    padding: '0 1.2rem',
    fontSize: '1.6rem',
    border: 'none',
    borderRadius: 0,
    width: '100%',
    height: '4.5rem',
    color: theme.bc5,

    '&:focus': {
      outline: 'none',
    },
  },

  inputLabel: {
    marginTop: '14px',
    marginLeft: '2px',
  },

  findNearMe: {
    ...fontStyles.small,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    alignItems: 'center',
    boxShadow: 'none',
    padding: '5px 1.2rem',
    fontSize: '1.6rem',
    border: 'none',
    width: '100%',
    height: '6rem',
    margin: '5px',
    color: theme.bc5,
    paddingTop: '10px',
    borderRadius: '5px',
  },

  buttonLrg: { height: '6rem', marginTop: '5px' },

  svg: {
    display: 'flex',
    alignSelf: 'center',
    padding: '0px 5px',
  },

  label: {
    position: 'absolute',
    left: '-90000px',
    opacity: 0,
  },
})

export default styles
