import gql from 'graphql-tag'

const outlet = `
      id
      name
      isOpen
      isOnline
      closedUntil
      openingTimes
      defaultDeliveryTime
      defaultCollectionTime
      allowCollection
      collectionCharge
      deliveryMinimumOrderValue
      collectionMinimumOrderValue
      paymentMethods
      outletPhone
      availableFulfillmentMethods
      distanceFromUserKM
      noPreordersBeforeOpening
      allowPreorders
      asapAllowed
      nextOpeningTime
      nextOpenDate
      openingTimeString
      preorderStatus
      daysOfferedInAdvanceMin
      daysOfferedInAdvanceMax
      restaurant {
        id
        image
        name
        deliveryOptions
        promoted
        deals
        dealsText
        collectionDeal
        deliveryDeal
        tableDeal
        cuisines {
          name
          id
        }
        discounts {
          id
          name
        }
      }
`

export const GET_OUTLETS_FOR_POSTCODE = gql`
  query outletsForPostcode(
    $marketplaceId: String
    $postAndCountryCode: PostAndCountryCodeInput!
    $cuisineIds: [String!]
  ) {
    outletsForPostcode(
      marketplaceId: $marketplaceId
      postAndCountryCode: $postAndCountryCode
      cuisineIds: $cuisineIds
    ) {
      ${outlet}
    }
  }
`

export const GET_OUTLETS_FOR_DELIVERY_ZONE = gql`
  query outletsForDeliveryZone(
    $deliveryZoneId: String!
    $cuisineIds: [String!]
  ) {
    outletsForDeliveryZone(
      deliveryZoneId: $deliveryZoneId
      cuisineIds: $cuisineIds
    ) {
      ${outlet}
    }
  }
`

export const GET_COLLECTION_OUTLETS_FOR_POSTCODE = gql`
query outletsOfferingCollection(
  $marketplaceId: String
  $marketplaceKey: String
  $postAndCountryCode: PostAndCountryCodeInput
  $cuisineIds: [String!]
  $coordinates: CoordinatesInput
) {
  outletsOfferingCollection(
    marketplaceId: $marketplaceId
    marketplaceKey: $marketplaceKey
    postAndCountryCode: $postAndCountryCode
    cuisineIds: $cuisineIds
    coordinates: $coordinates
  ) {
    ${outlet}
    collectionFulfilmentTimes: fulfilmentTimes(fulfilmentMethod: COLLECTION) {
      start
      end
    }
  }
}
`

export const GET_OUTLETS_FOR_COORDINATES = gql`
query outletsForCoordinates(
  $marketplaceId: String
  $marketplaceKey: String
  $coordinates: CoordinatesInput!
  $cuisineIds: [String!]
) {
  outletsForCoordinates(
    marketplaceId: $marketplaceId
    marketplaceKey: $marketplaceKey
    coordinates: $coordinates
    cuisineIds: $cuisineIds
  ) {
    ${outlet}
  }
}`
