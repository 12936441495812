import React from 'react'
import { withStyles } from 'react-jss'
import styles from './loginPromptStyles'
import cx from 'classnames'
import { client } from '@services/client'

import registerBackground from '../../../images/hero-image.jpg'

class LoginPromptEl extends React.Component {
  loadLoginModal = e => {
    e.preventDefault()
    client.writeData({
      data: {
        loginOpen: true,
      },
    })
  }

  loadRegisterModal = e => {
    e.preventDefault()
    client.writeData({
      data: {
        registerOpen: true,
      },
    })
  }

  render() {
    const {
      mainContainer,
      loginContainer,
      registerContainer,
      registerImage,
      title,
      excerpt,
      button,
      registerButton,
      loginButton,
    } = this.props.classes

    const registerExcerpt =
      'Register now to create an account & place your order'
    const loginExcerpt = 'Login now to your account to place your order'

    return (
      <main className={mainContainer}>
        {/* Register */}
        <section className={registerContainer}>
          <img
            src={registerBackground}
            className={registerImage}
            alt="Background"
          />
          <h2 className={title}>Register</h2>
          <p className={excerpt}>{registerExcerpt}</p>
          <button
            type="button"
            className={cx(button, registerButton)}
            onClick={this.loadRegisterModal}
          >
            Register Now
          </button>
        </section>

        {/* Login */}
        <section className={loginContainer}>
          <h2 className={title}>Login</h2>
          <p className={excerpt}>{loginExcerpt}</p>
          <button
            type="button"
            className={cx(button, loginButton)}
            onClick={this.loadLoginModal}
          >
            Login Now
          </button>
        </section>
      </main>
    )
  }
}

const LoginPrompt = withStyles(styles)(LoginPromptEl)
export default LoginPrompt
