const styles = theme => ({
  footer: {
    backgroundColor: theme.bc5,
    minHeight: '20rem',
    paddingTop: '6rem',
    paddingBottom: '4rem',
    textAlign: 'center',

    '@media screen and (min-width: 768px)': {
      paddingTop: '12rem',
      paddingBottom: '10rem',
      textAlign: 'left',
      marginTop: '7rem',
    },

    '@media print': {
      display: 'none',
    },
  },

  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignContent: 'center',
    flexWrap: 'wrap',

    '@media screen and (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  footerItem: {
    '@media screen and (max-width: 768px)': {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  socialMediaIcons: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '5px',
    marginTop: '-10px',

    '@media screen and (max-width: 768px)': {
      justifyContent: 'center',
      marginLeft: '0px',
    },
  },

  appleStoreLogo: {
    width: '145px',
    height: '50px',
    marginTop: '-15px',
    '@media screen and (max-width: 768px)': {
      marginLeft: '-6px',
    },
  },

  // If footer is on the homepage
  noMargin: {
    marginTop: 0,
  },

  title: {
    color: 'white',
    fontSize: '2rem',
    fontWeight: 600,
    marginBottom: '20px',

    '@media screen and (min-width: 1200px)': {
      marginBottom: '3.6rem',
    },
  },

  footerList: {
    marginBottom: '3.6rem',

    '@media screen and (min-width: 1200px)': {
      marginBottom: '0',
    },

    '& a': {
      color: 'white',
      opacity: 0.5,
      display: 'block',
      padding: '1rem 0',
      fontSize: '15px',
      fontWeight: 600,
      transition: 'all .25s ease-in-out',

      '&:hover': {
        opacity: 1,
      },
    },
  },

  address: {
    color: 'white',
    opacity: 0.5,
    lineHeight: '2.4rem',
    fontSize: '1.5rem',
    marginBottom: '3.6rem',

    '& strong': {
      fontWeight: 600,
      display: 'block',
      fontSize: ' 110%',
      paddingBottom: '4px',
    },
  },

  socialIcon: {
    marginRight: '12px',
    opacity: '0.5',
    padding: '5px',

    '&:last-child': {
      marginRight: '0px',
    },
  },
})

export default styles
