import React, { useContext, useEffect } from 'react'
import TotalRow from './TotalRow'
import get from 'lodash/get'
import {
  GET_DELIVERY_ESTIMATE_FOR_POSTCODE,
  GET_DELIVERY_ESTIMATE_FOR_ZONE,
  GET_DELIVERY_ESTIMATE_FROM_CUSTOMER_COORDINATES,
} from './queries/getDeliveryEstimate.query'
import { GET_DISCOUNTS } from './queries/getDiscounts.query'
import { OrderContext } from '@context/Order.context'
import { getMarketplace } from '@config/config'
import { withStyles } from 'react-jss'
import { Query } from 'react-apollo'
import { BasketContext } from '@context/Basket.context'
import { withRouter } from 'react-router-dom'
import { DeliveryCostToAddressContext } from '@context/DeliveryCostToAddress.context'
import { VoucherContext } from '@context/Voucher.context'
import { getDiscountApplied } from './utils/getDiscountApplied'
import { DiscountContext } from '@context/Discount.context'

const Totals = ({ classes, ...props }) => {
  const {
    partnerCharge,
    partnerChargeDescription,
    partnerTableCharge,
    partnerTableChargeDescription,
  } = getMarketplace()

  const { attributes } = useContext(OrderContext)
  const { fulfilmentChosen } = attributes
  const {
    fulfilmentCost,
    discountApplied,
    fulfilmentAvailable,
    deliveryFrom,
    subTotal,
    costOfAddOnItems,
    restaurantPartnerTableCharge,
    restaurantPartnerCharge,
  } = props

  const fulfilmentLabel =
    fulfilmentChosen && fulfilmentChosen.label ? fulfilmentChosen.label : '--'

  const isTableService = fulfilmentChosen.id === 'table'
  const showFulfilmentCost = !isTableService && fulfilmentCost > 0

  let serviceCharge =
    restaurantPartnerCharge !== null ? restaurantPartnerCharge : partnerCharge
  if (isTableService) {
    serviceCharge =
      restaurantPartnerTableCharge !== null
        ? restaurantPartnerTableCharge
        : partnerTableCharge
  }

  // used if the fixed discount is bigger than the minimum subtotal set on the discount
  const zeroedDiscount = discountApplied.discount > subTotal ? 0 - subTotal : 0

  // include add-on items separately so that they are not discounted
  // and a customer cannot navigate away and back to basket to get a further discount
  // on add on items
  const newTotal =
    discountApplied.discount > subTotal
      ? subTotal +
        serviceCharge +
        fulfilmentCost +
        zeroedDiscount +
        costOfAddOnItems
      : subTotal +
        serviceCharge +
        fulfilmentCost -
        discountApplied.discount +
        costOfAddOnItems

  return (
    <div className={classes.container}>
      <TotalRow
        row={{
          label: 'Subtotal',
          cost: subTotal + costOfAddOnItems,
        }}
      />
      {!!discountApplied.discount && (
        <TotalRow
          row={{
            label: discountApplied.name,
            cost: Math.abs(zeroedDiscount) || discountApplied.discount,
            discount: true,
          }}
        />
      )}
      {showFulfilmentCost && (
        <TotalRow
          row={{
            label: `${fulfilmentLabel}${deliveryFrom ? ' from' : ''}`,
            cost: fulfilmentCost,
            loading: false,
            free: fulfilmentCost === 0,
            unavailable: !fulfilmentAvailable,
          }}
        />
      )}
      {serviceCharge > 0 && (
        <TotalRow
          row={{
            label: isTableService
              ? partnerTableChargeDescription
              : partnerChargeDescription,
            cost: serviceCharge,
          }}
        />
      )}
      <br />
      <TotalRow
        row={{
          label: 'Total',
          cost: newTotal,
          large: true,
        }}
      />
    </div>
  )
}

const TotalsWithNoDeliveryCostEstimates = ({ ...props }) => (
  <Totals
    fulfilmentCost={null}
    fulfilmentAvailable={false}
    deliveryFrom={false}
    {...props}
  />
)

const TotalsEstimateDataWrapper = ({
  estimate,
  discountApplied,
  subTotal,
  costOfAddOnItems,
  ...props
}) => (
  <Totals
    fulfilmentCost={estimate.cost}
    discountApplied={discountApplied}
    fulfilmentAvailable={true}
    deliveryFrom={estimate.type === 'NETWORK_FALLBACK'}
    subTotal={subTotal}
    costOfAddOnItems={costOfAddOnItems}
    {...props}
  />
)

const ZoneTotals = ({ outletId, deliveryZoneId, ...props }) => (
  <Query
    query={GET_DELIVERY_ESTIMATE_FOR_ZONE}
    variables={{
      outletId,
      deliveryZoneId,
    }}
    context={{ version: 2 }}
  >
    {({ loading, data, error }) => {
      if (loading || error) {
        return <TotalsWithNoDeliveryCostEstimates {...props} />
      }

      const { deliveryEstimateForZone } = data
      return (
        <TotalsEstimateDataWrapper
          estimate={deliveryEstimateForZone}
          {...props}
        />
      )
    }}
  </Query>
)

const AddressTotals = ({ ...props }) => (
  <DeliveryCostToAddressContext.Consumer>
    {({ loading, data, error }) => {
      if (loading || error || Object.keys(data).length === 0) {
        return <TotalsWithNoDeliveryCostEstimates {...props} />
      }
      const { deliveryEstimateForAddress } = data
      return (
        <TotalsEstimateDataWrapper
          estimate={deliveryEstimateForAddress}
          {...props}
        />
      )
    }}
  </DeliveryCostToAddressContext.Consumer>
)

const DeliveryPostcodeOrCoordinatesTotals = ({
  outletId,
  deliveryDestination,
  ...props
}) => {
  const isCoordinatesMode = deliveryDestination.type === 'COORDINATES'
  const { destination } = deliveryDestination

  if (!destination) {
    return <TotalsWithNoDeliveryCostEstimates {...props} />
  }

  return isCoordinatesMode ? (
    <Query
      query={GET_DELIVERY_ESTIMATE_FROM_CUSTOMER_COORDINATES}
      variables={{
        outletIds: [outletId],
        latitude: destination.lat,
        longitude: destination.lng,
      }}
      context={{ version: 1 }}
    >
      {({ loading, data, error = null }) => {
        if (loading || error) {
          return <TotalsWithNoDeliveryCostEstimates {...props} />
        }

        // TODO - refactor for 6.1 and sync the resolver response
        // with the expected obj from TotalsEstimateDataWrapper
        const estimate = {
          cost: get(
            data,
            'deliveryEstimatesFromCustomerCoordinates[0].estimatedCost',
            null
          ),
        }

        if (!estimate.cost) {
          return <TotalsWithNoDeliveryCostEstimates {...props} />
        }

        return <TotalsEstimateDataWrapper estimate={estimate} {...props} />
      }}
    </Query>
  ) : (
    <Query
      query={GET_DELIVERY_ESTIMATE_FOR_POSTCODE}
      variables={{
        outletId,
        postcode: destination,
      }}
      context={{ version: 2 }}
    >
      {({ loading, data, error = null }) => {
        if (loading || error) {
          return <TotalsWithNoDeliveryCostEstimates {...props} />
        }
        const deliveryEstimate = data.deliveryEstimateForPostcode
        return (
          <TotalsEstimateDataWrapper estimate={deliveryEstimate} {...props} />
        )
      }}
    </Query>
  )
}

const TotalsDataProviderInner = ({
  outlet,
  customerDiscountForBusiness,
  outletId,
  collectionCharge,
  ...props
}) => {
  const { attributes } = useContext(OrderContext)
  const { subTotal, costOfAddOnItems } = useContext(BasketContext)
  const { discount: voucherDiscount } = useContext(VoucherContext)
  const { setAppliedDiscountId } = useContext(DiscountContext)
  const { isPostcodeMode } = getMarketplace()

  const isTableService =
    attributes.fulfilmentChosen.id.toUpperCase() === 'TABLE'
  const isCollection =
    attributes.fulfilmentChosen.id.toUpperCase() === 'COLLECTION'

  const { deliveryDestination = { type: 'POSTCODE', destination: '' } } =
    attributes

  let discounts = get(outlet, 'restaurant.discounts', [])
  if (customerDiscountForBusiness) {
    discounts.push(customerDiscountForBusiness)
  }

  if (voucherDiscount) {
    // we remove previous discounts, regardless of which one is better
    // if the minimum to spend is met (this can happen when the basket is edited after applying the voucher)
    if (voucherDiscount.minimumSubtotalGross < subTotal) {
      discounts = [voucherDiscount]
    }
  }

  //RED-2097 curently table service does not support discounts
  const discountApplied = isTableService
    ? { name: '', discount: 0 }
    : getDiscountApplied(subTotal, discounts)

  useEffect(() => {
    setAppliedDiscountId(discountApplied.id)
  }, [discountApplied.id])

  // table-service - no costs
  if (isTableService) {
    return (
      <Totals
        fulfilmentCost={0}
        discountApplied={discountApplied}
        fulfilmentAvailable={true}
        deliveryFrom={false}
        subTotal={subTotal}
        costOfAddOnItems={costOfAddOnItems}
        {...props}
      />
    )
  }
  // collection
  if (isCollection) {
    return (
      <Totals
        fulfilmentCost={collectionCharge || 0}
        discountApplied={discountApplied}
        fulfilmentAvailable={true}
        deliveryFrom={false}
        subTotal={subTotal}
        costOfAddOnItems={costOfAddOnItems}
        {...props}
      />
    )
  }
  // fixed delivery zone
  if (
    !isPostcodeMode &&
    attributes.deliveryZone &&
    attributes.deliveryZone.id
  ) {
    return (
      <ZoneTotals
        outletId={outletId}
        deliveryZoneId={attributes.deliveryZone.id}
        discountApplied={discountApplied}
        subTotal={subTotal}
        costOfAddOnItems={costOfAddOnItems}
        {...props}
      />
    )
  }
  const isCheckout = props.match.path.includes(':restaurant-checkout')
  // address based delivery costs
  if (attributes.address && isCheckout) {
    return (
      <AddressTotals
        discountApplied={discountApplied}
        subTotal={subTotal}
        costOfAddOnItems={costOfAddOnItems}
        {...props}
      />
    )
  }

  // delivery address unknown - get estimate from postcode
  if (
    isPostcodeMode &&
    ['POSTCODE', 'COORDINATES'].includes(deliveryDestination.type)
  ) {
    return (
      <DeliveryPostcodeOrCoordinatesTotals
        outletId={outletId}
        deliveryDestination={deliveryDestination}
        discountApplied={discountApplied}
        subTotal={subTotal}
        costOfAddOnItems={costOfAddOnItems}
        {...props}
      />
    )
  }
  return false
}

const TotalsDataProvider = ({ outletId, collectionCharge, ...props }) => {
  return (
    <Query
      query={GET_DISCOUNTS}
      variables={{ id: outletId }}
      context={{ version: 2 }}
    >
      {({ data, loading, error = null }) => {
        if (!data || loading || error) {
          return null
        }
        return (
          <TotalsDataProviderInner
            outlet={data.outlet}
            customerDiscountForBusiness={data.customerDiscountForBusiness}
            outletId={outletId}
            collectionCharge={collectionCharge}
            {...props}
          />
        )
      }}
    </Query>
  )
}

const styles = {
  container: {
    padding: '13px 15px 0 15px',
    borderTop: '1px solid #d0dbe1',
  },
}

export default withStyles(styles)(withRouter(TotalsDataProvider))
