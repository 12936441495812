const styles = theme => ({
  container: {
    minWidth: '50%',
    flexShrink: 0,
    textAlign: 'left',
    fontSize: '1.5rem',
    lineHeight: '22px',
    marginRight: '1rem',
  },
  day: {
    color: theme.bc5,
    opacity: 0.6,
  },
  time: {
    color: theme.bc5,
    fontWeight: 600,
  },
})

export default styles
