import fontStyles from '@styles/sharedJSS/fonts'
import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  LAPTOP_BREAKPOINT,
} from '@utils/breakpoints'

const styles = theme => ({
  '@keyframes spanTop': {
    '0%': {
      top: '0.5rem',
      transform: 'translateX(-50%)',
    },

    '49%': {
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },

    '50%': {
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },

    '100%': {
      top: '50%',
      transform: 'translate(-50%, -50%) rotate(45deg)',
    },
  },

  '@keyframes spanMiddle': {
    '0%': {
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },

    '49%': {
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },

    '50%': {
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },

    '100%': {
      top: '50%',
      transform: 'translate(-50%, -50%) rotate(45deg)',
    },
  },

  '@keyframes spanBottom': {
    '0%': {
      bottom: '0.5rem',
      transform: 'translateX(-50%)',
    },

    '49%': {
      bottom: '50%',
      transform: 'translate(-50%, 50%)',
    },

    '50%': {
      bottom: '50%',
      transform: 'translate(-50%, 50%)',
    },

    '100%': {
      bottom: '50%',
      transform: 'translate(-50%, 50%) rotate(-45deg)',
    },
  },

  header: {
    position: 'fixed',
    zIndex: 1200,
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    padding: '1.5rem 1rem',
    display: 'flex',
    alignItems: 'center',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      position: 'relative',
    },

    [`@media screen and (min-width: ${LAPTOP_BREAKPOINT}px)`]: {
      padding: '2.4rem 0',
    },
  },

  headerBackground: {
    backgroundColor: theme.bc3,

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      backgroundColor: 'transparent!important;',
      boxShadow: 'none',
    },
  },

  brandedHeader: {},

  logo: {
    display: 'inline-block',
    width: 'inherit',
    marginRight: 'auto',

    '& img': {
      transition: 'all .25s ease-in-out',
      height: 'auto',
      maxHeight: '39px',
    },

    '@media print': {
      display: 'none',
    },

    '&:hover > img': {
      [`@media screen and (min-width: ${TABLET_BREAKPOINT}px)`]: {
        opacity: 0.5,
      },
    },
  },

  navToggle: {
    float: 'right',
    width: '4rem',
    height: '3rem',
    display: 'inline-block',
    zIndex: 9999999999,
    marginLeft: '1.2rem',
    position: 'relative',
    background: 'transparent',

    '@media print': {
      display: 'none',
    },

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      display: 'none',
    },

    // All Spans
    '& span': {
      position: 'absolute',
      display: 'inline-block',
      width: '3rem',
      height: '2px',
      background: 'white',

      '&:first-child': {
        top: '0.5rem',
        left: '50%',
        transform: 'translateX(-50%)',
      },

      '&:nth-child(2)': {
        top: '50%',
        transform: 'translate(-50%, -50%)',
      },

      '&:last-child': {
        bottom: '0.5rem',
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },
  },

  // When Nav Toggle is Open
  navToggleIsOpen: {
    transform: 'rotate(180deg)',
    transitionDelay: '.25s',

    '& span': {
      '&:first-child': {
        animation: '1s spanTop cubic-bezier(0.8,0,0.2,1) both',
      },

      '&:nth-child(2)': {
        animation: '1s spanMiddle cubic-bezier(0.8,0,0.2,1) both',
      },

      '&:last-child': {
        animation: '1s spanBottom cubic-bezier(0.8,0,0.2,1) both',
      },
    },
  },

  nav: {
    position: 'fixed',
    overflow: 'hidden',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: '100%',
    background: theme.bc3,
    transition: 'bottom 0.5s cubic-bezier(0.8,0,0.2,1)',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      flexShrink: 0,
      background: 'none',
      position: 'initial',
      overflow: 'initial',
    },
  },

  navMenuOpen: {
    bottom: 0,

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      bottom: 'auto',
    },
  },

  navLoggedIn: {},

  navList: {
    listStyle: 'none',
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      position: 'initial',
      transform: 'none',
      width: 'auto',
    },
  },

  navListItem: {
    display: 'block',
    margin: '1.2rem 0',
    textAlign: 'center',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      display: 'inline-block',
      margin: 0,
    },

    '& a': {
      ...fontStyles.h3,
      color: 'white',
      display: 'inline-block',
      textTransform: 'uppercase',
      padding: '1.2rem',
      margin: '0 auto',

      [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
        ...fontStyles.small,
        textTransform: 'none',
        marginRight: '2.4rem',
        transition: 'opacity .25s ease-in-out',

        '&:hover': {
          opacity: 0.5,
        },
      },
    },
  },

  myAccount: {
    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      marginLeft: '1.2rem',

      '&:hover': {
        opacity: 1,
      },

      '& a': {
        border: `2px solid white`,
        borderRadius: '2.4rem',
        transition: 'all .25s ease-in-out',

        '&:hover': {
          color: theme.bc3,
          background: 'white',
          opacity: 1,
        },
      },
    },
  },

  loginRegisterContainer: {
    marginTop: '4.8rem',
    padding: '4.8rem',
    zIndex: 2,
    width: '100%',
    borderTop: `2px solid rgba(255,255,255,0.1)`,

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      border: 'none',
      padding: 0,
      margin: 0,
      width: 'auto',
      display: 'inline-block',
    },

    '& button': {
      ...fontStyles.h3,
      color: 'white',
      display: 'block',
      textTransform: 'uppercase',
      textAlign: 'center',
      padding: ' 1.2rem',
      margin: '0 auto',

      [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
        ...fontStyles.small,
        textTransform: 'none',
        display: 'inline-block',
        margin: '0',
        border: `2px solid white`,
        borderRadius: '0.4rem',
        transition: '.25s ease-in-out',
        transitionProperty: 'color, background',
        width: '10rem',

        '&:hover': {
          color: theme.bc3,
          background: 'white',
        },
      },
    },
  },

  login: {
    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      marginRight: '0 !important',
      borderRadius: '12rem 0 0 12rem !important',
    },
  },

  register: {
    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      marginLeft: '0 !important',
      borderRadius: '0 12rem 12rem 0 !important',
      borderLeft: 'none !important',
    },
  },

  basketIcon: {
    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      marginLeft: '1.2rem',
    },
  },
})

export default styles
