import gql from 'graphql-tag'

const GET_OUTLET_DETAILS = gql`
  query detailsForOutlet($id: String!, $showInactive: Boolean) {
    outlet(id: $id, showInactive: $showInactive) {
      id
      openingTimes
      deliveryMinimumOrderValue
      collectionMinimumOrderValue
      outletAddress {
        firstLine
        secondLine
        city
        postcode
      }
      restaurant {
        id
        description
      }
    }
  }
`

export default GET_OUTLET_DETAILS
