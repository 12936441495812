import checkoutFormStyles from '../../checkoutFormStyles'

const styles = theme => ({
  ...checkoutFormStyles(theme),
  titleArea: {
    padding: '15px 12px 0',
    position: 'relative',
    lineHeight: '20px',

    '& h2': {
      color: '#444444',
      fontSize: '17px',
      fontWeight: 500,
    },

    '& p': {
      color: '#636363',
      fontSize: '13px',
      fontWeight: 400,
    },
  },

  inputFieldWithPadding: {
    ...checkoutFormStyles(theme).inputField,
    paddingLeft: '45px',
  },

  formFooter: {
    marginTop: '8px',
    textAlign: 'center',
    borderTop: '1px dotted #e0e8ea',
    marginBottom: '20px',
    padding: '0 20px 20px 20px',
    '@media screen and (min-width: 768px)': {
      padding: '0',
    },
  },

  cardForm: {
    padding: '5px 15px',
  },
  securePara: {
    padding: '15px',
    fontSize: '12px',
    color: '#667882',
    fontWeight: 500,
    lineHeight: '1.5rem',
  },
  gap: {
    paddingLeft: '15px',
  },
  svgIcon: {
    display: 'block',
    marginBottom: '-34px',
    marginTop: '8px',
    marginLeft: '9px',

    '& svg': {
      width: '25px',
      height: '25px',
      strokeWidth: '4px',
      color: '#4d90b7',
    },
  },
})

export default styles
