import { MOBILE_BREAKPOINT, LAPTOP_BREAKPOINT } from '@utils/breakpoints'
import fontStyles from '@styles/sharedJSS/fonts'
import colors from '@styles/sharedJSS/colors'

const sharedStyles = {
  position: 'relative',
  padding: '0 1rem',

  [`@media screen and (min-width: ${LAPTOP_BREAKPOINT}px)`]: {
    padding: 0,
  },
}

const styles = theme => ({
  sectionTitle: {
    ...fontStyles.h2,
    color: theme.bc5,
    marginBottom: '3.6rem',
  },

  faqsContainer: {
    ...sharedStyles,
    marginBottom: '6rem',
  },

  contactContainer: {
    ...sharedStyles,
    paddingBottom: '3.6rem',
  },

  faqTitle: {
    color: theme.bc3,
    fontSize: '1.8rem',
    fontWeight: 600,
    lineHeight: '2.2rem',
  },

  faqContent: {
    color: '#2f2f2f',
    fontSize: '1.3rem',
    marginTop: '0.5rem',
    marginBottom: '2.4rem',
    lineHeight: '1.8rem',
  },

  contactUsPara: {
    color: '#2f2f2f',
    fontSize: '1.5rem',
    marginTop: '1.0rem',
    marginBottom: '2.4rem',
    lineHeight: '2rem',
  },
  emailLink: {
    color: theme.bc1,
  },
})

export default styles
