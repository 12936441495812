import gql from 'graphql-tag'

const GET_OUTLET_INFO = gql`
  query outletInfo($outletId: String!) {
    outlet(id: $outletId) {
      id
      name
      collectionCharge
      isOpen
      isOnline
      collectionMinimumOrderValue
      deliveryMinimumOrderValue
      outletPhone
      availableFulfillmentMethods
      noPreordersBeforeOpening
      allowPreorders
      openingTimes
      closedUntil
      nextOpeningTime
      defaultDeliveryTime
      defaultCollectionTime
      daysOfferedInAdvanceMin
      daysOfferedInAdvanceMax
      partnerCharge
      partnerTableCharge
      preorderStatus
      restaurant {
        id
        name
        enableAllergyInformation
      }
      tableFulfilmentTimes: fulfilmentTimes(fulfilmentMethod: TABLE) {
        start
        end
      }
      collectionFulfilmentTimes: fulfilmentTimes(fulfilmentMethod: COLLECTION) {
        start
        end
      }
      deliveryFulfilmentTimes: fulfilmentTimes(fulfilmentMethod: DELIVERY) {
        start
        end
        limit
      }
      fulfilmentTimeTypes
      availableFulfilmentTimeTypes(fulfilmentMethod: DELIVERY)
      asapAllowed
    }
  }
`

export default GET_OUTLET_INFO
