import _ from 'lodash'

class Ls {
  constructor() {
    this.listeners = []
  }

  set = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data))
    _.each(this.listeners, listener => {
      if (listener.key === key) listener.update()
    })
    return true
  }

  get = id => {
    const data = localStorage.getItem(id)

    try {
      return data ? JSON.parse(data) : ''
    } catch (e) {
      return id === 'jwt' ? data : ''
    }
  }

  remove = id => {
    localStorage.removeItem(id)
    _.each(this.listeners, listener => {
      if (listener.key === id) listener.update()
    })
    return true
  }

  pushListener = listener => {
    this.listeners.push(listener)
  }
  popListener = listenerKey => {
    _.remove(this.listeners, listener => {
      return listener.key === listenerKey
    })
  }
}

const ls = new Ls()

export default ls
