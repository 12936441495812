import gql from 'graphql-tag'
import React, { Component } from 'react'
import { withStyles } from 'react-jss'
import styles from './addAddressPopoverStyles'
import _ from 'lodash'
import cx from 'classnames'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import { ADD_USER_ADDRESS } from '../../queries/getUserDetails.query'
import { Query as CacheQuery, Mutation } from 'react-apollo'
import { toast } from 'react-toastify'
import Button from '@components/Button/Button'
import { GTMContext } from '@context/GTM.context'

class AddAddressPopoverEl extends Component {
  static contextType = GTMContext
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      address1: {
        label: 'House Number',
        error: false,
        value: '',
        placeholder: 'House Number',
        className: 'col-xs-12',
      },
      address2: {
        label: 'Street Name & Area',
        error: false,
        value: '',
        placeholder: 'Street Name & Area',
        className: 'col-xs-12',
      },
      towncity: {
        label: 'Town / City',
        error: false,
        value: '',
        placeholder: 'Town or City',
        className: 'col-xs-12',
      },
      postcode: {
        label: 'Postcode',
        error: false,
        value: '',
        placeholder: 'Postcode',
        className: 'col-xs-6',
      },
      country: null,
    }
  }

  closeLogic = e => {
    e.preventDefault()
    if (e.target.id === 'overlay') return this.props.close()
  }

  handleFieldChange = (e, field) => {
    let lastState = this.state[field]
    let stateUpdate = {
      [field]: {
        ...lastState,
        value:
          field === 'postcode' ? e.target.value.toUpperCase() : e.target.value,
      },
    }
    this.setState(stateUpdate)
  }

  validPostcode = postcode => {
    postcode = postcode.replace(/\s/g, '')
    // var regex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i
    // return regex.test(postcode)
    return true
  }

  saveAddress = (e, saveAddress, postCodeRegex) => {
    e.preventDefault()

    const { address1, address2, towncity, postcode } = this.state
    const isPostCodeValid = new RegExp(postCodeRegex).test(postcode.value)
    const keys = _.keysIn(this.state)

    let hasErrors = false

    _.each(keys, value => {
      if (_.isObject(this.state[value])) {
        if (_.isEmpty(this.state[value].value)) {
          this.state[value].error = true
          hasErrors = true
        } else {
          this.state[value].error = false
        }
      }
    })
    this.setState({
      postcode: {
        ...this.state.postcode,
        error: !isPostCodeValid,
      },
    })
    if (!isPostCodeValid || hasErrors) {
      return false
    }
    this.setState({
      isLoading: true,
    })

    const saveIt = saveAddress({
      variables: {
        firstLine: address1.value,
        secondLine: address2.value,
        city: towncity.value,
        postcode: postcode.value,

        default: true,
      },
    })

    saveIt.then(data => {
      const { id } = data.data.addAddress.address
      this.setState({
        isLoading: false,
      })
      this.props.refetch(data.data.addAddress.address)
      this.props.close()
    })
    saveIt.catch(err => {
      _.each(err.graphQLErrors, errorObj => {
        toast.error(errorObj.message, {
          position: toast.POSITION.TOP_CENTER,
        })
      })
      this.setState({
        isLoading: false,
      })
    })
  }

  renderField = field => {
    const { classes } = this.props
    const { label, value, error, className, placeholder } = this.state[field]
    const message =
      label.toLowerCase() === 'postcode'
        ? `${label} appears to be invalid`
        : `${label} is required`
    return (
      <div className={className}>
        <label className={classes.inputLabel}>{label}</label>
        <input
          placeholder={placeholder}
          type="text"
          className={classes.inputField}
          value={value}
          onChange={e => this.handleFieldChange(e, field)}
        />
        {error && <span className={classes.fieldErrorSpan}>{message}</span>}
      </div>
    )
  }

  render() {
    const { classes, existingAddress } = this.props
    const { isLoading } = this.state
    const modalTitle = existingAddress ? 'Edit Address' : 'Add New Address'

    const mutationName = existingAddress ? EDIT_USER_ADDRESS : ADD_USER_ADDRESS

    return (
      <div>
        <LoadingSpinner show={isLoading} />
        <div className={classes.titleArea}>
          <h2>{modalTitle}</h2>
        </div>
        <div className={classes.addressForm}>
          <div className={cx('row', classes.fieldRow)}>
            {this.renderField('address1')}
          </div>
          <div className={cx('row', classes.fieldRow)}>
            {this.renderField('address2')}
          </div>
          <div className={cx('row', classes.fieldRow)}>
            {this.renderField('towncity')}
          </div>
          <div className={cx('row', classes.fieldRow)}>
            {this.renderField('postcode')}
          </div>
          <div className={classes.formFooter}>
            <CacheQuery
              query={gql`
                query getMarketplace($cname: String!) {
                  marketplace(cname: $cname) {
                    marketplace {
                      country {
                        name
                        postCodeRegex
                      }
                    }
                  }
                }
              `}
              variables={{ cname: window.location.hostname }}
            >
              {({ data: queryResponse }) => {
                // get postcode validation regex from marketplace, falling back to accepting anything
                const postCodeRegex = _.get(
                  queryResponse,
                  'marketplace.marketplace.country.postCodeRegex',
                  '.+'
                )
                return (
                  <Mutation
                    mutation={mutationName}
                    onCompleted={() => {
                      this.context.pushDataToGTM({ event: 'add_shipping_info' })
                    }}
                  >
                    {saveAddress => (
                      <Button
                        className={classes.confirmButton}
                        appearance="positive"
                        capitalize
                        fullWidth
                        onClick={e =>
                          this.saveAddress(e, saveAddress, postCodeRegex)
                        }
                      >
                        Save Address
                      </Button>
                    )}
                  </Mutation>
                )
              }}
            </CacheQuery>
          </div>
        </div>
      </div>
    )
  }
}
const AddAddressPopover = withStyles(styles)(AddAddressPopoverEl)
export default AddAddressPopover
