import React, { useContext } from 'react'
import { OrderContext } from '@context/Order.context'
import { Query } from 'react-apollo'

import gql from 'graphql-tag'

const GET_DELIVERY_ESTIMATE_FOR_ADDRESS = gql`
  query deliveryEstimateForAddress(
    $outletId: String!
    $addressId: String!
    $preorderFor: DateTime
  ) {
    deliveryEstimateForAddress(
      outletId: $outletId
      addressId: $addressId
      preorderFor: $preorderFor
    ) {
      id
      cost
      expiryAt
      type
    }
  }
`

export const DeliveryCostToAddressContext = React.createContext({
  data: {},
  error: null,
  loading: false,
})

export const DeliveryCostToAddressContextProvider = ({ children }) => {
  const { attributes } = useContext(OrderContext)

  const variables = {
    outletId: attributes.outletId,
    addressId: attributes.address,
  }

  // if address or outlet are not yet chosen skip query and leave
  // default context values
  const skip = Object.values(variables).some(value => !value)
  return (
    <Query
      query={GET_DELIVERY_ESTIMATE_FOR_ADDRESS}
      variables={variables}
      errorPolicy="all"
      fetchPolicy="no-cache"
      context={{ version: 2 }}
      skip={skip}
    >
      {({ data, error, loading }) => (
        <DeliveryCostToAddressContext.Provider
          value={{ data: data ? data : {}, error, loading }}
        >
          {children}
        </DeliveryCostToAddressContext.Provider>
      )}
    </Query>
  )
}
