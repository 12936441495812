import { getMarketplace } from '@config/config'
import { pick, find } from 'lodash'
import { console, Number } from 'window-or-global'

// https://stackoverflow.com/questions/164979/regex-for-matching-uk-postcodes
// this is an updated an answer using the uk govornment postcode regex https://www.gov.uk/government/publications/bulk-data-transfer-for-sponsors-xml-schema
// if you read comments on the answer the is the javascript version of the xml schema accepted answer
// we will need different ones for different countrys
// modified so that we can accept postcodes without spaces ie S92LR
export const POSTCODE_REGEX_UK =
  '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})$'

const PENCE_IN_A_POUND = 100
export const poundsToPence = pounds =>
  (Number(pounds) * PENCE_IN_A_POUND).toFixed(0)

export const penceToPounds = pence =>
  (Number(pence) / PENCE_IN_A_POUND).toFixed(2)

export const kilometerToMiles = km => {
  const conversionFactor = 0.621371
  return (km * conversionFactor).toFixed(1)
}

export const getDeliveryZoneFromURL = match => {
  const { deliveryZones = [] } = getMarketplace()

  const zoneId = deliveryZones.filter(({ id, name }) =>
    name.toLowerCase().replace(/ /g, '-') ===
    (match.params.deliveryZone || '').toLowerCase()
      ? id
      : null
  )
  return zoneId[0]
}

export const cleanOrderStatusForClient = orderStatus => {
  switch (orderStatus) {
    case 'PENDING':
    case 'AWAITING_DELIVERY_NETWORK':
    case 'AWAITING_PAYMENT':
    case 'AUTH_ACTION_PENDING':
      return 'PENDING'
    case 'AUTH_ACTION_FAILURE':
      return 'AUTH FAILED'
    case 'PREPARING':
      return 'PREPARING'

    case 'READY':
      return 'READY'

    case 'COMPLETE':
      return 'COMPLETE'

    case 'REJECTED':
      return 'REJECTED'

    case 'DELIVERY_NETWORK_FAILURE':
    case 'DELIVERY_FAILURE_REFUND':
    case 'ORDER_FAILURE_REFUND':
    case 'REFUND_REQUESTED':
    case 'REFUND_CANCELLED':
    case 'REFUND_RELEASED':
    case 'REFUND_FAILED':
      return 'CANCELLED'

    default:
      return orderStatus
  }
}

export const getFulfilmentMethod = (methodArray, methodName) => {
  const has = find(methodArray, method => {
    return method.toLowerCase().includes(methodName.toLowerCase())
  })
  return !!has
}

export const navigateToOutlet = async ({
  history,
  match,
  outlet,
  pushDataToGTM,
  attributes,
  updateOrder,
  replaceOrder,
  items,
  clearItems,
}) => {
  try {
    const { id, isOpen, isOnline, restaurant, outletPhone } = outlet

    const { isListMode } = getMarketplace()
    let deliveryZone = {
      id: '',
      name: match.params.deliveryZone,
    }
    if (isListMode) {
      try {
        deliveryZone = await getDeliveryZoneFromURL(match)
      } catch (e) {
        //TODO handle this
      }
    }

    const newOrder = {
      // maintain deliveryDestination when we create a new
      // order as customer should not have to set this again
      deliveryDestination: attributes.deliveryDestination
        ? {
            type: attributes.deliveryDestination.type,
            destination: attributes.deliveryDestination.destination,
          }
        : { type: 'POSTCODE' },
      // Preserve this as its picked before entering new outlet
      fulfilmentChosen: attributes.fulfilmentChosen,
      // add outletID as this is to be updated when we go to a new outlet
      outletId: id,
      // used to get to cart page from homepage
      restaurantName: outlet.restaurant.name,
      restaurantId: restaurant.id,
      outletPhone,
      deliveryZone: { id: deliveryZone.id, name: deliveryZone.name },
    }

    const outletId = id
    const currentOrderOutletId = attributes.outletId || null
    const orderOutletChanged =
      currentOrderOutletId && currentOrderOutletId !== outletId

    if (orderOutletChanged) {
      const basketHasItems = items.length > 0
      // if user has items in the basket promt the user
      // clear the basket
      if (basketHasItems) {
        clearItems()
      }
      // if its a new outlet wipe the whole order with the new one
      replaceOrder({ ...newOrder, asap: isOpen && isOnline })
    } else {
      updateOrder(newOrder)
    }

    const cleanRestaurantName = outlet.restaurant.name
      .trim()
      .replace(/[^A-Z0-9]+/gi, '-')
      .toLowerCase()
    history.push(
      `${
        isListMode ? `/${match.params.deliveryZone}-takeaways` : ''
      }/${cleanRestaurantName}/${id}/menu`
    )

    if (typeof pushDataToGTM === 'function') {
      pushDataToGTM({
        event: 'order_started',
        order_restaurant: outlet.restaurant.name,
        order: pick(newOrder, ['restaurantName', 'restaurantId', 'outletId']),
        basket: items.reduce((acc, { menuItem, optionItems }) => {
          const basketItem = {
            menuItem: { id: menuItem.id, name: menuItem.name },
            optionItems: optionItems.map(({ id, name, price }) => ({
              id,
              name,
              price,
            })),
          }
          acc.push(basketItem)
          return acc
        }, []),
      })
    }
  } catch (e) {
    console.log('Failed to navigate to outlet', e)
  }
}

export const conditionalPropType = (condition, message) => {
  if (typeof condition !== 'function') {
    throw "Wrong argument type 'condition' supplied to 'conditionalPropType'"
  }

  return (props, propName, componentName) => {
    if (condition(props, propName, componentName)) {
      return new Error(
        `Invalid prop '${propName}' '${props[propName]}' supplied to '${componentName}'. ${message}`
      )
    }
  }
}
