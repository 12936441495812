import React, { useContext } from 'react'
import { withStyles } from 'react-jss'
import PropTypes from 'prop-types'
import styles from './restaurantStatusStyles'
import cx from 'classnames'
import { OrderContext } from '@root/context/Order.context'
import moment from 'moment-timezone'

const RestaurantStatus = ({
  classes,
  isOpen,
  isOnline,
  preorderStatus,
  allowPreorders,
  closedUntil,
  daysOfferedInAdvanceMax,
}) => {
  const { attributes } = useContext(OrderContext)
  const isTable = attributes.fulfilmentChosen.id === 'table'
  const isAvailableForOrder =
    isOnline &&
    (isOpen ||
      (!isOpen &&
        !isTable &&
        allowPreorders &&
        (!closedUntil ||
          moment(closedUntil).isBefore(
            moment().endOf('day').add(daysOfferedInAdvanceMax, 'days')
          ))))

  const canBePreordered =
    !closedUntil ||
    moment(closedUntil).isBefore(
      moment().endOf('day').add(daysOfferedInAdvanceMax, 'days')
    )

  const statusPhrase = isAvailableForOrder
    ? isOpen
      ? 'OPEN'
      : preorderStatus.includes(':')
      ? 'OPENING LATER'
      : allowPreorders &&
        !preorderStatus.toUpperCase().includes('ORDER FROM') &&
        canBePreordered
      ? 'PREORDER'
      : 'CLOSED'
    : 'CLOSED'

  return (
    <div
      className={cx(classes.status, {
        [classes.isOpen]: isAvailableForOrder && isOpen,
        [classes.isPreorder]:
          isAvailableForOrder &&
          !isOpen &&
          isOnline &&
          allowPreorders &&
          !preorderStatus.toUpperCase().includes('ORDER FROM') &&
          canBePreordered,
        [classes.isClosed]: !isOpen || !isOnline,
      })}
    >
      {statusPhrase}
    </div>
  )
}

RestaurantStatus.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool.isRequired,
  preorderStatus: PropTypes.string.isRequired,
  allowPreorders: PropTypes.bool.isRequired,
  closedUntil: PropTypes.string,
  daysOfferedInAdvanceMax: PropTypes.number,
}

export default withStyles(styles)(RestaurantStatus)
