import React, { useState, useCallback } from 'react'
import Checkbox from '@components/Checkbox/Checkbox'
import withStyles from 'react-jss'
import styles from './marketingPreferences.styles'

const MarketingPreferences = ({ handleSubmit, initialValues, classes }) => {
  const [values, setValues] = useState(initialValues)

  const handleChangeFor = useCallback(
    name => value =>
      setValues(values => ({
        ...values,
        [name]: value,
      })),
    []
  )

  return (
    <section className={classes.jacket}>
      <header className={classes.title}>Marketing Preferences</header>
      <header className={classes.subtitle}>Marketing types</header>
      <div className={classes.fieldRow}>
        <Checkbox
          checked={values.newRestaurantMarketing}
          onChange={handleChangeFor('newRestaurantMarketing')}
        />
        <label className={classes.label}>New Businesses</label>
      </div>
      <div className={classes.fieldRow}>
        <Checkbox
          checked={values.menuMarketing}
          onChange={handleChangeFor('menuMarketing')}
        />
        <label className={classes.label}>Product Updates</label>
      </div>
      <div className={classes.fieldRow}>
        <Checkbox
          checked={values.promotionsMarketing}
          onChange={handleChangeFor('promotionsMarketing')}
        />
        <label className={classes.label}>Promotions</label>
      </div>
      <header className={classes.subtitle}>Marketing channels</header>
      <div className={classes.fieldRow}>
        <Checkbox
          checked={values.marketingEmail}
          onChange={handleChangeFor('marketingEmail')}
        />
        <label className={classes.label}>Email</label>
      </div>
      <div className={classes.fieldRow}>
        <Checkbox
          checked={values.marketingPush}
          onChange={handleChangeFor('marketingPush')}
        />
        <label className={classes.label}>Mobile Push Notification</label>
      </div>
      <div className={classes.fieldRow}>
        <Checkbox
          checked={values.marketingSMS}
          onChange={handleChangeFor('marketingSMS')}
        />
        <label className={classes.label}>SMS</label>
      </div>
      <div className={classes.buttonContainer}>
        <button className={classes.button} onClick={() => handleSubmit(values)}>
          Save
        </button>
      </div>
    </section>
  )
}

export default withStyles(styles)(MarketingPreferences)
