import Color from 'color'
import rightArrow from '../../../images/right-arrow.svg'

const styles = theme => ({
  menuCategory: {
    color: theme.bc3,
    padding: '1.2rem 4.2rem 1.2rem 1.2rem',
    fontSize: '16px',
    fontWeight: 600,
    borderBottom: '1px solid black',
    borderBottomColor: `${Color(theme.bc3)
      .alpha(0.2)
      .rgb()
      .string()}`,
    paddingTop: '20px',
    paddingBottom: '20px',
    backgroundImage: `url(${rightArrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '13px',
    backgroundPosition: '95% center',
    boxShadow: '0px -1px 0px #ececec',

    '& > p': {
      fontSize: '12px',
      padding: '0.5rem 0 0 0',
      color: '#707070',
      fontWeight: 500,
      lineHeight: '16px',
      '@media screen and (min-width: 768px)': {
        padding: '1.2rem 0 0 0',
      },
    },

    '@media screen and (min-width: 768px)': {
      borderBottom: '2px solid black',
      padding: '2.5rem 1.2rem 1.5rem 1.2rem',
      backgroundImage: `none`,
      marginTop: '-5px',

      borderBottomColor: `${Color(theme.bc3)
        .rgb()
        .string()}`,
    },
  },

  menuItemSection: {
    display: 'block',
    position: 'fixed',
    right: '-100%',
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    zIndex: 2000,
    top: 0,
    overflow: 'hidden',
    transition: 'border-top 0.2s ease',

    '@media print': {
      position: 'initial',
      paddingTop: '0',
    },

    '@media screen and (min-width: 768px)': {
      position: 'initial',
      paddingTop: '0',
    },
  },

  menuItemSectionActive: {
    extend: 'menuItemSection',
    right: '0%',
    bottom: 0,
    overflow: 'scroll',
  },

  menuHeaderSection: {
    display: 'block',
    pageBreakInside: 'avoid',
    '@media print': {
      marginTop: '-10px',
    },
  },

  inMenuSubHeaderBack: {
    backgroundColor: '#f1f1f1',
    padding: '15px',
    color: '#2f2f2f',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '2.1rem',
    borderBottom: '1px solid #e3e5e6',

    '@media screen and (min-width: 768px)': {
      display: 'none',
    },

    '@media print': {
      display: 'none',
    },
  },

  inMenuHeaderBack: {
    backgroundColor: theme.bc3,
    top: 0,
    width: '100%',
    left: 0,
    right: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.4rem 1rem',

    '@media screen and (min-width: 768px)': {
      display: 'none',
    },

    '@media print': {
      display: 'none',
    },
  },

  inMenuHeaderBackLink: {
    display: 'inline-block',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer',
  },
})

export default styles
