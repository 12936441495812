const styles = theme => ({
  menuItem: {
    lineHeight: '1.7rem',
    cursor: 'pointer',
    transition: `0.2s ease`,
    transitionProperty: 'box-shadow',
    position: 'relative',

    padding: '1.5rem 1.2rem 1.5rem 1.2rem',
    borderBottom: '1px solid #eff3f8',

    '&:hover': {
      boxShadow: '0px 5px 30px rgba(225, 231, 234, 0.68)',
    },

    '&:after': {
      width: 0,
      top: 0,
      bottom: 0,
      left: '-3px',
      content: "''",
      position: 'absolute',
    },

    '&:last-child': {
      borderBottomColor: 'transparent',
    },
  },

  menuItemInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },

  menuItemMainText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.bc5,
    fontWeight: 500,
    fontSize: '1.5rem',
    lineHeight: '2.1rem',
  },

  menuItemDescriptionText: {
    color: theme.bc5,
    opacity: 0.7,
    fontSize: '1.3rem',
    fontWeight: 400,
    whiteSpace: 'pre-wrap',
  },

  itemPrice: {
    color: theme.bc1,
    display: 'block',
    fontSize: '1.4rem',
    fontWeight: 600,
    marginTop: '1rem',
  },

  soldOutLozenge: {
    color: '#fff',
    padding: '0px 5px',
    fontSize: '7px',
    fontWeight: 700,
    borderRadius: '5px',
    textTransform: 'uppercase',
    display: 'inline-block',
    backgroundColor: theme.error,
    marginTop: '-4px',
    marginBottom: '5px',
    lineHeight: '15px',
  },

  svgPriceIcon: {
    verticalAlign: 'middle',

    '& svg': {
      fill: theme.bc1,
      width: '1.5rem',
      height: '1.5rem',
      marginLeft: '0.8rem',
    },

    '@media print': {
      display: 'none',
    },
  },

  menuImageWrapper: {
    height: '8rem',
    width: '8rem',
    backgroundColor: '#f3f2f2',
    borderRadius: '5px',
    overflow: 'hidden',
    boxShadow: '0px 0px 2px #00000073',

    display: 'inline-block',
    flexShrink: 0,
    marginLeft: '1rem',
  },

  menuImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
  },

  itemInformation: {
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '0.5rem',
    borderRadius: '50%',
    border: '1px solid #999999',

    width: '2rem',
    height: '2rem',

    '& img': {
      width: '2.4rem',
      height: '2.4rem',
    },
  },

  ageRestricted: {
    width: '2rem',
    height: '2rem',
    marginLeft: '0.5rem',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& span': {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& svg': {
      width: '2rem',
      height: '2rem',
    },
  },
})

export default styles
