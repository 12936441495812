import React, { useContext } from 'react'
import { withStyles } from 'react-jss'
import Query from '@components/Query/Query'
import {
  OutletListContext,
  OutletListContextProvider,
} from '@components/OutletList/OutletList.context'

import {
  GET_OUTLETS_FOR_POSTCODE,
  GET_OUTLETS_FOR_DELIVERY_ZONE,
  GET_COLLECTION_OUTLETS_FOR_POSTCODE,
  GET_OUTLETS_FOR_COORDINATES,
} from '@components/OutletList/getOutlets.query.js'

import cx from 'classnames'
import styles from '@components/OutletList/outletList.styles'
import capitalize from 'lodash/capitalize'
import shuffle from 'lodash/shuffle'
import orderBy from 'lodash/orderBy'
import Hero from '@components/Hero/Hero'
import Modal from '@components/ModalWrapper/ModalWrapper'
import DeliveryZonesModal from '@components/DeliveryZones/DeliveryZonesModal'
import { ReactSVG } from 'react-svg'
import triangleDown from '@images/triangle-down.svg'
import noneFound from '@images/none-found.svg'
import { Link } from 'react-router-dom'
import StickySideBar from '@components/StickySideBar/StickySideBar'
import ListItem from '@components/List/ListItem'
import FulfilmentMethodsFilter from '@components/OutletList/Filters/FulfilmentMethodsFilter'
import CuisineFilter from '@components/OutletList/Filters/CuisineFilter'
import { OrderContext } from '@context/Order.context'
import OldDeliverToPostcode from '@components/DeliverToPostcode/OldDeliverToPostcode'
import moment from 'moment'

const Outlets = ({ classes, queryVariables, skipQuery }) => {
  const { activeCuisines, isPostcodeMode } = useContext(OutletListContext)
  const { attributes } = useContext(OrderContext)

  const activeFulfilmentMethod = attributes.fulfilmentChosen
    ? attributes.fulfilmentChosen.id
    : null

  const getSortOutlets = outlets => {
    const shuffledOutlets = shuffle(outlets)

    const sort = [
      [
        'isOnline',
        'isOpen',
        outlet => outlet.isOpen && !!outlet.restaurant.promoted,
        outlet => {
          //preorders
          return (
            outlet.allowPreorders &&
            (!outlet.closedUntil ||
              moment(outlet.closedUntil).isBefore(moment()) ||
              moment(outlet.closedUntil).isBefore(
                moment()
                  .startOf('day')
                  .add(outlet.daysOfferedInAdvanceMax, 'days')
              )) &&
            // check now + daysofferedinadvance >= next opening time
            (outlet.isOpen ||
              (!outlet.isOpen &&
                (moment()
                  .startOf('day')
                  .add(outlet.dayOfferedInAdvanceMin, 'days')
                  .isSameOrAfter(moment(outlet.nextOpenDate).startOf('day')) ||
                  moment()
                    .startOf('day')
                    .add(outlet.daysOfferedInAdvanceMax, 'days')
                    .isSameOrAfter(
                      moment(outlet.nextOpenDate).startOf('day')
                    ))))
          )
        },
        outlet => !!outlet.restaurant.promoted,
      ],
      ['desc', 'desc', 'desc', 'desc', 'desc'],
    ]

    if (
      activeFulfilmentMethod === 'collection' ||
      activeFulfilmentMethod === 'table'
    ) {
      sort[0].push('distanceFromUserKM')
      sort[1].push('asc')
    }

    const sortedOutlets = orderBy(
      activeFulfilmentMethod === 'delivery' ? shuffledOutlets : outlets,
      sort[0],
      sort[1]
    )

    const filteredAndSortedOutlets = sortedOutlets.filter(outlet => {
      if (activeFulfilmentMethod) {
        return outlet.availableFulfillmentMethods.includes(
          activeFulfilmentMethod.toUpperCase()
        )
      }
      return true
    })
    return filteredAndSortedOutlets
  }

  return (
    <Query
      query={
        isPostcodeMode
          ? activeFulfilmentMethod === 'collection'
            ? GET_COLLECTION_OUTLETS_FOR_POSTCODE
            : queryVariables.coordinates
            ? GET_OUTLETS_FOR_COORDINATES
            : GET_OUTLETS_FOR_POSTCODE
          : GET_OUTLETS_FOR_DELIVERY_ZONE
      }
      variables={{
        ...queryVariables,
        get cuisineIds() {
          if (activeCuisines.length) {
            return activeCuisines.map(({ id }) => id)
          }
          return undefined
        },
      }}
      context={{ version: 2 }}
      skip={skipQuery}
    >
      {({
        outletsForPostcode = [],
        outletsForDeliveryZone = [],
        outletsOfferingCollection = [],
        outletsForCoordinates = [],
      }) => {
        const sortedOutlets = getSortOutlets(
          isPostcodeMode
            ? activeFulfilmentMethod === 'collection'
              ? outletsOfferingCollection
              : queryVariables.coordinates
              ? outletsForCoordinates
              : outletsForPostcode
            : outletsForDeliveryZone
        )

        return (
          <>
            {sortedOutlets.length ? (
              sortedOutlets.map(outlet => (
                <ListItem outlet={outlet} key={outlet.id} />
              ))
            ) : (
              <div className={classes.noResults}>
                <ReactSVG src={noneFound} className={classes.noneFound} />
                <h1 className={classes.emptyList}>No restaurants found</h1>
                <p className={classes.noResultsInfo}>
                  Sorry, there are no restaurants delivering to your location.
                </p>
                <Link className={classes.noResultsHomeLink} to="/">
                  Try another delivery address
                </Link>
              </div>
            )}
          </>
        )
      }}
    </Query>
  )
}

const OutletList = ({ classes }) => {
  const {
    marketplace,
    isPostcodeMode,
    deliveryZonesModalOpen,
    setDeliveryZonesModalOpen,
  } = useContext(OutletListContext)
  const { attributes } = useContext(OrderContext)

  const {
    deliveryZone = { id: '', name: '' },
    deliveryDestination = { type: 'POSTCODE', destination: '' },
  } = attributes
  const deliveryZoneName = (deliveryZone.name || '').replace(/-/g, ' ')

  const locationUrl = isPostcodeMode
    ? deliveryDestination.destination
    : deliveryZoneName

  const skipQuery =
    (isPostcodeMode && locationUrl === '') ||
    (!isPostcodeMode && deliveryZone.id === '')

  const queryVariables = {
    ...(isPostcodeMode
      ? typeof locationUrl === 'string'
        ? {
            marketplaceId: marketplace.id,
            postAndCountryCode: {
              postcode: locationUrl.toUpperCase(),
              countryCode: marketplace.contactAddress.country.code,
            },
          }
        : {
            marketplaceId: marketplace.id,
            coordinates: {
              lat: locationUrl.lat,
              lng: locationUrl.lng,
            },
          }
      : {
          deliveryZoneId: deliveryZone.id,
        }),
  }

  const locationText = isPostcodeMode
    ? typeof locationUrl === 'string'
      ? locationUrl.toUpperCase()
      : 'Find near me'
    : capitalize(locationUrl)

  return (
    <div
      className={cx('wrapper', classes.listWrapper, {
        [classes.listWrapperPostcode]: isPostcodeMode,
      })}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-sm-3">
            <StickySideBar
              titleClassName={cx({
                [classes.titleAreaPostcode]: isPostcodeMode,
              })}
              title={
                isPostcodeMode ? (
                  <OldDeliverToPostcode
                    onSubmitCallBack={({ history }) => {
                      history.push('/takeaways')
                    }}
                    defaultValue={locationText}
                  />
                ) : (
                  <h2
                    className={classes.title}
                    onClick={() => setDeliveryZonesModalOpen(true)}
                  >
                    {locationText}
                    <ReactSVG src={triangleDown} wrapper="span" />
                  </h2>
                )
              }
              noBackgroundOnStick={isPostcodeMode}
            >
              <FulfilmentMethodsFilter
                queryVariables={queryVariables}
                skipQuery={skipQuery}
              />

              <CuisineFilter queryVariables={queryVariables} />
            </StickySideBar>
          </div>

          <div className="col-xs-12 col-sm-9">
            <Outlets
              classes={classes}
              queryVariables={queryVariables}
              skipQuery={skipQuery}
            />
          </div>
        </div>
      </div>

      <Modal
        open={deliveryZonesModalOpen}
        close={() => setDeliveryZonesModalOpen(false)}
      >
        <DeliveryZonesModal
          cuisine={null}
          close={() => setDeliveryZonesModalOpen(false)}
        />
      </Modal>
    </div>
  )
}

const ProvideContext = props => (
  <OutletListContextProvider>
    <div>
      <Hero {...props} />
      <OutletList {...props} />
    </div>
  </OutletListContextProvider>
)

export default withStyles(styles)(ProvideContext)
