import gql from 'graphql-tag'

const OUTLET_SUMMARY = gql`
  query details($id: String!) {
    outlet(id: $id) {
      id
      name
      paymentMethods
      deliveryMinimumOrderValue
      collectionMinimumOrderValue
      outletPhone
      restaurant {
        id
        name
        allowAddOnItems
        cuisines {
          id
          name
        }
        image
      }
    }
  }
`

export default OUTLET_SUMMARY
