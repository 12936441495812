import React, { useContext, useEffect, useState } from 'react'
import { object, bool } from 'prop-types'
import { withStyles } from 'react-jss'
import styles from './cartStyles'
import FulfilmentTimeSection from './FulfilmentTimeSection/FulfilmentTimeSection'
import { Link } from 'react-router-dom'
import Modal from '@components/ModalWrapper/ModalWrapper'
import AllergyModal from '@components/Allergies/AllergyModal'
import Totals from './Delivery/Totals/Totals'
import { ReactSVG } from 'react-svg'
import closeSVG from '@images/close.svg'
import cx from 'classnames'
import { CART_OPEN_QUERY } from '../Query/LocalStates'
import { client } from '@services/client'
import { penceToPounds } from '@utils/helpers'
import get from 'lodash/get'
import GET_OUTLET_INFO from './queries/getOutletInfo.query'
import BodyLocker from '@utils/BodyLocker'
import { withRouter } from 'react-router-dom'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import { OrderContext, OrderDeliveryMethods } from '@context/Order.context'
import { BasketContext } from '@context/Basket.context'
import TablePicker from '@components/Cart/TablePicker/TablePicker'
import FulfilmentMethodPicker from '@components/Cart/FulfilmentMethodPicker/FulfilmentMethodPicker'
import Query from '@components/Query/Query'

import ClearPopover from './ClearPopover'
import CheckoutButton from './CheckoutButton'
import CartItems from './CartItems'
import { upperFirst } from 'lodash'

const Cart = ({
  classes,
  outlet,
  cartOpen,
  summaryOnly,
  canEdit,
  menuButton,
  checkoutButton,
}) => {
  const { attributes, updateOrder } = useContext(OrderContext)
  const { items, subTotal, removeAddOnItems } = useContext(BasketContext)
  const urlParams = new URLSearchParams(window.location.search)

  const [state, _setState] = useState({
    tablePickerOpen: false,
    tablePickedCB: null,
    allergyModalOpen: false,
    lastWindowWidth: 0,
    clearPopoverOpen: false,
    minimumOrderValueWarningVisible: false,
    minimumOrderValue: 0,
    loading: false,
    deliveryMethods: Object.values(OrderDeliveryMethods).map(
      deliveryMethod => ({
        ...deliveryMethod,
        available: false,
      })
    ),
  })

  const setState = incomingState => {
    _setState(state => ({ ...state, ...incomingState }))
  }

  useEffect(() => {
    if (!window.location.href.includes('checkout')) {
      if (items.find(({ addOnItem }) => addOnItem)) removeAddOnItems()
    }
  }, [])

  useEffect(() => {
    if (outlet) {
      setState({
        deliveryMethods: [
          {
            id: 'delivery',
            label: 'Delivery',
            available: outlet.availableFulfillmentMethods.includes('DELIVERY'),
          },
          {
            id: 'collection',
            label: 'Collection',
            available:
              outlet.availableFulfillmentMethods.includes('COLLECTION'),
          },
          {
            id: 'table',
            label: 'Table',
            available: outlet.availableFulfillmentMethods.includes('TABLE'),
          },
        ],
      })
    }
  }, [outlet])

  useEffect(() => {
    const fulfilmentURL = urlParams.get('fulfilment')
    if (
      fulfilmentURL &&
      outlet &&
      outlet.availableFulfillmentMethods.includes(fulfilmentURL.toUpperCase())
    ) {
      const fulfilmentChosen = {
        id: fulfilmentURL,
        label: upperFirst(fulfilmentURL),
        available: true,
      }
      //currently does not check that a table id has been given in the url if fulfilment is table.
      //if no table id is provided you will be taken to table service menu but a table is not set.
      updateOrder({ fulfilmentChosen })
    }
    const isAvailable =
      attributes.fulfilmentChosen &&
      (
        state.deliveryMethods.find(
          deliveryMethod => deliveryMethod.id === attributes.fulfilmentChosen.id
        ) || {}
      ).available

    if (
      !attributes.fulfilmentChosen ||
      (outlet && attributes.outletId !== outlet.id) ||
      !isAvailable
    ) {
      const firstAvailable = state.deliveryMethods.find(
        deliveryMethod => deliveryMethod.available
      )
      if (firstAvailable) {
        updateOrder({ fulfilmentChosen: firstAvailable })
      }
    }
  }, [
    state.deliveryMethods.map(({ id, available }) => id + available).join(''),
  ])

  const setOrderNotes = e => {
    updateOrder({ orderNotes: e.target.value })
  }

  const {
    allergyModalOpen,
    minimumOrderValueWarningVisible,
    minimumOrderValue,
    loading,
  } = state

  const minVal = penceToPounds(minimumOrderValue)
  const showClear = items && items.length > 0

  useEffect(() => {
    if (outlet) {
      const { collectionMinimumOrderValue, deliveryMinimumOrderValue } = outlet
      const { fulfilmentChosen } = attributes
      const relevantValue =
        fulfilmentChosen && fulfilmentChosen.id !== 'delivery'
          ? collectionMinimumOrderValue
          : deliveryMinimumOrderValue
      const isVisible = subTotal < relevantValue && items && items.length > 0

      setState({
        minimumOrderValueWarningVisible: isVisible,
        minimumOrderValue: relevantValue,
      })
    }
  }, [outlet, subTotal, attributes.fulfilmentChosen])

  const closeCart = () => {
    BodyLocker.unlock()
    client.writeData({
      data: {
        cartOpen: false,
      },
    })
  }

  if (!outlet) {
    return null
  }

  if (summaryOnly) {
    return (
      <div>
        <CartItems classes={classes} canEdit={canEdit} />
        <Totals
          restaurantPartnerTableCharge={outlet.partnerTableCharge}
          restaurantPartnerCharge={outlet.partnerCharge}
          outletId={outlet.id}
          collectionCharge={outlet.collectionCharge}
        />
      </div>
    )
  }

  const hasDeliveryMethods = state.deliveryMethods.some(
    ({ available }) => available
  )

  return (
    <div className={cx(classes.cart, cartOpen ? classes.openState : '')}>
      <div className={classes.cartHeader}>
        <h3 className={classes.cartTitle}>
          {canEdit ? 'Your Order' : 'Order Summary'}
        </h3>
        {outlet.restaurant.enableAllergyInformation ? (
          <button
            className={classes.allergyLink}
            onClick={() => setState({ allergyModalOpen: true })}
          >
            Have a food allergy?
          </button>
        ) : null}

        <ClearPopover
          classes={classes}
          isOpen={state.clearPopoverOpen}
          close={() => setState({ clearPopoverOpen: false })}
        />

        {canEdit && showClear && (
          <button
            className={classes.clearLink}
            onClick={() => setState({ clearPopoverOpen: true })}
          >
            <ReactSVG src={closeSVG} wrapper="span" /> Clear Order
          </button>
        )}
        <button className={classes.closeLink} onClick={closeCart}>
          <ReactSVG src={closeSVG} wrapper="span" />
        </button>
      </div>
      <div className={classes.deliveryTimeOptions}>
        <LoadingSpinner show={loading} />

        {hasDeliveryMethods ? (
          <>
            <FulfilmentMethodPicker
              deliveryMethods={state.deliveryMethods}
              canASAP={outlet.isOnline && outlet.isOpen && outlet.asapAllowed}
            />

            {attributes.fulfilmentChosen &&
            attributes.fulfilmentChosen.id === 'table' ? (
              outlet.isOpen && outlet.isOnline ? (
                <TablePicker
                  outletId={outlet.id}
                  tablePickedCB={state.tablePickedCB}
                  isOpen={state.tablePickerOpen}
                  close={() => {
                    setState({ tablePickerOpen: false })
                  }}
                  open={() => {
                    setState({ tablePickerOpen: true })
                  }}
                />
              ) : (
                <div className={classes.noDeliveryMethodsLbl}>
                  Currently Closed
                </div>
              )
            ) : (
              <FulfilmentTimeSection outlet={outlet} />
            )}
          </>
        ) : outlet.isOnline ? (
          <div className={classes.noDeliveryMethodsLbl}>
            No fulfilment options specified...
          </div>
        ) : null}
      </div>

      {minimumOrderValueWarningVisible && (
        <div className={classes.minValueWarning}>
          <p className={classes.minWarningLabel}>
            Minimum Subtotal of £{minVal} not reached
          </p>
        </div>
      )}

      <CartItems classes={classes} canEdit={canEdit} />

      <div className={classes.cartFooter}>
        <Totals
          restaurantPartnerTableCharge={outlet.partnerTableCharge}
          restaurantPartnerCharge={outlet.partnerCharge}
          outletId={outlet.id}
          collectionCharge={outlet.collectionCharge}
        />

        <div className={classes.orderNotesBox}>
          <textarea
            placeholder={`Add a note to the order...`}
            className={classes.orderNotesTextArea}
            value={attributes.orderNotes}
            onChange={setOrderNotes}
          />
        </div>
        <div className={classes.actions}>
          {menuButton &&
            attributes.deliveryZone &&
            attributes.deliveryZone.name && (
              <Link
                to={`/${get(attributes, 'deliveryZone.name', '').replace(
                  / /g,
                  '-'
                )}-takeaways/${outlet.id}/${outlet.restaurant.name.replace(
                  / /g,
                  '-'
                )}`}
                className={classes.menuBtn}
                onClick={closeCart}
              >
                Go to menu
              </Link>
            )}
          {checkoutButton && (
            <CheckoutButton
              classes={classes}
              outlet={outlet}
              minimumOrderValueWarningVisible={
                state.minimumOrderValueWarningVisible
              }
              closeCart={closeCart}
              openTablePicker={cb => {
                setState({
                  tablePickerOpen: true,
                  tablePickedCB: cb ? cb : null,
                })
              }}
            />
          )}
        </div>
      </div>

      <Modal
        open={allergyModalOpen}
        close={() => setState({ allergyModalOpen: false })}
      >
        <AllergyModal name={outlet.name} phoneNumber={outlet.outletPhone} />
      </Modal>
    </div>
  )
}

const CartWrapper = props => {
  const { attributes } = useContext(OrderContext)
  const outletId = attributes.outletId || props.match.params.outletId
  if (!outletId || !outletId.length) return false

  return (
    <Query
      query={GET_OUTLET_INFO}
      variables={{ outletId }}
      context={{ version: 2 }}
    >
      {data => {
        const outlet = get(data, 'outlet')
        return (
          <Query query={CART_OPEN_QUERY}>
            {({ cartOpen }) => (
              <Cart
                cartOpen={cartOpen || props.forceOpen}
                {...props}
                outlet={outlet}
              />
            )}
          </Query>
        )
      }}
    </Query>
  )
}

Cart.propTypes = {
  classes: object,
  checkoutButton: bool,
  menuButton: bool,
}

export default withStyles(styles)(withRouter(CartWrapper))
