import React, { useContext, useState, useEffect } from 'react'
import styles from './oldDeliverToPostcode.styles'
import { withStyles } from 'react-jss'
import Button from '@components/Button/Button'
import { OrderContext } from '@context/Order.context'
import { withRouter } from 'react-router-dom'
import { POSTCODE_REGEX_UK } from '@utils/helpers'

const Header = ({ classes, history, onSubmitCallBack }) => {
  const { attributes, updateOrder } = useContext(OrderContext)
  const [postcode, setPostcode] = useState('')

  const { deliveryDestination = { type: 'POSTCODE', destination: '' } } =
    attributes

  const handlePostcodeChange = e => {
    const value = e.target.value
    const cleanValue = value ? value.toUpperCase() : value
    setPostcode(cleanValue)
  }

  const handleFormSubmit = e => {
    e.preventDefault()
    updateOrder({
      deliveryDestination: { type: 'POSTCODE', destination: postcode },
    })

    if (onSubmitCallBack) {
      onSubmitCallBack({ history })
    }
  }

  useEffect(() => {
    if (deliveryDestination.type === 'POSTCODE')
      setPostcode(deliveryDestination.destination)
  }, [])

  useEffect(() => {
    if (deliveryDestination.type === 'POSTCODE') {
      setPostcode(deliveryDestination.destination)
    }
  }, [deliveryDestination.destination])

  if (deliveryDestination.type === 'POSTCODE')
    return (
      <form className={classes.form} onSubmit={handleFormSubmit}>
        <label className={classes.label} htmlFor="postcode">
          Search for takeaways by postcode
        </label>
        <input
          id="postcode"
          type="text"
          className={classes.input}
          value={postcode}
          onChange={handlePostcodeChange}
          placeholder="Enter your postcode"
          title="Valid full postcode eg. S1 2LR"
          pattern={POSTCODE_REGEX_UK}
        />

        <Button
          square
          small
          type="submit"
          disabled={!postcode}
          onClick={handleFormSubmit}
        >
          Search
        </Button>
      </form>
    )
  return (
    <div className={classes.form}>
      <div className={classes.input}>
        <p className={classes.inputLabel}>Find near me</p>
      </div>

      <Button
        square
        small
        onClick={() => {
          updateOrder({ deliveryDestination: { type: '', destination: '' } })
          history.push('/')
        }}
      >
        Search
      </Button>
    </div>
  )
}

export default withStyles(styles)(withRouter(Header))
