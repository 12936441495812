import fontStyles from '@styles/sharedJSS/fonts'
import Color from 'color'

const styles = theme => ({
  jacket: {
    padding: '2.4rem',

    '@media screen and (min-width: 768px)': {
      padding: '4.8rem',
    },
  },

  title: {
    ...fontStyles.h2,
    color: theme.bc3,
    marginBottom: '2.4rem',

    '@media screen and (min-width: 768px)': {
      marginBottom: '3.6rem',
    },
  },

  list: {
    listStyle: 'none',
    display: 'flex',
    flexFlow: 'column',
  },

  listItem: {
    flexGrow: '1',
    padding: '2.4rem',
    background: 'white',
    borderRadius: '0.4rem',
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid ${Color(theme.bc5)
      .rgb()
      .alpha(0.2)
      .string()}`,
    marginBottom: '2.4rem',
    flexFlow: 'column',

    '&:last-child': {
      marginBottom: 0,
    },

    '@media screen and (min-width: 768px)': {
      flexFlow: 'row',
      alignItems: 'center',
    },
  },

  listItemPrimary: {
    marginBottom: '1.2rem',
    padding: '4px 6px',
    background: theme.bc1,
    borderRadius: '4px',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 700,
    marginTop: '-33px',
    position: 'absolute',
  },

  listItemContent: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    flexShrink: 1,
    justifyContent: 'left',
    alignItems: 'flex-start',
  },

  listItemHeading: {
    ...fontStyles.p,
  },

  listItemRest: {
    ...fontStyles.small,
  },

  listItemInteractions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '2.4rem',

    '@media screen and (min-width: 768px)': {
      justifyContent: 'right',
      width: 'auto',
      marginTop: 0,
    },
  },

  icon: {
    transition: 'all .25s ease-in-out',
    width: '2.4rem',
    height: '2.4rem',
    fill: 'none',
    stroke: theme.bc5,
    strokeWidth: '2px',

    '& svg': {
      height: '25px',
      width: '25px',
    },
  },

  listItemButton: {
    transition: 'all .25s ease-in-out',
    width: '4.8rem',
    height: '4.8rem',
    borderRadius: '100%',
    background: 'white',
    border: `2px solid ${theme.bc5}`,

    '&:focus': { outline: 'none' },
    '&:hover': { cursor: 'pointer' },

    '@media screen and (min-width: 768px)': {
      marginLeft: '1.2rem',
    },
  },

  buttonPrimary: {
    border: `2px solid ${theme.bc1}`,

    '& $icon': {
      stroke: theme.bc1,
    },

    '&:hover': {
      backgroundColor: theme.bc1,

      '& $icon': {
        stroke: 'white',
      },
    },
  },

  buttonEdit: {
    border: `2px solid ${theme.bc3}`,

    '& $icon': {
      stroke: theme.bc3,
    },

    '&:hover': {
      backgroundColor: theme.bc3,

      '& $icon': {
        stroke: 'white',
      },
    },
  },

  buttonDelete: {
    border: `2px solid ${theme.negative}`,

    '& $icon': {
      stroke: theme.negative,
    },

    '&:hover': {
      backgroundColor: theme.negative,

      '& $icon': {
        stroke: 'white',
      },
    },
  },

  addContainer: {
    marginTop: '3.6rem',
  },

  addButton: {
    padding: '1.6rem 2.4rem',
    display: 'block',
    color: 'white',
    width: '100%',
    fontWeight: 800,
    backgroundColor: theme.bc3,
    fontSize: '1.4rem',
    borderRadius: '60px',
    textTransform: 'uppercase',
    boxShadow: `0px 0px 40px rgba(0, 0, 0, 0.20)`,
    textAlign: 'center',

    '@media screen and (min-width: 768px)': {
      textAlign: 'left',
      display: 'inline-block',
      width: 'auto',
    },

    '&:focus': { outline: 'none' },
  },

  emptyText: {
    fontSize: '20px',
    color: '#a7a7a7',
    marginBottom: '50px',
    display: 'block',
  },
})
export default styles
