import each from 'lodash/each'
import filter from 'lodash/filter'
import _moment_ from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(_moment_)

export const ONE_SECOND = 1000
const ONE_MINUTE = ONE_SECOND * 60
export const TOAST_TIMEOUT = ONE_SECOND * 3

export const getCurrentTimeDeliveryLength = (outlet, slot = null) => {
  const orderSlot = slot ? moment(slot) : moment()
  const todaysOpeningTimes = outlet.openingTimes.filter(openingTime => {
    const [startHour, startMinute] = openingTime.start.time.split(':')
    const [endHour, endMinute] = openingTime.end.time.split(':')

    const openingTimeMoment = moment()
      .isoWeekday(openingTime.start.day)
      .hour(startHour)
      .minute(startMinute)

    const closingTimeMoment = moment()
      .isoWeekday(openingTime.end.day)
      .hour(endHour)
      .minute(endMinute)

    //   fix moment creating days in this week, if open is after close means open was last week
    if (closingTimeMoment.isBefore(openingTimeMoment)) {
      closingTimeMoment.add(1, 'week')
    }
    return orderSlot.isBetween(openingTimeMoment, closingTimeMoment)
  })

  const currentDeliveryTime = todaysOpeningTimes.filter(openingTime => {
    const [startHour, startMinute] = openingTime.start.time.split(':')
    const [endHour, endMinute] = openingTime.end.time.split(':')

    const openingTimeMoment = moment()
      .isoWeekday(openingTime.start.day)
      .hour(parseInt(startHour))
      .minute(parseInt(startMinute))
      .startOf('minute')

    const closingTimeMoment = moment()
      .isoWeekday(openingTime.end.day)
      .hour(parseInt(endHour))
      .minute(parseInt(endMinute))
      .endOf('minute')

    if (closingTimeMoment.isBefore(openingTimeMoment)) {
      closingTimeMoment.add(1, 'week')
    }
    const openingTimeRange = moment.range(openingTimeMoment, closingTimeMoment)

    return orderSlot.within(openingTimeRange)
  })

  return currentDeliveryTime.length && currentDeliveryTime[0]?.timeSlot
    ? currentDeliveryTime[0].timeSlot
    : outlet.defaultDeliveryTime
    ? outlet.defaultDeliveryTime
    : 15
}

const roundDateUp = (date, roundUpToNextNMinutes) => {
  date = new Date(date)
  return new Date(
    date.valueOf() -
      // remove all minutes, seconds, and milliseconds
      (date.getMinutes() * ONE_MINUTE +
        date.getSeconds() * ONE_SECOND +
        date.getMilliseconds()) +
      // add on rounded minutes
      Math.ceil(date.getMinutes() / roundUpToNextNMinutes) *
        roundUpToNextNMinutes *
        ONE_MINUTE
  )
}

export const getPrepTime = outlet => {
  const prepTime = Number.isSafeInteger(outlet.defaultCollectionTime)
    ? outlet.defaultCollectionTime
    : 25

  return prepTime
}

const getAsapDeliveryTime = outlet => {
  const currentTimeDeliveryLengthInMinutes =
    getCurrentTimeDeliveryLength(outlet)
  // add prep time + 1 minute to ensure that the customer has at least 1 minute to place order before time expires
  const startMoment = moment().add(getPrepTime(outlet) + 1, 'minutes')
  const roundedStartDate = roundDateUp(startMoment.clone().toDate(), 5)
  const endMoment = moment(roundedStartDate)
    .add(currentTimeDeliveryLengthInMinutes, 'minutes')
    .toDate()
  return {
    start: roundedStartDate,
    end: endMoment,
  }
}

const getAsapCollectionTime = outlet => {
  const endMoment = moment().add(getPrepTime(outlet) + 1, 'minutes')

  return {
    start: null,
    end: roundDateUp(endMoment.toDate(), 5),
  }
}

/**
 *
 * @param {Object} args
 * @param {Object} args.outlet
 * @param {string} args.fulfilmentIdChosen
 * @returns {{ start: Date, end: Date }} next asap window for chosen fulfilment
 */
export const getAsap = ({ fulfilmentIdChosen, outlet }) => {
  if (
    !fulfilmentIdChosen ||
    fulfilmentIdChosen.toUpperCase() !== 'COLLECTION'
  ) {
    return getAsapDeliveryTime(outlet)
  }

  return getAsapCollectionTime(outlet)
}

export const formatOpenTimes = openingTimes => {
  const fullWeek = [1, 2, 3, 4, 5, 6, 7]
  let formattedTimes = []

  each(fullWeek, weekdayNumber => {
    const openingTimesForThisDay = filter(
      openingTimes,
      times => times.start.day.toString() === weekdayNumber.toString()
    )
    each(openingTimesForThisDay, day => {
      if (day.start.day === day.end.day) {
        const dayName = moment().day(weekdayNumber).format('dddd')
        const timesLbl = `${day.start.time} - ${day.end.time}`
        formattedTimes.push({ day: dayName, timeBracket: timesLbl })
      } else {
        const fromDateMoment = moment().isoWeekday(day.start.day)
        const toDateMoment = moment().isoWeekday(day.end.day)
        if (toDateMoment.isBefore(fromDateMoment)) {
          toDateMoment.add(1, 'week')
        }
        for (
          let dayIncrement = fromDateMoment;
          dayIncrement.isBefore(toDateMoment.clone().add(1, 'day'));
          dayIncrement.add(1, 'day')
        ) {
          const dayName = dayIncrement.format('dddd')

          const timesBracket = {
            day: dayName,
            timeBracket: `${
              day.start.day === dayIncrement.isoWeekday()
                ? day.start.time
                : '00:00'
            } - ${
              day.end.day === dayIncrement.isoWeekday() ? day.end.time : '24:00'
            }`,
          }
          formattedTimes.push(timesBracket)
        }
      }
    })
  })
  return formattedTimes.sort((a, b) => {
    if (a.day === b.day) {
      return a.timeBracket.slice(0, 2) - b.timeBracket.slice(0, 2)
    } else {
      return (
        moment(a.day, 'dddd').isoWeekday() - moment(b.day, 'dddd').isoWeekday()
      )
    }
  })
}
