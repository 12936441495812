import React, { useContext, useState, useEffect } from 'react'
import styles from './fulfilmentTimeSectionStyles'
import DateTimePopover from './DateTimePopover/DateTimePopover'
import cx from 'classnames'
import Modal from '@components/ModalWrapper/ModalWrapper'
import { OrderContext } from '@context/Order.context'
import { toast } from 'react-toastify'
import _moment_ from 'moment'
import { extendMoment } from 'moment-range'
import {
  getAsap as getAsapHelper,
  getCurrentTimeDeliveryLength,
  getPrepTime,
  ONE_SECOND,
} from '@utils/deliveryWindows'
import withStyles from 'react-jss'
import NoFulfilmentTimeTypesAvailable from './NoFulfilmentTimeTypesAvailable'
import ASAPTime from './ASAPTime'
import PreorderTime from './PreorderTime'
import { logger } from '@utils/logger'
import { OrderFrom } from './OrderFrom'

const moment = extendMoment(_moment_)

// this component creates an interval that checks if the current fulfilment time has expired
// fulfilment times can be up to 31 days in advance, but setInterval only accepts a max of 24.8 days
// as its limited to a 32 bit signed integer
const MAX_INTERVAL = Math.pow(2, 31) - 1

const FULFILMENT_OBJECTS_BY_NAME = {
  COLLECTION: {
    id: 'collection',
    label: 'Collection',
    available: true,
  },
  DELIVERY: {
    id: 'delivery',
    label: 'Delivery',
    available: true,
  },
  TABLE: {
    id: 'table',
    label: 'Table',
    available: true,
  },
}

const FULFILMENT_TIMES_QUERY_FIELD_BY_FULFILMENT_METHOD = {
  COLLECTION: 'collectionFulfilmentTimes',
  DELIVERY: 'deliveryFulfilmentTimes',
  NETWORK: 'deliveryFulfilmentTimes',
  TABLE: 'tableFulfilmentTimes',
}

// calculate the milliseconds until the fulfilment expires by:
// taking prep (and delivery if applicable) off of the fulfilment time, and diffing that from the current time
const getMSUntilCurrentFulfilmentTimeExpires = (
  endOfFulfilmentTime,
  outlet,
  fulfilmentMethod
) => {
  const fulfilmentTime =
    getPrepTime(outlet) +
    (fulfilmentMethod === 'DELIVERY' ? getCurrentTimeDeliveryLength(outlet) : 0)
  const msUntilExpires =
    moment(endOfFulfilmentTime)
      .subtract(fulfilmentTime, 'minutes')
      .add(1, 'minute')
      .valueOf() - new Date().valueOf()
  return msUntilExpires
}

const getEarliestDeliveryTime = outlet => {
  const earliestTime = moment(outlet.closedUntil).isAfter(
    moment().add(outlet.daysOfferedInAdvanceMin, 'days')
  )
    ? moment(outlet.closedUntil)
    : moment().add(outlet.daysOfferedInAdvanceMin, 'days').startOf('day')

  if (earliestTime.isoWeekday() === moment().isoWeekday()) {
    const addedPrep = moment().add(getPrepTime(outlet), 'minutes')

    return addedPrep
  } else {
    return earliestTime
  }
}

const getAvailableFulfilmentTimeBrackets = ({ outlet, fulfilmentMethod }) => {
  if (!outlet.isOnline) {
    return []
  }
  const allFulfilmentTimeBrackets = (
    outlet[
      FULFILMENT_TIMES_QUERY_FIELD_BY_FULFILMENT_METHOD[fulfilmentMethod]
    ] || []
  ).map(({ start, end, limit }) => {
    return {
      start: start ? new Date(start) : null,
      end: new Date(end),
      limit: limit,
    }
  })

  const earliestFulfilmentTime = getEarliestDeliveryTime(outlet)
  const latestFulfilmentTime = moment()
    .add(outlet.daysOfferedInAdvanceMax, 'days')
    .endOf('day')

  return allFulfilmentTimeBrackets.filter(fulfilmentTimeBracket => {
    const from =
      fulfilmentTimeBracket.start !== null
        ? fulfilmentTimeBracket.start
        : fulfilmentTimeBracket.end

    return (
      fulfilmentTimeBracket.limit !== 0 &&
      from >= earliestFulfilmentTime.valueOf() &&
      fulfilmentTimeBracket.end.valueOf() <= latestFulfilmentTime.valueOf()
    )
  })
}

const isPreorderTimeAvailable = ({
  outlet,
  fulfilmentMethod,
  selectedFulfilmentSlot,
  selectedFulfilmentBracket,
}) =>
  getAvailableFulfilmentTimeBrackets({
    outlet,
    fulfilmentMethod,
  }).some(fulfilmentTimeBracket => {
    return (
      fulfilmentTimeBracket.limit !== 0 &&
      fulfilmentTimeBracket.end.valueOf() ===
        selectedFulfilmentSlot.valueOf() &&
      (fulfilmentTimeBracket.start && fulfilmentTimeBracket.start.valueOf()) ===
        (selectedFulfilmentBracket.start &&
          selectedFulfilmentBracket.start.valueOf()) &&
      fulfilmentTimeBracket.end.valueOf() ===
        selectedFulfilmentBracket.end.valueOf()
    )
  })

const FulfilmentTimeSection = ({ classes, outlet }) => {
  const {
    attributes: {
      fulfilmentChosen = FULFILMENT_OBJECTS_BY_NAME[
        outlet.availableFulfillmentMethods[0]
      ],
      selectedDeliverySlot,
      selectedDeliveryWindow,
      asap,
    },
    updateOrder,
  } = useContext(OrderContext)

  const getASAPBracket = () =>
    getAsapHelper({ fulfilmentIdChosen: fulfilmentChosen.id, outlet })
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const canASAP = outlet.isOnline && outlet.isOpen && outlet.asapAllowed

  /**
   * This component should recalculate the values of asap, selectedDeliverySlot, and selectedDeliveryWindow whenever:
   * 1. the fulfilment method changes
   * 2. the values in state contradict or are not possible
   * 3. the outlet closes (ie the ASAP time would be later than the outlet's last available time)
   * 4. the current asap time / selected delivery slot/window expires
   * This useEffects handles (1) and (2)
   */
  useEffect(() => {
    // set new asap time
    setASAPBracket(getASAPBracket())

    // unavailable fulfilment method, reset all values to defaults
    if (
      !outlet.availableFulfillmentMethods.includes(
        fulfilmentChosen.id.toUpperCase()
      )
    ) {
      if (canASAP) {
        logger('unavailable fulfilment method, resetting to asap')
        updateOrder({
          fulfilmentChosen:
            FULFILMENT_OBJECTS_BY_NAME[outlet.availableFulfillmentMethods[0]],
          selectedDeliverySlot: null,
          selectedDeliveryWindow: null,
          asap: true,
        })
      } else {
        const [firstAvailableFulfilmentTimeBracket = null] =
          getAvailableFulfilmentTimeBrackets({
            outlet,
            fulfilmentMethod: outlet.availableFulfillmentMethods[0],
          })
        logger('unavailable fulfilment method, resetting to preorder', {
          firstAvailableFulfilmentTimeBracket,
        })
        updateOrder({
          fulfilmentChosen:
            FULFILMENT_OBJECTS_BY_NAME[outlet.availableFulfillmentMethods[0]],
          selectedDeliverySlot: firstAvailableFulfilmentTimeBracket
            ? firstAvailableFulfilmentTimeBracket.end
            : null,
          selectedDeliveryWindow: firstAvailableFulfilmentTimeBracket,
          asap: false,
        })
      }
    }

    // asap is true but preorder time is also set, reset to defaults
    if (asap && (selectedDeliverySlot || selectedDeliveryWindow)) {
      if (canASAP) {
        logger('asap true and preorder times set, resetting to asap')
        updateOrder({
          selectedDeliverySlot: null,
          selectedDeliveryWindow: null,
          asap: true,
        })
      } else {
        const [firstAvailableFulfilmentTimeBracket = null] =
          getAvailableFulfilmentTimeBrackets({
            outlet,
            fulfilmentMethod: fulfilmentChosen.id.toUpperCase(),
          })
        logger('asap true and preorder times set, resetting to preorder', {
          firstAvailableFulfilmentTimeBracket,
        })
        updateOrder({
          selectedDeliverySlot: firstAvailableFulfilmentTimeBracket
            ? firstAvailableFulfilmentTimeBracket.end
            : null,
          selectedDeliveryWindow: firstAvailableFulfilmentTimeBracket,
          asap: false,
        })
      }
    }

    // asap is currently set to true but is not available
    else if (asap && !canASAP) {
      const [firstAvailableFulfilmentTimeBracket = null] =
        getAvailableFulfilmentTimeBrackets({
          outlet,
          fulfilmentMethod: fulfilmentChosen.id.toUpperCase(),
        })
      logger('asap true but asap is unavailable, resetting to preorder', {
        firstAvailableFulfilmentTimeBracket,
      })
      updateOrder({
        selectedDeliverySlot: firstAvailableFulfilmentTimeBracket
          ? firstAvailableFulfilmentTimeBracket.end
          : null,
        selectedDeliveryWindow: firstAvailableFulfilmentTimeBracket,
        asap: false,
      })
    }

    // asap is false and preorder fulfilment time is not set or
    // current selected preorder fulfilment time bracket / slot is not available
    // set to ASAP if available, otherwise set to first available fulfilment time bracket
    if (
      !asap &&
      (!selectedDeliverySlot ||
        !selectedDeliveryWindow ||
        !isPreorderTimeAvailable({
          outlet,
          fulfilmentMethod: fulfilmentChosen.id.toUpperCase(),
          selectedFulfilmentSlot: selectedDeliverySlot,
          selectedFulfilmentBracket: selectedDeliveryWindow,
        }))
    ) {
      if (canASAP) {
        logger(
          'asap false and preorder unset or unavailable, resetting to asap'
        )
        updateOrder({
          selectedDeliverySlot: null,
          selectedDeliveryWindow: null,
          asap: true,
        })
      } else {
        const [firstAvailableFulfilmentTimeBracket] =
          getAvailableFulfilmentTimeBrackets({
            outlet,
            fulfilmentMethod: fulfilmentChosen.id.toUpperCase(),
          })
        logger(
          'asap false and preorder unset or unavailable, resetting to preorder',
          { firstAvailableFulfilmentTimeBracket }
        )
        // this if seems redundant, but if asap is not available there are no available fulfilment times, then we set
        // asap to false and the preorder times to null - without this if, updateOrder would be called again causing an infinite loop
        if (
          firstAvailableFulfilmentTimeBracket ||
          selectedDeliverySlot ||
          selectedDeliveryWindow
        ) {
          logger(
            'asap false and preorder unset or unavailable, resetting to preorder',
            { firstAvailableFulfilmentTimeBracket }
          )
          updateOrder({
            asap: false,
            selectedDeliverySlot: firstAvailableFulfilmentTimeBracket
              ? firstAvailableFulfilmentTimeBracket.end
              : null,
            selectedDeliveryWindow: firstAvailableFulfilmentTimeBracket,
          })
        } else {
          logger(
            'asap false and preorder unset, but no preorder times available. Leaving unset'
          )
        }
      }
    }

    // selectedDeliverySlot is randomly a number ¯\_(ツ)_/¯
    // it seems weird that we use the number to set the start of the window, whereas everywhere else in this file
    // we set selectedDeliverySlot to the end of the window, but that's how this was before, so i'm gonna roll with it
    if (selectedDeliverySlot && typeof selectedDeliverySlot === 'number') {
      const selectedFulfilmentSlot = new Date(selectedDeliverySlot)
      const selectedFulfilmentBracket = {
        start: new Date(selectedDeliverySlot),
        end: moment(new Date(selectedDeliverySlot))
          .add('minutes', getCurrentTimeDeliveryLength(outlet))
          .toDate(),
      }
      if (
        isPreorderTimeAvailable({
          outlet,
          fulfilmentMethod: fulfilmentChosen.id.toUpperCase(),
          selectedFulfilmentSlot,
          selectedFulfilmentBracket,
        })
      ) {
        logger(
          'selectedDeliverySlot is a number, resetting to preorder dates',
          {
            selectedDeliverySlot,
            selectedDeliveryWindow,
            selectedFulfilmentSlot,
            selectedFulfilmentBracket,
          }
        )
        updateOrder({
          asap: false,
          selectedDeliverySlot: selectedFulfilmentSlot,
          selectedDeliveryWindow: selectedFulfilmentBracket,
        })
      } else {
        const [firstAvailableFulfilmentTimeBracket = null] =
          getAvailableFulfilmentTimeBrackets({
            outlet,
            fulfilmentMethod: fulfilmentChosen.id.toUpperCase(),
          })
        if (firstAvailableFulfilmentTimeBracket) {
          logger(
            'selectedDeliverySlot is a number and unavailable, resetting to first preorder slot',
            {
              selectedDeliverySlot,
              selectedDeliveryWindow,
              firstAvailableFulfilmentTimeBracket,
            }
          )
          // set to first available fulfilment time bracket
          updateOrder({
            asap: false,
            selectedDeliverySlot: firstAvailableFulfilmentTimeBracket.end,
            selectedDeliveryWindow: firstAvailableFulfilmentTimeBracket,
          })
        } else if (canASAP) {
          logger(
            'selectedDeliverySlot is a number and unavailable, and no available preorder slots, resetting to asap',
            {
              selectedDeliverySlot,
              selectedDeliveryWindow,
              firstAvailableFulfilmentTimeBracket,
            }
          )
          // set to asap
          updateOrder({
            asap: true,
            selectedDeliverySlot: null,
            selectedDeliveryWindow: null,
          })
        } else {
          logger(
            'selectedDeliverySlot is a number and unavailable, and no available preorder slots, and asap is unavailable, resetting to preorder with no time set',
            {
              selectedDeliverySlot,
              selectedDeliveryWindow,
              firstAvailableFulfilmentTimeBracket,
            }
          )
          // set to preorder with no selected slot / bracket
          updateOrder({
            asap: false,
            selectedDeliverySlot: null,
            selectedDeliveryWindow: null,
          })
        }
      }
    }
  }, [fulfilmentChosen.id])

  const [asapBracket, setASAPBracket] = useState(getASAPBracket())
  const refreshASAPBracket = () => {
    const newASAPBracket = getASAPBracket()
    if (newASAPBracket.end.valueOf() > asapBracket.end.valueOf()) {
      logger(`Setting new ASAP time bracket`, {
        asapBracket,
        newASAPBracket,
        now: new Date().toISOString(),
        asap,
        selectedDeliverySlot,
        selectedDeliveryWindow,
      })
      if (asap) {
        toast.warn(
          `Unfortunately your ASAP order time is no longer available. The time has been updated to 
            ${moment(newASAPBracket.end).format('h:mma')}`,
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 11 * ONE_SECOND,
          }
        )
      }
      setASAPBracket(newASAPBracket)
    } else {
      logger(
        `Failed to set new ASAP time bracket as new bracket not after current bracket`,
        {
          asapBracket,
          newASAPBracket,
          now: new Date().toISOString(),
        }
      )
    }
  }

  // This useEffect handles (3) - asap time expired
  useEffect(() => {
    const msUntilExpiry = getMSUntilCurrentFulfilmentTimeExpires(
      asapBracket.end,
      outlet,
      fulfilmentChosen.id.toUpperCase()
    )
    logger(
      `ASAP time expires at ${new Date(
        new Date().valueOf() + msUntilExpiry
      ).toLocaleString()}`
    )
    const timer = setInterval(refreshASAPBracket, msUntilExpiry)

    return () => clearInterval(timer)
  }, [asapBracket.end.valueOf(), asap, fulfilmentChosen.id, outlet.id])

  // This useEffect handles (4) - preorder time expired
  useEffect(() => {
    const availableFulfilmentTimeBrackets = getAvailableFulfilmentTimeBrackets({
      outlet,
      fulfilmentMethod: fulfilmentChosen.id.toUpperCase(),
    })
    if (availableFulfilmentTimeBrackets.length && selectedDeliveryWindow) {
      const msUntilExpiry = getMSUntilCurrentFulfilmentTimeExpires(
        selectedDeliveryWindow.end,
        outlet,
        fulfilmentChosen.id.toUpperCase()
      )

      // if msUntilExpiry is greater than MAX_INTERVAL, don't bother setting any interval
      // as it will be too long to be useful
      if (msUntilExpiry > MAX_INTERVAL) {
        logger(
          `Preorder time expires at ${new Date(
            new Date().valueOf() + msUntilExpiry
          ).toLocaleString()}, but is greater than MAX_INTERVAL (${MAX_INTERVAL})`
        )
        return
      }

      logger(
        `Preorder time expires at ${new Date(
          new Date().valueOf() + msUntilExpiry
        ).toLocaleString()}`
      )

      const timer = setInterval(() => {
        const subsequentFulfilmentBracket =
          availableFulfilmentTimeBrackets.find(fulfilmentTimeBracket => {
            return (
              fulfilmentTimeBracket.end.valueOf() >
              selectedDeliveryWindow.end.valueOf()
            )
          })
        // set to subsequest fulfilment time bracket
        if (subsequentFulfilmentBracket) {
          logger(`Updating to next available preorder time`, {
            selectedDeliveryWindow,
            subsequentFulfilmentBracket,
            now: new Date().toISOString(),
          })
          updateOrder({
            selectedDeliverySlot: subsequentFulfilmentBracket.end,
            selectedDeliveryWindow: subsequentFulfilmentBracket,
          })
          toast.warn(
            `Unfortunately your preorder time is no longer available. The time has been updated to ${moment(
              subsequentFulfilmentBracket.end
            ).format('Do MMMM [at] h:mma')}`,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 11 * ONE_SECOND,
            }
          )
        } else {
          logger(
            `No preorder times available. Setting to preorder without a time set`,
            {
              selectedDeliveryWindow,
              subsequentFulfilmentBracket,
              now: new Date().toISOString(),
            }
          )
          updateOrder({
            selectedDeliverySlot: null,
            selectedDeliveryWindow: null,
          })
          toast.warn(
            `Unfortunately your preorder time is no longer available, and there are no other available times.`,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 11 * ONE_SECOND,
            }
          )
        }
      }, msUntilExpiry)
      return () => clearInterval(timer)
    }
  }, [
    selectedDeliveryWindow && selectedDeliveryWindow.end,
    fulfilmentChosen.id,
    outlet.id,
  ])

  const closeModal = () => {
    setDatePickerOpen(false)
  }

  const availableFulfilmentTimeBrackets = getAvailableFulfilmentTimeBrackets({
    outlet,
    fulfilmentMethod: fulfilmentChosen.id.toUpperCase(),
  })

  let fulfilmentTimeStatus
  // ordering asap
  if (asap && !selectedDeliverySlot) {
    fulfilmentTimeStatus = 'ASAP'
  }
  // ordering preorder
  else if (!asap && selectedDeliverySlot) {
    fulfilmentTimeStatus = 'PREORDER'
  }
  // neither asap or preorder are available
  else if (
    (!asap && outlet.allowPreorders && !outlet.isOpen) ||
    (!availableFulfilmentTimeBrackets.length && outlet.allowPreorders)
  ) {
    fulfilmentTimeStatus = 'ORDER_FROM'
  } else if (
    (!asap && !outlet.allowPreorders) ||
    !availableFulfilmentTimeBrackets.length
  ) {
    fulfilmentTimeStatus = 'UNAVAILABLE'
  }

  logger('FulfilmentTimeSection rerendered', {
    asap,
    selectedDeliverySlot,
    selectedDeliveryWindow,
    asapBracket,
  })

  return (
    <div>
      <div className="col-xs-12">
        <div
          className={cx(classes.chosenTimeLbl, {
            [classes.chosenTimeLblDisabled]:
              (!availableFulfilmentTimeBrackets ||
                !availableFulfilmentTimeBrackets.length) &&
              !canASAP,
          })}
          onClick={() =>
            ((availableFulfilmentTimeBrackets &&
              availableFulfilmentTimeBrackets.length) ||
              canASAP) &&
            setDatePickerOpen(true)
          }
        >
          <div className={classes.subLabel}>
            {fulfilmentTimeStatus === 'ASAP' && (
              <ASAPTime
                fulfilmentMethod={fulfilmentChosen}
                asapBracket={asapBracket}
                classes={classes}
              />
            )}
            {fulfilmentTimeStatus === 'PREORDER' && (
              <PreorderTime
                fulfilmentMethod={fulfilmentChosen}
                selectedDeliveryWindow={selectedDeliveryWindow}
                classes={classes}
              />
            )}
            {fulfilmentTimeStatus === 'ORDER_FROM' && (
              <OrderFrom
                classes={classes}
                nextOpeningTime={outlet.nextOpeningTime}
              />
            )}
            {fulfilmentTimeStatus === 'UNAVAILABLE' && (
              <NoFulfilmentTimeTypesAvailable classes={classes} />
            )}
          </div>
        </div>
      </div>

      <Modal open={datePickerOpen} close={closeModal}>
        <DateTimePopover
          fulfilmentTimeBrackets={availableFulfilmentTimeBrackets}
          setPreorderTime={preorderBracket => {
            logger('new preorder time chosen', preorderBracket)
            updateOrder({
              selectedDeliverySlot: preorderBracket.end,
              selectedDeliveryWindow: preorderBracket,
              asap: false,
            })
            closeModal()
          }}
          asapBracket={asapBracket}
          setToASAP={() => {
            logger('asap chosen')
            updateOrder({
              selectedDeliverySlot: null,
              selectedDeliveryWindow: null,
              asap: true,
            })
            closeModal()
          }}
          selectedPreorderTime={selectedDeliverySlot}
          fulfilmentChosen={fulfilmentChosen}
          outlet={outlet}
        />
      </Modal>
    </div>
  )
}

export default withStyles(styles)(FulfilmentTimeSection)
