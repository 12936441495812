import React, { Component } from 'react'
import Login from '@components/Login/Login'
import Modal from '@components/ModalWrapper/ModalWrapper'
import { Query } from 'react-apollo'
import { LOGIN_MODAL_OPEN } from '../Query/LocalStates'
import { client } from '@services/client'
import BodyLocker from '../../utils/BodyLocker'
import { withRouter } from 'react-router-dom'
import ls from '@utils/localStorage'

class LoginModal extends Component {
  state = {
    loggedIn: false,
  }

  loadLoginModal = () => {
    client.writeData({
      data: {
        loginOpen: true,
      },
    })
    BodyLocker.lock()
  }

  componentDidMount() {
    const { pathname } = this.props.location
    if (pathname === '/login') {
      const token = ls.get('jwt')
      if (token) {
        this.setState({
          loggedIn: true,
        })
      }
      this.loadLoginModal()
    }
  }

  render() {
    return (
      <Query query={LOGIN_MODAL_OPEN}>
        {({ data }) => {
          return (
            <Modal open={data.loginOpen} close={() => {}}>
              <Login loggedIn={this.state.loggedIn} {...this.props} />
            </Modal>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(LoginModal)
