import React from 'react'
import { Query as ApolloQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { get, omit, startCase } from 'lodash'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import FetchQuery from '../../FetchQuery/FetchQuery'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import { getMarketplace } from '@config/config'

/*
 * This component is called by the router to redirect old urls (eg barrow-takeaways/:outletId/restaurantName) to new urls (eg barrow-takeaways/:restaurantName/:outletId)
 * First it attenpts to get the restaurant data from the server
 *   - if that succeeds it renders the children, forwarding the router props along with the restaurant data as props to the children
 * If nothing comes back, if assumes the restaurantName and outletId are the wrong way round so swaps them and does a lightweight server call
 * to see if that returns a restaurant
 *   - if that succeeds, then it does a 301 redirect to the correct url.
 *   - if that fails then the it redirects back to the restaurant list
 *  */
const RestaurantOutletRedirect = routerArgs => {
  const {
    match: {
      params: { outletId, restaurant: restaurantName, deliveryZone },
    },
    children: child,
  } = routerArgs
  const showInactive =
    new URLSearchParams(routerArgs && routerArgs.location.search).get(
      'showInactive'
    ) === 'true'

  const { isListMode } = getMarketplace()

  return (
    // attempt to get restaurants using the outletId (taken from window.location in the router)
    <FetchQuery
      path={`outlet/${outletId}/restaurants${
        showInactive ? '?showInactive=' + showInactive : ''
      }`}
    >
      {({ loading, error, data: getRestaurantsResponse }) => {
        if (loading) {
          return <LoadingSpinner show />
        }

        // toast the error
        if (error) {
          if (typeof error !== 'string') {
            error = 'Error!'
          }
          return toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
          })
        }

        const restaurant = get(
          getRestaurantsResponse,
          'restaurants.restaurants[0]',
          {}
        )

        // if we don't have a restaurant so the restaurantName and outletId may be the wrong way round in the url, so attempt to use the restaurantName as the outletId
        if (!restaurant || !restaurant.id) {
          const CHECK_OUTLET_EXISTS = gql`
            query checkOutletExists($id: String!, $showInactive: Boolean) {
              outlet(id: $id, showInactive: $showInactive) {
                id
                restaurant {
                  id
                }
              }
            }
          `

          return (
            <ApolloQuery
              query={CHECK_OUTLET_EXISTS}
              variables={{
                id: restaurantName,
                showInactive: showInactive,
              }}
              context={{ version: 2 }}
            >
              {({ loading, error, data: checkOutletExistsResponse }) => {
                if (loading) {
                  return <LoadingSpinner show />
                }

                // toast the error
                if (error) {
                  if (typeof error !== 'string') {
                    error = `${startCase(
                      restaurantName
                    )} is currently unavailable`
                  }
                  toast.error(error, {
                    position: toast.POSITION.TOP_CENTER,
                  })
                }

                const restaurant = get(checkOutletExistsResponse, 'outlet')
                // neither parts of the url returned a restaurant from the server, so redirect to the restaurant list
                if (!restaurant) {
                  return (
                    <Redirect
                      to={
                        isListMode ? `/${deliveryZone}-takeaways` : '/takeaways'
                      }
                      status={301}
                    />
                  )
                }

                // the restaurant is actually the outletId and vice-versa, so swap them as a redirect
                return (
                  <Redirect
                    status={301}
                    to={`${
                      isListMode ? `/${deliveryZone}-takeaways` : ''
                    }/${outletId}/${restaurantName}/menu`}
                  />
                )
              }}
            </ApolloQuery>
          )
        }

        // we got a restaurant back, so render the children, passing all props down, along with the restaurant from the server, but without the children
        const propsWithoutChildren = omit(
          { ...routerArgs, restaurant },
          'children'
        )
        return child(propsWithoutChildren)
      }}
    </FetchQuery>
  )
}

export default RestaurantOutletRedirect
