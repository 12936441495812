import fontStyles from '@styles/sharedJSS/fonts'
import { TABLET_BREAKPOINT } from '@utils/breakpoints'

const styles = theme => ({
  takeawayLogo: {
    extend: 'cuisineImage',

    '& img': {
      marginTop: 0,
    },
  },

  appSection: {
    marginTop: '4rem',
    textAlign: 'center',

    '@media screen and (min-width: 768px)': {
      marginTop: '10rem',
      textAlign: 'left',
    },
  },

  subHeader: {
    ...fontStyles.h2,
    color: theme.bc3,
    fontSize: '2.8rem',
    marginBottom: '2.4rem',
    padding: '0 2.4rem',

    '@media screen and (min-width: 768px)': {
      padding: '0',
      fontSize: '3.6rem',
      marginBottom: '3.6rem',
    },
  },

  subStrapline: {
    color: theme.bc5,
    marginBottom: '3.6rem',

    fontWeight: 600,
    fontSize: '1.6rem',
    lineHeight: '2.4rem',

    '@media screen and (min-width: 1024px)': {
      fontSize: '2rem',
      lineHeight: '3rem',
    },
  },

  buttonWrapper: {
    display: 'flex',
    marginLeft: '-0.5rem',
    marginBottom: '4rem',
    padding: '0 4rem',
    flexFlow: 'column',

    '@media screen and (min-width: 768px)': {
      marginBottom: '0rem',
      padding: '0',
      flexFlow: 'row',
    },
  },

  playStore: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0',
    marginTop: '2rem',

    '@media screen and (min-width: 768px)': {
      marginLeft: '2rem',
      marginTop: '0',
      padding: '1.4rem 2rem',
    },

    '& svg': {
      display: 'inline-block',
      fill: 'currentColor',
      width: '2.4rem',
      height: '2.4rem',
      marginRight: '1.2rem',
    },

    '& span': {
      display: 'inline-block',
    },
  },

  appStore: {
    display: 'flex',
    alignItems: 'center',

    '@media screen and (min-width: 768px)': {
      padding: '1.4rem 2rem',
    },

    '& svg': {
      display: 'inline-block',
      fill: 'currentColor',
      width: '2.4rem',
      height: '2.4rem',
      marginRight: '1.2rem',
    },

    '& span': {
      display: 'inline-block',
    },
  },

  appImage: {
    height: '35rem',
    padding: '0 2.4rem',
    overflow: 'hidden',

    '@media screen and (min-width: 768px)': {
      padding: 0,
    },

    '@media screen and (min-width: 1200px)': {
      height: '50rem',
      padding: 0,
    },

    '& img': {
      width: '100%',
    },
  },

  newSection: {
    boxShadow: '0px -5rem 5rem rgba(158, 168, 183, 0.1)',
    position: 'relative',
    zIndex: 100,
    backgroundColor: 'white',

    paddingTop: '6rem',

    '@media screen and (min-width: 768px)': {
      padding: '6rem 4rem',
    },

    '@media screen and (min-width: 1200px)': {
      padding: '12rem 0 0 0',
    },
  },

  ownersSection: {
    padding: '6rem 2rem 0',
    textAlign: 'center',
    backgroundColor: 'white',

    '@media screen and (min-width: 768px)': {
      padding: '12rem 0 0 0',
      textAlign: 'left',
    },
  },

  ownersImage: {
    width: '100%',
  },

  ownersStrapline: {
    color: theme.bc5,
    marginBottom: '1.2rem',

    fontWeight: 600,
    fontSize: '1.6rem',
    lineHeight: '2.4rem',

    '@media screen and (min-width: 1024px)': {
      fontSize: '2rem',
      lineHeight: '3rem',
    },

    '&:last-child': {
      marginBottom: '3.6rem',
    },
  },

  cart: {
    [`@media screen and (min-width: ${TABLET_BREAKPOINT}px)`]: {
      display: 'none',
    },
  },
})

export default styles
