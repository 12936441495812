import React, { useContext } from 'react'
import cx from 'classnames'
import { withStyles } from 'react-jss'
import { toUpper } from 'lodash'
import { getFormattedDay } from '@utils/getAbbreviatedDay'
import { OrderContext } from '@root/context/Order.context'
import moment from 'moment'

const AvailableForOrderButton = ({ classes, className, children }) => (
  <button type="button" className={cx(classes.moreBtn, className)}>
    {children}
  </button>
)
const UnavailableForOrderButton = ({ classes, className }) => (
  <button type="button" className={cx(classes.moreBtn, className)}>
    VIEW MENU
  </button>
)

const MenuLinkButton = ({
  classes,
  isOpen,
  isOnline,
  className,
  preorderStatus,
  allowPreorders,
  closedUntil,
  daysOfferedInAdvanceMax,
}) => {
  const { attributes } = useContext(OrderContext)
  const isTable = attributes.fulfilmentChosen.id === 'table'
  const canBePreordered =
    !closedUntil ||
    moment(closedUntil).isBefore(
      moment().endOf('day').add(daysOfferedInAdvanceMax, 'days')
    )

  const isAvailableForOrder =
    isOnline &&
    (isOpen || (!isOpen && !isTable && allowPreorders && canBePreordered))

  const preorderText = toUpper(getFormattedDay(preorderStatus))

  return isAvailableForOrder ? (
    <AvailableForOrderButton classes={classes} className={className}>
      {isOpen ? 'ORDER NOW' : preorderText}
    </AvailableForOrderButton>
  ) : (
    <UnavailableForOrderButton classes={classes} className={className} />
  )
}

const styles = theme => ({
  moreBtn: {
    height: '30px',
    padding: '0 1.6rem',
    backgroundColor: theme.bc3,
    border: 'none',
    color: 'white',
    fontWeight: 700,
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    borderRadius: '2.4rem',
    transition: `all 0.2s ease-in-out`,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      backgroundColor: theme.bc6,
    },
  },

  preorderBtn: {
    extend: 'moreBtn',
    backgroundColor: 'transparent',
    border: `2px solid ${theme.bc3}`,
    color: theme.bc3,
    minWidth: '114px',
    '&:hover': {
      color: 'white',
      backgroundColor: theme.bc3,
    },
  },
})

export default withStyles(styles)(MenuLinkButton)
