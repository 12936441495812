import React, { Fragment } from 'react'
import cx from 'classnames'
import moment from 'moment'

const PreorderTime = ({
  classes,
  fulfilmentMethod,
  selectedDeliveryWindow,
}) => (
  <Fragment>
    <span className={classes.subLblTitle}>{fulfilmentMethod.label} Time:</span>
    <span className={cx(classes.displayedTime, classes.displayedTimePreOrder)}>
      Preorder for{' '}
      {moment(
        fulfilmentMethod.id.toUpperCase() === 'DELIVERY'
          ? selectedDeliveryWindow.start
          : selectedDeliveryWindow.end
      ).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'MMMM Do',
        sameElse: 'MMMM Do',
      })}{' '}
      {fulfilmentMethod.id.toUpperCase() === 'DELIVERY' && (
        <Fragment>
          <br />
          {moment(selectedDeliveryWindow.start).format('HH:mm')}-
        </Fragment>
      )}
      {moment(selectedDeliveryWindow.end).format('HH:mm')}
    </span>
    <span className={classes.change}>Change?</span>
  </Fragment>
)

export default PreorderTime
