import React from 'react'
import PropTypes from 'prop-types'
import { config } from '@config/config'

const AWSImage = ({ imageKey, edits, ...props }) => {
  const imageRequestJSON = JSON.stringify({
    bucket: process.env.AWS_S3_BUCKET,
    key: imageKey,
    edits,
  })

  const imageRequestBase64 = Buffer.from(imageRequestJSON).toString('base64')

  return <img {...props} src={`${config.apiUrl}/img/${imageRequestBase64}`} />
}

AWSImage.propTypes = {
  imageKey: PropTypes.string.isRequired,
  edits: PropTypes.object,
}

export default AWSImage
