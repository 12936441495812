import React from 'react'
import { string, shape, number, object } from 'prop-types'
import { penceToPounds } from '@utils/helpers'

const CartOption = ({ option, classes }) => {
  const { itemOption, cartPriceTag } = classes
  return (
    <span className={itemOption} key={option.id}>
      - {option.name}
      {!!option.price && (
        <span className={cartPriceTag}>
          + {`£${penceToPounds(option.price)}`}
        </span>
      )}
    </span>
  )
}

CartOption.propTypes = {
  option: shape({
    id: string,
    name: string,
    price: number,
  }).isRequired,
  classes: object,
}

export default CartOption
