// Imports
// ------
import React from 'react'
import injectSheet from 'react-jss'
import styles from '../GeneralContent/generalContentStyles'
import Header from '@components/Header/Header'
import Hero from '../GeneralContent/Hero/Hero'

import { getMarketplace } from '@config/config'
import GET_LEGAL from './queries/getLegal.query'
import Query from '@components/Query/Query'
import Content from '../GeneralContent/Content/Markdown'

// Component
// ------
const MiscEl = () => (
  <>
    <Header branded />
    <Hero title="Misc Policy" />
    <Query query={GET_LEGAL} variables={{ key: getMarketplace().key }}>
      {({ marketplace: { marketplace } }) => (
        <div className="wrapper">
          <Content>{marketplace.legalPolicy}</Content>
        </div>
      )}
    </Query>
  </>
)

const Misc = injectSheet(styles)(MiscEl)
export default Misc
