import React, { Fragment } from 'react'
import Hero from '@components/Hero/Hero'
import injectSheet from 'react-jss'
import cx from 'classnames'
import ReviewForm from '@components/Review/ReviewForm'

const styles = {
  bigColumn: {
    position: 'relative',
    top: '-8rem',
    background: 'white',
    boxShadow: '0px 0px 2rem rgba(0, 0, 0, 0.1)',
    borderRadius: '0.4rem 0.4rem 0 0',
    minHeight: '50rem',
    marginBottom: '0.5rem',

    '@media screen and (min-width: 768px)': {
      borderRadius: '0.4rem',
    },

    '@media screen and (min-width: 1024px)': {
      marginBottom: '6rem',
      borderRadius: '0.4rem 0.4rem 0 0',
    },
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}

const AddReviewUnauthenticated = ({ classes, match }) => {
  const { orderId, token } = match.params

  return (
    <Fragment>
      <Hero />
      <div className="wrapper">
        <div className="container-fluid">
          <div className={cx('row', classes.flexRow)}>
            <div className="col-xs-12 col-md-8 col-lg-offset-1 col-lg-9">
              <div className={classes.bigColumn}>
                <ReviewForm orderId={orderId} anonymous token={token} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default injectSheet(styles)(AddReviewUnauthenticated)
