import gql from 'graphql-tag'

const GET_FAQS = gql`
  query getFAQs($key: String!) {
    marketplace(key: $key) {
      marketplace {
        id
        faqs {
          id
          answer
          question
        }
      }
    }
  }
`

export default GET_FAQS
