import gql from 'graphql-tag'

const checkoutTotals = `
id
total
basketSubtotal
serviceCharge
fulfilmentCost
fulfilmentType
expiryAt
`

export const GET_TABLE_TOTALS = gql`
  query tableCheckoutTotals($outletId: String!, $basketSubtotal: Int!) {
    tableCheckoutTotals(outletId: $outletId, basketSubtotal: $basketSubtotal) {
      ${checkoutTotals}
    }
  }
`

export const GET_COLLECTION_TOTALS = gql`
  query collectionCheckoutTotals($outletId: String!, $basketSubtotal: Int!, $appliedDiscountId: String) {
    collectionCheckoutTotals(
      outletId: $outletId
      basketSubtotal: $basketSubtotal
      appliedDiscountId: $appliedDiscountId
    ) {
      ${checkoutTotals}
    }
  }
`

export const GET_ADDRESS_DELIVERY_TOTALS = gql`
  query deliveryToAddressCheckoutTotals(
    $outletId: String!
    $basketSubtotal: Int!
    $deliveryAddressId: String!
    $preorderFor: DateTime
    $appliedDiscountId: String
  ) {
    deliveryToAddressCheckoutTotals(
      outletId: $outletId
      basketSubtotal: $basketSubtotal
      deliveryAddressId: $deliveryAddress
      preorderFor: $preorderFor
      appliedDiscountId: $appliedDiscountId
    ) {
      ${checkoutTotals}
    }
  }
`
export const GET_POSTCODE_DELIVERY_TOTALS = gql`
  query deliveryToPostcodeCheckoutTotals(
    $outletId: String!
    $basketSubtotal: Int!
    $deliveryPostcode: String!
    $appliedDiscountId: String
  ) {
    deliveryToPostcodeCheckoutTotals(
      outletId: $outletId
      basketSubtotal: $basketSubtotal
      deliveryPostcode: $deliveryPostcode
      appliedDiscountId: $appliedDiscountId
    ) {
      ${checkoutTotals}
    }
  }
`

export const GET_ZONE_DELIVERY_TOTALS = gql`
  query deliveryToZoneCheckoutTotals(
    $outletId: String!
    $basketSubtotal: Int!
    $deliveryZoneId: String!
    $appliedDiscountId: String
  ) {
    deliveryToZoneCheckoutTotals(
      outletId: $outletId
      basketSubtotal: $basketSubtotal
      deliveryZoneId: $deliveryZoneId
      appliedDiscountId: $appliedDiscountId
    ) {
      ${checkoutTotals}
    }
  }
`
