import React from 'react'
import injectSheet from 'react-jss'
import colors from '@styles/sharedJSS/colors'
import fontStyles from '@styles/sharedJSS/fonts'
import Color from 'color'
import { Link } from 'react-router-dom'
import moment from 'moment'
import StarsRating from '@components/Stars/Stars'

const styles = {
  reviewsContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '200px',
    overflow: 'auto',
    padding: '2.4rem',
    '@media screen and (min-width: 1024px)': {
      padding: '4.8rem',
    },
  },
  title: {
    ...fontStyles.h3,
    color: colors.bc3,
    marginBottom: '3.6rem',
  },
  singleReviewContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2.4rem',
    marginBottom: '2.4rem',
    border: `1px solid ${Color(colors.bc5)
      .rgb()
      .alpha(0.2)
      .string()}`,
    borderRadius: '4px',
    overflow: 'hidden',
    color: Color(colors.neutral)
      .rgb()
      .string(),
    ...fontStyles.small,
  },
  starsContainer: {
    transform: 'scale(1.4)',
    transformOrigin: 'left',
    marginTop: '1.2rem',
  },
  outletTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: colors.bc3,
    marginBottom: '1.2rem',
    transition: 'all .25s ease-in-out',
  },
  starAndLabelContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}

const ReviewsList = ({ classes, reviews }) => {
  const {
    reviewsContainer,
    title,
    singleReviewContainer,
    starsContainer,
    outletTitle,
    starAndLabelContainer,
  } = classes

  return (
    <section className={reviewsContainer}>
      <div className={title}>My Reviews</div>
      {reviews.map(review => {
        const {
          order,
          foodQuality,
          restaurantService,
          deliveryTime,
          reviewText,
        } = review

        return (
          <Link
            key={review.id}
            className={singleReviewContainer}
            to={`/account/orders/${order.id}/review`}
          >
            <div>
              <div className={outletTitle}>{order.outlet.name}</div>
              <div>
                {moment(review.createdAt).format('DD/MM/YYYY [at] HH:mm')}
              </div>
            </div>
            <div className={starAndLabelContainer}>
              <label htmlFor="foodQuality">Food quality</label>
              <div
                name="foodQuality"
                id="foodQuality"
                className={starsContainer}
              >
                <StarsRating value={foodQuality} />
              </div>
            </div>
            <div className={starAndLabelContainer}>
              <label htmlFor="restaurantService">Restaurant service</label>
              <div
                id="restaurantService"
                name="restaurantService"
                className={starsContainer}
              >
                <StarsRating value={restaurantService} />
              </div>
            </div>
            <div className={starAndLabelContainer}>
              <label htmlFor="deliveryTime">Delivery time</label>
              <div
                id="deliveryTime"
                name="deliveryTime"
                className={starsContainer}
              >
                <StarsRating value={deliveryTime} />
              </div>
            </div>
          </Link>
        )
      })}
    </section>
  )
}

export default injectSheet(styles)(ReviewsList)
