// import { getMarketplace } from '@config/config'

class Colors {
  constructor() {
    this.colors = {
      bc1: '',
      bc2: '',
      bc3: '#2576a8', // Blue
      bc4: '#f7f6f6', // Grey
      bc5: '#2f2f2f', // Charcoal
      bc6: '',
      positive: '#48C47A',
      negative: '#EC615B', // Red
      warning: '#FEA32D', // Yellow - Orange
      neutral: '#848484', // Darker Grey
      lightBorder: '#e4e4e4',
      error: '#e4423a',
    }
  }
  get() {
    return this.colors
  }
  setColors(marketplace) {
    const { brandColor, brandSecondaryColor, brandTertiaryColor } = marketplace
    this.colors = {
      ...this.colors,
      bc1: brandTertiaryColor,
      bc2: brandSecondaryColor,
      bc3: brandColor,
      bc6: brandColor,
    }
  }
}

const colors = new Colors()
export default colors
