import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'react-jss'
import cx from 'classnames'
import { ReactSVG } from 'react-svg'
import Color from 'color'
import clockSVG from '@images/clock.svg'
import coinSVG from '@images/coins.svg'
import compassSVG from '@images/compass.svg'
import {
  getFulfilmentMethod,
  penceToPounds,
  kilometerToMiles,
} from '@utils/helpers'
import { extendMoment } from 'moment-range'
import _moment_ from 'moment-timezone'
import {
  getCurrentTimeDeliveryLength,
  getPrepTime,
} from '@utils/deliveryWindows'

const moment = extendMoment(_moment_)

const RestaurantStats = ({ classes, className, outlet }) => {
  const offersDelivery = getFulfilmentMethod(outlet.paymentMethods, 'delivery')
  let timeToShow = offersDelivery
    ? `${
        getPrepTime(outlet) +
        getCurrentTimeDeliveryLength(outlet, moment().format())
      } Mins`
    : `${getPrepTime(outlet)} Mins`

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.stat}>
        <ReactSVG src={clockSVG} className={classes.svgIcon} wrapper="span" />
        {outlet.isOpen && outlet.isOnline
          ? timeToShow
          : outlet.openingTimeString}
      </div>
      <div className={classes.stat}>
        <ReactSVG src={coinSVG} className={classes.svgIcon} wrapper="span" />
        {`£${penceToPounds(outlet.deliveryMinimumOrderValue)}`}
      </div>
      {typeof outlet.distanceFromUserKM === 'number' && (
        <div className={classes.stat}>
          <ReactSVG
            src={compassSVG}
            className={classes.svgIcon}
            wrapper="span"
          />
          {kilometerToMiles(outlet.distanceFromUserKM)}
          {' Miles'}
        </div>
      )}
    </div>
  )
}

RestaurantStats.propTypes = {
  outlet: PropTypes.shape({
    defaultDeliveryTime: PropTypes.number,
    defaultCollectionTime: PropTypes.number,
    isOnline: PropTypes.bool,
    isOpen: PropTypes.bool,
    paymentMethods: PropTypes.arrayOf(PropTypes.string),
    deliveryMinimumOrderValue: PropTypes.number,
    openingTimes: PropTypes.arrayOf(
      PropTypes.shape({
        start: PropTypes.shape({
          time: PropTypes.string,
          day: PropTypes.number,
        }),
        end: PropTypes.shape({
          time: PropTypes.string,
          day: PropTypes.number,
        }),
        key: PropTypes.string,
      })
    ),
  }),
}

const styles = theme => ({
  container: {
    lineHeight: '22px',
    fontSize: '1.2rem',
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    marginTop: '0',
    marginRight: '1rem',
    alignItems: 'flex-start',

    color: Color(theme.bc5).rgb().alpha(0.5).string(),
  },

  stat: {},

  svgIcon: {
    verticalAlign: 'middle',
    marginRight: '0.7rem',

    '& svg': {
      width: '1.4rem',
      height: '1.4rem',
      fill: Color(theme.bc5).rgb().alpha(0.5).string(),
    },
  },
})

export default withStyles(styles)(RestaurantStats)
