export default theme => ({
  status: {
    display: 'inline-block',
    textAlign: 'center',
    padding: '3px 7px',
    fontSize: '1rem',
    fontWeight: 700,
    borderRadius: '5px',
    lineHeight: '15px',
    textTransform: 'uppercase',
  },
  isOpen: {
    border: '1px solid #3ebb42',
    backgroundColor: '#3eba42',
    color: '#fff',
  },
  isClosed: {
    border: '1px solid #adadad',
    background: '#fff',
    color: '#adadad',
  },
  isPreorder: {
    borderColor: '#ff9800',
    color: '#ff9800',
  },
})
