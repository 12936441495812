import fontStyles from '@styles/sharedJSS/fonts'
import Color from 'color'
import button, { buttonWhite, buttonIcon } from '@styles/sharedJSS/buttons'

const sharedPadding = {
  padding: '2.4rem',
}

const styles = theme => ({
  wrapper: {
    position: 'relative',
    paddingTop: '4rem',
    paddingBottom: '8rem',

    '@media screen and (min-width: 600px)': {
      paddingTop: '12rem',
      paddingBottom: '16rem',
    },
  },

  title: {
    ...fontStyles.h1,
    color: 'white',
    padding: '1.4rem',
    fontSize: '4.2rem',

    '@media screen and (min-width: 1024px)': {
      fontSize: '5rem',
      padding: 0,
      marginBottom: '1rem',
    },
  },

  strapline: {
    ...sharedPadding,
    color: 'white',
    marginBottom: '2.4rem',
    fontWeight: 600,
    fontSize: '1.8rem',
    lineHeight: '2.4rem',
    '@media screen and (min-width: 1024px)': {
      fontSize: '3rem',
      lineHeight: '4rem',
      padding: 0,
    },
  },

  postcode: {
    margin: '0 auto',
    maxWidth: '300px',
  },

  cta: {
    '&:hover': {
      opacity: 0.8,
    },
  },
})

export default styles
