// Imports
// ------
import React, { useContext } from 'react'
import { withStyles } from 'react-jss'
import styles from './orderListStyles'
import { GET_ORDERS } from '../getOrders.query'
import { Query } from 'react-apollo'
import cx from 'classnames'
import _ from 'lodash'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import { penceToPounds, cleanOrderStatusForClient } from '@utils/helpers'
import config from '@config/config'
import moment from 'moment'
import { getMarketplace } from '@config/config'
import Button from '@components/Button/Button'
import { OrderContext, OrderDeliveryMethods } from '@context/Order.context'
import { BasketContext } from '@context/Basket.context'

const OrderListViewEl = ({ classes, history, match }) => {
  const { attributes, updateOrder } = useContext(OrderContext)
  const { clearItems, addItem } = useContext(BasketContext)

  const onNavigate = order => {
    history.push(`${match.path}/${order.id}`)
  }

  const singleRow = order => {
    const cleanOrderStatus = cleanOrderStatusForClient(order.orderStatus)
    const momentDate = moment(order.createdAt)

    const { isListMode, isPostcodeMode, isSingleMode } = getMarketplace()

    const onReorderClick = e => {
      e.stopPropagation()

      clearItems()
      order.orderItems.forEach(item => {
        if (!item.isAddOnItem) addItem(item)
      })
      updateOrder({
        outletId: order.outlet.id,
        restaurantName: order.outlet.restaurant.name,
        get address() {
          if (
            order.customerDeliveryAddress &&
            order.customerDeliveryAddress.id
          ) {
            return order.customerDeliveryAddress.id
          }
          return undefined
        },
        get deliveryDestination() {
          if (
            isPostcodeMode &&
            order.customerDeliveryAddress &&
            order.customerDeliveryAddress.postcode
          ) {
            return {
              type: 'POSTCODE',
              destination: order.customerDeliveryAddress.postcode,
            }
          }
          return { type: '', destination: '' }
        },
        get deliveryZone() {
          if (order.deliveryZone) {
            return {
              id: order.deliveryZone.id,
              name: order.deliveryZone.name,
            }
          }
          return undefined
        },
        get fulfilmentChosen() {
          switch (order.fulfillmentMethod) {
            case 'TABLE':
              return OrderDeliveryMethods.table
            case 'COLLECTION':
              return OrderDeliveryMethods.collection
            case 'DELIVERY':
            case 'NETWORK':
              return OrderDeliveryMethods.delivery
            default:
              // assign itself to current value
              return attributes.fulfilmentChosen
          }
        },
        table: order.tableSnapshot,
      })

      const deliveryZoneName = (() => {
        if (isListMode && order.deliveryZone) {
          return order.deliveryZone.name
        }
        // setting to undefined looks bad but the site handles this by not redirecting users to a dead url based on no location information
        // Not needed for table service orders either
        return 'undefined'
      })()

      const outletPath = `${
        isListMode ? `/${deliveryZoneName}-takeaways` : ''
      }/${order.outlet.restaurant.name}/${order.outlet.id}/menu`
        .replace(/ /g, '-')
        .toLowerCase()
      history.push(outletPath)
    }

    return (
      <section
        key={order.id}
        className={classes.restaurant}
        onClick={() => onNavigate(order)}
      >
        {/* Restaurant Logo */}
        {!isSingleMode && (
          <figure className={classes.logoJacket}>
            <img
              className={classes.logo}
              src={`${config.config.apiUrl}/${order.outlet.restaurant.image}`}
            />
          </figure>
        )}

        <main className={classes.restaurantDetails}>
          {!isSingleMode && (
            <h6 className={classes.restaurantName}>
              {order.outlet.restaurant.name}
            </h6>
          )}
          {/*marketplace.allowReviews && review && (
            <StarsRating value={averageRatings(review)} />
          )*/}
          <footer className={classes.dateOfOrder}>
            {momentDate.format('DD/MM/YYYY [at] HH:mm')}
          </footer>
        </main>

        {/* Order Information / Details */}
        <summary className={classes.orderDetails}>
          {/* Cost */}
          <div className={classes.orderDetailsTotal}>
            <div>Total</div>
            <main>£{penceToPounds(order.grossTotal)}</main>
          </div>

          {/* Number of Items Ordered */}
          <div className={classes.orderDetailsItems}>
            <div>Items</div>
            <main>{order.orderItems.length}</main>
          </div>
        </summary>

        {/* Order Status */}

        <div className={classes.statusSection}>
          <summary className={classes.orderStatus}>
            <span
              className={cx(classes.status, {
                [classes.statusRejected]:
                  cleanOrderStatus === 'AUTH FAILED' ||
                  cleanOrderStatus === 'REJECTED' ||
                  cleanOrderStatus === 'CANCELLED',
                [classes.statusComplete]: cleanOrderStatus === 'COMPLETE',
              })}
            >
              {cleanOrderStatus}
            </span>
          </summary>

          <Button
            appearance="positive"
            square
            small
            onClick={onReorderClick}
            className={classes.reOrder}
          >
            REORDER
          </Button>
        </div>
      </section>
    )
  }

  const { mainContainer, title, emptyText } = classes

  return (
    <div className={mainContainer}>
      <header className={title}>My Orders</header>
      <Query query={GET_ORDERS} fetchPolicy="network-only">
        {({ data, loading }) => {
          if (loading) return <LoadingSpinner show={true} />
          const { orders } = data.myOrders
          if (_.isEmpty(orders))
            return <span className={emptyText}>No orders placed yet</span>
          return orders.map(order => {
            return singleRow(order)
          })
        }}
      </Query>
    </div>
  )
}
const OrderListView = withStyles(styles)(OrderListViewEl)
export default OrderListView
