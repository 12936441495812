import React, { useState, createContext } from 'react'
import Modal from './Modal'

export const ModalContext = createContext({
  modalOpen: false,
  openModal: () => {},
  closeModal: () => {},
  modalContent: null,
  closeOnClickOutside: true,
})

const useModal = () => {
  const [modalOpen, setModal] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [closeOnClickOutside, setCloseOnClickOutside] = useState(true)

  const openModal = (content, customOptions) => {
    const options = { closeOnClickOutside: true, ...customOptions }
    setModal(true)
    setModalContent(content)
    setCloseOnClickOutside(options.closeOnClickOutside)
  }

  const closeModal = () => {
    setModal(false)
    setModalContent(null)
  }

  return { modalOpen, openModal, closeModal, modalContent, closeOnClickOutside }
}

export const ModalContextProvider = ({ children }) => {
  const {
    modalOpen,
    openModal,
    closeModal,
    modalContent,
    closeOnClickOutside,
  } = useModal()
  return (
    <ModalContext.Provider
      value={{
        modalOpen,
        openModal,
        closeModal,
        modalContent,
        closeOnClickOutside,
      }}
    >
      <Modal />
      {children}
    </ModalContext.Provider>
  )
}
