import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  LAPTOP_BREAKPOINT,
} from '@utils/helpers'

export const backLinkStyles = () => ({
  backLink: {
    transition: 'opacity.25s ease-in-out',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontWeight: 600,
    fontSize: '12px',

    '&:hover': {
      opacity: 0.5,

      '&:before': {
        opacity: 0.5,
      },
    },
  },

  backSvg: {
    color: '#fff',
    lineHeight: 1,
    paddingRight: '6px',
    position: 'relative',
    top: '2px',
  },
})

export const styles = theme => ({
  leftSidebar: {
    position: 'sticky',
    top: '-1px',
    transition: 'background-color 0.2s ease',

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      marginLeft: '2.4rem',
    },

    [`@media screen and (min-width: ${LAPTOP_BREAKPOINT}px)`]: {
      marginLeft: '0rem',
    },

    '& h2': {
      color: 'white',
      fontWeight: 700,
      fontSize: '20px',

      [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
        fontSize: '24px',
      },

      '& svg': {
        width: '11px',
        marginTop: '5px',
        marginLeft: '11px',
        position: 'absolute',
      },
    },

    '& h4': {
      color: theme.bc5,
      fontWeight: 600,
      fontSize: '12px',
      marginTop: '1rem',
      marginBottom: '1rem',

      [`@media screen and (min-width: ${TABLET_BREAKPOINT}px)`]: {
        marginTop: '4.8rem',
      },
    },

    '& svg': {
      fill: '#fff',
    },

    '& ul': {
      lineHeight: '37px',
    },
  },

  leftSideBarIsSticky: {
    paddingTop: '1px',
    backgroundColor: '#fff',

    '& svg': {
      fill: '#000',
    },

    '& h2, a, svg': {
      color: '#000',
    },

    '& $titleArea': {
      paddingTop: '1rem',
    },

    '&$noBackgroundOnStick': {
      paddingTop: '0 !important',
      background: 'transparent !important',
    },
  },

  noStick: {
    position: 'relative',
  },

  noBackgroundOnStick: {},

  titleArea: {
    lineHeight: '22px',
    padding: '0 1.2rem',

    '& h4': {
      color: 'white',
      fontSize: '12px',
      marginTop: '0rem',
      fontWeight: 500,
      marginBottom: '0.5rem',
      maxWidth: 'calc(100vw - 2rem)',
      margin: '0 auto 0.5rem auto',
    },

    [`@media screen and (min-width: ${MOBILE_BREAKPOINT}px)`]: {
      padding: 0,
    },
  },
})
