import React, { useState } from 'react'
import { withStyles } from 'react-jss'
import styles from './detailsStyles'
import { Mutation } from 'react-apollo'
import UPDATE_USER_DETAILS from './queries/updateUserDetails.mutation'
import { toast } from 'react-toastify'
import { TOAST_TIMEOUT } from '@utils/helpers'
import PhoneInput from 'react-phone-number-input/input'

const Details = ({ customer, classes }) => {
  const [customerDetailsForm, setCustomerDetailsForm] = useState({
    firstName: customer.firstName,
    lastName: customer.lastName,
    phoneNumber: customer.phoneNumber,
  })

  const updateCustomerDetailsForm = event => {
    const field = event.target.name
    const value = event.target.value
    setCustomerDetailsForm({ ...customerDetailsForm, [field]: value })
  }

  const submitForm = (e, updateDetails) => {
    e.preventDefault()
    const { firstName, lastName, phoneNumber } = customerDetailsForm
    const runUpdateDetails = updateDetails({
      variables: {
        firstName,
        lastName,
        phoneNumber,
      },
    })
    runUpdateDetails.then(() => {
      toast.success('Details Updated', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: TOAST_TIMEOUT,
      })
    })
    runUpdateDetails.catch(error => {
      error.graphQLErrors.map(err => {
        let message = 'Problem updating details'
        switch (err.code) {
          case 'CLIENT_ERROR':
          case 'AUTHENTICATION_ERROR':
            message = err.message
            break
          default:
            message =
              err.data && err.data.errors
                ? err.data.errors.join(', ')
                : 'Problem updating details'
            break
        }
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: TOAST_TIMEOUT,
        })
      })
    })
  }

  const {
    container,
    title,
    formContainer,
    form,
    field,
    label,
    input,
    saveFormButton,
    phoneNumberInputwrapper,
  } = classes

  return (
    <main className={container}>
      {/* Title */}
      <header className={title}>My Details</header>
      <Mutation mutation={UPDATE_USER_DETAILS}>
        {editMyDetails => (
          <section className={formContainer}>
            <form className={form}>
              <div className={field}>
                <label htmlFor="FirstName" className={label}>
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={customerDetailsForm.firstName}
                  className={input}
                  onChange={updateCustomerDetailsForm}
                />
              </div>

              <div className={field}>
                <label htmlFor="LastName" className={label}>
                  Last Name
                </label>
                <input
                  type="tel"
                  name="lastName"
                  value={customerDetailsForm.lastName}
                  className={input}
                  onChange={updateCustomerDetailsForm}
                />
              </div>

              <div className={field}>
                <label htmlFor="phoneNumber" className={label}>
                  Phone Number
                </label>
                <div className={phoneNumberInputwrapper}>
                  <PhoneInput
                    value={customerDetailsForm.phoneNumber}
                    name="phoneNumber"
                    country="GB"
                    className={classes.input}
                    onChange={val =>
                      setCustomerDetailsForm({
                        ...customerDetailsForm,
                        phoneNumber: val,
                      })
                    }
                  />
                </div>
              </div>
              <div className={field}>
                <button
                  type="submit"
                  className={saveFormButton}
                  onClick={e => submitForm(e, editMyDetails)}
                >
                  Update &amp; Save Details
                </button>
              </div>
            </form>
          </section>
        )}
      </Mutation>
    </main>
  )
}

export default withStyles(styles)(Details)
