import React, { useContext } from 'react'
import { Query } from 'react-apollo'
import { GET_OUTLET_TABLES } from './getTables.query'
import { OrderContext } from '@context/Order.context'
import get from 'lodash/get'
import Modal from '@components/ModalWrapper/ModalWrapper'
import { withStyles } from 'react-jss'
import styles from './tablePicker.styles'

const Tables = withStyles(styles)(
  ({ classes, tables, tablePickedCB, isOpen, close, open }) => {
    const { attributes, updateOrder } = useContext(OrderContext)
    const urlParams = new URLSearchParams(window.location.search)

    const assertChosenTable = () => {
      const fallback = 'Choose Your Table'
      if (!urlParams.get('table')) {
        if (attributes.table) return attributes.table.friendlyName
        return fallback
      }
      const tableObject = tables.find(
        table => table.id === urlParams.get('table')
      )
      if (tableObject) {
        if (tableObject !== attributes.table)
          updateOrder({ table: tableObject })
        return tableObject.friendlyName
      }
      return fallback
    }

    return (
      <div className={classes.container}>
        <h4 className={classes.title} onClick={open}>
          <div className={classes.titleLabel}>Bring to table: </div>
          <span className={classes.table}>{assertChosenTable()}</span>

          <span className={classes.change}>Change?</span>
        </h4>

        <Modal open={isOpen} close={close}>
          <>
            <h2 className={classes.modalTitle}>Choose your table</h2>
            {tables.map(table => (
              <div
                key={table.id}
                className={classes.tableChoice}
                onClick={() => {
                  if (
                    !attributes.table ||
                    (attributes.table && attributes.table.id !== table.id)
                  ) {
                    updateOrder({ table })
                    close()
                    if (tablePickedCB && typeof tablePickedCB === 'function') {
                      tablePickedCB()
                    }
                  }
                }}
              >
                {table.friendlyName}
              </div>
            ))}
          </>
        </Modal>
      </div>
    )
  }
)

const TablePicker = ({ outletId, ...rest }) => {
  return (
    <Query
      query={GET_OUTLET_TABLES}
      variables={{ outletId }}
      context={{ version: 2 }}
    >
      {({ data }) => {
        const tables = get(data, 'outlet.tables', [])
        return <Tables tables={tables} {...rest} />
      }}
    </Query>
  )
}

export default TablePicker
