import React from 'react'
import { Query } from 'react-apollo'
import { ReactSVG } from 'react-svg'
import { GET_USER } from '@components/UserAccount/Addresses/queries/getUserDetails.query'
import postcodeSVG from '@images/property-location.svg'
import nearMeSVG from '@images/gps.svg'
import savedAddress from '@images/fav-property.svg'

const FulfilmentDropdown = ({
  showDropdown,
  classes,
  setWhereType,
  WHERE_TYPES,
  handleDropdown,
  setPostcode,
  findLocation,
  isPopover,
  setSavedAddressCount,
}) => {
  const { COORDINATES, SAVED_ADDRESSES, POSTCODE } = WHERE_TYPES
  return showDropdown === 'COORDINATES' ? (
    <div
      className={
        isPopover ? classes.secondDropdownPopover : classes.secondDropdown
      }
    >
      <Query query={GET_USER} fetchPolicy="network-only">
        {({ loading, error = null, data }) => {
          if (loading || error) return null
          const { deliveryAddress } = data.myDetails.customer
          const uniquePostcodes = Array.from(
            new Set(deliveryAddress.map(({ postcode }) => postcode))
          )
          setSavedAddressCount(uniquePostcodes.length)

          return uniquePostcodes.map(postcode => (
            <button
              className={classes.dropdownDiv}
              onMouseDown={e => e.preventDefault()}
              onClick={() => {
                handleDropdown('COORDINATES')
                setWhereType(SAVED_ADDRESSES)
                setPostcode(`${postcode}`)
              }}
              key={postcode}
            >
              <ReactSVG src={savedAddress} className={classes.svg} />
              <p className={classes.title}>{postcode}</p>
            </button>
          ))
        }}
      </Query>
      <button
        className={classes.dropdownDiv}
        onClick={() => {
          setWhereType(COORDINATES)
          handleDropdown('COORDINATES')
          findLocation()
        }}
      >
        <ReactSVG
          src={nearMeSVG}
          className={classes.svg}
          style={{ paddingLeft: '14px' }}
        />
        <p className={classes.title}>Find near me</p>
      </button>
      <button
        className={classes.dropdownDivNoBorder}
        onMouseDown={e => e.preventDefault()}
        onClick={() => {
          handleDropdown('COORDINATES')
          setWhereType(POSTCODE)
          setPostcode('')
        }}
      >
        <ReactSVG src={postcodeSVG} className={classes.svg} />
        <p className={classes.title}>Search by postcode</p>
      </button>
    </div>
  ) : null
}

export default FulfilmentDropdown
