import React, { useRef, useState, useEffect, useContext } from 'react'
import { BrowserRouter as Router, withRouter } from 'react-router-dom'
import MainRoutes from './MainRoutes'
import FetchQuery from '@components/FetchQuery/FetchQuery'
import ServicesSuspended from '@components/SiteNotAvailable/ServicesSuspended'
import { client } from '@services/client'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import Footer from '../Footer/Footer'
import { ApolloProvider } from 'react-apollo'
import LoginModal from '../Login/LoginModal'
import RegisterModal from '../Register/RegisterModal'
import ForgottenPasswordModal from '../ResetPassword/ForgottenPasswordModal'
import { ThemeProvider } from 'react-jss'
import colors from '../../styles/sharedJSS/colors'
import { setMarketplace } from '@config/config'
import HelmetWrapper from '../Helmet/Helmet'
import ReactGA from 'react-ga'
import { GTMContextProvider, GTMContext } from '@context/GTM.context'
import { OrderContextProvider } from '@context/Order.context'
import { BasketContextProvider } from '@context/Basket.context'
import { DeliveryCostToAddressContextProvider } from '@context/DeliveryCostToAddress.context'
import { ModalContextProvider } from '@context/Modal.context'
import DirtyBasketAlert from '@components/DirtyBasketAlert/DirtyBasketAlert'
import ls from '@utils/localStorage'
import ServerError from '@components/SiteNotAvailable/ServerError'
import { VoucherContextProvider } from '@context/Voucher.context'
import { DiscountContextProvider } from '@context/Discount.context'

const SiteWrapper = props => (
  <div className={'pageWrapper'}>
    <div className={'heightFix'}>{props.children}</div>
    <DirtyBasketAlert />
    <Footer />
    {props.marketplace.featureLogin && <LoginModal />}
    {props.marketplace.featureRegister && <RegisterModal />}
    <ForgottenPasswordModal />
    <ToastContainer
      newestOnTop
      hideProgressBar
      autoClose={4000}
      draggable={false}
    />
  </div>
)

const RouteMonitor = withRouter(({ children, location }) => {
  const gtm = useContext(GTMContext)
  const previousPathname = useRef(null)

  const scrollToTop = () => window.scrollto && window.scrollto(0, 0)

  const firePathNameEvent = () => {
    gtm.pushDataToGTM({
      pathName: location.pathname + location.search,
      event: 'pathName',
    })
  }

  const checkForReturningCustomer = () => {
    const userToken = ls.get('jwt')
    if (userToken) {
      gtm.pushDataToGTM({
        event: 'returning_customer',
      })
    }
  }

  useEffect(() => {
    gtm.pushDataToGTM({ landingPathName: location.pathname + location.search })
    checkForReturningCustomer()
  }, [])

  useEffect(() => {
    if (location.pathname !== previousPathname.current) {
      previousPathname.current = location.pathname
      firePathNameEvent()
      // old react ga way keep for now

      scrollToTop()
    }
  }, [location])

  return children
})

const App = ({ marketplace }) => {
  const gtm = useContext(GTMContext)
  const googleAnalyticsID = marketplace ? marketplace.trackingId : null

  useEffect(() => {
    gtm.setGoogleAnalyticsID(googleAnalyticsID)
  }, [googleAnalyticsID])

  return (
    <Router>
      <RouteMonitor>
        {/* provide the new theme after we get the market place */}
        <ThemeProvider theme={colors.get()}>
          {/* Greedy provider needs theme and router context above it */}
          <ModalContextProvider>
            <SiteWrapper marketplace={marketplace}>
              <HelmetWrapper marketplace={marketplace} />
              <MainRoutes marketplace={marketplace} />
            </SiteWrapper>
          </ModalContextProvider>
        </ThemeProvider>
      </RouteMonitor>
    </Router>
  )
}

const MarketplaceLoader = () => {
  const [error, setError] = useState('')

  return error === 'marketplace' ? (
    <ServicesSuspended />
  ) : error === 'server' ? (
    <ServerError />
  ) : (
    <FetchQuery
      path={`marketplace/cname/${window.location.hostname}`}
      onCompleted={({ marketplace: { marketplace } }) => {
        if (!marketplace) {
          setError('marketplace')
          return
        }
        setMarketplace(marketplace)
        ReactGA.initialize(marketplace.trackingId)
        colors.setColors(marketplace)
      }}
      handleLoading
      onError={() => {
        setError('server')
      }}
    >
      {({ data }) => {
        const {
          marketplace: { marketplace },
        } = data

        return <App marketplace={marketplace} />
      }}
    </FetchQuery>
  )
}

const Providers = () => (
  <ApolloProvider client={client}>
    <GTMContextProvider>
      <OrderContextProvider>
        <DiscountContextProvider>
          <VoucherContextProvider>
            <BasketContextProvider>
              <DeliveryCostToAddressContextProvider>
                {/* provide default theme to have colors before we load the marketplace */}
                <ThemeProvider theme={colors.get()}>
                  <MarketplaceLoader />
                </ThemeProvider>
              </DeliveryCostToAddressContextProvider>
            </BasketContextProvider>
          </VoucherContextProvider>
        </DiscountContextProvider>
      </OrderContextProvider>
    </GTMContextProvider>
  </ApolloProvider>
)

export default Providers
