import fontStyles from '@styles/sharedJSS/fonts'

const styles = theme => ({
  container: {
    padding: '3.6rem',
  },

  titleJacket: {
    position: 'relative',
    display: 'block',
    marginBottom: '2.4rem',

    '@media screen and (min-width: 375px)': {
      marginBottom: '3.6rem',
    },
  },

  title: {
    ...fontStyles.h2,
    color: theme.bc3,
  },

  close: {
    ...fontStyles.small,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
    padding: '0',
    background: 'none',
    border: 'none',
    color: theme.bc5,
    transition: 'color .25s ease-in-out',
    cursor: 'pointer',

    '&:hover': {
      color: theme.bc3,
    },
  },

  field: {
    position: 'relative',
    display: 'block',
    marginBottom: '3.6rem',

    '&:last-child': {
      marginBottom: 0,
    },

    '&:nth-child(3)': {
      marginBottom: '2.4rem',
    },
  },

  input: {
    ...fontStyles.small,
    backgroundColor: 'rgba(000,000,000,0.05)',
    boxShadow: 'none',
    padding: '0 1.2rem',
    border: 'none',
    width: '100%',
    height: '4.5rem',
    borderRadius: '4px',
    color: theme.bc5,

    '&:focus': {
      outline: 'none',
    },
  },

  label: {
    ...fontStyles.small,
    display: 'block',
    marginBottom: '0.2rem',
  },
  loginMessage: {
    borderBottom: '1px dashed #ccc',
    marginBottom: '14px',
    paddingBottom: '11px',

    '& strong': {
      color: 'white',
      backgroundColor: 'red',
      padding: '1px 5px',
      borderRadius: '3px',
      fontWeight: 700,
      fontSize: '11px',
    },
    '& span': {
      display: 'block',
      fontSize: '12px',
      lineHeight: '16px',
      paddingTop: '7px',
      fontWeight: 500,
      color: '#656565',
    },
  },

  submit: {
    fontSize: '2rem',
    padding: '20px',
    fontWeight: 600,
    textTransform: 'initial',
  },

  forgotPassword: {
    display: 'block',
    marginBottom: '2.4rem',
    textAlign: 'center',

    '& a': {
      ...fontStyles.small,
      color: theme.bc3,
    },
  },

  inlineLink: {
    color: theme.bc3,
    fontWeight: 600,
    textSecoration: 'underline',
  },

  actionButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '25px',
    '@media screen and (min-width: 768px)': {
      justifyContent: 'space-evenly',
    },
  },
  centeredTitle: {
    textAlign: 'center',
  },
  actionButton: {
    padding: '1.5rem',
    fontSize: '16px',
    textAlign: 'center',
    marginBottom: '15px',
    borderRadius: '50px',
    fontWeight: 600,
    opacity: 0.8,
    width: '100%',
    transition: `opacity 0.2s ease`,

    '&:hover ': {
      opacity: 1,
    },
  },

  goToBtn: {
    extend: 'actionButton',
    backgroundColor: theme.bc1,
    color: '#fff',
    width: '100%',
  },
})

export default styles
