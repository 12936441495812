import gql from 'graphql-tag'

export const GET_FEATURED_OUTLETS = gql`
  query getFeaturedOutlets($marketplaceId: String) {
    featuredOutlets(marketplaceId: $marketplaceId) {
      id
      name
      isOpen
      isOnline
      openingTimes
      paymentMethods
      deliveryMinimumOrderValue
      defaultDeliveryTime
      defaultCollectionTime
      preorderStatus
      daysOfferedInAdvanceMax
      allowPreorders
      asapAllowed
      tableFulfilmentTimes: fulfilmentTimes(fulfilmentMethod: TABLE) {
        start
        end
      }
      collectionFulfilmentTimes: fulfilmentTimes(fulfilmentMethod: COLLECTION) {
        start
        end
      }
      restaurant {
        id
        image
        name
        cuisines {
          id
          name
        }
      }
    }
  }
`
