import React, { Component } from 'react'
import { withStyles } from 'react-jss'
import { bool, object } from 'prop-types'
import cx from 'classnames'
import styles from './headerStyles'
import { Link } from 'react-router-dom'
import { client } from '@services/client'
import ls from '@utils/localStorage'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import BodyLocker from '../../utils/BodyLocker'
import Modal from '@components/ModalWrapper/ModalWrapper'
import DeliveryZonesModal from '@components/DeliveryZones/DeliveryZonesModal'
import { getMarketplace, AWS_CDN_URL } from '@config/config'
import BasketIcon from '../basketIcon/basketIcon'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoggedIn: false,
      isNavOpen: false,
      headerBackground: false,
      basketCount: 0,
      deliveryZonesModalOpen: false,
    }
    this.loadLoginModal = this.loadLoginModal.bind(this)
  }

  setLoginState = () => {
    const token = ls.get('jwt')
    if (token) {
      this.setState({
        isLoggedIn: true,
      })
    }
  }

  logInOutSuccess = () => {
    const token = ls.get('jwt')
    const message = token ? 'Logged In' : 'Logged Out'
    toast(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    })
    this.setLoginState()
  }

  handleScroll = () => {
    const lockedAt = BodyLocker.getCurrentLockPos()
    const { headerBackground } = this.state
    let scrollTop = window.scrollY
    if (!lockedAt) {
      if (scrollTop > 5 && !headerBackground) {
        this.setState({
          headerBackground: true,
        })
      } else if (scrollTop <= 5 && headerBackground) {
        this.setState({
          headerBackground: false,
        })
      }
    }
  }

  componentDidMount() {
    ls.pushListener({
      key: 'jwt',
      update: this.logInOutSuccess,
    })
    this.setLoginState()
    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
  }

  componentWillUnmount() {
    ls.popListener('jwt')
    ls.pushListener('basket')
    window.removeEventListener('scroll', this.handleScroll)
  }

  loadLoginModal = e => {
    if (e) e.preventDefault()
    client.writeData({
      data: {
        loginOpen: true,
      },
    })
    BodyLocker.lock()
  }

  loadRegisterModal = e => {
    e.preventDefault()
    client.writeData({
      data: {
        registerOpen: true,
      },
    })

    BodyLocker.lock()
  }

  openCartView = e => {
    e.preventDefault()
    client.writeData({
      data: {
        cartOpen: true,
      },
    })
  }

  toggleNavOpen = () => {
    this.setState({ isNavOpen: !this.state.isNavOpen })

    if (!this.state.isNavOpen) {
      BodyLocker.lock()
    } else {
      BodyLocker.unlock()
    }
  }

  openDeliveryZonesModal = e => {
    e.preventDefault()
    this.setState({
      deliveryZonesModalOpen: true,
    })
  }

  closeDeliveryZoneModal = () => {
    this.setState({
      deliveryZonesModalOpen: false,
      isNavOpen: false,
    })
  }

  render() {
    const { branded } = this.props
    const { isLoggedIn, headerBackground, deliveryZonesModalOpen } = this.state

    const extraHeaderClass = headerBackground
      ? this.props.classes.headerBackground
      : ''

    // Classes
    const {
      header,
      brandedHeader,
      logoContainer,
      logo,
      navLoggedIn,
      navToggle,
      navToggleIsOpen,
      navMenuOpen,
      nav,
      navList,
      navListItem,
      loginRegisterContainer,
      myAccount,
      login,
      register,
      basketIcon,
    } = this.props.classes

    const {
      name: marketplaceName,
      logoImage,
      featureRegister,
      featureLogin,
      featureFAQ,
      featureOrdering,
      brandColor,
      orderMode,
      headerLinks,
    } = getMarketplace()

    // If a user is logged in
    const headerClass = isLoggedIn ? cx(nav, navLoggedIn) : nav

    const isSingleMode = orderMode === 'SINGLE'
    const isPostcodeMode = orderMode === 'POSTCODE'
    // IF the nav is open
    const navToggleClasses = this.state.isNavOpen
      ? cx(navToggle, navToggleIsOpen)
      : navToggle
    const NavMenuClasses = this.state.isNavOpen
      ? cx(headerClass, navMenuOpen)
      : headerClass

    return (
      <header
        className={cx(
          'wrapper',
          header,
          { [brandedHeader]: branded },
          extraHeaderClass
        )}
        style={{
          backgroundColor: headerBackground ? brandColor : 'transparent',
        }}
      >
        {/* Logo */}
        <Link onClick={BodyLocker.unlock} to={'/'} className={logo}>
          <img
            src={`${AWS_CDN_URL}${logoImage}`}
            alt={`${marketplaceName} - logo`}
          />
        </Link>

        {/* Nav */}
        <nav className={NavMenuClasses}>
          {/* Main Navigation */}
          <ul className={navList}>
            {featureOrdering && !isSingleMode && !isPostcodeMode && (
              <li className={navListItem}>
                <a onClick={this.openDeliveryZonesModal} href="#_">
                  Order Now
                </a>
              </li>
            )}
            {featureOrdering && featureFAQ && (
              <li className={navListItem}>
                <Link onClick={BodyLocker.unlock} to="/help/">
                  Help
                </Link>
              </li>
            )}

            {headerLinks
              ? headerLinks.map(link => (
                  <li className={navListItem}>
                    <a
                      href={link.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.title}
                    </a>
                  </li>
                ))
              : null}

            {
              // If User is logged in
              isLoggedIn && (
                <li className={cx(navListItem, myAccount)}>
                  <Link onClick={BodyLocker.unlock} to="/account/orders">
                    My Account
                  </Link>
                </li>
              )
            }

            {
              // If User is not logged in
              !isLoggedIn && featureRegister && featureLogin && (
                <div className={loginRegisterContainer}>
                  <button onClick={this.loadLoginModal} className={login}>
                    Login
                  </button>
                  <button onClick={this.loadRegisterModal} className={register}>
                    Register
                  </button>
                </div>
              )
            }
          </ul>
        </nav>

        <div className={basketIcon}>
          <BasketIcon />
        </div>

        <button
          type="button"
          className={navToggleClasses}
          onClick={this.toggleNavOpen}
        >
          <span />
          <span />
          <span />
        </button>

        {/* Select a delivery Zone Modal */}
        <Modal
          open={deliveryZonesModalOpen}
          close={this.closeDeliveryZoneModal}
        >
          <DeliveryZonesModal
            close={this.closeDeliveryZoneModal}
            cuisine={null}
          />
        </Modal>
      </header>
    )
  }
}

Header.propTypes = {
  classes: object,
  branded: bool,
}

export default withStyles(styles)(Header)
