import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './modalWrapperStyles'
import { CSSTransition } from 'react-transition-group'
import ReactDOM from 'react-dom'
import cx from 'classnames'

const wrapper = document.getElementById('modal-root')

class ModalEl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open,
    }
    this.closeLogic = this.closeLogic.bind(this)
    this.el = document.createElement('div')
  }

  componentWillMount() {
    wrapper.appendChild(this.el)
  }

  componentWillUnmount() {
    wrapper.removeChild(this.el)
  }

  closeLogic = e => {
    if (e.target.id === 'overlay') {
      return this.props.close()
    }
  }

  openLogic = () => {
    this.setState({ open: true })
  }

  render() {
    const { children, classes, open, small } = this.props
    return ReactDOM.createPortal(
      <CSSTransition
        in={open}
        timeout={300}
        classNames={{
          enter: classes.modalEnter,
          enterActive: classes.modalEnterActive,
          enterDone: classes.modalEnterDone,
          exit: classes.modalExit,
          exitActive: classes.modalExitActive,
          exitDone: classes.modalExitDone,
        }}
        unmountOnExit
      >
        <div
          className={classes.modalOverlay}
          id="overlay"
          onClick={e => {
            this.closeLogic(e)
          }}
        >
          <div
            className={cx(classes.modalView, {
              [classes.modalViewSmall]: small,
            })}
          >
            {children}
          </div>
        </div>
      </CSSTransition>,
      this.el
    )
  }
}

const Modal = injectSheet(styles)(ModalEl)

export default Modal
