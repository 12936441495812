const styles = {
  h1: {
    fontSize: '3.2rem',
    fontWeight: 800,
  },

  h2: {
    fontSize: '2.2rem',
    '@media screen and (min-width: 768px)': {
      fontSize: '3.2rem',
    },
  },

  h3: {
    fontSize: '2rem',
    fontWeight: 800,
  },

  h4: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: 1.4,

    '@media screen and (min-width: 768px)': {
      fontSize: '2.2rem',
    },
  },

  p: {
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: '3.2rem',
  },

  small: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: '2rem',
  },

  xsmall: {
    fontSize: '1.2rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
  },
}

export default styles
