import { SMALL_MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from '@utils/breakpoints'
import fontStyles from '@styles/sharedJSS/fonts'

const styles = theme => ({
  cart: {
    minHeight: '200px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    boxShadow: `0px 0px 20px rgba(0, 0, 0, 0.1)`,
    margin: `0`,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9900,
    top: 0,
    display: 'none',
    maxHeight: '100vh',

    '& > *': {
      flexShrink: 0,
    },

    [`@media screen and (min-width: ${TABLET_BREAKPOINT}px)`]: {
      zIndex: 'initial',
      margin: `0`,
      backgroundColor: '#fff',
      position: 'sticky',
      bottom: 'auto',
      left: 'auto',
      right: 'auto',
      top: 0,
      display: 'flex',
      flexFlow: 'column',
    },
  },

  openState: {
    display: 'flex',
    flexFlow: 'column',
    '& > div': {
      [`@media screen and (max-width: ${TABLET_BREAKPOINT}px)`]: {
        backgroundColor: '#fff',
      },
    },
  },

  cartHeader: {
    padding: '14px 15px 13px',
    position: 'relative',
  },
  closeLink: {
    display: 'block',

    top: '20px',
    right: '20px',
    position: 'absolute',
    zIndex: 100,
    '& svg': {
      height: '20px',
      width: '20px',
      fill: '#6f8fa2',
    },

    [`@media screen and (min-width: ${TABLET_BREAKPOINT}px)`]: {
      display: 'none',
    },
  },
  cartTitle: {
    color: '#444444',
    fontSize: '15px',
    fontWeight: 600,
  },

  allergyLink: {
    fontSize: '1.2rem',
    background: 'none',
    paddingTop: '5px',
    display: 'block',
    color: '#65889c',
    fontWeight: 500,
    border: 'none',
    paddingLeft: 0,
    cursor: 'pointer',

    '&:hover': {
      color: theme.bc3,
    },

    '&:focus': {
      outline: 0,
    },
  },

  cartItems: {
    height: 'auto',
    minHeight: '67px',
    padding: '0 15px 0 15px',
    lineHeight: '17px',
    flexGrow: 1,
    flexShrink: 1,
  },

  cartItem: {
    margin: '10px 0px',
    position: 'relative',
  },

  cartItemTitle: {
    color: '#444444',
    fontSize: '14px',
    fontWeight: 600,
    position: 'relative',
    padding: '0 6rem 0 0',
    display: 'block',
    marginBottom: '2px',

    [`@media screen and (min-width: ${TABLET_BREAKPOINT}px)`]: {
      marginBottom: 0,
    },
  },

  itemPriceTag: {
    position: 'absolute',
    right: 0,
    fontSize: '14px',
    fontWeight: 600,
    display: 'block',
    textAlign: 'right',
    color: '#444444',
  },

  itemOption: {
    display: 'block',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#797979',
    marginTop: '0px',
    marginLeft: '12px',
  },

  removeItemIcon: {
    padding: '5px',
    marginLeft: '-5px',
    transition: `all 0.2s ease`,

    '& svg': {
      width: '15px',
      height: '15px',
      marginBottom: '-2px',
      fill: '#e46565',
      [`@media screen and (min-width: ${TABLET_BREAKPOINT}px)`]: {
        height: '10px',
        width: '10px',
        marginBottom: 0,
      },
    },

    '&:hover': {
      transform: 'scale(1.3)',
    },
  },

  checkoutBtn: {
    ...fontStyles.p,
    padding: '15px',
    fontWeight: 600,
    textTransform: 'initial',
  },

  menuBtn: {
    ...fontStyles.p,
    color: '#fff',
    backgroundColor: theme.bc3,
    display: 'block',
    textAlign: 'center',
    padding: '15px',
    fontWeight: 600,
    transition: `all 0.2s ease`,
  },

  disabledButton: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  inactiveButton: {
    opacity: 0.5,
    backgroundColor: theme.neutral,
    '&:hover': {
      backgroundColor: theme.neutral,
      opacity: 0.5,
    },
  },
  orderNotesBox: {
    padding: '15px',
  },

  cartFooter: {
    width: '100%',
    bottom: 0,

    [`@media screen and (min-width: ${TABLET_BREAKPOINT}px)`]: {
      position: 'relative',
      height: 'auto',
      backgroundColor: 'none',
    },
  },
  noItemsMessage: {
    fontSize: '12px',
    textAlign: 'center',
    padding: '25px 15px 25px 15px',
    color: '#7f9bac',
    fontWeight: 600,
    fontStyle: 'italic',
  },

  orderNotesTextArea: {
    width: '100%',
    border: '1px solid #d5e0e4',
    padding: '5px 6px',
    minHeight: '45px',
    borderRadius: '2px',

    '&:focus': {
      outline: 0,
      border: `1px solid ${theme.bc3}`,
    },
  },
  clearBasketPopover: {
    width: '240px',
    right: '100px',
    bottom: '-15px',
    zIndex: 10,
    position: 'absolute',
    boxShadow: '0 0 55px #00000085',
    borderRadius: '10px',
    backgroundColor: ' #fff',
    padding: '15px 15px 10px 15px',
    '&:after': {
      right: '-7px',
      width: '14px',
      height: '14px',
      top: '50%',
      marginTop: '-7px',
      content: "''",
      position: 'absolute',
      backgroundColor: '#fff',
      transform: 'rotate(45deg)',
    },

    '@media screen and (max-width: 768px)': {
      top: '54px',
      bottom: 'auto',
      width: 'auto',
      left: '12px',
      right: '12px',

      '&:after': {
        top: '0px',
        right: '92px',
      },
    },
  },
  popoverButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '7px',
  },
  popoverButton: {
    display: 'block',
    padding: '8px',
    fontSize: '16px',
    color: '#9e9f9e',
    fontWeight: 600,
    marginLeft: '10px',
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
  clearBtn: {
    color: theme.bc1,
  },
  popoverTitle: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  clearLink: {
    position: 'absolute',
    top: '19px',
    margin: 0,
    right: '70px',
    color: theme.negative,
    display: 'inline-block',
    border: `1px solid ${theme.negative}`,
    borderRadius: '70px',
    padding: '4px 8px',
    fontSize: '9px',
    fontWeight: 700,
    lineHeight: '12px',

    '&:hover': {
      backgroundColor: theme.negative,
      color: '#fff',
      '& svg': {
        fill: '#fff',
      },
    },

    '& svg': {
      fill: theme.negative,
      width: '10px',
      height: '11px',
      float: 'left',
      marginRight: '4px',
    },

    [`@media screen and (min-width: ${TABLET_BREAKPOINT}px)`]: {
      position: 'absolute',
      right: '8px',
      top: '14px',
      transform: 'scale(0.9)',
    },
  },

  minValueWarning: {
    height: '30px',
    backgroundColor: `${theme.negative}!important;`,
  },
  minWarningLabel: {
    textAlign: 'center',
    color: '#fff',
    fontWeight: 600,
    fontSize: '11px',
    paddingTop: '10px',
  },

  chosenFulfilmentRow: {
    color: theme.positive,
    padding: '10px 15px 10px',
    fontSize: '12px',
    borderTop: '1px dotted #d0dbe1',
    fontWeight: 600,
    borderBottom: '1px dotted #d0dbe1',
  },

  actions: {
    [`@media screen and (min-width: ${SMALL_MOBILE_BREAKPOINT}px)`]: {
      display: 'flex',

      '& a': {
        flexGrow: 1,
        flexBasis: 0,
      },
    },
  },

  noDeliveryMethodsLbl: {
    padding: '10px 15px',
    backgroundColor: '#fcf0ef',
    color: 'red',
    fontSize: '9px',
    fontWeight: 600,
  },
})

export default styles
