import React from 'react'
import { ReactSVG } from 'react-svg'
import starSVG from '@images/star.svg'
import { withStyles } from 'react-jss'
import cx from 'classnames'
import ls from '@utils/localStorage'

const DealsRow = ({
  classes,
  collectionDeal,
  deliveryDeal,
  tableDeal,
  openAndOnline,
}) => {
  const fulfilment = ls.get('order').fulfilmentChosen.id

  if (openAndOnline) {
    collectionDeal && fulfilment === 'collection' ? (
      <div className={cx('visible-xs', classes.dealsRow)}>
        <span className={classes.dealText}>
          <ReactSVG src={starSVG} className={classes.svgIcon} wrapper="span" />
          {collectionDeal}
        </span>
      </div>
    ) : deliveryDeal && fulfilment === 'delivery' ? (
      <div className={cx('visible-xs', classes.dealsRow)}>
        <span className={classes.dealText}>
          <ReactSVG src={starSVG} className={classes.svgIcon} wrapper="span" />
          {deliveryDeal}
        </span>
      </div>
    ) : tableDeal && fulfilment === 'table' ? (
      <div className={cx('visible-xs', classes.dealsRow)}>
        <span className={classes.dealText}>
          <ReactSVG src={starSVG} className={classes.svgIcon} wrapper="span" />
          {tableDeal}
        </span>
      </div>
    ) : null
  }
  return null
}

const styles = {
  dealsRow: {
    height: '28px',
    border: '1px dashed #ffe594',
    paddingLeft: '10px',
    marginBottom: '12px',
    backgroundColor: '#fdf9ef',
    borderRadius: '4px',
    margin: '10px 10px 5px 10px',

    '@media screen and (min-width: 1200px)': {
      margin: '-12px -12px 12px -12px',
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
    },
  },

  dealText: {
    color: '#eab300',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '26px',
    paddingLeft: '8px',

    '& svg': {
      fill: '#ffd13e',
      height: '19px',
      width: '18px',
      float: 'left',
      marginTop: '3px',
      marginLeft: '-4px',
    },

    '@media screen and (min-width: 1200px)': {
      lineHeight: '27px',
      marginLeft: '-8px',
      '& svg': {
        width: '25px',
        height: '25px',
        marginTop: '-13px',
        marginLeft: '-21px',
      },
    },
  },
}

export default withStyles(styles)(DealsRow)
