// Imports
// ------
import React from 'react'
import { withStyles } from 'react-jss'
import styles from './addressStyles'
import cx from 'classnames'
import {
  GET_USER,
  ARCHIVE_USER_ADDRESS,
  SET_DEFAULT_USER_ADDRESS,
} from './queries/getUserDetails.query'
import { Query, Mutation } from 'react-apollo'
import { ReactSVG } from 'react-svg'
import makeDefaultSVG from '@images/makeDefault.svg'
import deleteAddressSVG from '@images/deleteAddress.svg'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import { toast } from 'react-toastify'
import AddAddressPopover from '../../Checkout/AddressSection/AddAddressPopover/AddAddressPopover'
import Modal from '@components/ModalWrapper/ModalWrapper'
import _ from 'lodash'

// Component
// ------
class AddressesEl extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addAddressModalOpen: false,
      editAddressModalOpen: false,
      editingAddress: null,
    }
  }
  openAddModal = (e) => {
    e.preventDefault()
    this.setState({
      addAddressModalOpen: true,
    })
  }

  getAction = (e, addressAction, variables, id, refetch) => {
    e.preventDefault()

    if (
      id == 'delete' &&
      !confirm('Are you are you want to delete this address?')
    )
      return false

    const mutation = addressAction({
      variables: variables,
    })
    mutation
      .then(() => {
        const successMessage =
          id == 'delete' ? 'Address Deleted' : 'Default Address Updated'
        toast.success(successMessage, {
          position: toast.POSITION.TOP_CENTER,
        })
        refetch()
      })
      .catch((error) => {
        error.graphQLErrors.map((err) => {
          const message =
            err.code === 'CLIENT_ERROR'
              ? err.message
              : err.data.errors.join(', ')
          toast.error(message, {
            position: toast.POSITION.TOP_CENTER,
          })
        })
        refetch()
      })
  }

  getMutation = (id, address) => {
    let mutationVars = {}
    if (id === 'delete') {
      mutationVars = {
        q: ARCHIVE_USER_ADDRESS,
        v: { id: address.id },
      }
    }
    if (id === 'primary') {
      mutationVars = {
        q: SET_DEFAULT_USER_ADDRESS,
        v: { id: address.id },
      }
    }
    if (id === 'edit') {
      mutationVars = {
        q: ARCHIVE_USER_ADDRESS,
        v: { id: address.id },
      }
    }

    return mutationVars
  }

  getButton = (address, refetch, iconLink, buttonclass, id) => {
    const { listItemButton, icon } = this.props.classes
    const getMutation = this.getMutation(id, address)
    return (
      <Mutation mutation={getMutation.q}>
        {(addressAction) => (
          <button
            type="button"
            onClick={(e) =>
              this.getAction(
                e,
                addressAction,
                getMutation.v,
                id,
                refetch,
                address
              )
            }
            className={cx(listItemButton, buttonclass)}
          >
            <ReactSVG src={iconLink} className={icon} wrapper="span" />
          </button>
        )}
      </Mutation>
    )
  }

  refetch = (choice, refetchIt) => {
    refetchIt()
  }

  closeModal = () => {
    this.setState({
      addAddressModalOpen: false,
      editAddressModalOpen: false,
      editingAddress: null,
    })
  }

  render() {
    const {
      jacket,
      title,
      list,
      listItem,
      listItemContent,
      listItemHeading,
      listItemPrimary,
      listItemRest,
      listItemInteractions,
      buttonPrimary,
      buttonDelete,
      addContainer,
      addButton,
      emptyText,
    } = this.props.classes

    const { addAddressModalOpen } = this.state

    return (
      <Query query={GET_USER} fetchPolicy="network-only">
        {({ loading, error, data, refetch }) => {
          if (loading) return <LoadingSpinner show={true} />
          if (error) return `Error! ${error.message}`
          const { deliveryAddress } = data.myDetails.customer

          return (
            <section className={jacket}>
              <header className={title}>My Address Book</header>

              {_.isEmpty(deliveryAddress) ? (
                <span className={emptyText}>No addresses added yet</span>
              ) : (
                <ul className={list}>
                  {deliveryAddress.map((address) => {
                    const {
                      firstLine,
                      secondLine,
                      city,
                      postcode,
                      id,
                    } = address
                    const isDefault = address.default
                    return (
                      <li className={listItem} key={id}>
                        {/* Textual Content - Left Side */}
                        <div className={listItemContent}>
                          {isDefault && (
                            <em className={listItemPrimary}>DEFAULT</em>
                          )}
                          <h4 className={listItemHeading}>
                            {firstLine} {secondLine}
                          </h4>
                          <p className={listItemRest}>
                            {city}, {postcode}
                          </p>
                        </div>

                        {/* Interactional Content - Right Side */}
                        <div className={listItemInteractions}>
                          {!isDefault &&
                            this.getButton(
                              address,
                              refetch,
                              makeDefaultSVG,
                              buttonPrimary,
                              'primary'
                            )}
                          {/* { this.getButton(address, refetch, editAddressSVG, buttonEdit, 'edit')} */}
                          {this.getButton(
                            address,
                            refetch,
                            deleteAddressSVG,
                            buttonDelete,
                            'delete'
                          )}
                        </div>
                      </li>
                    )
                  })}
                </ul>
              )}

              <div className={addContainer}>
                <button className={addButton} onClick={this.openAddModal}>
                  Add New Address
                </button>
              </div>

              {/* REVIVE THIS IN V2
                 <Modal open={editAddressModalOpen} close={this.closeModal}>
                  <AddAddressPopover
                    close={this.closeModal}
                    refetch={choice => this.refetch(choice, refetch)}
                    existingAddress={editingAddress}
                  />
                </Modal> */}

              <Modal open={addAddressModalOpen} close={this.closeModal}>
                <AddAddressPopover
                  close={this.closeModal}
                  refetch={(choice) => this.refetch(choice, refetch)}
                />
              </Modal>
            </section>
          )
        }}
      </Query>
    )
  }
}

const Addresses = withStyles(styles)(AddressesEl)
export default Addresses
