import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { withClientState } from 'apollo-link-state'
import ls from '@utils/localStorage'
import gql from 'graphql-tag'
import fetch from 'unfetch'
import { config } from '@config/config'

const cache = new InMemoryCache()

const stateLink = withClientState({
  cache,
  resolvers: {},
})

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    )
  }
  return forward(operation)
})

function omitTypename(key, value) {
  return key === '__typename' ? undefined : value
}

// middleware to set auth header
const authMiddleware = new ApolloLink((operation, forward) => {
  const userToken = ls.get('jwt')
  if (userToken) {
    const { headers = {} } = operation.getContext()
    operation.setContext({
      ...headers,
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    })
  }
  return forward(operation)
})

// middleware to set custom headers
const customHeadersMiddleware = new ApolloLink((operation, forward) => {
  const { headers = {} } = operation.getContext()
  operation.setContext({
    headers: {
      ...headers,
      'Full-Referrer': window && window.location && window.location.href,
    },
  })
  return forward(operation)
})

const persistentHeaders = {
  'Site-Entry': window && window.location && window.location.href,
}

const uriDevFormatting =
  url =>
  ({ operationName, variables }) => {
    if (process.env.NODE_ENV === 'development') {
      return `${url}?op=${operationName}&vars=${Object.values(variables).join(
        ','
      )}`
    }
    return url
  }

const httpLink = createHttpLink({
  uri: uriDevFormatting(`${config.apiUrl}/gql`),
  fetch,
  headers: persistentHeaders,
})

const httpLinkV2 = createHttpLink({
  uri: uriDevFormatting(`${config.apiUrlV2}/gqlv2`),
  fetch,
  headers: persistentHeaders,
})

const splitLink = ApolloLink.split(
  operation => {
    const context = operation.getContext()
    const version = context.version
    return version === 2
  },
  httpLinkV2,
  httpLink
)

const link = ApolloLink.from([
  omitTypenameLink,
  authMiddleware,
  customHeadersMiddleware,
  stateLink,
  splitLink,
])

const client = new ApolloClient({
  link,
  cache,
  resolvers: {},
  name: 'customer-web',
})

export { client, gql }
