import fontStyles from '@styles/sharedJSS/fonts'
import Color from 'color'

const styles = theme => ({
  modalContainer: {
    position: 'relative',
    paddingBottom: '2.4rem',
  },

  titleArea: {
    padding: '2.4rem 2.4rem 0',
    marginBottom: '2.4rem',
    position: 'relative',
    lineHeight: '2rem',
    textAlign: 'center',

    '& h2': {
      color: theme.bc5,
      fontSize: '1.2rem',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
    },
  },

  inner: {},

  button: {
    ...fontStyles.p,
    color: theme.bc3,

    backgroundColor: Color(theme.bc3)
      .rgb()
      .alpha(0)
      .string(),

    '&:hover': {
      transition: 'all .25s ease-in-out',
      color: theme.bc3,
      cursor: 'pointer',
      backgroundColor: Color(theme.bc3)
        .rgb()
        .alpha(0.1)
        .string(),
    },

    '& a': {
      display: 'block',
      padding: '1.2rem 2.4rem',
      color: theme.bc3,
      textAlign: 'center',
    },
  },
})

export default styles
